import {
  ADD_ALL_CINEMAS,
  FETCH_ALL_CINEMAS,
  SET_SELECTED_CINEMA,
  ADVERTISE_PDF_DATA,
} from './cinemasTypes';

let initialState = {
  advertiseData: [],
  allCinemas: [],
  cinemasLoading: false,
  advertiseLoading: false,
  selectedCinema: null,
};

const cinemasReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_CINEMA:
      return { ...state, selectedCinema: action.payload };
    case ADD_ALL_CINEMAS:
      return {
        ...state,
        allCinemas: action.payload,
        cinemasLoading: false,
      };
    case FETCH_ALL_CINEMAS:
      return {
        ...state,
        allCinemas: [],
        cinemasLoading: true,
      };
    case ADVERTISE_PDF_DATA:
      return {
        ...state,
        advertiseData: action.payload,
        advertiseLoading: false,
      };
    default:
      return state;
  }
};

export default cinemasReducer;
