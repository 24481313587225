import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import Dropdown from 'react-bootstrap/Dropdown';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import useIsTablet from '@src/customHooks/useIsTablet';
import InlineScroll from '@components/partials/InlineScroll';
// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const OpeningHours = ({ t }) => {
  const isMobile = useIsMobile();

  const [show, setShow] = useState('');

  const handleshowhide = (event) => {
    const getCinemaelectedValue = event.target.value;
    console.log(getCinemaelectedValue, 'ffffffffff');
    setShow(getCinemaelectedValue);
  };
  const isTablet = useIsTablet();
  /* let openings = [
    {
      openingsFor: [
        'Opening Hours',
      ],
      hours: [
        'Monday to Thursday - 11:30-21:00',
        'Friday and Saturday - 11:30-23:00',
        'Sunday - 13:00-21:00',
      ]
    },
    {
      openingsFor: [
        'Opening Hours for',
        'Screening'
      ],
      hours: [
        'Monday to Thursday - 09:00-21:00',
        'Friday - 09:00-01:30',
        'Saturday - 10:30-01:30',
        'Sunday - 12:00-21:00',
      ]
    }
  ] */

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('More')} >`}
          secondHalf={t('Gift & Vouchers')}
        />
        <CenteredTitle firstHalf={t('Gift & Vouchers')} secondHalf={''} />
      </div>

      <div className="container-fluid opening_hours">
        <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
          {t(
            'QFX Cinemas provides Ticket Vouchers facilities to our individual customers and corporate clients. These Ticket Vouchers are prepaid and can be redeemed at QFX location of your choice. We have different options for Ticket Vouchers. Please refer to the below price lists.',
          )}
        </h4>

        <h4
          className="static_page_highlight_custom mb-4 mb-xl-5 text-center color-black"
          style={{ textDecoration: 'underline', textUnderlinePosition: 'under' }}
        >
          {t(' Movie Ticket Voucher Price List')}
        </h4>

        {/* {<!---QFX Cinemas - Civil, Labim, Chhaya & Bhaktapur--->} */}

        <section
          className=""
          style={{
            borderBottom: '1px solid var(--light-1)',
            paddingBottom: '30px',
          }}
        >
          <article className="col-md-12">
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas - Civil, Labim, Chhaya & Bhaktapur
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 480 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only </td>
                <td className="color-black">Rs. 410 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D/3D </td>
                <td className="color-black">Rs. 400 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 330 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <div className="mt-3">
              <h4 className="static_page_highlight_custom mb-4 text-primary">
                Discount :
              </h4>
              <table className="ticket_table">
                <tr>
                  <td>16 – 49 vouchers</td>
                  <td>15%</td>
                </tr>
                <tr>
                  <td>50 and above</td>
                  <td>20%</td>
                </tr>
              </table>

              <h4 className="static_page_highlight_custom mt-4 text-primary">
                Notes
              </h4>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Minimum 10 vouchers must be
                issued.
              </p>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Movie vouchers are not valid
                for Sofa Seats & Recliner Seats.
              </p>
            </div>
          </article>
        </section>

        {/* {<!---Durbar Cinemax – managed by QFX--->} */}

        <section
          className="mt-5"
          style={{
            borderBottom: '1px solid var(--light-1)',
            paddingBottom: '30px',
          }}
        >
          <article className="col-md-12">
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  Durbar Cinemax – managed by QFX
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 1400 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only </td>
                <td className="color-black">Rs. 1200 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D/3D </td>
                <td className="color-black">Rs. 1200 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 1000 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <div className="mt-3">
              <h4 className="static_page_highlight_custom mb-4 text-primary">
                Discount :
              </h4>
              <table className="ticket_table">
                <tr>
                  <td>20 – 99 vouchers</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>100 and above</td>
                  <td>10%</td>
                </tr>
              </table>

              <h4 className="static_page_highlight_custom mt-4 text-primary">
                Notes
              </h4>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Minimum 10 vouchers must be
                issued.
              </p>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Vouchers issued for Durbar
                Cinemax (unless specified) can be redeemed at all other inside
                valley locations.
              </p>
            </div>
          </article>
        </section>

        {/* {<!---QFX Cinemas Jalma, Narayangarh--->} */}

        <section
          className="mt-5"
          style={{
            borderBottom: '1px solid var(--light-1)',
            paddingBottom: '30px',
          }}
        >
          <article className="col-md-12">
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas Jalma, Narayangarh
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 420 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black"> Weekdays 2D/3D </td>
                <td className="color-black">Rs. 370 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only </td>
                <td className="color-black">Rs. 350 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 300 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <table style={{ width: '100%' }} className="ticket_table mt-4">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas Bageshwori, Nepalgunj
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 370 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black"> Weekdays 2D/3D </td>
                <td className="color-black">Rs. 320 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only</td>
                <td className="color-black">Rs. 300 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 250 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <table style={{ width: '100%' }} className="ticket_table mt-4">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas One Stop Mall, Birtamode
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 320 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black"> Weekdays 2D/3D </td>
                <td className="color-black">Rs. 320 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black"> Weekdays/Weekends 2D only </td>
                <td className="color-black">Rs. 250 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 250 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <table style={{ width: '100%' }} className="ticket_table mt-4">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas Bhatbhateni Butwal and Bhatbhateni Biratnagar
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 370 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D/3D </td>
                <td className="color-black">Rs. 320 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only</td>
                <td className="color-black">Rs. 300 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 250 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>

            <table style={{ width: '100%' }} className="ticket_table mt-4">
              <tr>
                {' '}
                <td colSpan={3} className="text-center dark_1_bg text-white">
                  QFX Cinemas Apex Mall, Damauli
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Option</td>
                <td className="text-primary font-weight-bold">Rate</td>
                <td className="text-primary font-weight-bold">Remarks</td>
              </tr>

              <tr>
                <td className="color-black">
                  All Option (Weekdays/ Weekends/ 2D/3D){' '}
                </td>
                <td className="color-black">Rs. 320 </td>
                <td className="color-black">
                  Voucher can be used for all kind of movie (2D/3D) and valid
                  throughout the week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D/3D </td>
                <td className="color-black">Rs. 290 </td>
                <td className="color-black">
                  Voucher can be used for all 2D/3D movies, valid only on weekdays{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays/Weekends 2D only</td>
                <td className="color-black">Rs. 250 </td>
                <td className="color-black">
                  Voucher can be used for all 2D movies only, valid throughout the
                  week{' '}
                </td>
              </tr>

              <tr>
                <td className="color-black">Weekdays 2D only </td>
                <td className="color-black">Rs. 220 </td>
                <td className="color-black">
                  Voucher can be used for 2D movie only and valid only on weekdays{' '}
                </td>
              </tr>
            </table>
            <div className="mt-3">
              <h4 className="static_page_highlight_custom mb-4 text-primary">
                Discount :
              </h4>
              <table className="ticket_table">
                <tr>
                  <td>16 – 49 vouchers</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>50 and above</td>
                  <td>10%</td>
                </tr>
              </table>

              <h4 className="static_page_highlight_custom mt-4 text-primary">
                Notes
              </h4>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Minimum 10 vouchers must be
                issued.
              </p>
              <p className="tnc_new">
                <span style={{ color: 'red' }}>* </span>Movie vouchers are not valid
                for Deluxe & Premium Seats
              </p>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OpeningHours);
