import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import back from '@assets/pictures/svgs/back-modal.svg';
import { withNamespaces } from 'react-i18next';
import { otpValidation } from '@helper/formatting';
import showPasswordIcon from '@assets/pictures/svgs/show-password.svg';
import hidePasswordIcon from '@assets/pictures/svgs/hide-password.svg';

import {
  forgotPassword,
  verifyOTP,
  resetPassword,
  resetTimer,
  startTimer,
} from '../../store/auth/authActions';
// const handleChangeOtp = (value) => {
//   setOtp(value);
// };
const OtpPopup = (props) => {
  const {
    t,
    onClickResend,
    allowResend,
    setField,
    field,
    setAllowResend,
    isPrivate = false,
  } = props;
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const state = useSelector((state) => state);
  const {
    auth: {
      forgotPasswordError,
      forgotPasswordUserDetails,
      otpError,
      timer: { seconds, minutes },
    },
  } = state;
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const onClickConfirm = () => {
    switch (step) {
      case 1:
        setNewPassword('');
        setSubmitted(true);
        if (field === ``) return;
        setLoading(true);
        dispatch(
          forgotPassword({
            field,
            callback: setLoading,
            successCallback: () => {
              setSubmitted(false);
              setStep(step + 1);
              setAllowResend(false);
              dispatch(resetTimer());
              dispatch(startTimer());
              setTimeout(() => setAllowResend(true), 30000);
            },
          }),
        );
        break;

      case 2:
        setSubmitted(true);
        if (!otp) return;
        setLoading(true);
        dispatch(
          verifyOTP({
            otp,
            callback: setLoading,
            successCallback: () => {
              setSubmitted(false);
              setStep(step + 1);
            },
          }),
        );
        break;
      case 3:
        setSubmitted(true);
        if (!newPassword) return;
        setLoading(true);
        dispatch(
          resetPassword({
            newPassword,
            callback: setLoading,
            successCallback: () => {
              setOtp('');
              setField('');
              setSubmitted(false);
              setStep(step + 1);
            },
          }),
        );
        break;
      case 4:
        setField('');
        setSubmitted(false);
        setOtp('');
        setStep(1);
        props.closeFunc(false);
        break;
      default:
    }
  };
  return (
    <>
      {/* // <section className="wrapper container-fluid">
    //   <img
    //     src={back}
    //     className="back-btn-main"
    //     onClick={() => {
    //       step > 1 ? setStep(step - 1) : props.closeFunc(false);
    //     }}
    //     alt=""
    //   /> */}

      <p className="title">
        <small>
          {step === 1
            ? t(`login_form.forgot-password`)
            : step === 2
            ? t(`Enter OTP`)
            : step === 3
            ? t(`form_general.reset-pwd`)
            : step === 4
            ? t(`Password_Changed_Succ`)
            : null}
        </small>
      </p>

      <form onSubmit={(e) => e.preventDefault()} className="user-form">
        {step === 1 && (
          <>
            <label className="mb-0">
              {t('login_form.placeholders.email-mobile')}
              <input
                type="text"
                placeholder={t(`Enter_email_or_mobile`)}
                value={field}
                onChange={(e) => setField(e.target.value)}
              />
            </label>
            <p className={`error ${isPrivate && ' error-golden '}`}>
              {submitted && field === '' && t('field_required')}
            </p>
          </>
        )}
        {step === 3 && (
          <>
            <label className="position-relative mb-0">
              {t(`login_form.placeholders.pwd`)}
              <input
                type={showLoginPassword ? 'text' : 'password'}
                autocomplete="new-password"
                placeholder={t('login_form.placeholders.Enter your password')}
                min="4"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {!showLoginPassword && (
                <img
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={showPasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                  alt=""
                />
              )}
              {showLoginPassword && (
                <img
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={hidePasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                  alt=""
                />
              )}
            </label>
            {submitted && newPassword === '' && (
              <p className={`error ${isPrivate && ' error-golden '}`}>
                {t('field_required')}
              </p>
            )}
          </>
        )}
      </form>

      {step === 2 && forgotPasswordUserDetails && (
        <>
          <p className="msg mb-2 fs-14">
            {t(`Enter OTP sent to`)} {forgotPasswordUserDetails?.cust_email}{' '}
            {t(`and`)} {forgotPasswordUserDetails?.cust_mobile}
          </p>
          <div className="otp-input">
            {/* <OtpInput
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
              separator={<span></span>}
            /> */}
            <input
              type="text"
              value={otp}
              onChange={(e) => {
                if (otpValidation(e.target.value)) {
                  setOtp(e.target.value);
                }
              }}
            />
          </div>
          {/* {submitted && !otp && ( */}
          {submitted && !otp && (
            <p className={`error ${isPrivate && ' error-golden '}`}>
              {t('field_required')}
            </p>
          )}
          {/* )} */}
          {/* {true && (
              <button
                onClick={onClickResend}
                className={`btn-main mx-auto ${!allowResend && `inactive`}`}
              >
                {t(`Resent OTP`)}
              </button>
            )} */}
          <span className="fs-14 mx-1 mt-1  d-inline-block">
            {minutes}:{seconds}&nbsp;
          </span>
          {allowResend && (
            <>
              {' '}
              <div className="d-flex align-items-center justify-content-between mt-2">
                <p className="mr-2 fs-14">{t(`otp_message`)}&nbsp;</p>
                <button
                  className={`${
                    isPrivate ? 'btn-main guest_btn_pvt ' : 'btn-basic '
                  } ml-2`}
                  onClick={onClickResend}
                  // className={`btn-main mx-auto ${!allowResend && `inactive`}`}
                >
                  {t(`Resend`)}
                </button>
              </div>
            </>
          )}
        </>
      )}
      {forgotPasswordError && (
        <p className={`error ${isPrivate && ' error-golden '}`}>
          {t(forgotPasswordError)}
        </p>
      )}
      {otpError && (
        <p className={`error ${isPrivate && ' error-golden '}`}>{t(otpError)}</p>
      )}
      <button
        className={`btn-main ${isPrivate && ' guest_btn_pvt '} mt-4 mx-auto ${
          loading && `loader-text`
        }`}
        onClick={onClickConfirm}
      >
        {step === 4 ? t(`Close`) : t(`Confirm`)}
      </button>
      {/* // </section> */}
    </>
  );
};

export default withNamespaces()(OtpPopup);
