export const FETCH_SEAT_LAYOUT = 'FETCH_SEAT_LAYOUT';
export const FETCH_SEAT_LAYOUT_SUCCESS = 'FETCH_SEAT_LAYOUT_SUCCESS';
export const FETCH_SEAT_LAYOUT_ERROR = 'FETCH_SEAT_LAYOUT_ERROR';

export const FETCH_SEAT_TYPE = 'FETCH_SEAT_TYPE';
export const FETCH_SEAT_TYPE_SUCCESS = 'FETCH_SEAT_TYPE_SUCCESS';
export const FETCH_SEAT_TYPE_ERROR = 'FETCH_SEAT_TYPE_ERROR';

export const SET_SEAT_SELECTED = 'SET_SEAT_SELECTED';

export const CLEAR_SEAT_SELECTION = 'CLEAR_SEAT_SELECTION';

export const UPDATE_SEAT_LAYOUT = 'UPDATE_SEAT_LAYOUT';

export const RESERVE_SEAT = 'RESERVE_SEAT';
export const RESERVE_SEAT_SUCCESS = 'RESERVE_SEAT_SUCCESS';
export const RESERVE_SEAT_ERROR = 'RESERVE_SEAT_ERROR';

export const RELEASE_SEAT = 'RELEASE_SEAT';
export const RELEASE_SEAT_SUCCESS = 'RELEASE_SEAT_SUCCESS';
export const RELEASE_SEAT_ERROR = 'RELEASE_SEAT_ERROR';

export const SET_RESERVATION_ID = 'SET_RESERVATION_ID';
export const SET_TEMP_RESERVATION_ID = 'SET_TEMP_RESERVATION_ID';
export const SET_INTERNET_HANDLING_FEES_APPLIED =
  'SET_INTERNET_HANDLING_FEES_APPLIED';
