import React from 'react';
import { withNamespaces } from 'react-i18next';

import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Immersivae1 from '@assets/pictures/brand-immersive-1.png';
import Immersivae2 from '@assets/pictures/brand-immersive-2.png';
import Immersivae3 from '@assets/pictures/brand-immersive-3.png';
import Immersivae4 from '@assets/pictures/brand-immersive-4.png';

import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Theater Innovation')} /> */}
          <CenteredTitle firstHalf={t('Immersive Theater')} />
        </div>

        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
              {t(
                'Every detail is focused on enhancing and maximizing your cinema experience',
              )}
            </h4>

            {/* First Row */}
            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Immersivae1} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Enjoy Plush Comfort and Class
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className="color-black">
                  Contemporary interiors and design aesthetics complement the plush
                  comfort of seats - making it deeply relaxing movie watching
                  experience.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Experience Seamless Ticketing
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  Fill your senses with wonder!
                </h5> */}
                <p className="color-black">
                  The online ticketing system through the digital platform and
                  paperless transactions, make bookings a breeze! You can even
                  choose your own seats with a single touch, without waiting in
                  line.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Immersivae3} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Third Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Immersivae2} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  World Class Amenities and Aesthetics
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  The innovative architectural designs, security services and
                  overall hygiene and are on par with the world’s best standards.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Get Rewarded with Club QFX 
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  Every QFX purchase adds to your rewards. Start earning and
                  redeeming free tickets and other exciting offers, because your
                  loyalty counts!
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Immersivae4} className="img-fluid custom_img_about" />
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
