import Api from './Api';
import { createQueryString } from './ApiHelper';
export default {
  GetCinemas(payload) {
    if (payload) {
      const { location } = payload;
      return Api().post(`/api/external/cinemas/?&location=${location}`, payload);
    }
    return Api().post(`/api/external/cinemas/`, {});
  },
  GetCMSData(payload) {
    return Api().get(`/api/external/cms/${payload.cinema_id}`);
  },
  GetAdvertisePdfData(payload) {
    const query = createQueryString(payload);
    return Api().get(`/api/admin/get_vip_fnb${query}`);
  },
};
