import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import Dropdown from 'react-bootstrap/Dropdown';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import useIsTablet from '@src/customHooks/useIsTablet';
import InlineScroll from '@components/partials/InlineScroll';
// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const OpeningHours = ({ t }) => {
  const isMobile = useIsMobile();

  const [show, setShow] = useState('');

  const handleshowhide = (event) => {
    const getCinemaelectedValue = event.target.value;
    console.log(getCinemaelectedValue, 'ffffffffff');
    setShow(getCinemaelectedValue);
  };
  const isTablet = useIsTablet();
  /* let openings = [
    {
      openingsFor: [
        'Opening Hours',
      ],
      hours: [
        'Monday to Thursday - 11:30-21:00',
        'Friday and Saturday - 11:30-23:00',
        'Sunday - 13:00-21:00',
      ]
    },
    {
      openingsFor: [
        'Opening Hours for',
        'Screening'
      ],
      hours: [
        'Monday to Thursday - 09:00-21:00',
        'Friday - 09:00-01:30',
        'Saturday - 10:30-01:30',
        'Sunday - 12:00-21:00',
      ]
    }
  ] */

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Ticket Rates')} />
        <CenteredTitle firstHalf={t('Ticket Rates')} secondHalf={''} />

        <div className="mb-3">
          <select
            className={'custom_select_tickets color-black'}
            onChange={(e) => handleshowhide(e)}
          >
            <option value="" disabled selected hidden>
              Select Cinema
            </option>

            <option value="1">
              <span className={'p-3'}>Civil Mall</span>
            </option>
            <option value="2">Labim Mall</option>
            <option value="3">Qfx Chhaya Center</option>
            <option value="4">Qfx Bhaktapur</option>
            <option value="5">Durbar Cinemax</option>
            <option value="6">QFX Biratnagar</option>
            <option value="7">QFX Damauli</option>
            <option value="8">QFX Birtamode</option>
            <option value="9">QFX Bageshwori</option>
            <option value="10">QFX Jalma</option>
            <option value="11">QFX Butwal</option>
          </select>

          {/* <InlineScroll>
            <a href="#QFX Civil Mall">
              <button
                className={`faq_btn`}
                onClick={civil == true}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Civil Mall
              </button>
            </a>
            <a href="#Labim Mall">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                Labim Mall
              </button>
            </a>

            <a href="#QFX Chhaya Center">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Chhaya Center
              </button>
            </a>

            <a href="#QFX Bhaktapur">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Bhaktapur
              </button>
            </a>

            <a href="#Durbar Cinemax">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                Durbar Cinemax
              </button>
            </a>

            <a href="#QFX Biratnagar">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Biratnagar
              </button>
            </a>

            <a href="#QFX Damauli">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Damauli
              </button>
            </a>
            <a href="#QFX Birtamode">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Birtamode
              </button>
            </a>

            <a href="#QFX Bageshwori">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Bageshwori
              </button>
            </a>

            <a href="#QFX Jalma">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Jalma
              </button>
            </a>

            <a href="#QFX Butwal">
              <button
                className={`faq_btn`}
                // onClick={() => {
                //   if (c.category === selectedCategory) setSelectedCategory('');
                //   else setSelectedCategory(c.category);
                // }}
              >
                QFX Butwal
              </button>
            </a>
          </InlineScroll> */}
        </div>
      </div>

      <div className="container-fluid opening_hours">
        {/* {<!---Civil Mall--->} */}
        <div id="QFX Civil Mall"></div>

        <section
          className={` ${show == '' ? 'row' : show !== '1' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            paddingBottom: '30px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Civil Mall</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>3</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 459</p>
                    <p>Platinum: 40</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 247</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 247</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Deals Days
                </td>
                <td className="">2D</td>
                <td className=""></td>
              </tr>
              <tr>
                <td>3D</td>
                <td></td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 330</p>
                    <p>Premium: 500</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 400</p>
                    <p>Premium: 570</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 410</p>
                    <p>Premium: 590</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 480</p>
                    <p>Premium: 660</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="Labim Mall"></div>
        </section>

        {/* {<!---Labim Mal--->} */}

        <section
          className={` ${show == '' ? 'row' : show !== '2' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Labim Mall</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>3</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 182</p>
                    {/* <p>Platinum: 40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 180</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 183</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Deals Days
                </td>
                <td className="">2D</td>
                <td className=""></td>
              </tr>
              <tr>
                <td>3D</td>
                <td></td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">330</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>400</td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">410</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>480</td>
              </tr>
            </table>
          </article>
          <div id="QFX Chhaya Center"></div>
        </section>

        {/* {<!---QFX Chhaya Center--->} */}

        <section
          className={` ${show == '' ? 'row' : show !== '3' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Chhaya Center</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>3</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 112</p>
                    {/* <p>Platinum: 40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 150</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 110</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Deals Days
                </td>
                <td className="">2D</td>
                <td className=""></td>
              </tr>
              <tr>
                <td>3D</td>
                <td></td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">330</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>400</td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">410</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>480</td>
              </tr>
            </table>
          </article>

          <div id="QFX Bhaktapur"></div>
        </section>

        {/* {<!---QFX Bhaktapur--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '4' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Bhaktapur</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>3</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 195</p>
                    <p>Premium: 12</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 234</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 233</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Tuesday /Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 200</p>
                    <p>Premium: 450</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 270</p>
                    <p>Premium: 520</p>
                  </div>
                </td>
              </tr>
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 330</p>
                    <p>Premium: 800</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 400</p>
                    <p>Premium: 870</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 410</p>
                    <p>Premium: 950</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 480</p>
                    <p>Premium: 1020</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="Durbar Cinemax"></div>
        </section>

        {/* {<!---Durbar Cinemax--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '5' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">Durbar Cinemax</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Premium: 50</p>
                    {/* <p>Platinum: 40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Premium: 53</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Deals Days
                </td>
                <td className="">2D</td>
                <td className=""></td>
              </tr>
              <tr>
                <td>3D</td>
                <td></td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">1000</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>1200</td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">1200</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>1400</td>
              </tr>
            </table>
          </article>

          <div id="QFX Biratnagar"></div>
        </section>

        {/* {<!---QFX Biratnagar--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '6' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Biratnagar</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 180</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 180</p>
                    <p>Premium: 15</p>
                  </div>
                </td>
              </tr>

              {/* <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 233</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr> */}
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Tuesday /Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 150</p>
                    <p>Premium: 350</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 220</p>
                    <p>Premium: 420</p>
                  </div>
                </td>
              </tr>
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Premium: 650</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 320</p>
                    <p>Premium: 720</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 300</p>
                    <p>Premium: 800</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 370</p>
                    <p>Premium: 870</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="QFX Damauli"></div>
        </section>

        {/* {<!---QFX Damauli--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '7' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Damauli</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 120</p>
                    {/* <p>Platinum: 40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 240</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">150</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>220</td>
              </tr>
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">220</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>290</td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">250</td>
              </tr>
              <tr>
                <td>3D</td>
                <td>320</td>
              </tr>
            </table>
          </article>
          <div id="QFX Birtamode"></div>
        </section>

        {/* {<!---QFX Birtamode--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '8' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Birtamode</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 123</p>
                    <p>Premium: 15</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 117</p>
                    <p>Premium: 16</p>
                  </div>
                </td>
              </tr>

              {/* <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 233</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr> */}
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Tuesday /Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 200</p>
                    <p>Premium: 450</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 270</p>
                    <p>Premium: 520</p>
                  </div>
                </td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Premium: 350</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 320</p>
                    <p>Premium: 420</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Premium: 350</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 320</p>
                    <p>Premium: 420</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="QFX Bageshwori"></div>
        </section>

        {/* {<!---QFX Bageshwori--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '9' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Bageshwori</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 180</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    {/* <p>Platinum: 315</p> */}
                    <p>Premium: 290</p>
                  </div>
                </td>
              </tr>

              {/* <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 233</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr> */}
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 180</p>
                    <p>Premium: 200</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Premium: 270</p>
                  </div>
                </td>
              </tr>
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Delux: 320</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 320</p>
                    {/* <p>Premium: 720</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 300</p>
                    <p>Delux: 370</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 370</p>
                    {/* <p>Premium: 870</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="QFX Jalma"></div>
        </section>

        {/* {<!---QFX Jalma--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '10' ? 'd-none' : 'row'}`}
          style={{
            borderBottom: '1px solid var(--light-1)',
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Jalma</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 268</p>
                    {/* <p>Platinum: 40</p> */}
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 265</p>
                    {/* <p>Platinum:40</p> */}
                  </div>
                </td>
              </tr>
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              {/* <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Deals Days
                </td>
                <td className="">2D</td>
                <td className=""></td>
              </tr>
              <tr>
                <td>3D</td>
                <td></td>
              </tr> */}
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Monday/Tuesday/Wednesday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 130</p>
                    <p>Premium: 180</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 165</p>
                    <p>Premium: 215</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Sunday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 130</p>
                    <p>Premium: 180</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 165</p>
                    <p>Premium: 215</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
          <div id="QFX Butwal"></div>
        </section>

        {/* {<!---QFX Butwal--->} */}
        <section
          className={` ${show == '' ? 'row' : show !== '11' ? 'd-none' : 'row'}`}
          style={{
            padding: '30px 0px',
          }}
        >
          <article className="col-md-4">
            <h4 className="text-center color-black">QFX Butwal</h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Number of Audis</td>
                <td>2</td>
              </tr>
              <tr>
                <td></td>
                <td className="text-primary font-weight-bold">Seat Capacity</td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 1</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 162</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="text-primary font-weight-bold">Audi 2</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 180</p>
                    <p>Premium: 16</p>
                  </div>
                </td>
              </tr>

              {/* <tr>
                <td className="text-primary font-weight-bold">Audi 3</td>
                <td>
                  <div className="d-block">
                    <p>Platinum: 233</p>
                    <p>Premium: 14</p>
                  </div>
                </td>
              </tr> */}
            </table>
          </article>

          <article className="col-md-8">
            <h4 className={`text-center color-black ${isMobile ? 'pt-3' : ''}`}>
              Ticket Rates
            </h4>
            <table style={{ width: '100%' }} className="ticket_table">
              <tr>
                <td className="text-primary font-weight-bold">Days</td>
                <td className="text-primary font-weight-bold">Movie Option</td>
                <td className="text-primary font-weight-bold">Rates</td>
              </tr>
              {/* {<!---Deals Dats---> Start} */}
              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Tuesday /Wednesday (Deals Days)
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 200</p>
                    <p>Premium: 450</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 270</p>
                    <p>Premium: 520</p>
                  </div>
                </td>
              </tr>
              {/* {<!---Deals Dats---> End} */}
              <tr>
                <td
                  rowSpan={2}
                  className="text-primary font-weight-bold"
                  style={{ wordBreak: isMobile ? 'break-all' : '' }}
                >
                  Sunday/Thursday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 250</p>
                    <p>Premium: 650</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  {' '}
                  <div className="d-block">
                    <p> Platinum: 320</p>
                    <p>Premium: 720</p>
                  </div>
                </td>
              </tr>

              <tr>
                <td rowSpan={2} className="text-primary font-weight-bold">
                  Friday/Saturday/ Monday
                </td>
                <td className="">2D</td>
                <td className="">
                  <div className="d-block">
                    <p> Platinum: 300</p>
                    <p>Premium: 800</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3D</td>
                <td>
                  <div className="d-block">
                    <p> Platinum: 370</p>
                    <p>Premium: 870</p>
                  </div>
                </td>
              </tr>
            </table>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OpeningHours);
