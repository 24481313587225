import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Images
import { ReactComponent as LeftArrow } from '@assets/pictures/svgs/slider-arrow-left.svg';

const GoBack = (props) => {
  const { isPrivate } = props;
  const { pathname } = useLocation();
  const { t, classes, onClick } = props;
  const [text, setText] = useState('');
  GoBack.defaultProps = {
    classes: '',
    onClick: () => history.go(-1),
  };
  const history = useHistory();
  // useEffect(() => {
  //     if (pathname.startsWith("/now-showing-booking")) {
  //         setText("Now Showing")
  //     } else if (pathname.startsWith("/private-booking-flow")) {
  //         setText("On Demand")
  //     } else if (pathname.startsWith("/gift-card-details")) {
  //         setText("Gift Card Details")
  //     } else {
  //         setText("")
  //     }
  // }, [pathname])
  return (
    <>
      <div
        className={`  ${text ? 'with_text' : ''} ${
          isPrivate ? ' gold_go_back' : 'go_back'
        }`}
        onClick={onClick}
      >
        <p>
          <LeftArrow />
          <span>{t('')}</span>
        </p>
        {/* {text && <span>{t(text)}</span>} */}
      </div>
    </>
  );
};

export default withNamespaces()(GoBack);
