import React from 'react';
import { withNamespaces } from 'react-i18next';

const BreadCrumb = (props) => {
  const { t, classes = '', firstHalf = '', secondHalf = '' } = props;
  return (
    <>
      <h1 className={`bread_crumbs ${classes}`}>
        <span style={{ textTransform: 'uppercase' }}>{firstHalf}</span>&nbsp;
        <span style={{ textTransform: 'capitalize' }}>{secondHalf}</span>
      </h1>
    </>
  );
};

export default withNamespaces()(BreadCrumb);
