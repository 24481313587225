import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../../customHooks/useIsMobile';
import { useLocation } from 'react-router-dom';

// Images
import giftCardImg from '@assets/pictures/gift-card.png';
import { useSelector } from 'react-redux';
import { currencyFormatter } from '../../../helper/currencyFormatter';
import Spinner from '../../partials/Spinner';

const CashCardInfoTopupCard = (props) => {
  const { t, classes = '' } = props;
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const [showCheckout, setShowCheckout] = useState(false);
  const giftCardBottomRef = useRef();
  const giftCardInfoRef = useRef();

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector(
    (state) => state.cashCard.cashCardDetailsLoading,
  );
  const cashCardTopUpSelection = useSelector(
    (state) => state.cashCard.cashCardTopUpSelection,
  );

  useEffect(() => {
    if (
      pathname.startsWith('/cash-card-payment') ||
      pathname.startsWith('/cash-card-topup')
    ) {
      setShowCheckout(true);
    } else {
      setShowCheckout(false);
    }
  }, [pathname]);

  useEffect(() => {
    const setGiftCardBottomWidth = () => {
      const giftCardBottom = giftCardBottomRef.current;
      const giftCardInfo = giftCardInfoRef.current;
      if (giftCardBottom && giftCardInfo) {
        giftCardBottom.style.width = giftCardInfo.offsetWidth + 'px';
      }
    };
    setGiftCardBottomWidth();
    window.addEventListener('resize', setGiftCardBottomWidth);
    return () => window.removeEventListener('resize', setGiftCardBottomWidth);
  }, [giftCardBottomRef.current, giftCardInfoRef.current]);

  const [firstCashCard, setFirstCashCard] = useState({});
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length)
      setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);

  if (cashCardDetailsLoading)
    return (
      <div className="row">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  return (
    <>
      <div
        className={`gift_card_info_wrapper movie_info_wrapper payment_details_new ${
          isMobile ? 'sticky_movie_info' : ''
        }`}
        ref={giftCardInfoRef}
      >
        <article className="gift_card_info">
          <figure>
            <img
              src={firstCashCard?.cash_card_image || giftCardImg}
              alt={firstCashCard?.cash_card_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = () => {}; // prevents looping
                currentTarget.src = giftCardImg;
              }}
            />
          </figure>
          <section className="">
            <h6>{t('Hello')}</h6>
            <p>
              {t(firstCashCard?.first_name)} {t(firstCashCard?.last_name)}
            </p>
          </section>
          <section className="">
            <h6>{t('Your Current Balance is')}:</h6>
            <p>
              {curr_code} {currencyFormatter(firstCashCard?.balance_amount)}
            </p>
          </section>

          {showCheckout && (
            <>
              <section className="text-white">
                <h6>{t('Checkout Summary')}</h6>

                <article className="payment_details_rows">
                  <>
                    <section className="">
                      <p className="left_value">
                        <span className="ml-0"></span>
                        <>
                          <p className="d-inline text-nowrap">
                            &nbsp;{t('Cash Card')}
                          </p>
                        </>
                        <span className="">{t('Top-up')}</span>
                      </p>

                      <p className="right_value">
                        {curr_code}{' '}
                        {currencyFormatter(cashCardTopUpSelection?.amount)}
                      </p>
                    </section>
                  </>
                </article>
              </section>
              {isMobile && (
                <article className="payment_details_rows payment_details_total">
                  <section className="">
                    <h5>{t('Grand Total')}</h5>
                    <h4 className="">
                      {curr_code}{' '}
                      {currencyFormatter(cashCardTopUpSelection?.amount)}
                    </h4>
                  </section>
                  <h6>{t('(Incl. of 2.5% VAT)')}</h6>
                </article>
              )}
            </>
          )}
        </article>

        {!isMobile && showCheckout && (
          <>
            <article className="movie_info_scroll_space" />
            <article className="movie-bottom-cash" ref={giftCardBottomRef}>
              <section className="">
                <div className="cash_space">
                  <h5 className="cash_text">{t('Grand Total')}</h5>
                  <h4 className="cash_text">
                    {curr_code} {currencyFormatter(cashCardTopUpSelection?.amount)}
                  </h4>
                </div>

                <h6 className="check_span" style={{ marginTop: '6px' }}>
                  {t('(Incl. of 2.5% VAT)')}
                </h6>
              </section>
            </article>
          </>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(CashCardInfoTopupCard);
