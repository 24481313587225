// import React from 'react';
import { withNamespaces } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Carousel,
} from 'react-bootstrap';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsDesktop from '@src/customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet';
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';

// Components

import Slider from 'react-slick';
import SpinnerCompo from '../components/partials/Spinner';
import MovieService from '@apiService/MovieService';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { BASEURL } from '../config/index';
import { getSeatIconUrl } from '@src/helper';
const Offers = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [OfferPagesData, setOfferPagesData] = useState([]);

  const [offerdiv, setofferdiv] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const OfferPagesDataFunc = () => async (dispatch) => {
    try {
      const response = await MovieService.GetaActivePromotionAndOffersNew();
      const { data } = response;
      if (data?.status) {
        setOfferPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(OfferPagesDataFunc());
  }, []);

  let banner_arr = [];
  OfferPagesData?.map((x) => {
    return banner_arr.push(x?.offer_banner_image);
  });

  let filtered_bannerarr = banner_arr?.filter((y) => y);

  let poster_banner = [];
  OfferPagesData?.map((x) => {
    return poster_banner.push(x?.offer_poster_image);
  });

  const [swiping, setSwiping] = useState(false);
  const bannerSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const showButtons = OfferPagesData.length > 1;
  const handleNext = () => {
    console.log('handleNext:', 'handleNext');
    setCurrentIndex((prevIndex) => (prevIndex + 3) % OfferPagesData.length);
  };

  const handlePrev = () => {
    console.log('handlePrev:', 'handlePrev');
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 3 + OfferPagesData.length) % OfferPagesData.length,
    );
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  return (
    <>
      {loading ? (
        <SpinnerCompo />
      ) : (
        <div className="align_new offer_main_wrapper">
          {/* <div className="left_right_align" style={{ color: '#00aad3' }}>
            <p>PROMOTIONS &gt;Offers</p>
          </div> */}
          <div className="left_right_align mt-2">
            <CenteredTitle firstHalf={`${'Offers'}`} secondHalf={''} />
          </div>

          <Slider
            {...bannerSlider}
            className="home_banner"
            style={{ marginBottom: '0px', marginLeft: '0px !important' }}
          >
            {filtered_bannerarr.length > 0 &&
              filtered_bannerarr?.map((x) => {
                return (
                  <>
                    {x && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={getSeatIconUrl(x)}
                        alt={getSeatIconUrl(x)}
                        className="img-fluid m-auto"
                        style={{ width: '100%', height: isMobile ? '161.34px' : isTablet ? '288px' : '572.25px' }}
                      />
                    )}
                  </>
                );
              })}
          </Slider>

          <section className="container-fluid container-xl mt-3">
            <h5 className="font-weight-bold text-dark">Cinema Offers</h5>
          </section>
          <section className="container-fluid container-xl mt-3 offer_poster_wrapper">
            <div className="mt-4">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                className="custom_nav_link"
              >
                <Row>
                  <Col
                    sm={12}
                    lg={12}
                    xl={12}
                    className="mt-3 d-flex flex-column align-items-center"
                  >
                    <div className="slider_section d-flex flex-column align-items-center">
                      {/* <div
                        className="slider-controls d-flex justify-content-between  position-absolute start-5 translate-middle-x"
                        style={{ top: '140px', width: '90%' }}
                      >
                        {showButtons && (
                          <>
                            <button
                              onClick={handlePrev}
                              style={{
                                border: 'none',
                                height: '30px',
                                width: '30px',
                                fontWeight: 'bold',
                              }}
                            >
                              &lt;
                            </button>
                            <button
                              onClick={handleNext}
                              style={{
                                border: 'none',
                                height: '30px',
                                width: '30px',
                                fontWeight: 'bold',
                              }}
                            >
                              &gt;
                            </button>
                          </>
                        )}
                      </div> */}
                      {/* <Nav variant="pills" className="custom_overflow_location">
                                                {OfferPagesData?.slice(currentIndex, currentIndex + 3).map((x, index) => (
                                                    <Nav.Item key={index}>
                                                        <Nav.Link eventKey={x?.os_id}>
                                                            <div className="">
                                                                <img
                                                                    style={{ cursor: 'pointer' }}
                                                                    src={getSeatIconUrl(x?.offer_poster_image)}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src = dummyPosterStatic;
                                                                    }}
                                                                    alt={getSeatIconUrl(x?.offer_poster_image)}
                                                                    className="custom-img-fluid m-auto"
                                                                />
                                                            </div>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav> */}
                    {!isMobile &&(  <Carousel>
                        {OfferPagesData &&
                          OfferPagesData.length > 0 &&
                          OfferPagesData.reduce((acc, _, i) => {
                            if (i % 3 === 0) {
                              acc.push(OfferPagesData.slice(i, i + 3));
                            }
                            return acc;
                          }, []).map((chunk, index) => (
                            <Carousel.Item key={index}>
                              <Nav
                                variant="pills"
                                className="custom_overflow_location"
                              >
                                {chunk.map((x) => (
                                  <Nav.Item key={x.os_id}>
                                    <Nav.Link eventKey={x.os_id}
                                    onClick={() => scrollToSection('gallery')}
                                    >
                                      <div className="">
                                     {!isTablet &&(   <img
                                          style={{ cursor: 'pointer' }}
                                          src={getSeatIconUrl(x.offer_poster_image)}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = dummyPosterStatic;
                                          }}
                                          alt={getSeatIconUrl(x.offer_poster_image)}
                                          className="custom-img-fluid m-auto"
                                        />
                                    )}
                                    {isTablet &&(   <img
                                          style={{ cursor: 'pointer' }}
                                          src={getSeatIconUrl(x.offer_poster_image)}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = dummyPosterStatic;
                                          }}
                                          alt={getSeatIconUrl(x.offer_poster_image)}
                                          className="custom-img-fluid-tablet m-auto"
                                        />
                                    )}
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </Carousel.Item>
                          ))}
                      </Carousel>

)}

                {isMobile &&(     <Carousel>
                        {OfferPagesData &&
                          OfferPagesData.length > 0 &&
                          OfferPagesData.reduce((acc, _, i) => {
                            if (i % 1 === 0) {
                              acc.push(OfferPagesData.slice(i, i + 1));
                            }
                            return acc;
                          }, []).map((chunk, index) => (
                            <Carousel.Item key={index}>
                              <Nav
                                variant="pills"
                                className="custom_overflow_location"
                                onClick={() => scrollToSection('gallery')}
                              >
                                {chunk.map((x) => (
                                  <Nav.Item key={x.os_id}>
                                    <Nav.Link eventKey={x.os_id}>
                                      <div className="">
                                        <img
                                          style={{ cursor: 'pointer' }}
                                          src={getSeatIconUrl(x.offer_poster_image)}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = dummyPosterStatic;
                                          }}
                                          alt={getSeatIconUrl(x.offer_poster_image)}
                                          className="custom-img-fluid m-auto"
                                        />
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                ))}
                              </Nav>
                            </Carousel.Item>
                          ))}
                      </Carousel>
                      )} 
                    </div>
                  </Col>
                  <Col
                    sm={10}
                    lg={12}
                    xl={8}
                    className="mt-3 mx-auto"
                    style={{
                      backgroundColor: '#414042',
                      color: 'white',
                      borderRadius: '10px',
                    }}
                    id="gallery"
                  >
                    <Tab.Content>
                      {OfferPagesData?.map((y, index) => (
                        <Tab.Pane eventKey={y?.os_id} >
                          <Row className="m-auto">
                            <Col
                              sm={12}
                              lg={3}
                              xl={3}
                              className="d-flex align-items-center justify-content-center text-center"
                            >
                              <img
                                style={{
                                  cursor: 'pointer',
                                  maxWidth: '100%',
                                  height: '80%',
                                }}
                                src={getSeatIconUrl(y?.offer_poster_image)}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = dummyPosterStatic;
                                }}
                                alt={getSeatIconUrl(y?.offer_poster_image)}
                              />
                            </Col>
                            <Col
                              sm={12}
                              lg={9}
                              xl={9}
                              className="d-flex flex-column align-items-left justify-content-start text-left"
                              // style={{ backgroundColor: '#e5e6e7', color: '#000' }}
                            >
                              <div className="mt-4">
                                <p className="tnc_name_heading my-3">
                                  {y?.offer_title}
                                </p>
                              </div>
                              <h6>Terms & Conditions </h6>
                              {/* <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: y?.offer_description ? y?.offer_description : '',
                                                                }}
                                                                className="offer_description_wrapper"
                                                            ></div> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: y?.offer_description
                                    ? `<ul><li>${y.offer_description
                                        .split('\n')
                                        .join('</li><li>')}</li></ul>`
                                    : '',
                                }}
                                className="offer_description_wrapper"
                              ></div>

                              {y?.offer_redeem_at_boxoffice == 'Y' &&
                                y?.offer_redeem_at_online == 'N' &&
                                !y?.offer_description && (
                                  <div className="offer_description_wrapper">
                                    <p>
                                      This Offer is Applicable Only on BoxOffice
                                    </p>
                                  </div>
                                )}
                            </Col>
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(Offers);
