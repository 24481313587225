import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import ContactUs from './Feedback';
import DatePicker from 'react-date-picker';
import { sendemail } from '@store/auth/authActions';
import moment from 'moment';
import calendar from '@assets/pictures/svgs/calendar.svg';
import calendarWhite from '@assets/pictures/svgs/calendar-white.svg';
import { motion } from 'framer-motion';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { toast } from 'react-toastify';

const BookEvent = ({ t }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const loginDetails = useSelector((state) => state.auth);

  const { loginError, loggedInUser } = loginDetails;
  const [name, setName] = useState(
    loggedInUser ? `${loggedInUser.first_name} ${loggedInUser.last_name}` : null,
  );
  const [email, setEmail] = useState(loggedInUser ? loggedInUser.cust_email : null);

  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_name, iso_2, lang_id } = selected_language;

  const cinema = useSelector((state) => state.movies.selectedCinema);
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  const [people, setPeople] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [event, setEvent] = useState(null);
  const [suggestions, setSuggestions] = useState(``);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [calenderDateSelected, setCalenderDateSelected] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [ogDate, setOgDate] = useState(new Date());
  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      //console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });
  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (name && email && people && event && suggestions) {
      setProcessed(true);
    }
  };

  useEffect(() => {
    if (processed) {
      dispatch(
        sendemail({
          name: name,
          email: email,
          contact_no: !!contact_no ? contact_no : '',
          message: generateMessage(selectedDate, people, event, suggestions),
          cinema_id,
        }),
      ).then(() => {
        setStatus(true);
        window.scrollTo(0, 0);
        // toast.success(t("Thank you for your interest Our team will get in touch with you shortly"))
      });
    }
  }, [processed]);
  useEffect(() => {
    if (status) {
      if (!loggedInUser) {
        setName('');
        setEmail('');
      }
      setPeople('');
      setEvent('');
      setSelectedDate('');
      setSuggestions('');
    }
  }, [status]);

  return (
    <>
      {/* <ContactUs /> */}
      <div
        className="header-space container-fluid container-xl feedback-container"
        onClick={() => {
          setCalendarPopup(false);
        }}
      >
        <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {t('Booking')}
        </motion.p>
        <motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          <p className="sub-title mx-auto">{t('Book an Event')}</p>

          <p className="sub-title-desc col-sm-10 col-lg-8 col-xl-6 px-0">
            {t('book-event-text')}
          </p>

          {status && (
            <div className="email-success-message">
              {t(
                'Thank you for your interest Our team will get in touch with you shortly',
              )}
              {/* {t("Message sent successfully")}{" "} */}
            </div>
          )}

          {!status && (
            <form onSubmit={submitForm}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <input
                    className="fields"
                    type="text"
                    placeholder={t('Enter Name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <p className="error">
                    {submitted && !status && !name && t('Please enter your name')}
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <input
                    className="fields"
                    type="email"
                    placeholder={t('Enter Email Address')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <p className="error">
                    {submitted && !status && !email && t('Please enter your email')}
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <input
                    min="1"
                    max="1000"
                    step="1"
                    className="fields"
                    type="number"
                    placeholder={t('Enter No. of People')}
                    value={people}
                    onChange={(e) => {
                      //replace non-digits with blank
                      const val = e.target.value.replace(/[^\d]/, '');
                      setPeople(
                        val < 1000 ? val : parseInt(val.toString().substring(0, 6)),
                      );
                    }}
                  />

                  <p className="error">
                    {submitted &&
                      !status &&
                      !people &&
                      t('Please enter no. of people')}
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <article className="w-100">
                    {calendarPopup && (
                      // <DatePicker
                      //   formatShortWeekday={(locale, date) =>
                      //     moment(date).locale(locale).format("dd").substr(0, 1)
                      //   }
                      //   minDate={new Date()}
                      //   locale={iso_2?.toLowerCase()}
                      //   className="date-picker-popup"
                      //   isOpen={calendarPopup}
                      //   onChange={(date) => {
                      //     setSelectedDate(date.toLocaleDateString());
                      //     console.log("date", date, selectedDate);
                      //   }}
                      //   onCalendarClose={() => setCalendarPopup(false)}
                      // />
                      <span
                        className="new-date-picker"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <DayPicker
                          selectedDays={ogDate}
                          disabledDays={{
                            before: new Date(),
                          }}
                          onDayClick={(date, modifiers) => {
                            if (modifiers && modifiers.disabled) return;
                            setOgDate(date);
                            setCalendarPopup(false);
                            setSelectedDate(moment(date).format('DD-MM-YYYY'));
                            console.log('date', date, selectedDate);
                          }}
                          localeUtils={MomentLocaleUtils}
                          locale={iso_2.toLowerCase()}
                        />
                      </span>
                    )}
                    <input
                      className="fields"
                      type="text"
                      placeholder={t('Choose Date')}
                      value={selectedDate}
                      onClick={(e) => {
                        if (e.target.tagName !== 'P') {
                          calendarPopup
                            ? setCalendarPopup(false)
                            : setCalendarPopup(true);
                          e.stopPropagation();
                        }
                      }}
                    />
                    <p className="error">
                      {submitted &&
                        !status &&
                        !selectedDate &&
                        t('Please enter event date')}
                    </p>
                  </article>
                </div>
                <div className="col-12 col-md-12">
                  <input
                    className="fields"
                    type="text"
                    placeholder={t('Type of Event')}
                    value={event}
                    onChange={(e) => setEvent(e.target.value)}
                  />

                  <p className="error">
                    {submitted &&
                      !status &&
                      !event &&
                      t('Please enter type of event')}
                  </p>
                </div>

                {/* <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="fb">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Subject
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div> */}
                <div className="col-12">
                  <textarea
                    className="fields"
                    rows="8"
                    placeholder={t('Enter additional details')}
                    value={suggestions}
                    onChange={(e) => setSuggestions(e.target.value)}
                  />

                  <p className="error">
                    {submitted &&
                      !status &&
                      !suggestions &&
                      t('Please enter suggestions')}
                  </p>
                </div>
                <div className="col-12 col-sm-5 mx-auto">
                  <button className="btn-main mx-auto" type="submit">
                    {t('Submit')}
                  </button>
                </div>
              </div>
            </form>
          )}
        </motion.div>
      </div>
    </>
  );
};

function generateMessage(selectedDate, people, event, suggestions) {
  return `
This is an enquiry for Booking An Event. 
Date: ${selectedDate}. 
The number of people are: ${people}. 
Type of event: ${event}. 

Additional details: 
${suggestions} 
`;
}

export default withNamespaces()(BookEvent);
