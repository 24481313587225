import React from 'react';
import { withNamespaces } from 'react-i18next';

const Button = (props) => {
  const {
    t,
    classes,
    text,
    img,
    onClick,
    active,
    isPrivate,
    disabled,
    loading,
    size,
  } = props;
  return (
    <>
      <button
        className={`btn-main  ${
          size === 'lg' ? 'btn-main-lg' : size === 'sm' && 'btn-main-sm'
        } ${active ? 'active' : disabled && 'inactive'}  ${
          loading && 'loader-text'
        } ${classes} ${isPrivate ? 'golden_btn_pvt' : ''}`}
        onClick={onClick}
      >
        {text}
        {img && <img src={img} alt="" />}
      </button>
    </>
  );
};

// Default Props
Button.defaultProps = {
  classes: '',
  loading: false,
  active: false,
  disabled: false,
  img: null,
  text: 'Click Me!',
  onClick: () => {},
  size: '',
};

export default withNamespaces()(Button);
