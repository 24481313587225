import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import Bageshwori_banner from '@assets/pictures/bageshwori-banner.png';
import Bageshwori_banner1 from '@assets/pictures/bageshwori-1.png';
import Bageshwori_banner2 from '@assets/pictures/bageshwori-2.png';

import { BASEURL, HOST_URL } from '../config/index';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
          <CenteredTitle firstHalf={t('QFX BAGESHWORI')} />
        </div>
        <div className="banner_static_page">
          <img src={Bageshwori_banner} className="img-fluid w-100" />
        </div>
        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                <h3
                  className={`font-weight-bold text-primary ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Border Entertainment
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  This is the only prime cinema attraction catering to both Nepalese
                  and Indians from across the border. It is situated near the prime
                  location of Nepalgunj - an emerging tourist destination and
                  business hub for the mid far-western region of Nepal.
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img
                  src={Bageshwori_banner1}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>

            {/* Third Row */}

            <div
              className={`row ${
                isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
              }`}
            >
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img
                  src={Bageshwori_banner2}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  As the first modern theater in western Nepal, QFX Bageshwori
                  redefined the movie experience with high quality Barco 2K
                  projection, silver screens equipped with Depth Q 3D technology,
                  crisp audio and great comfort. This single destination with
                  multiple screens, is designed for your best viewing pleasure!
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            <div class="pt-5">
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                <li>
                  Regular ticket rates apply for afternoon, evening and night shows.
                </li>
                <li>Rates are inclusive of all taxes.</li>
                <li>We offer 50% discount on morning shows.</li>
                <li>
                  The discount is calculated based on the regular ticket rate for
                  the day.
                </li>
                <li>
                  Amazing Deal offer not applicable for new movie releases on these
                  days, regular ticket prices apply.
                </li>
              </p>
              <br></br>
              <p>
                <b>Address:</b> Setu BK Chowk , Newroad, Nepalgunj, Nepal<br></br>
                <b>Email:</b> marketing@qfxcinemas.com{' '}
              </p>
            </div>

            <div class="pt-3">
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                For Ticket Price{' '}
                <span
                  onClick={() => history.push('/TicketRates')}
                  className="text-primary font-weight-bold"
                >
                  Click Here !
                </span>
              </p>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
