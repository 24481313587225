import React from 'react';
import { withNamespaces } from 'react-i18next';

import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Theater_Innovation1 from '@assets/pictures/Theatre-Innovation-1.png';
import Theater_Innovation2 from '@assets/pictures/Theatre-Innovation-2.png';
import Theater_Innovation3 from '@assets/pictures/Theatre-Innovation-3.png';
import Theater_Innovation4 from '@assets/pictures/theater-innovation.png';

import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Theater Innovation')} /> */}
          <CenteredTitle firstHalf={t('Theater Innovation')} />
        </div>

        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
              {t(
                'Consistently enhanced experiences and always something new to look forward to!',
              )}
            </h4>

            {/* First Row */}
            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={Theater_Innovation1}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  First 4K Projection
                </h3>
                <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5>
                <p className="color-black">
                  QFX Cinemas are pioneers in introducing the latest visual
                  technology, offering outstanding picture quality and first of its
                  kind 4K projection in Nepal.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  First 3D Screen
                </h3>
                <h5 className="font-weight-bold text-primary">
                  Fill your senses with wonder!
                </h5>
                <p className="color-black">
                  Offerings such as the first 3D screen with Depth Q 3D technology,
                  deliver better luminosity and clarity and vivid picture quality.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={Theater_Innovation2}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>

            {/* Third Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={Theater_Innovation3}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Dolby Atmos at QFX Chhaya
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  You feel you are virtually inside every movie you watch. Surround
                  your senses with Dolby Atmos - a work of art through sound that
                  pulsates with power and makes your heart race!
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={` ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  First to Introduce Online Booking
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  It's a pleasure to access an easy-to-use website and a mobile
                  application, to make online reservations and purchases. And even
                  better with a wide selection of payment gateways.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={Theater_Innovation4}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
