import React from 'react';
import { withNamespaces } from 'react-i18next';

import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Platter1 from '@assets/pictures/platter-1.png';
import Platter2 from '@assets/pictures/platter-2.png';
import Platter3 from '@assets/pictures/platter-3.png';
import Platter4 from '@assets/pictures/platter-4.png';
import Platter5 from '@assets/pictures/innovation-food-4.jpg';
import Platter6 from '@assets/pictures/innovation-food-5.jpg';

import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Theater Innovation')} /> */}
          <CenteredTitle firstHalf={t('Pick Of The Platter')} />
        </div>

        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
              {t('Let all your senses come to play – with taste!')}
            </h4>

            {/* First Row */}
            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter1} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Prepared Fresh
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className="color-black">
                  Complementing the QFX experience is food and drink. We know you
                  like to try new food flavours, served fresh!
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={` ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The Pleasure of Popcorn
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  Fill your senses with wonder!
                </h5> */}
                <p className="color-black">
                  Special new flavours to tickle your taste buds! QFX cinemas offer
                  a variety of freshly made popcorn to enhance your viewing
                  pleasure.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter2} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Third Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter3} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Delicious to Drink
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX Bites
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <ul className="color-black">
                  <li>Chicken / Veg burger</li>
                  <li>Spicy chicken strip burger</li>
                  <li>Chicken Hawaiian sandwich</li>
                  <li>Cheese tomato sandwich</li>
                  <li>Tuna sandwich</li>
                  <li>Chicken / Veg roll</li>
                  <li>Hot Dog</li>
                  <li>Nachos</li>
                </ul>
                {/* <p>
                  Every ticket purchase adds to your rewards. Start earning and
                  redeeming free tickets and other exciting offers, because your
                  loyalty counts!
                </p> */}
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter4} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Fifth Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter5} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Assorted Labeling
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <p className="color-black">
                  QFX Cinemas cares about the diversity in food choice. Thats why we
                  cater to all preferences, with Vegetarian, Non-Vegetarian and
                  Halal labeling.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Sixth Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Widest Selection
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  Fill your senses with wonder!
                </h5> */}
                <p className="color-black">
                  Fresh quality food and beverage services to please your palette!
                  QFX cinemas always strives to add new products in-line and
                  simultaneously, sets the bar for advanced production systems.
                </p>
                {/* <span className="mt-2">
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Platter6} className="img-fluid custom_img_about" />
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
