import React from 'react';
import { Modal } from 'react-bootstrap';

import close from '@assets/pictures/svgs/close.svg';
import calendar from '@assets/pictures/svgs/calendar.svg';

export default function showDetailsPopup(props) {
  const t = props.t;
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="show-details-popup"
      centered
    >
      <section className="wrapper container-fluid">
        <img
          src={close}
          className="close-btn-main"
          onClick={() => props.closeFunc(false)}
          onHide={() => props.closeFunc(false)}
          alt=""
        />

        <div className="row align-items-center">
          <div className="col-sm-4 d-none d-md-block">
            <div className="poster-img">
              <img src={props.showDetails.movieImg} alt="Poster 2" className="" />
            </div>
          </div>
          <div className="col-sm-8">
            <h4 className="heading">Forgotten Christmas</h4>
            <div className="tags">
              {props.showDetails.tags.map((tag) => (
                <span className="">{tag}</span>
              ))}
            </div>
            <div className="features">
              <div className="features-part">
                {props.showDetails.features.map((feature) => (
                  <div>
                    <img src={calendar} alt="calendar" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="px-3 w-100">
            <h6 className="sub-heading">{t('Choose Language')}</h6>
            <div className="btn-main-group">
              {props.showDetails.languages.map((language) => (
                // add class active in button element when selected
                <button className="tile">{language}</button>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="px-3 w-100">
            <h6 className="sub-heading">{t('Choose Format')}</h6>
            <div className="btn-main-group">
              {props.showDetails.formats.map((format) => (
                // add class active in button element when selected
                <button className="tile format">{format}</button>
              ))}
            </div>
          </div>
        </div>

        <div className="row pt-2">
          <div className="px-3 mt-4 mx-auto">
            {/* add class inactive when it is not selectable */}
            <button className="btn-main">Confirm</button>
          </div>
        </div>
      </section>
    </Modal>
  );
}
