import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Slider from 'react-slick';
import CenteredTitle from '@components/partials/CenteredTitle';
import AboutUsCTA from './AboutUsCTA';
import useIsMobile from '@src/customHooks/useIsMobile';

// Images
import seatsImg from '@assets/pictures/about-us.png';
import seatsImg1 from '@assets/pictures/about-us-1.png';
import seatsImg2 from '@assets/pictures/about-us-2.png';
import seatsImg3 from '@assets/pictures/about-us-3.png';
import seatsImg4 from '@assets/pictures/about-us-4.png';
import { ReactComponent as TrailerIcon } from '@assets/pictures/svgs/play_new.svg';
import ModalVideo from 'react-modal-video';

import { RenderInWindow } from '@components/partials/RenderInWindow';

const AboutUsBookingType = (props) => {
  const {
    t,
    classes = '',
    type = '',
    titleFirstHalf = '',
    titleSecondHalf = '',
    description = '',
    onGetStarted,
    reverse = false,
    videoId = '',
    url = '',
  } = props;
  const [swiping, setSwiping] = useState(false);

  const [isOpen, setOpen] = useState(false);

  const handleSwipe = () => {
    setSwiping(true);
  };
  const isMobile = useIsMobile();

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImage = (type = '') => {
    switch (type) {
      case 'crowdsourced':
        return seatsImg1;
      case 'private':
        return seatsImg2;
      case 'watch_party':
        return seatsImg3;
      default:
        return seatsImg4;
    }
  };

  return (
    <>
      {/*{isOpen && <RenderInWindow url={url} />}*/}

      <ModalVideo
        autoplay
        channel={'vimeo'}
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />

      <section
        className={`container-fluid about_us_booking_type ${classes} ${type}`}
      >
        <div className="row">
          <article className="col-xl-10 ml-auto">
            <section className={`row ${reverse ? 'flex-row-reverse' : ''}`}>
              <article
                className={`col-md-7 mb-4 mb-md-0 ${
                  reverse ? 'pl-md-5' : 'pr-md-5'
                }`}
              >
                <div className={`${reverse && !isMobile ? 'pl-3' : 'pr-3'}`}>
                  <div
                    className="d-flex"
                    style={{
                      alignItems: isMobile ? undefined : 'center',
                      flexWrap: isMobile ? 'wrap' : undefined,
                      flexDirection: isMobile ? 'column' : undefined,
                    }}
                  >
                    <CenteredTitle
                      firstHalf={titleFirstHalf}
                      secondHalf={titleSecondHalf}
                    />

                    <div
                      onClick={() => {
                        setOpen(!isOpen);
                      }}
                      className={`d-flex slide-animation trans pointer ${
                        reverse ? 'watch_party svg path' : ''
                      }`}
                      style={{
                        alignItems: 'center',

                        gap: '8px',

                        marginLeft: isMobile ? '0px' : '10px',
                        marginBottom: isMobile ? '10px' : '20px',
                      }}
                    >
                      <TrailerIcon width={25} />

                      <h5 style={{ marginBottom: '0px' }}>{t('Watch Clip')}</h5>
                    </div>
                  </div>
                  <p className="about_us_booking_type_synopsis">{description}</p>
                  <AboutUsCTA onClick={onGetStarted} text={t('Get Started')} />
                </div>
              </article>
              <article className="col-md-5">
                {/* <Slider {...heroSlider}>
                            {Array(4).fill(0).map((item, index) => (
                                <article key={index}> */}
                <figure onClick={handleClick}>
                  <img src={getImage(type)} alt="" />
                </figure>
                {/* </article>
                            ))}
                        </Slider> */}
              </article>
            </section>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsBookingType);
