import React, { useEffect, useState } from 'react';
import { LanguageService } from '@apiService/tokenService';
import GenericService from '@apiService/GenericService';

export const globalConfigContext = React.createContext();

const isArabic = () => {
  const language = LanguageService.getLanguage();
  if (language == 'ar' || language == 'ab') {
    return true;
  } else {
    return false;
  }
};

const GlobalConfigContext = (props) => {
  const setMerchantIdAndAccessCode = (merchant_id, access_code) => {
    if (!merchant_id) return;
    setState((prev) => ({
      ...prev,
      payment_data: {
        ...prev.payment_data,
        merchant_identifier: merchant_id,
        access_code,
      },
    }));
  };

  const [state, setState] = useState({
    arabic: isArabic(),
    country_selector_url: null,
    currency: {},
    payment_data: {},
    country_data: {},
    countries: [],
    site_data: {},
    setMerchantIdAndAccessCode,
  });

  useEffect(() => {
    GenericService.GetCountries().then((resp) => {
      const { data } = resp;
      if (data?.data?.length > 0) {
        let [nepal, others] = data.data.reduce(
          ([n, o], country) => {
            country?.country_mob_code === '977' ? n.push(country) : o.push(country);
            return [n, o];
          },
          [[], []],
        );

        data.data = [...nepal, ...others];

        setState((prev) => ({ ...prev, countries: data.data }));
      }
    });
  }, []);

  return (
    <globalConfigContext.Provider value={state}>
      {props.children}
    </globalConfigContext.Provider>
  );
};

export default GlobalConfigContext;
