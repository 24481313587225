import React, { useEffect, useState, useRef, useContext } from 'react';

import { withNamespaces } from 'react-i18next';

function CreateScreen({ t }) {
  useEffect(() => {}, []);
  return (
    <div>
      <h4>Create Screen </h4>
    </div>
  );
}

export default withNamespaces()(CreateScreen);
