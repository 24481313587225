import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className="accordion-title-custom"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="custom_acc_title">{title}</div>
        <div className="custom_acc_btn">{isActive ? '-' : '+'}</div>
      </div>
      {isActive && (
        <div
          className="accordion-content"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      )}
    </div>
  );
};

export default Accordion;
