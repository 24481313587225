import _ from 'lodash';

export const timeConvert = (n) => {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + 'h' + rminutes + 'm';
};

export const getYoutubeVideoCode = (link) => {
  if (!link) {
    return '';
  }

  let code = null;
  link = link.trim();
  if (link.indexOf('youtube.com') !== -1) {
    let res = link.split('=');
    code = res[1].split('&')[0];
  } else {
    let res = link.split('/');
    code = res[3];
  }
  return code;
};

// https://www.youtube.com/watch?v=oImLGeUkWnk

export const getMobileNumber = (number) => {
  if (!number) {
    return [null, null];
  }

  if (number.length === 0) {
    return [number];
  } else {
    let country_code = number.substring(0, number.length - 9);
    let mobile_number = number.substring(number.length - 9);
    if (country_code && country_code.startsWith('+')) {
      country_code = country_code.substring(1, country_code.length);
    }
    return [mobile_number, country_code];
  }
};

export const isImageURLNull = (url) => {
  if (!url || url.endsWith('null') || url.endsWith('undefined')) {
    return true;
  }
  return false;
};

export const formatYuppErrors = (errs) => {
  return errs && errs
    ? Object.assign({}, ...errs.inner.map((obj) => ({ [obj.path]: obj })))
    : null;
};

export const toggleReadMore = (
  e,
  description,
  showMoreText = 'read more',
  showLessText = 'read less',
) => {
  let descEle = e.target.parentElement.querySelector('.movie-description');
  if (descEle) {
    if (descEle.classList.contains('show-more')) {
      e.target.innerText = showLessText;
      descEle.classList.remove('show-more');
      descEle.classList.add('show-less');
      descEle.innerText = description;
    } else {
      e.target.innerText = showMoreText;
      descEle.classList.remove('show-less');
      descEle.classList.add('show-more');
      descEle.innerText = readMore(description, 50);
    }
  }
};

const something = () => {
  document.querySelector('#readmore').addEventListener('click', (e) => {
    e.target.innerText = 'read less';
    console.log('click');
  });
};

export const toggleRM = function (id, description, words) {
  console.log(`#${id}`);
  const targetElems = document.querySelectorAll(`#${id}`);

  targetElems.forEach(function randomFunc(targetElem) {
    console.log({ targetElem });
    if (description.length > words) {
      let spanRm = document.createElement('span');
      spanRm.setAttribute('id', 'read-more');
      spanRm.innerText = '...read more';
      if (targetElem) {
        targetElem.innerText = description.slice(0, parseInt(words));
        targetElem.appendChild(spanRm);
        spanRm.addEventListener('click', (e) => {
          if (e.target.parentElement === targetElem) {
            targetElem.innerText = description;
            let spanRl = document.createElement('span');
            spanRl.setAttribute('id', 'read-less');
            spanRl.innerText = ' read less';
            targetElem.appendChild(spanRl);
            spanRl.addEventListener('click', () => {
              targetElem.removeChild(spanRl);
              randomFunc(targetElem);
            });
          }
        });
      }
    } else {
      if (targetElem) {
        targetElem.innerText = description;
      }
    }
  });
  // this.appendChild(span);
  // readMore(description, words);
};

export const readMore = (description, words) => {
  if (description.length > words) {
    let span = document.createElement('span');
    span.setAttribute('id', 'readmore');
    span.innerText = '...read more';
    description = description + span.innerText;
    // this.appendChild(span);
    console.log(`this: ${this}`);
  }
  console.log(description.length);
  return description;
};

export function titleCase(word) {
  if (!word) return;
  const sentence = String(word)?.toLowerCase()?.split(' ');
  for (let i = 0; i < sentence.length; i++) {
    if (sentence[i]) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
  }

  return sentence.join(' ');
}

export const headerBg = () => {
  // document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelectorAll('.main-menu');
  window.addEventListener('scroll', function () {
    let scroll = window.scrollY;
    if (!header[0].classList.contains('scrolled') && scroll > 15) {
      header[0].classList.add('scrolled');
    } else if (scroll < 15) {
      header[0].classList.remove('scrolled');
    }
    if (!header[1].classList.contains('scrolled') && scroll > 10) {
      header[1].classList.add('scrolled');
    } else if (scroll < 10) {
      header[1].classList.remove('scrolled');
    }
  });
  // });
};

export const slImageOnhover = () => {
  document.querySelectorAll('#hover-image').forEach((item) => {
    item.addEventListener('mouseenter', (e) => {
      e.target.nextSibling.style.visibility = 'visible';
      e.target.nextSibling.style.opacity = '1';
    });
    item.addEventListener('mouseleave', (e) => {
      e.target.nextSibling.style.visibility = 'hidden';
      e.target.nextSibling.style.opacity = '0';
    });
  });
};

export const languages = {
  english: 'en',
  german: 'de',
  french: 'fr',
  italian: 'it',
};

export const getLangISOCode = (lang) => {
  if (!lang) {
    return 'en';
  }

  if (languages[lang.toLowerCase()]) {
    return languages[lang.toLowerCase()];
  } else {
    return 'en';
  }
};

export function checkIfURLSame(url1, url2) {
  if (!url1 || !url2) return false;

  url1 = url1.endsWith('/') ? url1.substring(0, url1.length - 1) : url1;
  url2 = url2.endsWith('/') ? url2.substring(0, url2.length - 1) : url2;

  return url1 === url2;
}

export function otpValidation(text = '') {
  if (text.length <= 6 && /^[a-zA-Z0-9]+$/.test(text)) {
    return true;
  }

  return false;
}

export function generateMovieVersionsText(
  region_name,
  audio_lang_iso_3,
  sub1_iso_3,
  sub2_iso_3,
  audio_lang_iso_2,
) {
  if (!audio_lang_iso_2) audio_lang_iso_2 = 'OV';
  else audio_lang_iso_2 = audio_lang_iso_2.toUpperCase();

  let movie = {
    audio_lang: audio_lang_iso_3 ? audio_lang_iso_3.toUpperCase() : null,
    subtitle1: sub1_iso_3 ? sub1_iso_3.toUpperCase() : null,
    subtitle2: sub2_iso_3 ? sub2_iso_3.toUpperCase() : null,
    lang_region: region_name ? region_name.toLowerCase() : null,
  };

  let version = 'OV';
  let countries = ['Switzerland'];
  countries.map((country) => {
    if (country === 'Switzerland') {
      if (movie.lang_region === 'de') {
        if (movie.audio_lang == 'ENG') {
          if (
            (movie.subtitle1 === 'DEU' && movie.subtitle2 === 'FRA') ||
            (movie.subtitle1 === 'FRA' && movie.subtitle2 === 'DEU')
          ) {
            version = 'E/d,f';
          } else if (movie.subtitle1 === 'DEU') {
            version = 'Ed';
          } else if (movie.subtitle1 === 'FRA') {
            version = 'Ef';
          } else if (movie.subtitle1 === 'ITA') {
            version = 'E/i';
          } else {
            version = 'EN';
          }
        } else if (movie.audio_lang === 'DEU') {
          if (movie.subtitle1 === 'FRA') {
            version = 'Df';
          } else {
            version = 'D';
          }
        } else if (movie.audio_lang === 'FRA') {
          if (movie.subtitle1 === 'DEU') {
            version = 'Fd';
          } else {
            version = 'F';
          }
        } else if (movie.audio_lang === 'GSW') {
          /*else if (movie.audio_lang === "ITA") {
          if (
            (movie.subtitle1 === "DEU" && movie.subtitle2 === "FRA") ||
            (movie.subtitle1 === "FRA" && movie.subtitle2 === "DEU")
          ) {
            version = "itall./df";
          } else if (movie.subtitle1 === "DEU") {
            version = "itall./d";
          } else if (movie.subtitle1 === "FRA") {
            version = "itall./f";
          } else {
            version = "ital.";
          }
        }*/
          version = 'D-CH';
        } else {
          if (
            (movie.subtitle1 === 'DEU' && movie.subtitle2 === 'FRA') ||
            (movie.subtitle1 === 'FRA' && movie.subtitle2 === 'DEU')
          ) {
            version = `${audio_lang_iso_2}/d,f`;
          } else if (movie.subtitle1 === 'DEU') {
            version = `${audio_lang_iso_2}/d`;
          } else if (movie.subtitle1 === 'FRA') {
            version = `${audio_lang_iso_2}/f`;
          } else {
            version = `${audio_lang_iso_2}`;
          }
        }
      } else if (movie.lang_region === 'fr') {
        if (movie.audio_lang == 'FRA') {
          version = 'Vf';
        } else {
          if (
            (movie.subtitle1 === 'DEU' && movie.subtitle2 === 'FRA') ||
            (movie.subtitle1 === 'FRA' && movie.subtitle2 === 'DEU')
          ) {
            version = 'VOdf';
          } else if (movie.subtitle1 === 'FRA') {
            version = 'VOf';
          } else if (movie.subtitle1 === 'DEU') {
            version = 'VOd';
          } else if (movie.subtitle1 === 'ITA') {
            version = 'VOi';
          } else {
            version = 'VO';
          }
        }
      } else if (movie.lang_region === 'it') {
        if (movie.audio_lang == 'ITA') {
          version = 'VI';
        } else {
          version = 'VO';
        }
      }
    }
  });

  if (!version) return version;

  return String.prototype.replaceAll
    ? version.replaceAll('/', '').replaceAll(',', '')
    : version.split('/').join('').split(',').join('');
}

export function getMovieVersionsText(
  movie_versions = [],
  region_name,
  with_format = true,
) {
  let versions_text_arr = [];

  movie_versions.forEach((movie_version) => {
    let {
      movie_lang,
      movie_lang_iso_3,
      sub_lang_1,
      sub_lang_1_iso_3,
      sub_lang_2,
      sub_lang_2_iso_3,
      movie_lang_iso_2,
    } = movie_version;

    let version = generateMovieVersionsText(
      region_name,
      movie_lang_iso_3,
      sub_lang_1_iso_3,
      sub_lang_2_iso_3,
      movie_lang_iso_2 ?? '',
    );

    if (with_format && movie_version.mf_name && movie_version.mf_name !== '2D') {
      versions_text_arr.push(`${version} (${movie_version.mf_name})`);
    } else {
      versions_text_arr.push(version);
    }
  });

  // return versions_text_arr.join(" / ");

  // temporarily
  return versions_text_arr;
}

export const getLabeledGroupedData = (dataArray = []) => {
  let movieLabelsArray = [];
  let groupedData = [];
  let withoutLabels = [];
  let labelList = [];
  if (dataArray.length > 0) {
    if (
      dataArray.hasOwnProperty('movie_labels_with_translations') &&
      dataArray.movie_labels_with_translations.length > 0
    ) {
      movieLabelsArray = [
        ...new Set(dataArray.movie_labels_with_translations.map((l) => l.label)),
      ];
      labelList = labelList.concat([
        ...new Set(
          dataArray.movie_labels_with_translations.map((l) => {
            return { label: l.label, order: l.order };
          }),
        ),
      ]);

      labelList = [
        ...new Map(labelList.map((item) => [item['label'], item])).values(),
      ];

      withoutLabels = dataArray
        .filter((data) => data.movie_labels.length == 0)
        .map((z) => {
          return { ...z, label: 'Others', order: 0 };
        });

      groupedData.push(withoutLabels);

      labelList.forEach((x) => {
        let tempArray = [];

        tempArray = dataArray
          .filter((data) => data.movie_labels.some((ml) => ml == x.label))
          .map((z) => {
            return { ...z, label: x.label, order: x.order };
          });

        groupedData.push(tempArray);
      });

      return groupedData;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export function movieLanguagesWithSubs(
  lang_name = '',
  sub_lang_1_iso_2 = '',
  sub_lang_2_iso_2 = '',
) {
  return `${!!lang_name ? lang_name : ''}${
    !!sub_lang_1_iso_2 ? `/${sub_lang_1_iso_2}` : ''
  }${!!sub_lang_1_iso_2 && !!sub_lang_2_iso_2 ? `-${sub_lang_2_iso_2}` : ''}`;
}

export function getLabelToshow(labels) {
  let tempArray = [];
  if (labels?.length > 0) {
    let sortedArray = _.sortBy(labels, (e) => e.order);
    let first_label_id = sortedArray[0]?.label_id;
    tempArray = labels?.filter((x) => x.label_id == first_label_id);
  } else {
    tempArray = [];
  }
  return tempArray;
}
