import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import civil_mall_banner from '@assets/pictures/civil-mall-banner.png';
import civil_mall_banner1 from '@assets/pictures/civil-mall-1.png';
import civil_mall_banner2 from '@assets/pictures/civil-mall-2.png';
import civil_mall_banner3 from '@assets/pictures/civil-mall-3.png';
import { BASEURL, HOST_URL } from '../config/index';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
          <CenteredTitle firstHalf={t('QFX CIVIL MALL')} />
        </div>
        <div className="banner_static_page">
          <img src={civil_mall_banner} className="img-fluid" />
        </div>
        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                <h3
                  className={`font-weight-bold text-primary ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  QFX Cinemas Civil, is located in the heart of the city making its
                  mark in the movie industry as the preferred hub for people from
                  all walks of life.
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img
                  src={civil_mall_banner1}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>

            {/* Third Row */}

            <div
              className={`row ${
                isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
              }`}
            >
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img
                  src={civil_mall_banner2}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  Today it is beautifully refurbished, maximizing the movie going
                  experience with premium sofa seats and wider leg space, great
                  technology that harmonizes 360 sound and 3D imagery along with 4K
                  projection!
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  And ambience and culinary offering to enjoy, which as relaxing as
                  being at home!
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img
                  src={civil_mall_banner3}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>

            <div class="pt-5">
              <p className={`${isMobile || isTablet ? 'mt-4 m-4' : ''}`}>
                <li>
                  Regular ticket rates apply for afternoon, evening and night shows.
                </li>
                <li>Rates are inclusive of all taxes.</li>
                <li>We offer 50% discount on morning shows.</li>
                <li>
                  The discount is calculated based on the regular ticket rate for
                  the day..
                </li>
                <li>No discounts are applicable for Premium Category.</li>
              </p>
              <br></br>
              <p>
                <b>Address:</b> 7th Floor Civil Mall, Sundhara, Kathmandu, Nepal.
                <br></br>
                <b>Email:</b> marketing@qfxcinemas.com{' '}
              </p>
            </div>

            <div class="mt-3">
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                For Ticket Price{' '}
                <span
                  onClick={() => history.push('/TicketRates')}
                  className="text-primary font-weight-bold"
                >
                  Click Here !
                </span>
              </p>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
