import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Our_Story_03 from '@assets/pictures/Our-Story_03.png';
import Our_Story_06 from '@assets/pictures/Our-Story_06.png';
import Our_Story_13 from '@assets/pictures/Our-Story_13.png';
import Our_Story_18 from '@assets/pictures/Our-Story_18.png';
import Our_Story_21 from '@assets/pictures/Our-Story_21.png';
import Our_Story_09 from '@assets/pictures/Our-Story_09.png';
import Our_Story_25 from '@assets/pictures/Our-Story_25.png';
import Our_Story_28 from '@assets/pictures/Our-Story_28.png';
import Our_Story_33 from '@assets/pictures/Our-Story_33.png';
import Our_Story_39 from '@assets/pictures/Our-Story_39.png';
import BageshWori from '@assets/pictures/BageshWori.png';
import gogi_loyalty from '@assets/pictures/brand-immersive-4.png';
import One_stop_mall from '@assets/pictures/One_stop_mall.png';
import bhaktapur from '@assets/pictures/bhaktapur.png';
import butwal from '@assets/pictures/butwal.png';
import Birtnagar from '@assets/pictures/Birtnagar.png';
import Damauli from '@assets/pictures/Damauli.jpg';
import Durbar from '@assets/pictures/Durbar.png';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import '@assets/css/styles.23f55d086d63f25d9b4d.css';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={t('Our Brand Story')}
          />
          <CenteredTitle firstHalf={t('Our Brand Story')} />
        </div>
        <section _ngcontent-c15="" className="body-inner">
          <div
            _ngcontent-c15=""
            className="body-inner-story section-pad-top section-pad-bottom"
          >
            <header _ngcontent-c15="" className="header-banner">
              <div _ngcontent-c15="" className="container">
                {/* <h2 _ngcontent-c15="">OUR STORY</h2> */}
                <h4 _ngcontent-c15="">
                  The journey of how our brand, QFX Cinemas came to be one of
                  Nepal's biggest cinema chain.
                </h4>
              </div>
            </header>
            <div _ngcontent-c15="" className="container">
              <div _ngcontent-c15="" className="cntl">
                <span _ngcontent-c15="" className="cntl-bar cntl-center">
                  <span
                    _ngcontent-c15=""
                    className="cntl-bar-fill"
                    style={{ height: '2352.41px' }}
                  ></span>
                </span>
                <div _ngcontent-c15="" className="cntl-states">
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Jai Nepal
                      </h1>
                      <p _ngcontent-c15="">
                        Pioneers in changing movie-going experience by refurbishing
                        Jai Nepal as one of Nepal's first modern cinema theaters.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_03}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2002</h1>
                        <h4 _ngcontent-c15="">Jul</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Kumari
                      </h1>
                      <p _ngcontent-c15="">
                        Remodeled Kumari to give multiple movie options under the
                        same roof with a modern movie experience.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_06}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2004 </h1>
                        <h4 _ngcontent-c15="">Jan</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        3D Experience
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas becomes the first cinema theater to project a 3D
                        movie in Nepal.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_13}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2010</h1>
                        <h4 _ngcontent-c15="">Mar</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        Online Presence
                      </h1>
                      <p _ngcontent-c15="">
                        To pace up with the growing cinema industry, QFX Cinemas
                        launched an online booking and ticketing system.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_18}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2010</h1>
                        <h4 _ngcontent-c15="">Mar</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        Club QFX
                      </h1>
                      <p _ngcontent-c15="">
                        Keeping customers at the center of our service, QFX Cinemas
                        launched the industry's first Loyalty Program - Club QFX -
                        the purpose of which was to reward customers for their
                        loyalty.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_21}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2010</h1>
                        <h4 _ngcontent-c15="">Mar</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Cinemas
                        <br _ngcontent-c15="" />
                        as a Brand
                      </h1>
                      <p _ngcontent-c15="">
                        The brand QFX Cinemas emerged with the launch of the first
                        Digital Cineplex in Civil Mall. Jai Nepal and Kumari too
                        were rebranded at the same time.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_09}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2010</h1>
                        <h4 _ngcontent-c15="">Oct</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Beyond <br _ngcontent-c15="" />
                        Kathmandu
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas Jalma in Narayangarh was our first cinema
                        theater outside the capital.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_25}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2015</h1>
                        <h4 _ngcontent-c15="">Jun</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Cinemas <br _ngcontent-c15="" />
                        App Launch
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas launched a mobile application to further add
                        convenience to the customers in the growing online medium.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image small-img">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_28}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2016</h1>
                        <h4 _ngcontent-c15="">Mar</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        QFX Labim
                      </h1>
                      <p _ngcontent-c15="">
                        For an advance and elevated movie-going experience, we
                        launched another multiplex at Labim Mall, Pulchowk
                        completely redefining contemporary cinemas in Nepal.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_33}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2016</h1>
                        <h4 _ngcontent-c15="">Apr</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Bageshwori
                      </h1>
                      <p _ngcontent-c15="">
                        In efforts to expand the QFX brand to regions across Nepal,
                        we opened QFX Bageshwori as a state-of-the-art cinema hall
                        in Nepalgunj.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={BageshWori}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2016</h1>
                        <h4 _ngcontent-c15="">Aug</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Cineplex
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cineplex is launched in Pokhara to meet the growing
                        demand of modern cinemas across Nepal.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Our_Story_39}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2016</h1>
                        <h4 _ngcontent-c15="">Dec</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        Goji Loyalty
                      </h1>
                      <p _ngcontent-c15="">
                        We launched a revamped version of Club QFX, the Goji
                        Loyalty, with its very own mobile application and website to
                        make the rewarding experience for our returning customer's
                        simpler and more efficient.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={gogi_loyalty}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2017</h1>
                        <h4 _ngcontent-c15="">Jun</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX One Stop (Birtamod)
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas opens at Birtamod, being at the center of Jhapa
                        district which is connected to all parts of the country by a
                        central highway.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={One_stop_mall}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2018</h1>
                        <h4 _ngcontent-c15="">Oct</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Bhaktapur
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas, launches its first Multiplex with Nepal’s
                        largest retail chain Bhatbhateni Supermarket in Bhaktapur.
                        Our first theater with recliner seats to meet the comfort of
                        our customer.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={bhaktapur}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2019</h1>
                        <h4 _ngcontent-c15="">Nov</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Butwal
                      </h1>
                      <p _ngcontent-c15="">
                        QFX Cinemas opens at Bhatbhateni Supermarket Butwal to meet
                        the needs for modern cinemas with the rapidly growing
                        urbanization.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={butwal}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2019</h1>
                        <h4 _ngcontent-c15="">Dec</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Biratnagar
                      </h1>
                      <p _ngcontent-c15="">
                        It is showtime in Biratnagar with the opening of new QFX
                        Cinemas at Bhatbhateni Supermarket complex, providing modern
                        movie experience to our beloved customers.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Birtnagar}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2019</h1>
                        <h4 _ngcontent-c15="">Dec</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        QFX Damauli
                      </h1>
                      <p _ngcontent-c15="">
                        We are expanding movie experience for our customers as we
                        open new QFX Cinemas in Apex Mall, Damauli.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Damauli}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2021</h1>
                        <h4 _ngcontent-c15="">Nov</h4>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-c15=""
                    className="cntl-state cntl-animate mb-5"
                    inviewport=""
                  >
                    <div _ngcontent-c15="" className="cntl-content">
                      <h1 _ngcontent-c15="" className="custom_line_height">
                        {' '}
                        Durbar Cinemax managed by QFX
                      </h1>
                      <p _ngcontent-c15="">
                        Experience comfort, luxury, ambience and feel relaxed
                        watching your favorite movies at our premium theatre with
                        all recliner seats and in-theatre services in heart of city
                        Durbar Marg.
                      </p>
                    </div>
                    <div _ngcontent-c15="" className="cntl-image">
                      <img
                        _ngcontent-c15=""
                        src={Durbar}
                        className="custom_img_about"
                      />
                    </div>
                    <div _ngcontent-c15="" className="cntl-icon cntl-center">
                      <div _ngcontent-c15="">
                        <h1 _ngcontent-c15="">2022</h1>
                        <h4 _ngcontent-c15="">Apr</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
