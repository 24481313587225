import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';

const selectedSeatsSelector = (state, type = 'schedule', iso_2 = 'en') => {
  if (type === 'schedule' && state?.movies?.scheduleDetails?.selectedSeats)
    return [state.movies.scheduleDetails.selectedSeats, iso_2];

  if (type === 'final_ticket' && state?.movies?.bookingDetails?.selectedSeats)
    return [state.movies.bookingDetails.selectedSeats, iso_2];

  return [[], iso_2];
};

function extractSeatTypes(selectedSeats) {
  let result = _.chain(selectedSeats)
    .groupBy('screen_seat_type_id')
    .toPairs()
    .map((x) => ({
      sst_seat_type: x[1][0].sst_seat_type,
      seats: x[1],
    }))
    .map((x) => {
      x.seats = _.chain(x.seats)
        .groupBy('rate_card_group_child_id')
        .toPairs()
        .map((x) => ({
          tt_name: x[1][0].tt_name,
          ticket_type_translations: x[1][0].ticketTypeTranslations,
          no_of_seats: x[1].length,
          single_seat_price: x[1][0].rc_total_amount,
          single_seat_net_price: x[1][0].rc_net_amount,
          total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
          seats: x[1],
          seatName: x[1].reduce((acc, x) => acc + x.sl_seat_name, 0),
        }))

        .value();
      return x;
    })
    .value();
  let resultseatname = [];
  result.map((a) => {
    a['newSeatsName'] = [];
    a.seats.map((x) => {
      x.seats.map((y) => {
        a['newSeatsName'].push(y.sl_seat_name);
      });
    });
  });
  // console.log(["newSeatsName"].toString().split(',').join(', '), 'resultseatname');
  // console.log(result, 'gfgfgffgfgfg');
  return result;
}

export const seatTypesSelector = createSelector(
  selectedSeatsSelector,
  ([selectedSeats]) => {
    if (selectedSeats && selectedSeats.length > 0) {
      let result = extractSeatTypes(selectedSeats);
      return result;
    } else {
      return [];
    }
  },
);

// export const compactSeatTypes = createSelector(
//   seatTypesSelector,
//   (seatTypes) => {
//     let finalResult = [];
//     for (let seatType of seatTypes) {
//       let seats = seatType.seats.map((x) => `${x.no_of_seats} ${x.tt_name}`);
//       finalResult.push(`${seatType.sst_seat_type} - ${seats.join(", ")}`);
//     }

//     return finalResult;
//   }
// );

// used only in this file
export const seatTypesSelectorWithIso2 = createSelector(
  selectedSeatsSelector,
  ([selectedSeats, iso_2]) => {
    if (selectedSeats && selectedSeats.length > 0) {
      let result = extractSeatTypes(selectedSeats);
      return [result, iso_2];
    } else {
      return [[], iso_2];
    }
  },
);

export const compactSeatTypes = createSelector(
  seatTypesSelectorWithIso2,
  ([seatTypes, iso_2]) => {
    let finalResult = [];
    for (let seatType of seatTypes) {
      let seats = seatType.seats.map(
        (x) =>
          `${x.no_of_seats} ${
            x?.ticket_type_translations?.[iso_2?.toLowerCase()] || x?.tt_name
          }`,
      );
      finalResult.push({
        ticket_type: seatType.sst_seat_type,
        seat_types: seats,
      });
    }

    return finalResult;
  },
);
