import MovieService from '@apiService/MovieService';
import {
  FETCH_CASH_CARD_DETAILS,
  FETCH_CASH_CARD_DETAILS_SUCCESS,
  FETCH_CASH_CARD_DETAILS_ERROR,
  SET_CASHCARD_TOPUP_AMOUNT,
  SET_CASHCARD_BUY_AMOUNT,
  SET_CASHCARD_NAME_TO_PRINT,
  SET_CASHCARD_BUY_QUANTITY,
  SET_CASHCARD_REDEMPTION_AMOUNT,
  FETCH_CASH_CARD_GALLERY,
  FETCH_CASH_CARD_GALLERY_SUCCESS,
  FETCH_CASH_CARD_GALLERY_ERROR,
  FETCH_WEBSITE_CASH_CARD,
  FETCH_WEBSITE_CASH_CARD_SUCCESS,
  FETCH_WEBSITE_CASH_CARD_ERROR,
  FETCH_CASH_CARD_RESERVATION,
  FETCH_CASH_CARD_RESERVATION_SUCCESS,
  FETCH_CASH_CARD_RESERVATION_ERROR,
  FETCH_CASH_CARD_BUY_TRX,
  FETCH_CASH_CARD_BUY_TRX_SUCCESS,
  FETCH_CASH_CARD_BUY_TRX_ERROR,
} from './cashCardTypes';
import { toast } from 'react-toastify';
import moment from 'moment';
import { BASEURL, TOKEN } from '@config';
import { getLangISOCode } from '../../helper/formatting';
import { useSelector } from 'react-redux';
import CashCardService from '../../ApiServices/CashCardService';
import { SET_TIMEOUT_ERROR, SET_TIMER } from '../movies/movieTypes';
import { startTimerAction } from '../movies/movieActions';

// export const setVouchersError = (payload) => ({
//   type: 'SET_REDEMPTION_ERROR',
//   payload,
// });
//
// export const setRedemptionAmount = (payload) => ({
//   type: 'SET_REDEMPTION_AMOUNT',
//   payload,
// });

export const fetchWebsiteCashCardByUniqueId =
  (w_cash_card_unique_id) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_WEBSITE_CASH_CARD });
      if (!w_cash_card_unique_id) {
        toast.dismiss();
        toast.error('Something went wrong');
        return { status: false, message: 'Something went wrong' };
      }

      let store = getStore();
      const { data } = await CashCardService.GetWebsiteCashCardByUniqueId(
        w_cash_card_unique_id,
      );

      if (data.status) {
        if (data?.Records?.length && data?.card_issue_time) {
          data.Records.forEach((c) => {
            c.card_issue_time = data?.card_issue_time;
          });
        }
        if (data?.Records?.length && data?.card_issue_time) {
          data.Records.forEach((c) => {
            c.card_issue_date_only = data?.card_issue_date_only;
          });
        }

        dispatch({
          type: FETCH_WEBSITE_CASH_CARD_SUCCESS,
          payload: data?.Records[0],
        });

        return { status: true };
      } else {
        dispatch({
          type: FETCH_WEBSITE_CASH_CARD_ERROR,
          payload: data?.message,
        });
        toast.dismiss();
        toast.error(data?.message ?? 'Something went wrong');

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_WEBSITE_CASH_CARD_ERROR,
        payload: error?.message ?? error,
      });
      toast.dismiss();
      toast.error(error?.message ?? error);

      return { status: false, message: error?.message ?? error };
    }
  };

export const fetchCashCardGallery = () => async (dispatch, getStore) => {
  try {
    dispatch({ type: FETCH_CASH_CARD_GALLERY });

    let store = getStore();
    const { data } = await CashCardService.GetCashCardGallery();

    if (data.status) {
      dispatch({
        type: FETCH_CASH_CARD_GALLERY_SUCCESS,
        payload: data?.Records,
      });

      return { status: true };
    } else {
      dispatch({ type: FETCH_CASH_CARD_GALLERY_ERROR, payload: data?.message });
      toast.dismiss();
      toast.error(data?.message ?? 'Something went wrong');

      return { status: false, message: data?.message };
    }
  } catch (error) {
    dispatch({
      type: FETCH_CASH_CARD_GALLERY_ERROR,
      payload: error?.message ?? error,
    });
    toast.dismiss();
    toast.error(error?.message ?? error);

    return { status: false, message: error?.message ?? error };
  }
};

export const fetchCashCardDetailsFromCardNumber =
  (payload) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_CASH_CARD_DETAILS });

      let store = getStore();

      const { data } = await CashCardService.GetCardDetailFromCardNumber(payload);

      if (data.status) {
        dispatch({
          type: FETCH_CASH_CARD_DETAILS_SUCCESS,
          payload: data?.Records,
        });

        return { status: true };
      } else {
        dispatch({
          type: FETCH_CASH_CARD_DETAILS_ERROR,
          payload: data?.message,
        });
        // toast.dismiss();
        // toast.error(data.message ?? "Something went wrong");

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASH_CARD_DETAILS_ERROR,
        payload: error?.message ?? error,
      });

      return { status: false, message: error?.message ?? error };
    }
  };

export const fetchCashCardDetailsFromCardNumberPin =
  (payload) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_CASH_CARD_DETAILS });

      let store = getStore();

      const { data } = await CashCardService.GetCardDetailFromCardNumberPin(
        payload,
      );

      if (data.status) {
        dispatch({
          type: FETCH_CASH_CARD_DETAILS_SUCCESS,
          payload: data?.Records,
        });

        return { status: true };
      } else {
        dispatch({
          type: FETCH_CASH_CARD_DETAILS_ERROR,
          payload: data?.message,
        });
        // toast.dismiss();
        // toast.error(data.message ?? 'Something went wrong');

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASH_CARD_DETAILS_ERROR,
        payload: error?.message ?? error,
      });

      return { status: false, message: error?.message ?? error };
    }
  };

export const addTopUpToCashCard = (payload) => async (dispatch, getStore) => {
  try {
    let res = await CashCardService.AddTopup(payload);
    let { data } = res;

    if (data?.status) {
      return { status: true, message: data?.message };
    } else {
      toast.dismiss();
      toast.error(data.message ?? 'Something went wrong');

      return { status: false, message: data?.message };
    }
  } catch (error) {
    return { status: false, message: error?.message ?? error };
  }
};

export const buyCashCardReserve = (payload) => async (dispatch, getStore) => {
  try {
    let res = await CashCardService.ReserveBuyCashCard(payload);
    let { data } = res;

    if (data?.status) {
      console.log({ data });
      return { status: true, message: data?.message, data };
    } else {
      toast.dismiss();
      toast.error(data.message ?? 'Something went wrong');

      return { status: false, message: data?.message };
    }
  } catch (error) {
    return { status: false, message: error?.message ?? error };
  }
};

export const fetchReserveBuyCashCard =
  (reservation_id) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_CASH_CARD_RESERVATION });

      let res = await CashCardService.GetReserveBuyCashCard(reservation_id);
      let { data } = res;

      if (data?.status) {
        // dispatch data to store
        console.log({ data });

        dispatch({
          type: FETCH_CASH_CARD_RESERVATION_SUCCESS,
          payload: {
            ...data.Records?.[0],
            currentDateTime: data.currentDateTime,
            GlobalTime: data?.GlobalTime?.[0],
          },
        });

        dispatch({
          type: SET_TIMER,
          payload: {
            minutes: data.Records[0].timer_minutes,
            seconds: data.Records[0].timer_second,
          },
        });
        dispatch(startTimerAction());

        return { status: true, message: data?.message, data };
      } else {
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
        dispatch({
          type: FETCH_CASH_CARD_RESERVATION_ERROR,
          payload: data?.message ?? 'Something went wrong',
        });
        // toast.dismiss();
        // toast.error(data.message ?? "Something went wrong");

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASH_CARD_RESERVATION_ERROR,
        payload: error?.message ?? error,
      });
      return { status: false, message: error?.message ?? error };
    }
  };

export const fetchCashCardBuyTransactionDetail =
  (reservation_id) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_CASH_CARD_BUY_TRX });

      let res = await CashCardService.GetBuyCashCardTrx(reservation_id);
      let { data } = res;

      if (data?.status) {
        dispatch({
          type: FETCH_CASH_CARD_BUY_TRX_SUCCESS,
          payload: data?.Records || [],
        });

        return {
          status: true,
          message: data?.message,
          payload: data?.Records || [],
        };
      } else {
        dispatch({
          type: FETCH_CASH_CARD_BUY_TRX_ERROR,
          payload: data?.message ?? 'Something went wrong',
        });

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASH_CARD_BUY_TRX_ERROR,
        payload: error?.message ?? error,
      });
      return { status: false, message: error?.message ?? error };
    }
  };

export const fetchCashCardTopupTransactionDetail =
  (topup_code) => async (dispatch, getStore) => {
    try {
      dispatch({ type: FETCH_CASH_CARD_BUY_TRX });

      let res = await CashCardService.GetTopupCashCardTrx(topup_code);
      let { data } = res;

      if (data?.status) {
        dispatch({
          type: FETCH_CASH_CARD_BUY_TRX_SUCCESS,
          payload: data?.Records || [],
        });

        return {
          status: true,
          message: data?.message,
          payload: data?.Records || [],
        };
      } else {
        dispatch({
          type: FETCH_CASH_CARD_BUY_TRX_ERROR,
          payload: data?.message ?? 'Something went wrong',
        });

        return { status: false, message: data?.message };
      }
    } catch (error) {
      dispatch({
        type: FETCH_CASH_CARD_BUY_TRX_ERROR,
        payload: error?.message ?? error,
      });
      return { status: false, message: error?.message ?? error };
    }
  };
