import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import Dropdown from 'react-bootstrap/Dropdown';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import useIsTablet from '@src/customHooks/useIsTablet';
import InlineScroll from '@components/partials/InlineScroll';
// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import spend from '@assets/pictures/spend_loyalty.png';
import earn from '@assets/pictures/earn_loyalty.png';
import download from '@assets/pictures/download_loyalty.png';
import redeem from '@assets/pictures/reedem_loyalty.png';
import mug from '@assets/pictures/mug-loyalty.png';
import qfx_ticket from '@assets/pictures/qfx_ticket-loyalty.png';
import combo from '@assets/pictures/combo_6-loyalty.png';
import club_points from '@assets/pictures/club_points_loyalty.png';
import club_qfx from '@assets/pictures/club_qfx_loyalty.png';
import qr_loyalty from '@assets/pictures/qr_loyalty.png';
const AboutLoyalty = ({ t }) => {
  const isMobile = useIsMobile();

  const [show, setShow] = useState('');

  const handleshowhide = (event) => {
    const getCinemaelectedValue = event.target.value;
    console.log(getCinemaelectedValue, 'ffffffffff');
    setShow(getCinemaelectedValue);
  };
  const isTablet = useIsTablet();
  /* let openings = [
    {
      openingsFor: [
        'Opening Hours',
      ],
      hours: [
        'Monday to Thursday - 11:30-21:00',
        'Friday and Saturday - 11:30-23:00',
        'Sunday - 13:00-21:00',
      ]
    },
    {
      openingsFor: [
        'Opening Hours for',
        'Screening'
      ],
      hours: [
        'Monday to Thursday - 09:00-21:00',
        'Friday - 09:00-01:30',
        'Saturday - 10:30-01:30',
        'Sunday - 12:00-21:00',
      ]
    }
  ] */

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('More')} >`}
          secondHalf={t('Gift & Vouchers')}
        />
        <CenteredTitle firstHalf={t('About Loyalty')} secondHalf={''} />
      </div>

      <div className="container-fluid opening_hours">
        <section className="row">
          <div className="col-12">
            <h4 className="ab_loyalty_heading">
              SPEND MORE , EARN MORE , REDEEM MORE
            </h4>
          </div>
        </section>
        <section className={`row ${isMobile ? 'mt-2' : 'mt-5'}`}>
          <div className="col-xl-4 col-md-4">
            <div className="ab_circle">
              Club QFX <br />
              <span>bronze</span>
            </div>
            <p className="ab_points">
              Earn 3% reward points on movie Tickets and 3% on F&B Purchase
            </p>
          </div>

          <div className="col-xl-4 col-md-4">
            <div className="ab_circle">
              Club QFX <br />
              <span>silver</span>
            </div>
            <p className="ab_points">
              Earn 4% reward points on movie Tickets and 4% on F&B Purchase
            </p>
          </div>

          <div className="col-xl-4 col-md-4">
            <div className="ab_circle">
              Club QFX <br />
              <span>gold</span>
            </div>
            <p className="ab_points">
              Earn 5% reward points on movie Tickets and 5% on F&B Purchase
            </p>
          </div>
        </section>

        <section className={`row ${isMobile ? 'my-2' : 'my-5'}`}>
          <div className="col-12">
            <h4 className="ab_loyalty_heading">WORKINGS</h4>
          </div>
        </section>
        <section className="row">
          <div className="col-xl-3 col-md-6 br_right">
            <div className="d-flex justify-content-center align-items-center">
              <img src={download} className="heading_img" />

              <h5 className="ab_loyalty_heading mob_heading_loyalty">
                DOWNLOAD AND ENROLL
              </h5>
            </div>
            <p className="ab_points">
              Download our QFX cinemas app and register to automatically enroll in
              Club QFX Loyalty Program.
            </p>
            <img src={qr_loyalty} className="img-fluid mob_qr_img" />
          </div>

          <div className="col-xl-3 col-md-6 br_right">
            <div className="d-flex justify-content-center align-items-center">
              <img src={spend} className="heading_img" />

              <h5 className="ab_loyalty_heading mob_heading_spen_loyalty">SPEND</h5>
            </div>

            <p className="ab_points">
              Purchase Movie Tickets, Food & Beverage and Merchandise
            </p>
            <div className="d-flex flex-wrap justify-content-center align-items-center custom_loyalty_margin">
              <div className="spend_img_wrapper">
                <img src={qfx_ticket} className="loyalty_ticket_img" />
                <img src={combo} className="loyalty_combo_img" />
                <div className="d-flex justify-content-center align-items-center">
                  <img src={mug} className="loyalty_mug_img" />
                </div>
              </div>
              <div></div>
            </div>
          </div>

          <div className="col-xl-3 col-md-6 br_right">
            <div className="d-flex justify-content-center align-items-center custom_mob_height">
              <img
                src={earn}
                className={`${
                  isMobile || isTablet ? 'earn_heading_img' : 'heading_img'
                }`}
              />

              <h5 className="ab_loyalty_heading mob_earn_heading_loyalty"> EARN</h5>
            </div>

            <p className="ab_points">
              Collect Point and level up to higher tiers for better offers.
            </p>
            <img src={club_qfx} className="img-fluid mob_qr_img" />
          </div>

          <div className="col-xl-3 col-md-6">
            <div className="d-flex justify-content-center align-items-center custom_mob_height_redeem">
              <img
                src={redeem}
                className={`${
                  isMobile || isTablet ? 'earn_heading_img' : 'heading_img'
                }`}
              />
              <h5 className="ab_loyalty_heading mob_rdeem_heading_loyalty">
                {' '}
                REDEEM LOYALTY POINTS
              </h5>
            </div>

            <p className="ab_points">Redeem your club QFX loyalty points</p>
            <img src={club_points} className="img-fluid mob_qr_img" />
          </div>
        </section>

        <section
          className={`row ${
            isMobile
              ? 'my-2'
              : isTablet
              ? 'flex-nowrap my-5'
              : 'my-5 justify-content-between'
          }`}
        >
          {!isMobile && !isTablet ? (
            <section className="col-xl-4 ab_define_wrapper">
              <h6 className="text-center invisible" style={{ padding: '15px' }}>
                CLUB QFX <br />
                <span>BRONZE</span>
              </h6>
              <p className="white_space">Point eligibility criteria</p>
              <p className="white_space">Point Validity</p>
              <p className="white_space">
                Earn Points On Movie Tickets As per spending
              </p>
              <p className="white_space">
                Earn Points On Food & Beverages As per spending
              </p>
              <p className="white_space">Maximum Earning Points per Spending</p>
              <p className="white_space">Maximum Points Earned</p>
              <p className="white_space">Bonus for birthdays (Validity 30 days)</p>
              <p className="white_space">
                Marriage Anniversaries (Validity 30 days)
              </p>
            </section>
          ) : (
            <></>
          )}
          <div className="col-xl-2 ab_define_wrapper ab_card">
            <h6 className="text-center">
              CLUB QFX <br />
              <span>BRONZE</span>
            </h6>
            {!isMobile && !isTablet ? (
              <>
                <p className="text-center">0 to 149</p>
                <p className="text-center">1 year</p>
                <p className="text-center">3%</p>
                <p className="text-center">3%</p>
                <p className="text-center">5000</p>
                <p className="text-center">150</p>
                <p className="text-center">-</p>
                <p className="text-center">-</p>
              </>
            ) : (
              <>
                <p className="text-center">
                  Point eligibility criteria: <br />0 to 149
                </p>
                <p className="text-center">
                  Point Validity: <br />1 year
                </p>
                <p className="text-center">
                  Earn Points On Movie Tickets As per spending:
                  <br /> 3%
                </p>
                <p className="text-center">
                  Earn Points On Food & Beverages As per spending:
                  <br /> 3%
                </p>
                <p className="text-center">
                  Maximum Earning Points per Spending: <br />
                  5000
                </p>
                <p className="text-center">
                  Maximum Points Earned:
                  <br /> 150
                </p>
                <p className="text-center ">
                  Bonus for birthdays (Validity 30 days):
                  <br /> -
                </p>
                <p className="text-center">
                  Marriage Anniversaries (Validity 30 days):
                  <br /> -
                </p>
              </>
            )}
            {/* {isMobile ||
              (isTablet && (
             
              ))} */}
          </div>

          <div className="col-xl-2 ab_define_wrapper ab_card ">
            <h6 className="text-center">
              CLUB QFX <br />
              <span>SILVER</span>
            </h6>
            {!isMobile && !isTablet ? (
              <>
                <p className="text-center">150 to 799</p>
                <p className="text-center">1 year</p>
                <p className="text-center">4%</p>
                <p className="text-center">4%</p>
                <p className="text-center">5000</p>
                <p className="text-center">200</p>
                <p className="text-center pb-0 white_space">
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
                <p className="text-center white_space">
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
              </>
            ) : (
              <>
                <p className="text-center">
                  Point eligibility criteria: <br />
                  150 to 799
                </p>
                <p className="text-center">
                  Point Validity: <br />1 year
                </p>
                <p className="text-center">
                  Earn Points On Movie Tickets As per spending:
                  <br /> 4%
                </p>
                <p className="text-center">
                  Earn Points On Food & Beverages As per spending: <br />
                  4%
                </p>
                <p className="text-center">
                  Maximum Earning Points per Spending:
                  <br /> 5000
                </p>
                <p className="text-center">
                  Maximum Points Earned:
                  <br /> 200
                </p>
                <p className="text-center pb-0">
                  Bonus for birthdays (Validity 30 days):
                  <br />
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
                <p className="text-center">
                  Marriage Anniversaries (Validity 30 days):
                  <br />
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
              </>
            )}
          </div>

          <div className="col-xl-2  ab_define_wrapper ab_card">
            <h6 className="text-center">
              CLUB QFX <br />
              <span>GOLD</span>
            </h6>
            {!isMobile && !isTablet ? (
              <>
                <p className="text-center">800 and above</p>
                <p className="text-center">1 year</p>
                <p className="text-center">5%</p>
                <p className="text-center">5%</p>
                <p className="text-center">5000</p>
                <p className="text-center">250</p>
                <p className="text-center pb-0 white_space">
                  1 ticket on anydays,
                  <br />
                  2d + 3d
                </p>
                <p className="text-center white_space">
                  1 ticket on anydays,
                  <br />
                  2d + 3d
                </p>
              </>
            ) : (
              <>
                <p className="text-center">
                  <b>Point eligibility criteria:</b> <br /> 800 and above
                </p>
                <p className="text-center">
                  <b>Point Validity:</b> <br />1 year
                </p>
                <p className="text-center">
                  <b>Earn Points On Movie Tickets As per spending:</b> <br /> 5%
                </p>
                <p className="text-center">
                  <b>Earn Points On Food & Beverages As per spending:</b> <br /> 5%
                </p>
                <p className="text-center">
                  <b>Maximum Earning Points per Spending:</b> <br />
                  5000
                </p>
                <p className="text-center">
                  <b>Maximum Points Earned:</b> <br />
                  250
                </p>
                <p className="text-center pb-0">
                  <b>Bonus for birthdays (Validity 30 days):</b>
                  <br />
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
                <p className="text-center">
                  <b>Marriage Anniversaries (Validity 30 days):</b>
                  <br />
                  1 ticket on weekdays,
                  <br />
                  2d + 3d
                </p>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(AboutLoyalty);
