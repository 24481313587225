import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const LanguageVersions = ({ t }) => {
  let versions = [
    { symbol: 'D', language: 'German' },
    { symbol: 'CH-D', language: 'German' },
    { symbol: 'E', language: 'English' },
    { symbol: 'F', language: 'French' },
    { symbol: 'ital', language: 'Italian' },
    { symbol: 'Truk', language: 'Turkish' },
  ];

  return (
    <>
      <div className="language_versions_wrapper align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={t('Language Versions')}
          />
          <CenteredTitle firstHalf={t('Language Versions')} secondHalf="" />
        </div>
        <section className="row lang_back">
          <article className="col-xl-10 mx-auto">
            <p className="language_versions_highlight mb-4">
              {t('LangVersion.Text', { version: 'Eng/de,fr' })}
            </p>

            <p>
              {t('LangVersion.Text1', {
                audioLangSymbol: 'E',
                audioLang: 'English',
              })}
            </p>
            <p className="mb-4">
              {t('LangVersion.Text2', { subtitles: 'd,f' })}
              <span className="language_versions_highlight text-primary"></span>
            </p>

            <p className="language_versions_highlight mb-4">
              {t('LangVersion.Text3')}
            </p>
            <section className="row">
              <article className="col-lg-10 col-xl-9">
                <div className="language_versions">
                  {versions.map((item, index) => (
                    <article key={index} className="language_version">
                      <div>{item.symbol}</div>
                      <span>{t(item.language)}</span>
                    </article>
                  ))}
                </div>
              </article>
            </section>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(LanguageVersions);
