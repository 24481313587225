import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import OneStopMall_mall_banner from '@assets/pictures/OneStopMall-banner.png';
// import chhaya_mall_banner1 from '@assets/pictures/chhya-1.png';
// import chhya_mall_banner2 from '@assets/pictures/chhya-2.png';
// import chhya_mall_banner3 from '@assets/pictures/chhya-3.png';
// import chhya_mall_banner4 from '@assets/pictures/chhya-4.png';
import { BASEURL, HOST_URL } from '../config/index';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
          <CenteredTitle firstHalf={t('QFX BUTWAL CENTER')} />
        </div>
        <div className="banner_static_page">
          <img src={OneStopMall_mall_banner} className="img-fluid" />
        </div>
        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                <h3
                  className={`font-weight-bold text-primary ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX OneStopMall
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <div class="pt-4">
                  <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                    {/* Situated in Thamel, the designated tourist destination of
                  Kathmandu Valley, QFX Chhaya Center is a big draw for local
                  tourists alike. */}
                    <li>
                      Regular ticket rates apply for afternoon, evening and night
                      shows.
                    </li>
                    <li>Rates are inclusive of all taxes.</li>
                  </p>
                  <br></br>
                  <p>
                    <b>Address:</b> Krishna Hall Road, Birtamode, Nepal<br></br>
                    <b>Email:</b> marketing@qfxcinemas.com{' '}
                  </p>
                  {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
                </div>
              </div>
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                {/* <img
                  src={Butwal_mall_banner}
                  className="img-fluid custom_img_about"
                /> */}
              </div>
            </div>

            {/* Third Row */}

            <div
              className={`row ${
                isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
              }`}
            >
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                {/* <img
                  src={chhya_mall_banner2}
                  className="img-fluid custom_img_about"
                /> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  {/* Being the only cinema in this destination, we made sure you
                  experience the most plush, aesthetic designs and comfort - with
                  unique stories in the wall murals, to offer a glimpse of Thamel’s
                  heritage and evolution. */}
                </p>

                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  {/* Enjoy crisp audio and superb sound clarity is one of Chhaya’s
                  theaters! Made possible with Dolby Atmos sound technology and THX
                  baffle walls that create and immersive experience */}
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                {/* <img
                  src={chhya_mall_banner3}
                  className="img-fluid custom_img_about"
                /> */}
              </div>
            </div>

            {/* Fifith Row */}

            <div
              className={`row ${
                isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
              }`}
            >
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                {/* <img
                  src={chhya_mall_banner4}
                  className="img-fluid custom_img_about"
                /> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  {/* Our ‘exclusive seating’ further enhances the overall movie going
                  experience to make it truly memorable and lasting. */}
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            <div>
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                For Ticket Price{' '}
                <span
                  onClick={() => history.push('/TicketRates')}
                  className="text-primary font-weight-bold"
                >
                  Click Here !
                </span>
              </p>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
