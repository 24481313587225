import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CountryCodeDropdown from '../partials/CountryCodeDropdown';
import useIsMobile from '../../customHooks/useIsMobile';
import moment from 'moment';
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Button,
} from 'react-bootstrap';
import redoEdit from '@assets/pictures/svgs/redo-edit.svg';
import { updateUser } from '../../store/auth/authActions';
import { withNamespaces } from 'react-i18next';

const EditAccount = ({ setToggleEdit, ...props }) => {
  const isMobile = useIsMobile();
  const { t } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [middleName, setmiddleName] = useState(``);
  const [editable, setEditable] = useState(false);
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [phone_no, setPhone_no] = useState(``);
  const [gender, setgender] = useState(``);
  const [anniversary, setanniversary] = useState(null);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const loginDetails = useSelector((state) => state.auth);
  const [dob, setdob] = useState(null);
  const [updateddob, setupdateddob] = useState(null);
  const { loginError, loggedInUser, guestUser } = loginDetails;

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  useEffect(() => {
    if (loggedInUser) {
      setFirstName(loggedInUser?.first_name);
      setmiddleName(loggedInUser?.mid_name);
      setLastName(loggedInUser?.last_name);
      setEmail(loggedInUser?.cust_email);
      setgender(loggedInUser?.gender);
      setanniversary(loggedInUser?.cust_anniversary_date);
      setPhone_no(loggedInUser?.cust_mobile);
      setdob(loggedInUser?.dob);
      // setupdateddob(loggedInUser?.cust_dob);
    } else if (guestUser) {
      setFirstName(guestUser?.guest_first_name);
      setLastName(guestUser?.guest_last_name);
      setEmail(guestUser?.guest_email);
      setPhone_no(guestUser?.guest_mobile);
      // setdob(loggedInUser?.dob);
    }
  }, [loggedInUser, guestUser]);
  const onClickUpdateUser = () => {
    if (loggedInUser && !guestUser) {
      dispatch(
        updateUser({
          first_name: firstName,
          last_name: lastName,
          mid_name: middleName,
          callback: setToggleEdit,
          email,
          phone_no,
          gender,
          dob,
          cust_anniversary_date: moment(anniversary).format('YYYY-MM-DD'),
        }),
      );
      setEditable(false);
    }
  };

  return (
    <>
      <div className={`custom-form col-md-8 ${isMobile ? '' : 'col-6'}`}>
        <div>
          <label>{t('login_form.First Name')}</label>
          <input
            type="text"
            className="form-control"
            value={firstName}
            required
            disabled={!loggedInUser && guestUser ? true : undefined}
            onChange={(e) => {
              setFirstName(e.target.value);
              setEditable(true);
            }}
          />
        </div>

        <div>
          <label>{t('Middle Name')}</label>
          <input
            type="text"
            className="form-control"
            value={middleName}
            disabled={!loggedInUser && guestUser ? true : undefined}
            onChange={(e) => {
              setmiddleName(e.target.value);
              setEditable(true);
            }}
          />
        </div>

        <div>
          <label>{t('login_form.Last Name')}</label>
          <input
            type="text"
            className="form-control"
            value={lastName}
            disabled={!loggedInUser && guestUser ? true : undefined}
            onChange={(e) => {
              setLastName(e.target.value);
              setEditable(true);
            }}
          />
        </div>

        <div className="">
          <label>{t('Email')}</label>
          <input
            type="text"
            className="form-control"
            value={email}
            required
            disabled
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="">
          <label className="new_head">{t('Date Of Birth')}</label>

          <input
            className="form-control"
            type="date"
            id="start"
            name="dateofbirth"
            placeholder={t('login_form.DateOfBirth')}
            min="1920-01-01"
            max={moment().format('YYYY-MM-DD')}
            value={moment(dob).format('YYYY-MM-DD') ?? loggedInUser?.dob}
            onChange={(e) => {
              setdob(e?.target?.value);
              setEditable(true);
            }}
          />
        </div>

        <div className="">
          <label className="new_head">{t('Anniversary Date')}</label>

          <input
            className="form-control"
            type="date"
            id="anniversary"
            name="anniversarydate"
            placeholder={t('Enter Your Anniversary Date')}
            // min="1920-01-01"
            max={moment().format('YYYY-MM-DD')}
            value={
              moment(anniversary).format('YYYY-MM-DD') ??
              loggedInUser?.cust_anniversary_date
            }
            onChange={(e) => {
              setanniversary(e?.target?.value);
              setEditable(true);
            }}
          />
        </div>

        <div className="">
          <label className="new_head">{t('Gender')}</label>

          <select
            value={gender}
            className={'form-control'}
            onChange={(e) => {
              setgender(e?.target?.value);
              setEditable(true);
            }}
          >
            <option value="" disabled selected hidden>
              Select Your Gender
            </option>
            <option value={'M'}>Male</option>
            <option value={'F'}>Female</option>
            <option value={'O'}>Others</option>
            <option value={'D'}>Do not want to disclose</option>
          </select>
        </div>

        <div className="">
          <label className="new_head">{t('login_form.Phone Number')}</label>
          <div className="d-flex custom-form align-items-center">
            {/* <CountryCodeDropdown
            className="form-control mr-2"
            onSelectCountry={({ country_id, country_mob_code }) => {
              setCountryId(country_id);
              setCountryMobCode(country_mob_code);
            }}
          /> */}
            {loggedInUser?.country_mob_code && (
              <input
                type="number"
                name=""
                id=""
                disabled
                value={loggedInUser?.country_mob_code}
                className="form-control mr-2 w-25 mb-0"
              />
            )}
            <input
              type="text"
              className="form-control mb-0"
              value={phone_no}
              required
              min="10"
              disabled
              onChange={(e) => {
                setPhone_no(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {false && (
        <div className={`${isMobile ? 'col-md-8' : 'col-8'}`}>
          <label className="new_head">{t('login_form.Phone Number')}</label>
          <div className="d-flex custom-form">
            {/* <CountryCodeDropdown
            className="form-control mr-2"
            onSelectCountry={({ country_id, country_mob_code }) => {
              setCountryId(country_id);
              setCountryMobCode(country_mob_code);
            }}
          /> */}
            {loggedInUser?.country_mob_code && (
              <input
                type="number"
                name=""
                id=""
                disabled
                value={loggedInUser?.country_mob_code}
                className="form-control mr-2 w-25"
              />
            )}
            <input
              type="text"
              className="form-control"
              value={phone_no}
              required
              min="10"
              disabled
              onChange={(e) => {
                setPhone_no(e.target.value);
              }}
            />
          </div>
        </div>
      )}
      {editable && (
        <div className="d-flex justify-content-center align-items-center mb-4 mb-md-5 flex-column flex-md-row">
          <button
            className="btn-main mr-md-4 mb-3 mb-md-0"
            onClick={() => onClickUpdateUser()}
          >
            {t('Confirm')}
          </button>
          <button
            className="btn-basic"
            onClick={() => {
              setToggleEdit(false);
              setEditable(false);
            }}
          >
            {capitalizeFirstLetter(t('common.CANCEL'))}
          </button>
        </div>
      )}
    </>
  );
};

const UserSettings = (props) => {
  //Start Edit Account
  //End Edit Account
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const [toggleEdit, setToggleEdit] = useState(false);
  const { t } = props;
  return (
    <div className="user-settings">
      {false && (
        <div className="header">
          <h5 className="font-weight-bold">{t(`User Settings`)}</h5>
          {/* <img
          src={redoEdit}
          alt="redo-edit"
          className="redo-icon"
          onClick={() => setToggleEdit((prev) => !prev)}
        /> */}
        </div>
      )}
      <div className="content">
        {/* <div>
          <p>
            {t("login_form.First Name")} {t("login_form.Last Name")}
          </p>
          <p>
            {loggedInUser?.first_name} {loggedInUser?.last_name}
          </p>
        </div>
        <div>
          <p>{t("Email")}</p>
          <p>{loggedInUser?.cust_email}</p>
        </div>
        <div>
          <p>{t("login_form.Phone Number")}</p>
          <p>{loggedInUser?.cust_mobile}</p>
        </div> */}
        {/* <div className="close-account text-right">
          <span className="text-primary font-weight-bold">
            Close my account
          </span>
        </div> */}
        {/* {toggleEdit && ( */}
        <EditAccount t={t} setToggleEdit={setToggleEdit} />
        {/* )} */}
      </div>
    </div>
  );
};

export default withNamespaces()(UserSettings);
