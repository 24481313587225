import Api from './Api';
import { createQueryString } from './ApiHelper';
import moment from 'moment';
import {
  addTopUpToCashCard,
  fetchWebsiteCashCardByUniqueId,
} from '../store/cashCard/cashCardActions';

export default {
  GetCardDetailFromCardNumber(payload) {
    const { cash_card_number } = payload;
    return Api().get(`/api/get-cash-card-detail/${cash_card_number}/`);
  },
  GetCardDetailFromCardNumberPin(payload) {
    return Api().post(`/api/get-cash-card-balance`, payload);
  },
  AddTopup(payload) {
    return Api().post(`/api/pos/credit-cash-card`, payload);
  },
  CreateTopupStripePaymentIntent(payload) {
    return Api().post(
      `/api/external/create-stripe-payment-intent-for-top-up`,
      payload,
    );
  },
  CreateTopupSaferPay(payload) {
    return Api().post(`/api/cinema/cash-card-saferpay-recharge`, payload);
  },
  CreateSaferBuyCashCardPayRedirect(payload) {
    const { reservation_id } = payload;
    return Api().post(
      `/api/cinema/cash-card-saferpay-purchase/${reservation_id}`,
      payload,
    );
  },
  CreateStripeCashCardPay(payload) {
    const { reservation_id } = payload;
    return Api().post(`/api/buy_cash_card/${reservation_id}`, payload);
  },
  GetCashCardGallery() {
    return Api().get(`/api/get-website-cash-card?filter_is_active=Y`);
  },
  GetWebsiteCashCardByUniqueId(w_cash_card_unique_id) {
    return Api().get(`/api/get-website-cashcard-detail/${w_cash_card_unique_id}`);
  },
  ReserveBuyCashCard(payload) {
    return Api().post(`/api/cash-card-reserve`, payload);
  },
  GetReserveBuyCashCard(reservation_id) {
    return Api().get(`/api/get-cashcard-reservation-detail/${reservation_id}/`);
  },
  GetBuyCashCardTrx(reservation_id) {
    return Api().get(`/api/get-cashcard-transaction-detail/${reservation_id}/`);
  },
  GetTopupCashCardTrx(topup_code) {
    return Api().get(`/api/get-cashcard-topup-detail?topup_code=${topup_code}`);
  },
};
