import React, { useState, useEffect } from 'react';

function useCountries(currentCountryCode, setCurrentCountryCode) {
  const [countries, setCountries] = useState(null);

  useEffect(() => {}, []);

  return countries;
}

export default useCountries;
