import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

// Components
import Slider from 'react-slick';

// Images
import { ReactComponent as CloseIcon } from '@assets/pictures/svgs/close.svg';
import seatsImg from '@assets/pictures/about-us.png';

const AboutUsGallery = (props) => {
  const { t, classes, show = false, onClose = () => {}, selectedScreen } = props;
  const [swiping, setSwiping] = useState(false);
  const handleSwipe = () => {
    setSwiping(true);
  };
  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [images, setImages] = useState([]);
  useEffect(() => {
    if (selectedScreen) {
      let images = [];
      images.push(selectedScreen.screen_image_url);
      // images.push(selectedScreen.screen_image_url);
      // images.push(selectedScreen.screen_image_url);
      setImages(images);
    }
  }, [selectedScreen]);

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            className="container-fluid about_us_gallery_wrapper"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.15 }}
            exit={{ scale: 0.8, opacity: 0 }}
            key="gallery"
          >
            <CloseIcon onClick={onClose} />
            <div className="about_us_gallery">
              <section className="row">
                <article className="col-lg-10 col-xl-8 mx-auto">
                  <Slider {...heroSlider}>
                    {images.map((item, index) => (
                      <article key={index}>
                        <figure>
                          <img src={item} alt="" />
                        </figure>
                      </article>
                    ))}
                  </Slider>
                </article>
              </section>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default AboutUsGallery;
