import { IMG_BASEURL, BASEURL } from '@src/config';
import crypto from 'crypto';
// const NodeRSA = require('node-rsa');

export const hasStaring = (castCrewArray) => {
  let count = 0;
  castCrewArray &&
    castCrewArray.forEach((cast) => cast?.crew_title !== `Director` && count++);
  return count > 0;
};

export const hasDirector = (castCrewArray) => {
  let count = 0;
  castCrewArray &&
    castCrewArray.forEach((cast) => cast?.crew_title === `Director` && count++);
  return count > 0;
};

export const sortAlphabetically = (property) => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (a.lang_id && b.lang_id) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  };
};

export function getSeatIconUrl(icon, BASEURLConfig = IMG_BASEURL) {
  if (!icon) return '';
  if (icon?.startsWith('http')) return icon;

  let base = BASEURLConfig;

  // if (!!IMG_BASEURL) base = IMG_BASEURL

  if (base?.endsWith('/')) base = base?.substring(0, base?.length - 1);

  let i = icon;
  if (!i?.startsWith('/')) i = `/${i}`;

  return `${base}${i}`;
}

export const encryptPayload = (keys = { key: '', iv: '' }, data) => {
  //   const key = new NodeRSA(publicKey, 'pkcs8-public-pem');
  // const encryptedData = key.encrypt(data, 'base64');
  // return encryptedData;
  if (!keys.key && !keys.iv) return false;
  const key = Buffer.from(keys.key, 'hex');
  const iv = Buffer.from(keys.iv, 'hex');
  const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
  let encryptedData = cipher.update(data, 'utf8', 'hex');
  encryptedData += cipher.final('hex');
  // console.log({ encryptedData }, "encryptedResponsed");
  return encryptedData.trim();
};

export const decryptPayload = (encryptedData, keys = { key: '', iv: '' }) => {
  // keys.key = 'de44244c7f47a8cc514f4cd145bf6017c73ba7170fd5646e66c8d2955ceb53e2';
  // keys.iv = '96d4e410bdd0ceab47149d315629747d';
  if (!keys.key && !keys.iv) return false;
  const key = Buffer.from(keys.key, 'hex');
  const iv = Buffer.from(keys.iv, 'hex');

  const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
  const encryptedDataButtfer = Buffer.from(encryptedData.trim(), 'hex');
  let decrypted = decipher.update(encryptedDataButtfer, 'base64', 'utf8');
  decrypted += decipher.final('utf8');
  // console.log({ decrypted, encryptedData });
  return decrypted;
};
