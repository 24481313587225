import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

//Components
import CustomInput from '@components/partials/CustomInput';
import DayPicker from 'react-day-picker';
import Button from '@components/partials/Button';

// Images
import { ReactComponent as LeftArrow } from '@assets/pictures/svgs/slider-arrow-left.svg';
import { emailRegex } from '../../../store/auth/authActions';
import Spinner from '../../partials/Spinner';
import { buyCashCardReserve } from '../../../store/cashCard/cashCardActions';
import { SET_CASHCARD_NAME_TO_PRINT } from '../../../store/cashCard/cashCardTypes';

const CashCardStepTwo = (props) => {
  const { t, classes = '' } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  let { w_cash_card_unique_id } = useParams();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id, lang_name, iso_2 } = selected_language;

  const [submitted, setsubmitted] = useState(false);
  const [loading, setloading] = useState(false);
  const [isGift, setisGift] = useState(false);
  const [specialMessage, setspecialMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [nameToPrint, setnameToPrint] = useState('');
  const [email, setemil] = useState('');
  const [phone, setphone] = useState('');
  const [countryCode, setcountryCode] = useState('');
  const [address, setaddress] = useState('');
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);

  const [isDayPickerUsed, setDayPickerUsed] = useState(false);
  const [calDate, changeCalDate] = useState(null);

  const websiteCashCard = useSelector((s) => s.cashCard.websiteCashCard) ?? [];
  const websiteCashCardLoading =
    useSelector((s) => s.cashCard.websiteCashCardLoading) ?? false;
  const cashCardBuySelection = useSelector(
    (state) => state.cashCard.cashCardBuySelection,
  );

  function cashCardReserve() {
    setsubmitted(true);
    let count = 0;

    if (!nameToPrint) {
      console.log({ nameToPrint });
      ++count;
    }
    if (!email) {
      console.log({ email });
      ++count;
    }
    if (!emailRegex(email)) {
      console.log({ email });
      ++count;
    }
    if (!phone) {
      console.log({ phone });
      ++count;
    }
    if (!address) {
      console.log({ address });
      ++count;
    }
    if (!cashCardBuySelection?.quantity) {
      console.log({ quantity: cashCardBuySelection?.quantity });
      ++count;
    }
    if (!cashCardBuySelection?.amount) {
      console.log({ amount: cashCardBuySelection?.amount });
      ++count;
    }
    if (!calDate || !selectedDate) {
      console.log({ calDate, selectedDate });
      ++count;
    }
    if (count > 0) {
      return;
    } else setloading(true);

    let payload = {
      w_cash_card_unique_id: w_cash_card_unique_id,
      quantity: cashCardBuySelection?.quantity,
      amount: cashCardBuySelection?.amount,
      receipeint_detail: {
        name_on_card: nameToPrint.trim(),
        email: email.trim(),
        address: address.trim(),
        date_issue_card: moment(calDate).format('YYYY-MM-DD'),
        status: 'Pending',
        is_gift_card: !!isGift ? 'Y' : 'N',
        specialMessage: !!isGift ? specialMessage.trim() : '',
        phone,
        countryId,
        countryMobCode,
        selectedDate,
        // cashSelection: cashCardBuySelection,
        websiteCashCard,
      },
    };

    // dispatch card reservation
    dispatch(buyCashCardReserve(payload))
      .then(({ status, message, data }) => {
        if (!!status) {
          history.push(`/cash-card-payment/${data.reservation_id}`);
        } else {
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    if (
      selectedDate == null &&
      (websiteCashCard?.card_issue_date_only || websiteCashCard?.card_issue_time)
    ) {
      setSelectedDate(
        moment(
          new Date(
            websiteCashCard?.card_issue_date_only ||
              websiteCashCard?.card_issue_time,
          ),
        ).format('DD-MM-YY'),
      );
    }

    if (
      calDate == null &&
      (websiteCashCard?.card_issue_date_only || websiteCashCard?.card_issue_time)
    ) {
      changeCalDate(
        new Date(
          websiteCashCard?.card_issue_date_only || websiteCashCard?.card_issue_time,
        ),
      );
    }
  }, [websiteCashCardLoading, websiteCashCard]);

  if (websiteCashCardLoading)
    return (
      <div className="row">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  return (
    <>
      <div className={`gift_card_step_two ${classes}`}>
        <section className="row">
          <article className="col-lg-6 col-xl-5">
            <div className="checkbox-row-pink">
              {' '}
              {/*agree-check   ml-3*/}
              <label className="custom-check-pink">
                <input
                  type="checkbox"
                  name="slabCheckbox"
                  id="exampleRadios1"
                  checked={isGift}
                  onChange={(e) => {
                    console.log({
                      isGift,
                      'e.target.checked': e.target.checked,
                    });
                    setisGift(e.target.checked);
                  }}
                />
                <span />
              </label>
              <p className="ml-2">{t('Send as Gift')}</p>
              {/*<input
                    type="checkbox"
                    name="slabCheckbox"
                    id="exampleRadios1"
                    checked={isGift}
                    onChange={(e) => {
                      setisGift(e.target.checked)

                    }}
                  />

              <label
                className="form-check-label pt-1 pl-1"
                htmlFor="exampleRadios1"
              >
                {t("Send as Gift")}
                  </label>*/}
            </div>
          </article>
        </section>

        <section className="row">
          <article className="col-lg-6 col-xl-5">
            <CustomInput
              type="text"
              label={t('Name/text')}
              subLabel={t('to be printed on the gift card')}
              placeholder={
                !!isGift
                  ? t('Enter Recipient Name')
                  : t('Enter your name/text here')
              }
              errorMsg={t('Please enter name/text')}
              errorStatus={submitted && !nameToPrint}
              value={nameToPrint}
              onChange={(e) => {
                dispatch({
                  type: SET_CASHCARD_NAME_TO_PRINT,
                  payload: e?.target?.value ?? '',
                });
                setnameToPrint(e?.target?.value ?? '');
              }}
            />
          </article>

          <article className="col-lg-6 col-xl-5">
            <CustomInput
              type="email"
              label={t('login_form.Email')}
              placeholder={t('login_form.placeholders.Enter your email')}
              errorMsg={t('Please enter email')}
              errorStatus={
                (submitted && !emailRegex(email)) || (submitted && !email)
              }
              value={email}
              onChange={(e) => {
                setemil(e?.target?.value ?? '');
              }}
            />
          </article>

          <article className="col-lg-6 col-xl-5">
            <CustomInput
              type="number"
              label={t('login_form.Phone Number')}
              placeholder={t('login_form.placeholders.Enter your phone number')}
              errorMsg={t('login_form.error_messages.Phone number is required')}
              errorStatus={submitted && !phone}
              value={phone}
              onChange={(e) => {
                setphone(e?.target?.value ?? '');
              }}
              countryCode
              onSelectCountry={(onSelectCountryData) => {
                let { country_id, country_mob_code } = onSelectCountryData;
                setCountryId(country_id);
                setCountryMobCode(country_mob_code);
              }}
            />
          </article>

          <article className="col-lg-6 col-xl-5">
            <CustomInput
              label={t('Address')}
              placeholder={t('Enter your address')}
              errorMsg={t('Please enter address')}
              errorStatus={submitted && !address}
              textarea
              value={address}
              onChange={(e) => {
                setaddress(e?.target?.value ?? '');
              }}
            />
          </article>
        </section>

        <section className="row align-items-center">
          {!!isGift && (
            <article className="col-lg-6 col-xl-5">
              <CustomInput
                label={t('Special Message')}
                placeholder={t('Enter your message')}
                errorMsg="Please enter message"
                errorStatus={false}
                textarea
                value={specialMessage}
                onChange={(e) => {
                  setspecialMessage(e?.target?.value ?? '');
                }}
              />
            </article>
          )}

          <article className="col-lg-6 col-xl-5 mb-4 mb-lg-0">
            <p className="">{t('When do you wish to delivered the card')}</p>
            <div
              className={`mb-3 booking_date ${showDatePicker ? 'active' : ''}`}
              onClick={(e) => e.stopPropagation()}
            >
              <p
                onClick={() => {
                  setShowDatePicker(!showDatePicker);
                }}
              >
                <span>{selectedDate}</span>
                <LeftArrow />
              </p>
              {showDatePicker && (
                <div
                  className="new-date-picker new-date-picker-mobile"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <DayPicker
                    selectedDays={calDate}
                    disabledDays={[
                      {
                        before: !!websiteCashCard?.card_issue_date_only
                          ? new Date(websiteCashCard?.card_issue_date_only)
                          : !!websiteCashCard?.card_issue_time
                          ? new Date(websiteCashCard?.card_issue_time)
                          : new Date(),
                      },
                    ]}
                    onDayClick={(date, modifiers) => {
                      setDayPickerUsed(true);
                      if (modifiers && modifiers.disabled) return;
                      changeCalDate(date);
                      setSelectedDate(moment(date).format('DD-MM-YY'));
                      setShowDatePicker(false);
                    }}
                    localeUtils={MomentLocaleUtils}
                    locale={iso_2?.toLowerCase()}
                  />
                </div>
              )}
            </div>
            <ul className="">
              <li>
                {/*{t('If not selected, the card will be sent in 4-5 business days.')}*/}
                {t('If not selected, the card will be sent by')}{' '}
                {moment(
                  websiteCashCard?.card_issue_time ||
                    websiteCashCard?.card_issue_date_only,
                ).format('DD-MM-YY')}
              </li>
              <li>{t('A processing fee will be added to checkout')}</li>
            </ul>
            <p className="need_help">{t('Need Help')}?</p>
          </article>

          <article className="col-sm-8 col-lg-6 col-xl-5 mx-auto mx-lg-0">
            <Button
              text={t('Proceed to Checkout')}
              classes={`w-100`}
              loading={loading}
              onClick={() => {
                cashCardReserve();
              }}
            />
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(CashCardStepTwo);
