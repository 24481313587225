import MovieService from '@apiService/MovieService';
import {
  FETCH_SEAT_LAYOUT,
  FETCH_SEAT_LAYOUT_SUCCESS,
  FETCH_SEAT_LAYOUT_ERROR,
  FETCH_SEAT_TYPE,
  FETCH_SEAT_TYPE_SUCCESS,
  FETCH_SEAT_TYPE_ERROR,
  SET_SEAT_SELECTED,
  CLEAR_SEAT_SELECTION,
  UPDATE_SEAT_LAYOUT,
  RESERVE_SEAT,
  RESERVE_SEAT_SUCCESS,
  RESERVE_SEAT_ERROR,
  SET_RESERVATION_ID,
  RELEASE_SEAT,
  RELEASE_SEAT_SUCCESS,
  RELEASE_SEAT_ERROR,
  SET_TEMP_RESERVATION_ID,
  SET_INTERNET_HANDLING_FEES_APPLIED,
} from './seatLayoutTypes';

import {
  SET_PRIVATE_SCREENING_TYPE_PAYLOAD,
  SET_TIMEOUT_ERROR,
} from '../movies/movieTypes';

import { fetchScheduleDetails } from '../movies/movieActions';

import { toast } from 'react-toastify';
import FnbService from '../../ApiServices/FnbService';
import _ from 'lodash';

export const fetchSeatLayoutBrij =
  ({ screen_id, ss_id, md_id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_SEAT_LAYOUT });
      const { data: seatLayoutData } = await MovieService.GetSeatLayoutBrij({
        screen_id,
        ss_id,
        md_id,
        type_seat_show: 1,
      });
      if (!seatLayoutData.status) {
        dispatch({
          type: FETCH_SEAT_LAYOUT_ERROR,
          payload: seatLayoutData.message,
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: seatLayoutData.message, show: true },
        });
      }
      dispatch({
        type: SET_INTERNET_HANDLING_FEES_APPLIED,
        payload: seatLayoutData?.is_internet_handling_fee_applied === 'Y',
      });
      dispatch({ type: FETCH_SEAT_TYPE });
      const { data: seatTypeData } = await MovieService.GetSeatType({
        screen_id,
      });
      if (!seatTypeData.status) {
        dispatch({
          type: FETCH_SEAT_TYPE_ERROR,
          payload: seatLayoutData.message,
        });
      }
      let seatLayoutPayload = {};
      seatLayoutPayload.seats = seatLayoutData;
      seatLayoutPayload.seatType = seatTypeData;

      if (
        seatLayoutData &&
        seatLayoutData?.Records &&
        seatLayoutData?.Records?.length > 0 &&
        seatLayoutData?.ticketTypeTranslations &&
        seatLayoutData?.ticketTypeTranslations?.length > 0
      ) {
        let grouped_ticketTypeTranslations = _.groupBy(
          seatLayoutData?.ticketTypeTranslations,
          'tt_id',
        );
        seatLayoutData.Records.forEach((sl) => {
          if (sl?.rate_card_group && sl?.rate_card_group?.length > 0) {
            sl.rate_card_group.forEach((rcg) => {
              if (
                grouped_ticketTypeTranslations &&
                grouped_ticketTypeTranslations[`${rcg.tt_id}`]?.length === 1
              ) {
                rcg.ticketTypeTranslations =
                  grouped_ticketTypeTranslations[`${rcg.tt_id}`][0];
              }
            });
          }
        });
      }

      dispatch({
        type: FETCH_SEAT_LAYOUT_SUCCESS,
        payload: seatLayoutPayload,
      });
      dispatch({
        type: FETCH_SEAT_TYPE_SUCCESS,
        payload: seatTypeData,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: FETCH_SEAT_LAYOUT_ERROR, payload: error?.message });
    }
  };

export const fetchSeatLayout =
  ({ screen_id, ss_id, md_id, category_id, gogo_ss_id, mycinema_ss_d_id }) =>
  async (dispatch, getState) => {
    try {
      // const mycinema_ss_d_id = getState().movies.myCinemaCreateShow
      //   .mycinema_ss_d_id;
      dispatch({ type: FETCH_SEAT_LAYOUT });
      // const payload = {
      //   screen_id,
      //   ss_id,
      //   md_id,

      //   gogo_ss_id,
      // };
      // if (category_id == 1 || category_id == 3) {
      //   payload.isCinemaSeat = true;
      // }
      const { data: seatLayoutData } = await MovieService.GetSeatLayout({
        mycinema_ss_d_id,
      });
      if (!seatLayoutData.status) {
        dispatch({
          type: FETCH_SEAT_LAYOUT_ERROR,
          payload: seatLayoutData.message,
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: seatLayoutData.message, show: true },
        });
      }
      // dispatch({ type: FETCH_SEAT_TYPE });
      // const { data: seatTypeData } = await MovieService.GetSeatType({
      //   screen_id,
      // });
      // if (!seatTypeData.status) {
      //   dispatch({
      //     type: FETCH_SEAT_TYPE_ERROR,
      //     payload: seatLayoutData.message,
      //   });
      // }
      const seatTypeData = seatLayoutData.getSeatTypeArray;
      let seatLayoutPayload = {};
      seatLayoutPayload.seats = seatLayoutData;
      seatLayoutPayload.seatType = seatTypeData;
      dispatch({
        type: FETCH_SEAT_LAYOUT_SUCCESS,
        payload: seatLayoutPayload,
      });
      dispatch({
        type: FETCH_SEAT_TYPE_SUCCESS,
        payload: seatTypeData,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: FETCH_SEAT_LAYOUT_ERROR, payload: error?.message });
    }
  };

export const selectSeats = (payload) => async (dispatch) =>
  dispatch({
    type: SET_SEAT_SELECTED,
    payload,
  });

export const clearSeatSelection = (payload) => async (dispatch) =>
  dispatch({
    type: CLEAR_SEAT_SELECTION,
  });

export const updateSeatLayout = (payload) => async (dispatch, getState) => {
  const { globalOptions } = getState().movies;
  dispatch({
    type: UPDATE_SEAT_LAYOUT,
    payload,
    globalOptions,
  });
};

export const reserveSeats =
  ({
    selected_seats,
    schedule_show_id,
    history,
    cinema_id,
    seatGroup,
    gogo_ss_id,
    gogo_booking_type_id,
    category_id,
    callback,
    screen_id,
    ss_id,
    md_id,
    closeModal,
    specialRequest,
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
    setReserveSeatError,
    setShowModal,
    setModalMessage,
    skipFnbStep,
  }) =>
  async (dispatch, getState) => {
    try {
      let state = getState();
      let { reservation_id_temp } = state.seatLayout;
      dispatch({ type: RESERVE_SEAT });
      let seats = selected_seats.map((x) => x.sl_id);
      let SeatTypeId = selected_seats.map((x) => x.sst_id);
      let isVipOrBlockSeats =
        selected_seats.filter(
          (x) => x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12,
        ).length > 0;
      let newSeatLayoutArray = [];
      let rateCardGroup = [];
      seatGroup.map((seat_g) => {
        seat_g.rate_card_group.map((rg) => {
          let findIndex32 = rateCardGroup.findIndex(
            (newrg) => newrg.tt_id == rg.tt_id,
          );

          if (findIndex32 >= 0) {
            rateCardGroup[findIndex32]['count'] =
              rateCardGroup[findIndex32]['count'] + rg.totalSeatSelected;
          } else {
            let objRateCard = {
              tt_id: rg.tt_id,
              tt_name: rg.tt_name.trim(),
              count: rg.totalSeatSelected,
            };
            rateCardGroup.push(objRateCard);
          }

          for (let i = 0; i < rg.totalSeatSelected; i++) {
            let obj = {
              rate_card_id: rg.rate_card_id,
              tt_id: rg.tt_id,
              rc_g_id: rg.rc_g_id,
              rcg_c_id: rg.rcg_c_id,
              tt_name: rg.tt_name,
              seat_type_id: seat_g.sst_id,
              status: false,
            };

            newSeatLayoutArray.push(obj);
          }
        });
      });
      // const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
      let reservation_id = reservation_id_temp;
      const { data } = await MovieService.ReserveSeats({
        groupRateCard: true,
        seat_layout_id: seats,
        seat_type_id: SeatTypeId,
        newSeatLayout: newSeatLayoutArray,
        schedule_show_id: schedule_show_id || ss_id,
        reservation_id,
        isVipOrBlockSeats,
        cinema_id,
        gogo_ss_id,
        gogo_booking_type_id,
        specialRequest,
        is_website: true,
      });

      const fnbPayload = {
        category: null,
        aggregator_cinema_id: cinema_id,
        cinema_id,
        isCombo: false,
        isEgg: '',
        isVeg: '',
        search: '',
        subCategory: null,
        reservation_id,
      };
      const { data: fnbData } = await FnbService.GetAllFnbItems(fnbPayload);
      let skipFnb = skipFnbStep ?? false;
      if (fnbData?.Records?.Records?.length === 0) {
        skipFnb = true;
      }

      if (data && data.status) {
        dispatch({ type: RESERVE_SEAT_SUCCESS, payload: reservation_id });
        // dispatch(startTimerAction());
        // dispatch({ type: CLEAR_SEAT_SELECTION });

        if (skipFnb) {
          history.push(`/payment/${reservation_id}/${category_id}`);
        } else {
          let fnb_item_is_compulsory = selected_seats?.[0]?.item_is_compulsory;
          let fnb_item_id = selected_seats?.[0]?.item_id;

          history.push(
            `/fnb/${ss_id || schedule_show_id}/${screen_id}/${md_id}/${
              fnb_item_is_compulsory == 'Y' ? fnb_item_id : ''
            }`,
          );
        }
      } else {
        // toast.dismiss();
        // toast.error(data.message);
        setReserveSeatError(data.message);
        closeModal && closeModal();
        dispatch(clearSeatSelection());
        dispatch(
          fetchSeatLayoutBrij({
            screen_id,
            ss_id: ss_id || schedule_show_id,
            md_id,
            category_id,
            gogo_ss_id,
          }),
        );
        setShowModal(true);
        setModalMessage(data.message);

        return data;
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error?.message });
      }
    } finally {
      callback(false);
    }
  };

export const cinemaReserveSeat =
  ({
    selected_seats,
    schedule_show_id,
    history,
    cinema_id,
    seatGroup,
    gogo_ss_id,
    gogo_booking_type_id,
    category_id,
    callback,
    screen_id,
    ss_id,
    md_id,
    closeModal,
    specialRequest,
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
    mycinema_ss_d_id,
    booking_type_id,
    setReserveSeatError,
    setShowModal,
    setModalMessage,
    skipFnbStep,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: RESERVE_SEAT });
      console.log('enter step 1');
      let seats = selected_seats.map((x) => x.sl_id);
      let SeatTypeId = selected_seats.map((x) => x.sst_id);
      let isVipOrBlockSeats =
        selected_seats.filter(
          (x) => x.seat_reserve_type_id == 5 || x.seat_reserve_type_id == 12,
        ).length > 0;
      let newSeatLayoutArray = [];
      let rateCardGroup = [];
      console.log('enter step 2');

      seatGroup.map((seat_g) => {
        seat_g.rate_card_group.map((rg) => {
          let findIndex32 = rateCardGroup.findIndex(
            (newrg) => newrg.tt_id == rg.tt_id,
          );

          if (findIndex32 >= 0) {
            rateCardGroup[findIndex32]['count'] =
              rateCardGroup[findIndex32]['count'] + rg.totalSeatSelected;
          } else {
            let objRateCard = {
              tt_id: rg.tt_id,
              tt_name: rg.tt_name.trim(),
              count: rg.totalSeatSelected,
            };
            rateCardGroup.push(objRateCard);
          }

          for (let i = 0; i < rg.totalSeatSelected; i++) {
            let obj = {
              rate_card_id: rg.rate_card_id,
              tt_id: rg.tt_id,
              rc_g_id: rg.rc_g_id,
              rcg_c_id: rg.rcg_c_id,
              tt_name: rg.tt_name,
              seat_type_id: seat_g.sst_id,
              status: false,
            };

            newSeatLayoutArray.push(obj);
          }
        });
      });
      console.log('enter step 3');

      const reservation_id = Math.floor(100000000 + Math.random() * 900000000);
      console.log('enter step 4');

      const { data } = await MovieService.CinemaReserveSeats({
        groupRateCard: true,
        seat_layout_id: seats,
        seat_type_id: SeatTypeId,
        newSeatLayout: newSeatLayoutArray,
        schedule_show_id,
        cinema_reservation_id: reservation_id,
        isVipOrBlockSeats,
        cinema_id,
        booking_type_id,
        mycinema_booking_type_id: booking_type_id,
        specialRequest,
        mycinema_ss_d_id,
      });
      console.log('enter step 5');

      const fnbPayload = {
        category: null,
        aggregator_cinema_id: cinema_id,
        cinema_id,
        isCombo: false,
        isEgg: '',
        isVeg: '',
        search: '',
        subCategory: null,
        reservation_id,
      };
      const { data: fnbData } = await FnbService.GetAllFnbItems(fnbPayload);
      let skipFnb = skipFnbStep ?? false;
      if (fnbData?.Records?.Records?.length === 0) {
        skipFnb = true;
      }

      if (data && data.status) {
        dispatch({ type: RESERVE_SEAT_SUCCESS, payload: reservation_id });

        if (skipFnb) {
          history.push(`/payment/${reservation_id}/${category_id}`);
        } else {
          let fnb_item_is_compulsory = selected_seats?.[0]?.item_is_compulsory;
          let fnb_item_id = selected_seats?.[0]?.item_id;

          history.push(
            `/fnb/${mycinema_ss_d_id}/${booking_type_id}/${
              fnb_item_is_compulsory == 'Y' ? fnb_item_id : ''
            }`,
          );
        }
        console.log(
          'enter step 6',
          { reservation_id },
          { mycinema_ss_d_id },
          { booking_type_id },
        );

        // dispatch({ type: CLEAR_SEAT_SELECTION });
      } else {
        // toast.dismiss();
        // toast.error(data.message);
        setReserveSeatError(data.message);
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
        closeModal && closeModal();
        dispatch(clearSeatSelection());
        dispatch(
          fetchSeatLayout({
            screen_id,
            ss_id,
            md_id,
            category_id,
            gogo_ss_id,
          }),
        );
        setShowModal(true);
        setModalMessage(data.message);
        console.log('enter step 7');
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error?.message });
      }
      console.log('enter step 8', { error });
    } finally {
      callback(false);
    }
    console.log('enter step 9');
  };

export const privateScreeningReserveSeat =
  ({
    screen_id,
    history,
    cinema_id,
    specialRequest,
    category_id,
    mycinema_ss_d_id,
    movie_id,
    content_lang_id,
    genre_id,
    isPrivate,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: RESERVE_SEAT });
      console.log('category_id ajit:>> ', category_id);
      const cinema_reservation_id = Math.floor(
        100000000 + Math.random() * 900000000,
      );

      let payload = {
        groupRateCard: true,
        seat_layout_id: [],
        schedule_show_id: 0,
        cinema_reservation_id,
        isVipOrBlockSeats: false,
        cinema_id,
        screen_id,
        mycinema_ss_d_id,
        mycinema_booking_type_id: 3,
        newSeatLayout: [],
        seat_type_id: [],
        specialRequest,
      };

      let privateScreeningTypePayload =
        getState()?.movies?.privateScreeningTypePayload ?? null;
      if (privateScreeningTypePayload) {
        payload.booking_data = {
          customSeatCount: privateScreeningTypePayload?.customSeatCount ?? null,
          total: privateScreeningTypePayload?.total ?? null,
          total_net: privateScreeningTypePayload?.total_net ?? null,
          isTicketSlab: privateScreeningTypePayload?.isTicketSlab ?? null,
          isFnbCompulsory: privateScreeningTypePayload?.isFnbCompulsory ?? null,
          slab: privateScreeningTypePayload?.slab?.rc_id
            ? [
                {
                  rc_id: privateScreeningTypePayload?.slab?.rc_id ?? null,
                  rcg_c_id: privateScreeningTypePayload?.slab?.rcg_c_id ?? null,
                  price: privateScreeningTypePayload?.slab?.price ?? null,
                  quantity: privateScreeningTypePayload?.slab?.quantity ?? null,
                  net_price: privateScreeningTypePayload?.slab?.net_price ?? null,
                },
              ]
            : [],
          fnb: privateScreeningTypePayload?.fnb?.fnb_private_id
            ? [
                {
                  fnb_private_id:
                    privateScreeningTypePayload?.fnb?.fnb_private_id ?? null,
                  item_id: privateScreeningTypePayload?.fnb?.item_id ?? null,
                  item_name: privateScreeningTypePayload?.fnb?.item_name ?? null,
                  price: privateScreeningTypePayload?.fnb?.price ?? null,
                  quantity: privateScreeningTypePayload?.fnb?.quantity ?? null,
                  net_price: privateScreeningTypePayload?.fnb?.net_price ?? null,
                },
              ]
            : [],
          addon: privateScreeningTypePayload?.addon?.fnb_private_id
            ? [
                {
                  fnb_private_id:
                    privateScreeningTypePayload?.addon?.fnb_private_id ?? null,
                  item_id: privateScreeningTypePayload?.addon?.item_id ?? null,
                  item_name: privateScreeningTypePayload?.addon?.item_name ?? null,
                  price: privateScreeningTypePayload?.addon?.price ?? null,
                  quantity: privateScreeningTypePayload?.addon?.quantity ?? null,
                  net_price: privateScreeningTypePayload?.addon?.net_price ?? null,
                },
              ]
            : [],
        };
      }

      const { data } = await MovieService.CinemaReserveSeats(payload);

      if (data && data.status) {
        dispatch(
          fetchScheduleDetails({
            mycinema_ss_d_id,
          }),
        );
        dispatch({
          type: RESERVE_SEAT_SUCCESS,
          payload: cinema_reservation_id,
        });
        // history.push(`/payment/${cinema_reservation_id}/${category_id}`);
        // history.push(`/fnb/${mycinema_ss_d_id}/3`);
        history.push(`/payment/${cinema_reservation_id}/${category_id}`);
      } else {
        toast.dismiss();
        toast.error(data.message);
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: RESERVE_SEAT_ERROR, payload: error?.message });
      }
    } finally {
    }
  };

export const setReservationID = (payload) => async (dispatch) =>
  dispatch({ type: SET_RESERVATION_ID, payload });

export const setPrivateScreeningPayload = (payload) => async (dispatch) =>
  dispatch({ type: SET_PRIVATE_SCREENING_TYPE_PAYLOAD, payload });

export const releaseSeat =
  ({ reservation_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: RELEASE_SEAT });
      const { data } = await MovieService.ReleaseSeat({ reservation_id });
      if (data && data.status) {
        dispatch({ type: RELEASE_SEAT_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: RELEASE_SEAT_ERROR, payload: error });
    }
  };

export const releaseSeatBrij =
  ({ reservation_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: RELEASE_SEAT });
      const { data } = await MovieService.ReleaseSeatBrij({ reservation_id });
      if (data && data.status) {
        dispatch({ type: RELEASE_SEAT_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: RELEASE_SEAT_ERROR, payload: error?.message });
    }
  };

export const releaseRealTimeBlockedSeats = () => async (dispatch) => {
  let reservation_id = window.localStorage.getItem('reservation_id_temp');
  let guest_user_id = window.localStorage.getItem('guest_user_id');

  if (!reservation_id || !guest_user_id || isNaN(parseInt(reservation_id))) {
    return true;
  }

  try {
    const { data } = await MovieService.ReleaseRealtimeSeats({
      reservation_id,
      guest_user_id,
    });

    if (!data.status) {
      console.log('error in releasing realtime seats', data);
    } else {
      window.localStorage.removeItem('reservation_id_temp');
    }

    return data.status;
  } catch (err) {
    console.log('error in release real time seats', err);
  }
};

export const setTempReservationId = (payload) => {
  window.localStorage.setItem('reservation_id_temp', payload);

  return {
    type: SET_TEMP_RESERVATION_ID,
    payload,
  };
};

export const resetTempReservationId = () => {
  window.localStorage.removeItem('reservation_id_temp');

  return {
    type: SET_TEMP_RESERVATION_ID,
    payload: null,
  };
};
