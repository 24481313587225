import React, { useEffect } from 'react';
import useIsOnline from './customHooks/useIsOnline';
import { Provider, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import 'moment/locale/ar';
//import store
import store from './store/index';
// import router
import Router from './Router';
//import i18n
import './plugins/i18n';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
//import compoents
import Header from '@components/partials/Header.jsx';
import Footer from '@components/partials/Footer.jsx';
import FooterNew from '@components/partials/FooterNew.jsx';

//CSS Imports
// import '@assets/css/App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@assets/css/slick.css';
import 'react-day-picker/lib/style.css';

import '@assets/css/responsive.css';
// import "@assets/css/style.css";
import '@assets/css/user-profile.css';

import 'react-modal-video/css/modal-video.min.css';

import { ToastContainer } from 'react-toastify';
import { LanguageService } from '@apiService/tokenService';
import { MainSpinner } from './components/partials/MainSpinner';

import Spinner from './components/partials/Spinner';
// import HeaderNew from "@components/partials/HeaderNew.jsx";
const LazyHeaderNew = React.lazy(() => import('./components/partials/HeaderNew'));

const language = LanguageService.getLanguage();

const App = ({ t }) => {
  const { pathname } = useLocation();
  const isOnline = useIsOnline();

  useEffect(() => {
    // let mainSpinner = document.getElementById('main-spinner');
    // if(mainSpinner) {
    // 	mainSpinner.style.display = "none";
    // }
  }, []);

  return isOnline ? (
    <Provider store={store}>
      <RouterWrapper />
    </Provider>
  ) : (
    <div />
  );
};

let RouterWrapper = () => {
  const translationsLoading = useSelector((s) => s.config.translationsLoading);
  const orgDetails = useSelector((state) => state.movies.organizationDetails);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', orgDetails.org_color);
    // document.documentElement.style.setProperty(
    //   "--light-5",
    //   orgDetails.org_font_color
    // );
  }, [orgDetails]);

  // if (translationsLoading) return <MainSpinner />;

  return (
    <>
      {/* <Header /> */}
      {/*<HeaderNew/>*/}
      <React.Suspense
        fallback={
          <div className="row">
            <div className="col-12 text-center">{/*<Spinner/>*/}</div>
          </div>
        }
      >
        <LazyHeaderNew />
      </React.Suspense>
      <main className="App">
        <Router />
      </main>
      {/* <Footer /> */}
      <FooterNew />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </>
  );
};
export default withNamespaces()(App);
