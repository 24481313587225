import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import cinemaSample from '@assets/pictures/cinema-sample.png';
import cineOne from '@assets/pictures/cine-one.png';
import cineTwo from '@assets/pictures/cine-two.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import seat from '@assets/pictures/svgs/seat-standard.svg';
import mapActive from '@assets/pictures/svgs/map-active.svg';
import Slider from 'react-slick';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import PnoSlider from './partials/PnoSlider.jsx';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import {
  fetchAuditoriums,
  fetchAudiSeatLayout,
  fetchCMS,
} from '../store/ancilliary/ancilliaryActions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchAllCinemas } from '@store/cinemas/cinemasActions';
import SeatLayoutPopup from './partials/popups/SeatLayoutPopup.jsx';
import { getLangSpecificAttribute } from '@helper/languages';
import Spinner2 from './partials/Spinner';
import { motion } from 'framer-motion';

const CinemaDetails = ({ t }) => {
  const dispatch = useDispatch();
  const cinemas = useSelector((state) => state.cinemas);
  const { allCinemas } = cinemas;
  const { cinema_id } = useParams();
  console.log(cinema_id);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;

  // const history = useHistory();
  const heroSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // dispatch(fetchAllCinemas());
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();

  const stack = [
    {
      title: 'See all the films in the comfort',
      msg: 'The Arena Basel Stücki opened in spring 2021 and is one of the most modern cinemas in Europe. With 14 auditoriums and a specially equipped multifunctional event cinema hall, Arena Cinemas Basel Stücki can accommodate up to 2100 guests. With ScreenX, 4DX and an Arena Mega with a 23 meter screen and stadium lighting, the cinema leaves nothing to be desired.',
      imgSrc: cineOne,
    },
    {
      title: 'Enjoy a delicious meal in our cinema',
      msg: 'In addition to the famous Arenas popcorn, which is always freshly prepared for you, we also have a great sports bar in the Cinebowling, which invites before or after the movie to cocktails, beer and finger snacks.',
      imgSrc: cineTwo,
    },
  ];

  const heroSectionObj = {
    arr: [1, 2],
    backgroundImg: cinemaSample,
    title: t('how_it_works.maintitle'),
    msg: t('how_it_works.maindesc'),
  };
  const [showOffers, setShowOffers] = useState(true);

  const [seatLayout, setSeatLayout] = useState(false);
  // useEffect(() => ), []);
  const auditoriums = useSelector((state) => state.ancilliary.auditoriums);
  const CMS = useSelector((state) => state.ancilliary.cmsData);
  const cmsLoading = useSelector((state) => state.ancilliary.cmsLoading);

  let cinema = useSelector((state) => state.cinemas.selectedCinema);
  // cinemas.allCinemas &&
  //   cinemas.allCinemas.forEach((item) => {
  //     if (parseInt(item.cinema_id) === parseInt(cinema_id)) {
  //       cinema = item;
  //       console.log("true", cinema);
  //     } else {
  //       console.log("false", item.cinema_id, cinema_id);
  //     }
  //   });
  useEffect(() => {
    // if (allCinemas.length === 0) {
    //   history.push(`/cinema-listing`);
    // }
  }, [allCinemas.length]);
  useEffect(() => {
    dispatch(fetchAuditoriums({ cinema_id }));
    dispatch(fetchCMS({ cinema_id: cinema_id }));
  }, [cinema_id, allCinemas]);
  const onClickAudiCard = (screen_id) => {
    setLoader(true);
    dispatch(fetchAudiSeatLayout({ screen_id: screen_id, callback: setLoader }));
  };

  return (
    <div>
      <SeatLayoutPopup
        show={seatLayout}
        closeFunc={setSeatLayout}
        loader={loader}
      />
      {cmsLoading ? (
        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-12 text-center">
              <Spinner2 />
            </div>
          </div>
        </div>
      ) : (
        <div className="px-0 cinema-details">
          {/* Hero Section */}
          <Slider {...heroSlider} className="">
            <section className="cinema-hero-section">
              <div className="hero-img-section">
                <img
                  src={
                    getLangSpecificAttribute(
                      CMS,
                      lang_id,
                      'cms_header_data',
                      'lang_id',
                      true,
                      'image_url',
                    )?.image_url || cinemaBg
                  }
                  alt="Movie Banner"
                  className="hero-img-1"
                />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row h-100 align-items-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                      <p className="title">{cinema?.cinema_name}</p>
                      <p className="msg d-none d-md-block">
                        {getLangSpecificAttribute(
                          CMS,
                          lang_id,
                          'cms_header_data',
                          'lang_id',
                          true,
                          'description',
                        )?.description || ''}
                      </p>
                      {getLangSpecificAttribute(
                        CMS,
                        lang_id,
                        'cms_header_data',
                        'lang_id',
                        true,
                        'show_cinema_address',
                      )?.show_cinema_address == 'Y' && (
                        <div className="address">
                          <p className="d-flex align-items-center pr-1">
                            <img src={mapActive} alt="" />
                            Address:
                          </p>
                          <span>{cinema?.cinema_address}</span>
                        </div>
                      )}
                      <div className="tags justify-content-center flex-wrap d-none d-md-flex">
                        {getLangSpecificAttribute(
                          CMS,
                          lang_id,
                          'cms_header_data',
                          'lang_id',
                          true,
                          'sound_formats',
                        )?.sound_formats?.map((x, index) => (
                          <span className="" key={'cms_header_data' + index}>
                            {x.sound_format_name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Slider>
          {/* <AncilliaryHeroSection sectionObj={heroSectionObj} /> */}
          {/* Hero Section Ends */}
          <section className="container-fluid container-xl mt-3">
            {/* getLangSpecificAttribute(CMS, lang_id, "cms_data") */}
            {CMS &&
            CMS.length > 0 &&
            getLangSpecificAttribute(CMS, lang_id, 'cms_data', 'lang_id')?.length >
              0
              ? getLangSpecificAttribute(CMS, lang_id, 'cms_data', 'lang_id')?.map(
                  (stack, index) => (
                    <div className="row presentation">
                      <motion.div
                        className={`col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0 ${
                          index % 2 === 0 ? 'order-sm-first' : 'order-sm-last'
                        }`}
                        initial={{ x: -75, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.75 }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-start h-100">
                          <p className="title">{stack.title}</p>
                          <p className="msg">{stack.description}</p>

                          {stack.button_text && (
                            <button
                              className="btn-main btn-main-lg mt-3 mt-lg-4"
                              onClick={() =>
                                stack.button_url
                                  ? history.push(stack.button_url)
                                  : history.push('/')
                              }
                            >
                              {stack.button_text}
                            </button>
                          )}
                        </div>
                      </motion.div>
                      <motion.div
                        className="col-12 col-sm-6 col-lg-7 text-center"
                        initial={{ x: 75, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.75 }}
                      >
                        <img className="img-fluid" src={stack.image_url} alt="" />
                      </motion.div>
                    </div>
                  ),
                )
              : stack?.map((stack, index) => (
                  <div className="row presentation">
                    <motion.div
                      className={`col-12 col-sm-6 col-lg-5 mb-3 mb-sm-0 ${
                        index % 2 === 0 ? 'order-sm-first' : 'order-sm-last'
                      }`}
                      initial={{ x: -75, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.75 }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-start h-100">
                        <p className="title">{stack.title}</p>
                        <p className="msg">{stack.msg}</p>

                        <button
                          className="btn-main btn-main-lg mt-3 mt-lg-4"
                          onClick={() => history.push('/')}
                        >
                          {t('Find Movies')}
                        </button>
                      </div>
                    </motion.div>
                    <motion.div
                      className="col-12 col-sm-6 col-lg-7 text-center"
                      initial={{ x: 75, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.75 }}
                    >
                      <img className="img-fluid" src={stack.imgSrc} alt="" />
                    </motion.div>
                  </div>
                ))}
            <div className="row">
              {auditoriums?.map((audi, index) => (
                <AuditoriumCard
                  key={index}
                  sideIcon={seat}
                  // feature="IMAX"
                  clickFunc={() => {
                    setSeatLayout(true);
                    onClickAudiCard(audi.screen_id);
                  }}
                  audi={audi}
                />
              ))}
            </div>
            {showOffers && <PnoSlider />}
          </section>
        </div>
      )}
    </div>
  );
};
export default withNamespaces()(CinemaDetails);
