import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Images
import { ReactComponent as ScreenSl } from '@assets/pictures/svgs/screen-sl.svg';
import { ReactComponent as ScreenS2 } from '@assets/pictures/svgs/screen-s2-gold.svg';
const SeatPlanScreen = (props) => {
  const { t, classes, isPrivate = false } = props;
  let userSelection = useSelector((state) => state.movies.userSelection);
  // const movieDetails = useSelector((state) => state.movies.movieDetails);
  return (
    <>
      {/* ${classes} add this in class to get the offsetwidth */}
      <div className={`seat_plan_screen w-100`}>
        {/* <span className={isPrivate ? 'isPrivate' : '!isPrivate'}>
          {userSelection?.selectedShow?.cinema_name
            ? userSelection?.selectedShow?.cinema_name
            : ''}{' '}
          -{' '}
          {userSelection?.selectedScreen?.screen_name
            ? userSelection?.selectedScreen?.screen_name
            : ''}
        </span> */}
        <span className={isPrivate ? 'isPrivate' : '!isPrivate'}>Screen</span>
        {isPrivate ? (
          <ScreenS2 className="prvt-svg" />
        ) : (
          <ScreenSl className="nowshowing-svg" />
        )}
      </div>
    </>
  );
};

// Default Props
SeatPlanScreen.defaultProps = {
  classes: '',
};

export default withNamespaces()(SeatPlanScreen);
