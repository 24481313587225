import Api from './Api';

export default {
  GetVouchersList(payload) {
    return Api().post(`/api/external/offers-vouchers`, payload);
  },
  ValidateVoucherCode(payload) {
    return Api().post(`/api/external/verify-voucher`, payload);
  },
  ValidateVoucherCodeV2(payload) {
    return Api().post(`/api/external/v2/verify-voucher`, payload);
  },
  ValidateLoyalty(payload) {
    return Api().post(`/api/pos/validate_points`, payload);
  },
  AddCustomerToSegment(payload) {
    return Api().post('/api/segments', payload);
  },
  VerifyPromotion(payload) {
    return Api().post('/api/marketing/v2/verifyPromotion', payload);
  },
  ResetVoucherAndOfferInReservation(reservation_id) {
    return Api().post(
      '/api/external/save-voucher?action=ResetVoucherAndOfferInReservation',
      {
        reservation_id,
      },
    );
  },
  SavePromotionInReservation(payload) {
    return Api().post(
      '/api/external/save-voucher?action=SavePromotionInReservation',
      payload,
    );
  },
  SaveVoucherInReservation(payload) {
    return Api().post(
      '/api/external/save-voucher?action=SaveVoucherInReservation',
      payload,
    );
  },
  SaveLoyaltyInReservation(payload) {
    return Api().post(
      '/api/external/save-voucher?action=SaveLoyaltyInReservation',
      payload,
    );
  },
  SaveEwalletInReservation(payload) {
    return Api().post(
      '/api/external/save-voucher?action=SaveEwalletInReservation',
      payload,
    );
  },
};
