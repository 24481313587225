import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import jalma_banner from '@assets/pictures/jalma-banner.png';
import jalma_banner1 from '@assets/pictures/jalma-1.png';
import jalma_banner2 from '@assets/pictures/jalma-2.png';

import { BASEURL, HOST_URL } from '../config/index';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
          <CenteredTitle firstHalf={t('QFX JALMA')} />
        </div>
        <div className="banner_static_page">
          <img src={jalma_banner} className="img-fluid" />
        </div>
        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                <h3
                  className={`font-weight-bold text-primary ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The Choicest Best
                </h3>
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  The first QFX Cinema Theater outside Kathmandu Valley, QFX Jalma
                  is the business and transit hub for people from far-western and
                  eastern regions. It offers a refreshingly new experience, with
                  refined aesthetics, interior and dual screen theaters.
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img src={jalma_banner1} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Third Row */}

            <div
              className={`row ${
                isMobile || isTablet ? 'flex-column-reverse' : 'mb-4'
              }`}
            >
              <div
                className={`col-md-12 col-xl-6 ${
                  isMobile || isTablet ? 'mt-2' : ''
                }`}
              >
                <img src={jalma_banner2} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`col-md-12 col-xl-6 ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                }`}
              >
                {/* <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  The First Multiplex in Nepal
                </h3> */}
                {/* <h5 className="font-weight-bold text-primary">
                  A thrilling new experience !
                </h5> */}
                <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                  To enhance your movie going experience to the highest, our menu is
                  continually being evolved with innovations in food technology.
                  Advanced amenities include comfortable seating, Christie
                  projection and Depth Q 3D technology coupled with great sound
                  quality. What a treat!
                </p>
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            <div class="pt-5">
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                <li>
                  Regular ticket rates apply for afternoon, evening and night shows.
                </li>
                <li>Rates are inclusive of all taxes.</li>
                <li>We offer 50% discount on morning shows.</li>
                <li>
                  The discount is calculated based on the regular ticket rate for
                  the day..
                </li>
              </p>
              <br></br>
              <p>
                <b>Address:</b> Bharatpur 02, Kshetrapur - Jalma Hall Road,
                Narayanghat, Nepall<br></br>
                <b>Email:</b> marketing@qfxcinemas.com{' '}
              </p>
            </div>

            <div class="pt-3">
              <p className={`${isMobile || isTablet ? 'mt-4 mb-4' : ''}`}>
                For Ticket Price{' '}
                <span
                  onClick={() => history.push('/TicketRates')}
                  className="text-primary font-weight-bold"
                >
                  Click Here !
                </span>
              </p>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
