import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import sampleBg from '@assets/pictures/sample-bg.png';
import whitearrowdown from '@assets/pictures/svgs/white-arrow-down.svg';
import MovieSection from './partials/movieSection.jsx';
import MovieCard from './partials/MovieCard.jsx';
import filtericon from '@assets/pictures/svgs/filters.svg';
import poster2 from '@assets/pictures/poster-2.png';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
// import { fetchMiniFestivals } from "../store/homepage/homepageActions";
import store from '../store/index';
import Spinner from './partials/Spinner';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { getLangSpecificAttribute } from '@helper/languages';

const MiniFestivals = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [category_link_name, setCategoryName] = useState(null);
  const [limit, setLimit] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);

  let params = new URLSearchParams(document.location.search.substring(1));
  const [miniFestId, setMiniFestId] = useState(params.get('mini_fest_id') || null);

  const heroSlider = {
    pauseOnFocus: true,
    // onSwipe: onSlide,
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id } = selected_language;
  const onClickMiniFestivalLoadMore = async (
    index,
    currentPage2,
    category_link_name,
  ) => {
    setSelectedIndex(index);
    // dispatch(
    //   fetchMiniFestivals({
    //     currentPage: currentPage2,
    //     index: null,
    //     category_link_name: null,
    //   })
    // );
  };

  // const [miniFestivalsData, setMiniFestivalData] = useState(null);
  const miniFestivalsData = useSelector((state) => state.homepage.miniFestivals);
  const miniFestivalsLoading = useSelector(
    (state) => state.homepage.miniFestivalsLoading,
  );

  useEffect(() => {
    if (miniFestivalsData && miniFestivalsData?.Records?.length > 0 && miniFestId) {
      setTimeout(() => {
        let element = document.querySelector(`#fest-${miniFestId}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
          setMiniFestId(null);
        }
      }, 500);
    }
  }, [miniFestivalsData]);

  useEffect(() => {
    // dispatch(
    //   fetchMiniFestivals({
    //     currentPage,
    //     category_link_name: null,
    //     index: null,
    //   })
    // );
  }, [currentPage]);

  // useEffect(() => {
  //   setMiniFestivalData(tempminiFestivalsData);
  //   console.log("tempminiFestivalsData", tempminiFestivalsData);
  // }, [tempminiFestivalsData]);

  const festsObj = {
    arr: miniFestivalsData?.Records,
    backgroundImg:
      miniFestivalsData?.Records?.length > 0 &&
      miniFestivalsData?.Records[0].category_link_image,
    title: t(`common.mini-fests`),
    msg: t(`mini_festival_desc`),
  };

  return (
    <section className="mini-festival">
      {/* Start Banner */}
      <Slider {...heroSlider} className="dots-1">
        {miniFestivalsData?.Records.map((festival, index) => (
          <article key={index}>
            {/* class= "banner" */}
            <div className="cinema-hero-section">
              {/* class= "banner-img" */}
              <div className="hero-img-section">
                <img
                  // src={miniFestivalsData?.bannerDetail?.category_image}
                  src={festival?.category_link_image}
                  alt="Movie Banner"
                  // className="poster-img"
                  className="hero-img-1"
                />
              </div>
              <div className="hero-text justify-content-center">
                <motion.div
                  className="container-fluid container-xl"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <div className="row h-100 align-items-center">
                    <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                      <p className="title">{t(`common.mini-fests`)}</p>
                      <p className="msg">{t(`mini_festival_desc`)}</p>
                      {/* <div className="d-flex justify-content-center">
                      <img src={whiteArrowDown} className="down-arrow" alt="" />
                    </div> */}
                    </div>
                  </div>
                </motion.div>
              </div>
              {/* <div className="banner-content">
                <div>
                  <h1 className="heading">{t(`common.mini-fests`)}</h1>
                  <h6 className="desc">{t(`mini_festival_desc`)}</h6> */}
              {/* <div className="scroll-down">
                <img
                  src={whitearrowdown}
                  alt="white-arrow-down"
                  className="img"
                />
              </div> */}
              {/* </div>
              </div> */}
            </div>
          </article>
        ))}
      </Slider>
      {/* <AncilliaryHeroSection sectionObj={festsObj} /> */}

      {/* End Banner */}

      {/* <div className="page-filters d-flex justify-content-center align-items-center mt-5">
        <div>
          <div className="dropdown-bg">
            <Dropdown className="w-100 h-100 pr-4">
              <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
                Language
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdownItems">
                <Dropdown.Item href="#/action-1" className="">
                  ENGLISH
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2" className="">
                  SANSKRIT
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div>
          <h6 className="mb-0">Date</h6>
        </div>
        <div className="d-flex align-items-center mb-0">
          <img src={filtericon} alt="white-arrow-down" className="img" />
          <h6 className="mb-0 pl-2">Filters</h6>
        </div>
      </div> */}

      {miniFestivalsLoading && !miniFestivalsData ? (
        // <div className="row my-2">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      ) : // </div>
      miniFestivalsData?.Records?.length === 0 ? (
        // <div className="row my-2">
        <div className="col-12 no-result">
          {/* <div>{t("home_page.No Results Found")}</div> */}
          <div>{t(miniFestivalsData.message)}</div>
        </div>
      ) : (
        // </div>
        miniFestivalsData?.Records?.map((festival, index) => (
          <div
            className="container-fluid fests-n-events"
            id={`fest-${festival.unique_id}`}
            key={index}
          >
            <div className="mini-festival-content my-5 py-lg-5 py-4" key={index}>
              <div className="row content-header">
                <div className="col-md-6 col-lg-6">
                  <p className="name">
                    {(festival &&
                      getLangSpecificAttribute(
                        festival.language_content,
                        lang_id,
                        'event_name',
                        'lang_id',
                      )) ||
                      festival?.category_link_name}
                  </p>
                  <p className="desc">
                    {(festival &&
                      getLangSpecificAttribute(
                        festival.language_content,
                        lang_id,
                        'event_description',
                        'lang_id',
                      )) ||
                      festival?.category_link_description}
                  </p>
                </div>
                <div className="col-md-6 offset-lg-1 col-lg-5">
                  <div className="top">
                    <div className="border-right pr-4">
                      <p className="heading">{t(`Movies`)}</p>
                      <h6>{festival?.total_movies}</h6>
                    </div>
                    {/* <hr className="vertical-separator"></hr> */}
                    <div className="pl-4">
                      <p className="heading">{t(`Dates`)}</p>
                      <h6>
                        {moment(festival?.from_date).format('Do MMM')} -{' '}
                        {moment(festival?.to_date).format('Do MMM')}
                      </h6>
                    </div>
                  </div>
                  <div className="bottom">
                    {/* <div className="d-flex">
                    <p className="heading">Formats</p>
                    <ul>
                      <li>2D,</li>
                      <li>3D,</li>
                      <li>4D</li>
                    </ul>
                  </div> */}
                    {/* <div className="d-flex">
                    <p className="heading">Languages</p>
                    <ul>
                      <li>EN,</li>
                      <li>ER,</li>
                      <li>FR</li>
                    </ul>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="row content-footer mt-lg-4">
                {festival?.movie_data?.data?.map((movie, index) => (
                  <MovieCard {...movie} key={index} setShowOffers={() => {}} />
                ))}
                {festival?.movie_data?.pagination.lastPage >
                  festival?.movie_data?.pagination.currentPage && (
                  <>
                    {selectedIndex === index && miniFestivalsLoading ? (
                      <div className="col-12 text-center">
                        <Spinner />
                      </div>
                    ) : (
                      <div className="w-100 mt-2">
                        <button
                          className="btn-main mx-auto"
                          onClick={() =>
                            onClickMiniFestivalLoadMore(
                              index,
                              festival?.movie_data?.pagination.currentPage + 1,
                              festival?.category_link_name,
                            )
                          }
                        >
                          {t('common.Load More')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </section>
  );
};

export default withNamespaces()(MiniFestivals);
