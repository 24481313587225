import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASEURL, TOKEN } from '@src/config';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { decryptPayload } from '@helper/index';

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASEURL}`,
  prepareHeaders: (headers, { getState, extra, endpoint, forced, type }) => {
    // const token = getState().auth.token

    let user_token = reactLocalStorage.get('user_token', null, true);
    if (!!user_token) {
      headers.set('x-user-token', `${user_token}`);
    }

    if (TOKEN) {
      headers.set('Authorization', `${TOKEN}`);
      headers.set('x-web-security', true);
    }

    return headers;
  },
});

const baseApi = createApi({
  reducerPath: 'base',
  baseQuery,
  endpoints: () => ({}),

  // providesTags of all api endpoint
  tagTypes: [
    'BANNERS',
    'UPCOMING',
    'ONDEMAND',
    'NOWSHOWING',
    'MINIFESTIVAL',
    'FESTIVALLIST',
    'QUICKBOOK',
  ],
});

export { baseApi, baseQuery };
