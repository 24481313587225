import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import { sendeqfxmail } from '@store/auth/authActions';
import BreadCrumb from '@components/partials/BreadCrumbs';
import MovieShow from './partials/movieShow.jsx';
import Checkbox from '@components/Checkbox.js';

import ShowDetailsPopup from './partials/popups/showDetailsPopup.jsx';

import SeatTypePopup from './partials/popups/SeatTypePopup.jsx';
import { motion } from 'framer-motion';

import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Form,
} from 'react-bootstrap';
import Slider from 'react-slick';

function Feedback({ t }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(``);
  const [suggestions, setSuggestions] = useState(``);
  const [fullname, setfullname] = useState(``);
  const [organization, setOrganization] = useState(``);
  const [contactNo, setContactNo] = useState(``);
  const [numbervoucher, setnumbervoucher] = useState(``);
  const [Check1, setCheck1] = useState(``);
  const [Check2, setCheck2] = useState(``);
  const [Check3, setCheck3] = useState(``);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const { pathname } = useLocation();

  const isBookEvent = pathname === '/book-event';
  let VoucherData = [
    {
      id: '01',
      name: '2d',
    },
    {
      id: '02',
      name: '3d',
    },
    {
      id: '03',
      name: 'Weekdays',
    },
    {
      id: '04',
      name: 'Weekend',
    },
  ];

  let CinemaData = [
    {
      id: '01',
      name: 'Chhaya Center, Kathmandu',
    },
    {
      id: '02',
      name: 'Civil Mall, Kathmandu',
    },
    {
      id: '03',
      name: 'Durbar Cinemax, Kathmandu',
    },
    {
      id: '04',
      name: 'Labim Mall, Lalitpur',
    },
    {
      id: '05',
      name: 'Bhatbhateni, Bhaktapur',
    },
    {
      id: '06',
      name: 'Bhatbhateni, Biratnagar',
    },
    {
      id: '07',
      name: 'One Stop Mall, Birtamode',
    },
    {
      id: '08',
      name: 'Bhatbhateni, Butwal',
    },
    {
      id: '09',
      name: 'Apex Mall, Damauli',
    },
    {
      id: '10',
      name: 'Jalma, Narayangarh',
    },
    {
      id: '11',
      name: 'Bageshwori, Nepalgunj',
    },
  ];

  // Cinema CheckBox code Start Here

  const [isCinemaCheckAll, setCinemaIsCheckAll] = useState(false);
  const [isCinemaCheck, setCinemaIsCheck] = useState([]);
  const [Cinemalist, setCinemaList] = useState([]);

  useEffect(() => {
    setCinemaList(CinemaData);
  }, [...Cinemalist]);

  const handleSelectAllCinema = (e) => {
    setCinemaIsCheckAll(!isCinemaCheckAll);
    setCinemaIsCheck(Cinemalist.map((li) => li.name));
    if (isCinemaCheckAll) {
      setCinemaIsCheck([]);
    }
  };

  const handleClickCinema = (e) => {
    const { id, checked, name } = e.target;
    setCinemaIsCheck([...isCinemaCheck, name]);
    if (!checked) {
      setCinemaIsCheck(isCinemaCheck.filter((item) => item !== name));
    }
  };

  console.log(isCinemaCheck);

  const catalogCinema = Cinemalist.map(({ id, name }) => {
    return (
      <>
        <div className="">
          <Checkbox
            key={id}
            type="checkbox"
            name={name}
            id={id}
            handleClick={handleClickCinema}
            isChecked={isCinemaCheck.includes(name)}
          />
          {/* <span className="mx-2">{name}</span> */}
        </div>
      </>
    );
  });

  // CheckBox Code Ends Here

  // Ticket option CheckBox code Start Here

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(VoucherData);
  }, [...list]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.name));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked, name } = e.target;
    setIsCheck([...isCheck, name]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== name));
    }
  };

  console.log(isCheck);

  const catalog = list.map(({ id, name }) => {
    return (
      <>
        <Checkbox
          key={id}
          type="checkbox"
          name={name}
          id={id}
          handleClick={handleClick}
          isChecked={isCheck.includes(name)}
        />
        {/* <span className="mx-2">{name}</span> */}
      </>
    );
  });

  // CheckBox Code Ends Here

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  //   const stc_id =
  //     globalOptions.find(
  //       (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
  //     )?.go_value ?? 3;
  //   console.log({ stc_id });
  const stc_id = 3;
  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);

  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null;
  let contact_no = loggedInUser?.cust_mobile || null;
  let cinema_id = cinema?.cinema_id || null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll('.fields');
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains('error')
      ) {
        field.style.marginBottom = '3px';
      }
    });
  });

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      fullname &&
      email &&
      contactNo &&
      numbervoucher &&
      isCheck &&
      isCinemaCheck
    ) {
      setValidForm(true);
    }
  };

  useEffect(() => {
    if (validForm) {
      dispatch(
        sendeqfxmail({
          name: fullname,
          organization_name: organization,
          email,
          contact_no: contactNo,
          numbervoucher,
          isCheck,
          isCinemaCheck,
          // message: suggestions,
          // customer_id,
          // cinema_id,
          stc_id,
        }),
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setfullname('');
      setEmail('');
      setSuggestions('');
      setOrganization('');
      setContactNo('');
      setnumbervoucher('');
      setIsCheck('');
    }
  }, [status]);

  return (
    <>
      <div className="contact_breadcrumb">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Voucher')} />
      </div>
      <div className="header-space container-fluid container-xl feedback-container">
        {/* <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {pathname === "/book-event" ? "Booking" : "Contact"}
        </motion.p> */}

        <motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          <p className="sub-title mx-auto">
            {pathname === '/book-event' ? t('Book an Event') : t('Voucher Form')}
          </p>

          {status && (
            <div className="email-success-message">
              {t('Message sent successfully')}{' '}
            </div>
          )}

          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <h5 className="input_headings mb-3 text-primary font-weight-bold">
                  Your Name
                </h5>
                <input
                  className="fields"
                  type="text"
                  placeholder={t('Enter Your Name')}
                  value={fullname}
                  onChange={(e) => setfullname(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    fullname === '' &&
                    t('Please enter your Name')}
                </p>
              </div>

              <div className="col-xl-6 col-md-12">
                <h5 className="input_headings mb-3 text-primary font-weight-bold">
                  Your Organization
                </h5>
                <input
                  className="fields"
                  type="text"
                  placeholder={t('Enter Your Organization (Optional)')}
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />

                {/* <p className="error">
                  {submitted &&
                    !status &&
                    organization === '' &&
                    t('Please enter your Name')}
                </p> */}
              </div>

              <div className="col-xl-6 col-md-12">
                <h5 className="input_headings mb-3 text-primary font-weight-bold">
                  Your Contact Number
                </h5>
                <input
                  className="fields"
                  type="number"
                  placeholder={t('Enter Your Contact Number')}
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    contactNo === '' &&
                    t('Please enter your Contact Number')}
                </p>
              </div>

              <div className="col-xl-6 col-md-12">
                <h5 className="input_headings mb-3 text-primary font-weight-bold">
                  Your Email
                </h5>
                <input
                  className="fields"
                  type="email"
                  placeholder={
                    pathname === '/book-event'
                      ? t('Please provide event details')
                      : t('Enter Email Address')
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    email === '' &&
                    t('Please enter your email')}
                </p>
              </div>

              <div className="col-xl-6 col-md-12">
                <h5 className="input_headings mb-3 text-primary font-weight-bold">
                  Number of Voucher
                </h5>
                <input
                  className="fields"
                  type="number"
                  placeholder={t('Number Of Vouchers ?')}
                  value={numbervoucher}
                  maxLength={2}
                  onChange={(e) => setnumbervoucher(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    numbervoucher === '' &&
                    t('Please enter Number Of Vouchers')}
                </p>
              </div>

              <h5 className="input_headings col-12 col-md-12 mb-3 text-primary font-weight-bold">
                Select Ticket Option
              </h5>
              <div className="col-12 col-md-12 mb-2 d-flex flex-wrap align-items-center">
                <Checkbox
                  type="checkbox"
                  name="All"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                />
                {/* <span className="mx-2">All</span> */}
                {catalog}
              </div>
              <p className="error">
                {submitted &&
                  !status &&
                  isCheck == false &&
                  t('Please Select Ticket Options')}
              </p>

              <h5 className="input_headings col-12 col-md-12 mb-3 text-primary font-weight-bold">
                Select Cinema
              </h5>
              <div className="col-12 col-md-12 cinema_checkbox">
                <div className="mb-2 d-flex flex-wrap align-items-center">
                  <Checkbox
                    type="checkbox"
                    name="All"
                    id="selectAll"
                    handleClick={handleSelectAllCinema}
                    isChecked={isCinemaCheckAll}
                  />
                  {/* <span className="mx-2">All</span> */}
                  {catalogCinema}
                </div>
                <p className="error">
                  {submitted &&
                    !status &&
                    isCinemaCheck == false &&
                    t('Please Select Ticket Options')}
                </p>
              </div>
              {/* <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Select Cinema
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="fb">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Subject
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="fields"
                  type="text"
                  placeholder="Showtime and Ticket"
                />
              </div> */}
              {/* <div className="col-12">
                <textarea
                  className="fields"
                  rows="8"
                  placeholder={t('type-suggestions')}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    suggestions === '' &&
                    t('Please enter suggestions')}
                </p>
              </div> */}
              <div className="col-12 col-sm-5 mx-auto">
                <button className="btn-main mx-auto" type="submit">
                  {t('Submit')}
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </div>
    </>
  );
}

export default withNamespaces()(Feedback);
