import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import { array } from 'yup';
import BreadCrumb from '@components/partials/BreadCrumbs';

const TariffsAndVouchers = (props) => {
  const { t, classes = '' } = props;

  let tariffs = [
    {
      header: [
        { title: `${t('Prices at the Stussihof')}`, subTitle: '' },
        { title: `${t('Auditorium')} 1`, subTitle: '' },
        { title: `${t('Auditorium')} 2`, subTitle: '' },
      ],
      body: [
        {
          title: `${t('Adult')}`,
          subTitle: ``,
          tariff: [{ value: '18.00' }, { value: '16.00' }],
        },
        {
          title: `${t('Children and Teenagers')}`,
          subTitle: `${t('Up to 14 years')}`,
          tariff: [{ value: '15.00' }, { value: '13.00' }],
        },
        {
          title: `${t('On-demand')}`,
          subTitle: ``,
          tariff: [
            { value: `${t('From')} 299.00` },
            { value: `${t('From')} 299.00` },
          ],
        },
        {
          title: `${t('Confirmed Screening')}`,
          subTitle: ``,
          tariff: [
            { value: `${t('From')} 180.00 (ab 10 Tickets)` },
            { value: `${t('From')} 80.00 (ab 5 Tickets)` },
          ],
        },
        {
          title: `${t('Rent your Stussihof')} 2h`,
          subTitle: `${t('(w/o movie)')}`,
          tariff: [{ value: `350.00` }, { value: `250.00` }],
        },
        {
          title: `${t('Rent your Stussihof')} 4h`,
          subTitle: `${t('(w/o movie)')}`,
          tariff: [{ value: `550.00` }, { value: `450.00` }],
        },
        {
          title: `${t('Rent your Stussihof')} 8h`,
          subTitle: `${t('(w/o movie)')}`,
          tariff: [{ value: `850.00` }, { value: `650.00` }],
        },
        {
          title: `${t('Saale Pigale')} 1h`,
          subTitle: ``,
          tariff: [{ value: `250.00` }, { value: `` }],
        },
        {
          title: `${t('Per Hour')}`,
          subTitle: ``,
          tariff: [{ value: `+100.00` }],
        },
        {
          title: `${t('Whole Day')}`,
          subTitle: ``,
          tariff: [{ value: `650.00` }],
        },
      ],
    },
  ];

  return (
    <>
      <section className="tariffs_and_vouchers align_new">
        <div className="left_right_align">
          <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Our Tariff')} />
          <CenteredTitle firstHalf={t('Our Tariff')} secondHalf="" />
        </div>
        <div className="row">
          <article className="col-lg-10 col-xl-8 mx-auto">
            <article className=" tariffs_and_vouchers_cards_wrapper">
              <>
                {/* <h6>
                {"Tariffs"}
            </h6> */}
              </>
            </article>
          </article>
        </div>

        {/* TABLE */}
        {tariffs.map((tariff, index) => (
          <div className="tariff_table" key={index}>
            <table>
              <thead>
                <tr>
                  {tariff.header.map((head, headIndex) => (
                    <th key={headIndex}>
                      <h6>{head.title}</h6>
                      <p>{head.subTitle}</p>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tariff.body.map((data, dataindex) => (
                  <tr key={dataindex}>
                    <td>
                      <h6>{data.title}</h6>
                      <p>{data.subTitle}</p>
                    </td>
                    {data.tariff.map((t, tindex) => (
                      <td key={tindex}>
                        <div className={t.value && 'tariff_code'}>{t.value}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div className="tarrif_para">
          <p className="tariff_highlight mb-3">
            {t('Prices can be adjusted at any time without prior notice.')}
          </p>
          <p className="tariff_highlight">
            {t(
              'Additional costs such as technicians, additional infrastructure, etc. will be charged at cost.',
            )}
          </p>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(TariffsAndVouchers);
