import React, { useEffect, useState, useRef, useContext } from 'react';

import { withNamespaces } from 'react-i18next';

function ConfirmedBooking({ t }) {
  useEffect(() => {}, []);
  return (
    <div>
      <h4>Confirmed Booking Detail </h4>
    </div>
  );
}

export default withNamespaces()(ConfirmedBooking);
