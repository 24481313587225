import MovieService from '@apiService/MovieService';
import {
  ALLOW_WEBSITE_BOOKING,
  BOOK_SEAT,
  BOOK_SEAT_ERROR,
  BOOK_SEAT_SUCCESS,
  CLEAR_MOVIE_SCHEDULES,
  CLEAR_STATE,
  CREATE_SHOW_ERROR,
  CREATE_SHOW_SUCCESS,
  FETCH_ALL_MOVIES_SCHEDULES,
  FETCH_ALL_MOVIES_SCHEDULES_ERROR,
  FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
  FETCH_BOOKING_DETAILS,
  FETCH_BOOKING_DETAILS_ERROR,
  FETCH_BOOKING_DETAILS_SUCCESS,
  FETCH_CINEMAS,
  FETCH_CINEMAS_ERROR,
  FETCH_CINEMAS_SUCCESS,
  FETCH_CITIES_SUCCESS,
  FETCH_CURRENCY,
  FETCH_CURRENCY_ERROR,
  FETCH_CURRENCY_SUCCESS,
  FETCH_GLOBAL_OPTIONS,
  FETCH_GLOBAL_OPTIONS_ERROR,
  FETCH_GLOBAL_OPTIONS_SUCCESS,
  FETCH_LABEL_MOVIES,
  FETCH_MOVIE_DETAILS,
  FETCH_MOVIE_DETAILS_ERROR,
  FETCH_MOVIE_DETAILS_SUCCESS,
  FETCH_MOVIE_DETAILS_SUCCESS2,
  FETCH_MOVIE_SCHEDULES,
  FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
  FETCH_MOVIE_SCHEDULES_ERROR,
  FETCH_MOVIE_SCHEDULES_SUCCESS,
  FETCH_MOVIE_SCHEDULES_SUCCESS2,
  FETCH_MOVIE_SCHEDULES_SUCCESS3,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_OTHER_SCREENINGS,
  FETCH_OTHER_SCREENINGS_ERROR,
  FETCH_OTHER_SCREENINGS_SUCCESS,
  FETCH_RESERVATION_DETAILS,
  FETCH_RESERVATION_DETAILS_ERROR,
  FETCH_RESERVATION_DETAILS_SUCCESS,
  FETCH_SCHEDULE_DETAILS,
  FETCH_SCHEDULE_DETAILS_ERROR,
  FETCH_SCHEDULE_DETAILS_SUCCESS,
  FETCH_SCREENING_TYPES,
  FETCH_SCREENING_TYPES_ERROR,
  FETCH_SCREENING_TYPES_SUCCESS,
  MAX_SEAT_SELECTION,
  ON_SET_COMPLETE_NEW_TOTAL_AMOUNT,
  ON_SET_COMPLETE_RATE_CARD,
  RESET_TIMER,
  SELECT_CINEMA_SUCCESS,
  SELECT_CITY_SUCCESS,
  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  SEND_EMAIL_SUCCESS,
  SEND_SMS,
  SEND_SMS_ERROR,
  SEND_SMS_SUCCESS,
  SET_ACTIVE_PAYMENT_MODES,
  SET_ALL_LABELS,
  SET_BOOKING_TYPE,
  SET_FROM_MOVIE_PAGE,
  SET_IS_CREATE_YOUR_SHOW_ENABLED,
  SET_LABEL_DATA,
  SET_LABEL_MOVIES,
  SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS,
  SET_MOVIE_FORMATS,
  SET_MOVIE_LANGUAGES,
  SET_PAY_DISABLE_SAFER_PAY,
  SET_PRIVATE_SCREEN_AMOUNT,
  SET_SAFER_PAY_ACTIVE,
  SET_SELECTED_LANGUAGE,
  SET_SHOW,
  SET_SHOW_BOOKING_ID,
  SET_STAGE,
  SET_STRIPE_PUBLISHER_KEY,
  SET_TIMEOUT_ERROR,
  SET_TIMER,
  START_TIMER,
  STOP_TIMER,
  STRIPE_PAYMENT_SUCCESS,
  USER_SELECTION_SELECT_DATE,
  USER_SELECTION_SELECT_LANG,
  USER_SELECTION_SELECT_SCREENING_TYPE,
  USER_SELECTION_SELECT_SESSION,
  USER_SELECTION_SELECT_SHOW,
  FETCH_PROMOTIONS_OFFERS,
  FETCH_PROMOTIONS_OFFERS_ERROR,
  FETCH_PROMOTIONS_OFFERS_SUCCESS,
} from './movieTypes';
import { CLEAR_VOUCHERS_DATA } from '../promotions/promotionsTypes';
import {
  bookFnbGetDataObj,
  updateFnbDataIfNotPresent,
} from '../foodAndBeverages/foodAndBeveragesActions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TOKEN } from '@config';
import { resetTempReservationId } from '../seatLayout/seatLayoutActions';
import { SET_INTERNET_HANDLING_FEES_APPLIED } from '../seatLayout/seatLayoutTypes';
import { getLangISOCode } from '@helper/formatting';
import i18n from '../../plugins/i18n';
import { addTopUpToCashCard } from '../cashCard/cashCardActions';
import CashCardService from '../../ApiServices/CashCardService';
import { getMomentLocalLang } from '@helper/languages';
import PromotionsService from '@apiService/PromotionsService';
import { createQueryString } from '@apiService/ApiHelper';

let timerInterval = null;

export const setFromMoviePage = () => ({
  type: SET_FROM_MOVIE_PAGE,
});

export const setOndemandDate = (payload = null) => ({
  type: USER_SELECTION_SELECT_DATE,
  payload,
});
export const setOndemandShowOld = (payload = null) => ({
  type: USER_SELECTION_SELECT_SHOW,
  payload,
});
export const setOndemandShow =
  (payload = null) =>
  async (dispatch, getStore) => {
    dispatch({
      type: USER_SELECTION_SELECT_SHOW,
      payload,
    });
  };
export const setOndemandSession = (payload = null) => ({
  type: USER_SELECTION_SELECT_SESSION,
  payload,
});
export const setOndemandLang = (payload = null) => ({
  type: USER_SELECTION_SELECT_LANG,
  payload,
});
export const setOndemandScreeningType = (payload = null) => ({
  type: USER_SELECTION_SELECT_SCREENING_TYPE,
  payload,
});

export const resetScreeningTypes = (payload = null) => ({
  type: FETCH_SCREENING_TYPES,
  payload,
});
export const clearNewOndemandState = (payload = null) => ({
  type: 'RESET_USER_SELECTION',
  payload,
});
export const clearMovieDetails = (payload = null) => ({
  type: FETCH_MOVIE_DETAILS,
  payload,
});
export const setPayNowTotal = (payload = null) => ({
  type: 'PAY_NOW_TOTAL',
  payload,
});

export const fetchMoviesListByLabel = (label_id) => async (dispatch, getStore) => {
  dispatch({ type: FETCH_LABEL_MOVIES });
  MovieService.GetMoviesListByLabels({
    label_id: label_id,
  })
    .then((response) => {
      const { data } = response;
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: SET_LABEL_DATA,
          payload: data.labelData,
        });
        dispatch({
          type: SET_LABEL_MOVIES,
          payload: data.Records,
        });
      } else {
        dispatch({
          type: SET_LABEL_MOVIES,
          payload: [],
        });
      }
    })
    .catch((err) => {
      console.log('Error:', err);
      dispatch({
        type: SET_LABEL_MOVIES,
        payload: [],
      });
    });
};

export const fetchLabelsList = () => async (dispatch, getStore) => {
  MovieService.GetLabelsList()
    .then((response) => {
      const { data } = response;
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: SET_ALL_LABELS,
          payload: data.Records,
        });
      } else {
        dispatch({
          type: SET_ALL_LABELS,
          payload: [],
        });
      }
    })
    .catch((err) => {
      console.log('Error:', err);
      dispatch({
        type: SET_ALL_LABELS,
        payload: [],
      });
    });
};

export const fetchMovieDetails =
  ({ movie_id, content_lang_id, category_id, cinema_id, cust_id = null }) =>
  async (dispatch, getStore) => {
    try {
      //let store = getStore()
      // skip if already loaded to avoid calling two times
      // if (
      //     store?.movies?.movieDetailsLoading === false &&
      //     store?.movies?.movieDetailsError === null
      // ) {
      dispatch({ type: FETCH_MOVIE_DETAILS });
      const { data } = await MovieService.GetMovieDetails({
        movie_id,
        content_lang_id,
        category_id,
        cinema_id,
        md_for_ondemand: 'Y',
        guest_user_id: localStorage.getItem(`guest_user_id`),
        cust_id: cust_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_MOVIE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: FETCH_MOVIE_DETAILS_SUCCESS2,
          payload: data.Records[0],
        });
        dispatch(setLanguageSpecificMovieFunction());
      } else {
        dispatch({ type: FETCH_MOVIE_DETAILS_ERROR, payload: data.message });
      }
      // }
    } catch (error) {
      dispatch({
        type: FETCH_MOVIE_DETAILS_ERROR,
        payload: error?.message ?? error,
      });
    }
  };

export const setFirstAvailPrivate =
  ({ movie_id, cinema_id, dateSchedule = undefined }) =>
  async (dispatch, getStore) => {
    MovieService.SetFirstAvailPrivate({
      movie_id,
      queries: {
        cinema_id,
        isUserLogged: localStorage.getItem('user') ? 'Y' : 'N',
        fromDate: dateSchedule,
      },
    });
  };

export const fetchMovieSchedules =
  ({
    movie_id,
    dateSchedule,
    cinema_id,
    city_id,
    lang_id,
    mf_id,
    call,
    callback,
    onConfirmShowDetailsPopup,
    onChangeFormat,
    onChangeLanguage,
    dateNull,
    setSelectedLanguage,
    setSelectedFormat,
    setSelectedLanguage2,
    setSelectedFormat2,
    onChangeCinema,
    selectedLanguage2,
    selectedFormat2,
    langSelectedFromNowShowingPage,
    mdIdSelectedFromNowShowingPage,
    setUnavailableDateLoader,
  }) =>
  async (dispatch, getStore) => {
    try {
      let store = getStore();
      // console.log(`call`, call);
      // console.log(`lang_id`, lang_id);
      // console.log(`mf_id`, mf_id);
      dispatch({ type: FETCH_MOVIE_SCHEDULES });
      const { data } = await MovieService.GetMovieSchedules({
        movie_id,
        queries: {
          fromDate: dateNull ? null : dateSchedule,
          cinema_id,
          city_id,
          lang_id,
          mf_id,
          isUserLogged: localStorage.getItem('user') ? 'Y' : 'N',
          md_id:
            !!mdIdSelectedFromNowShowingPage &&
            Number.isSafeInteger(parseInt(mdIdSelectedFromNowShowingPage, 10))
              ? mdIdSelectedFromNowShowingPage
              : undefined,
        },
      });
      if (data.status) {
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS,
          payload: data,
        });
        if (call === 1) {
          dispatch({ type: `${FETCH_MOVIE_SCHEDULES}_LOADING` });
          dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          dispatch({ type: SET_MOVIE_FORMATS, payload: data.FormatArray });
          dispatch({
            type: SET_MOVIE_LANGUAGES,
            payload: data?.LanguageArray?.filter((l) => l.lang_name !== 'All').map(
              (l) => ({
                lang_id: l.lang_id,
                lang_name: l.lang_name,
              }),
            ),
          });
          dispatch({
            type: `${SET_MOVIE_LANGUAGES}_WITH_SUBS`,
            payload: data?.VersionNameArray || [],
          });
          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
          ) {
            // callback(true);

            if (data.LanguageArray.length === 1) {
              // setSelectedLanguage(data.LanguageArray[0]);
              // onChangeLanguage(data.LanguageArray[0]);
            }

            if (data.FormatArray.length === 1) {
              // setSelectedFormat(data.FormatArray[0]);
              // onChangeFormat(data.FormatArray[0]);
            }
          } else if (data.MovieListArray.length > 0) {
            // console.log(
            //   "inital date obj ======> 1",
            //   data.dateList[0]?.ss_actual_start_date
            // );
            onChangeFormat(data.FormatArray[0]);
            onChangeLanguage(data.LanguageArray[0]);
            onConfirmShowDetailsPopup(
              data.LanguageArray[0],
              data.FormatArray[0],
              data.dateList[0]?.ss_actual_start_date,
            );
          } else {
            // console.log(
            //   "inital date obj ======> 2",
            //   data.dateList[0]?.ss_actual_start_date
            // );
            onConfirmShowDetailsPopup(
              null,
              null,
              data.dateList[0]?.ss_actual_start_date,
            );
          }
          if (data?.CinemaArray.length === 1) {
            onChangeCinema(data?.CinemaArray[0], false);
          }

          if (data.LanguageArray.length > 0) {
            let langToSet = data.LanguageArray[0];

            if (langSelectedFromNowShowingPage) {
              let movielangs = data.LanguageArray.filter(
                (l) => l?.lang_id == langSelectedFromNowShowingPage,
              );
              if (movielangs.length) langToSet = movielangs[0];
            }

            /*try {
            const globalOptions = store?.movies?.globalOptions;
            let languageArray = globalOptions.find((go) => go.go_key === `KIOSK_LANGUAGE`)?.go_value;
            languageArray = JSON.parse(languageArray)

            if (languageArray && languageArray.length) {
              let movielangs = data.LanguageArray.filter(l => l?.lang_id === languageArray[0]?.lang_id)
              if (movielangs.length)
                langToSet = movielangs[0]
            }
          } catch (e) {
            console.log(`Lang parsing Error in fetchMovieSchedules`, { call });
          }*/

            if (langToSet) {
              // dispatch({ type: USER_SELECTION_SELECT_LANG, payload: langToSet })
            }
          }

          if (data?.actualDate || data?.checkLowestDate)
            dispatch({
              type: USER_SELECTION_SELECT_DATE,
              payload:
                data?.dateList?.[0]?.ss_actual_start_date ||
                data?.checkLowestDate ||
                data?.actualDate,
            });
          else {
            dispatch({
              type: USER_SELECTION_SELECT_DATE,
              payload: moment().format('YYYY-MM-DD'),
            });
            setUnavailableDateLoader && setUnavailableDateLoader(false);
          }
        } else if (call === 2) {
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
            payload: data,
          });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          dispatch({
            type: SET_MOVIE_LANGUAGES,
            payload: data?.LanguageArray?.filter((l) => l.lang_name !== 'All').map(
              (l) => ({
                lang_id: l.lang_id,
                lang_name: l.lang_name,
              }),
            ),
          });
          dispatch({
            type: `${SET_MOVIE_LANGUAGES}_WITH_SUBS`,
            payload: data?.VersionNameArray || [],
          });
          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 1 || data.FormatArray.length > 1)
          ) {
            if (!lang_id || !mf_id) {
              // callback(true);
            }
            // if (
            //   data.LanguageArray.find(
            //     (x) => x.lang_id === selectedLanguage2?.lang_id
            //   ) === undefined ||
            //   data.FormatArray.find((x) => x.mf_id === selectedFormat2?.mf_id) ===
            //     undefined
            // ) {
            //   callback(true);
            // } else {
            //   if (selectedLanguage2) {
            //     setSelectedLanguage(selectedLanguage2);
            //   }

            //   if (selectedFormat2) {
            //     setSelectedFormat(selectedFormat2);
            //   }
            // }

            if (!lang_id && data.LanguageArray.length === 1) {
              // setSelectedLanguage2(data.LanguageArray[0]);
            }

            if (!mf_id && data.FormatArray.length === 1) {
              // setSelectedFormat2(data.FormatArray[0]);
            }
          } else if (data.MovieListArray.length > 0) {
            if (!lang_id && data.LanguageArray.length > 0) {
              // setSelectedLanguage(data.LanguageArray[0]);
              // setSelectedLanguage2(data.LanguageArray[0]);
            }

            if (!mf_id && data.FormatArray.length > 0) {
              // setSelectedFormat(data.FormatArray[0]);
              // setSelectedFormat2(data.FormatArray[0]);
            }
          }
        }
      } else {
        console.log(`Error in fetchMovieSchedules`, { call });
        dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: data.message });
      }
    } catch (error) {
      console.log(`Error in fetchMovieSchedules`, { call });
      dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: error?.message });
    }
  };

export const fetchConfirmedList =
  ({
    movie_id,
    dateSchedule,
    cinema_id,
    city_id,
    lang_id,
    mf_id,
    call,
    callback,
    setDate,
    setDateValue,
    isNowShowing,
    onChangeCinema,
    setSelectedLanguage,
    onChangeLanguage,
    onChangeFormat,
    setSelectedFormat,
    skip,
    setUnavailableDateLoader,
    langWithSubsSelected,
    mdIdSelected,
  }) =>
  async (dispatch, getStore) => {
    try {
      let store = getStore();
      dispatch({ type: FETCH_MOVIE_SCHEDULES });
      const { data } = await MovieService.GetConfirmedList({
        movie_id,
        queries: {
          fromDate: dateSchedule,
          cinema_id,
          city_id,
          lang_id,
          mf_id,
          md_id:
            !!mdIdSelected && Number.isSafeInteger(parseInt(mdIdSelected, 10))
              ? mdIdSelected
              : undefined,
        },
      });
      if (data.status) {
        if (data?.MovieListArrayNew && data?.MovieListArrayNew?.length) {
          data.MovieListArrayNew.forEach((movie) => {
            movie.CinemaList.forEach((cine) => {
              cine.format_list.forEach((mf) => {
                mf.screens.forEach((scr) => {
                  scr.schedule_list.forEach((ss) => {
                    let time = moment(ss.showTime, ['HH.mm'])
                      .locale('en')
                      .format('hh:mm A')
                      .toLowerCase();

                    ss._isPrevDayAmShow =
                      time.includes('am') &&
                      (time.startsWith('12') ||
                        time.startsWith('01') ||
                        time.startsWith('02') ||
                        time.startsWith('03') ||
                        time.startsWith('04') ||
                        time.startsWith('05'));
                  });

                  scr.schedule_list_after_midnight =
                    scr.midnight_schedule_array || [];
                });
              });
            });
          });
        }

        // let currentDayObjFromDateList = data?.dateList?.[0];
        //
        // let nextDate = moment(
        //   currentDayObjFromDateList?.ss_actual_start_date ??
        //     moment().format('YYYY-MM-DD'),
        // )
        //   .add(1, 'days')
        //   .format('YYYY-MM-DD');
        //
        // console.log({
        //   call,
        //   currentDayObjFromDateList,
        //   nextDate,
        // });
        //
        // const nextDayData = await MovieService.GetConfirmedList({
        //   movie_id,
        //   queries: {
        //     fromDate: nextDate,
        //     cinema_id,
        //     city_id,
        //     lang_id,
        //     mf_id,
        //     md_id:
        //       !!mdIdSelected && Number.isSafeInteger(parseInt(mdIdSelected, 10))
        //         ? mdIdSelected
        //         : undefined,
        //   },
        // }).catch(console.log);
        //
        // if (nextDayData && nextDayData?.data?.status) {
        //   if (
        //     nextDayData?.data?.MovieListArrayNew &&
        //     nextDayData?.data?.MovieListArrayNew?.length
        //   ) {
        //     nextDayData.data.MovieListArrayNew.forEach((movie) => {
        //       movie.CinemaList.forEach((cine) => {
        //         cine.format_list.forEach((mf) => {
        //           mf.screens.forEach((scr) => {
        //             scr.schedule_list.forEach((ss) => {
        //               let time = moment(ss.showTime, ['HH.mm'])
        //                 .locale('en')
        //                 .format('hh:mm A')
        //                 .toLowerCase();
        //
        //               ss._isNextDayAmShow =
        //                 time.includes('am') &&
        //                 (time.startsWith('12') ||
        //                   time.startsWith('01') ||
        //                   time.startsWith('02') ||
        //                   time.startsWith('03') ||
        //                   time.startsWith('04') ||
        //                   time.startsWith('05'));
        //             });
        //
        //             scr.schedule_list_after_midnight = scr.schedule_list.filter(
        //               (x) => x._isNextDayAmShow,
        //             );
        //           });
        //         });
        //       });
        //     });
        //   }
        //
        //   console.log({
        //     'nextDayData.data.status': nextDayData.data.status,
        //     'nextDayData.data.dateList': nextDayData.data.dateList,
        //   });
        // }
        //
        // if (data?.MovieListArrayNew && data?.MovieListArrayNew?.length) {
        //   data.MovieListArrayNew.forEach((movie) => {
        //     movie.CinemaList.forEach((cine) => {
        //       cine.format_list.forEach((mf) => {
        //         mf.screens.forEach((scr) => {
        //           if (
        //             nextDayData?.data?.MovieListArrayNew &&
        //             nextDayData?.data?.MovieListArrayNew?.length
        //           ) {
        //             nextDayData.data.MovieListArrayNew.forEach((movieN) => {
        //               movieN.CinemaList.forEach((cineN) => {
        //                 cineN.format_list.forEach((mfN) => {
        //                   mfN.screens.forEach((scrN) => {
        //                     if (
        //                       cine.cinema_id == cineN.cinema_id &&
        //                       mf.lang_id == mfN.lang_id &&
        //                       mf.version_name == mfN.version_name &&
        //                       mf.version_full_name == mfN.version_full_name &&
        //                       scr.screen_id == scrN.screen_id &&
        //                       scrN?.schedule_list_after_midnight?.length > 0
        //                     ) {
        //                       scr.schedule_list_after_midnight =
        //                         scrN?.schedule_list_after_midnight ?? [];
        //                     }
        //                   });
        //                 });
        //               });
        //             });
        //           }
        //         });
        //       });
        //     });
        //   });
        // }
        //
        // console.log({
        //   nextDayData,
        //   data,
        // });

        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS,
          payload: data,
        });

        // if (!dateSchedule) {
        //   dispatch({
        //     type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
        //     payload: data,
        //   });
        // }
        if (call === 1) {
          dispatch({ type: `${FETCH_MOVIE_SCHEDULES}_LOADING` });
          dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          dispatch({
            type: SET_MOVIE_LANGUAGES,
            payload: data?.LanguageArray?.filter((l) => l.lang_name !== 'All').map(
              (l) => ({
                lang_id: l.lang_id,
                lang_name: l.lang_name,
              }),
            ),
          });
          dispatch({
            type: `${SET_MOVIE_LANGUAGES}_WITH_SUBS`,
            payload: data?.VersionNameArray || [],
          });
          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 2 || data.FormatArray.length > 2)
          ) {
            callback(true);
          }
          // console.log("data?.CinemaArray.length :>> ", data?.CinemaArray.length);
          if (data?.CinemaArray.length === 2) {
            onChangeCinema(
              data?.CinemaArray[1],
              data?.dateList[0].ss_actual_start_date,
              skip && skip?.length ? skip : [], //skipping these
            );
          }
          if (data?.FormatArray.length === 2) {
            // console.log("its coming here.. setSelectedFormat");
            setSelectedFormat(data?.FormatArray[1]);
            if (onChangeFormat) onChangeFormat(data?.FormatArray[1]);
          }
          if (data?.LanguageArray.length === 2) {
            // console.log("its coming here.. setSelectedLanguage");

            setSelectedLanguage(data?.LanguageArray[1]);
            if (onChangeLanguage) onChangeLanguage(data?.LanguageArray[1]);
          }
          if (data.dateList.length > 0) {
            setDate(data.dateList[0].ss_actual_start_date);
            setDateValue(new Date(data.dateList[0].ss_actual_start_date));

            dispatch({
              type: USER_SELECTION_SELECT_DATE,
              payload: data?.dateList?.[0].ss_actual_start_date,
            });
          } else {
            /*dispatch({
            type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
            payload: data,
          })
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          })*/
            dispatch({
              type: USER_SELECTION_SELECT_DATE,
              payload: moment().format('YYYY-MM-DD'),
            });
            setUnavailableDateLoader && setUnavailableDateLoader(false);
          }
        } else if (call === 2) {
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_DETAILS_SUCCESS,
            payload: data,
          });
          dispatch({
            type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
            payload: data,
          });

          dispatch({
            type: SET_MOVIE_LANGUAGES,
            payload: data?.LanguageArray?.filter((l) => l.lang_name !== 'All').map(
              (l) => ({
                lang_id: l.lang_id,
                lang_name: l.lang_name,
              }),
            ),
          });
          dispatch({
            type: `${SET_MOVIE_LANGUAGES}_WITH_SUBS`,
            payload: data?.VersionNameArray || [],
          });
          if (
            data.MovieListArray.length > 0 &&
            (data.LanguageArray.length > 2 || data.FormatArray.length > 2)
          ) {
            callback(true);
          }
        }
      } else {
        // console.log(`Error in fetchConfirmedList`);
        // dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: data.message });
        dispatch({ type: FETCH_MOVIE_SCHEDULES_SUCCESS2, payload: data });
        dispatch({
          type: FETCH_MOVIE_SCHEDULES_SUCCESS3,
          payload: data,
        });
      }
    } catch (error) {
      console.log(`Error in fetchConfirmedList`);
      dispatch({ type: FETCH_MOVIE_SCHEDULES_ERROR, payload: error?.message });
    }
  };

export const setStage = (payload) => async (dispatch, getState) => {
  if (!payload.show) {
    dispatch({
      type: SET_STAGE,
      payload,
    });
  } else {
    const { mycinema_ss_d_id } = getState().movies.myCinemaCreateShow;
    // console.log(
    //   "🚀 ~ file: movieActions.js ~ line 382 ~ setStage ~ mycinema_ss_d_id",
    //   mycinema_ss_d_id
    // );
    // console.log("payload :>> ", payload);
    // dispatch(fetchScreeningTypes({ mycinema_ss_d_id, payload }));
    if (payload.direct !== true) {
      payload.history.push(`/screening-type/${mycinema_ss_d_id}`);
    }
  }
};

export const setBookingType = (payload) => async (dispatch) =>
  dispatch({
    type: SET_BOOKING_TYPE,
    payload,
  });

export const onSetCompleteRateCard = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_RATE_CARD, payload });

export const onSetCompleteNewTotalAmount = (payload) => async (dispatch) =>
  dispatch({ type: ON_SET_COMPLETE_NEW_TOTAL_AMOUNT, payload });

export const setShowBookingID = (payload) => async (dispatch) =>
  dispatch({ type: SET_SHOW_BOOKING_ID, payload });

export const setPrivateScreeningAmount = (payload) => async (dispatch) =>
  dispatch({ type: SET_PRIVATE_SCREEN_AMOUNT, payload });

export const fetchScheduleDetailsBrij =
  ({ ss_id, category_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCHEDULE_DETAILS });
      const service =
        category_id === 1
          ? MovieService.GetScheduleDetailsBrij
          : MovieService.GetScheduleDetailsBrij;
      const { data } = await service({
        ss_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: { ...data.Records[0], ss_id },
        });
        dispatch({
          type: FETCH_MOVIE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: SET_SHOW,
          payload: { ...data.Records[0], category_id },
        });
      } else {
        dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: error?.message });
    }
  };

export const fetchScheduleDetails =
  ({ ss_id, category_id, mycinema_ss_d_id, booking_type_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCHEDULE_DETAILS });
      const service =
        category_id === 1
          ? MovieService.GetScheduleDetails
          : MovieService.GetCinemaScheduleDetails;
      const { data } = await service({
        ss_id,
        mycinema_ss_d_id,
        booking_type_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: { ...data.Records[0], ss_id },
        });
        dispatch({
          type: FETCH_MOVIE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: SET_SHOW,
          payload: { ...data.Records[0], category_id },
        });
      } else {
        dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_SCHEDULE_DETAILS_ERROR, payload: error?.message });
    }
  };

const getOfferObject = (state) => {
  let offerData = {
    t_offer_price: 0,
    oc_discount_value: 0,
    is_on_net_amount: null,
    is_offer_for: null,
    is_offer_applied: false,
  };

  if (!state) {
    return offerData;
  }

  const { applied_voucher, offer_type, voucher_discount } = state.promotions;

  if (applied_voucher) {
    offerData = {
      ...offerData,
      t_offer_price: voucher_discount,
      oc_discount_value: voucher_discount,
      is_on_net_amount: applied_voucher.voucher_redeem_on_net,
      is_offer_for: offer_type,
      is_offer_applied: true,
      ...applied_voucher,
    };
  }

  return offerData;
};

export const bookSeats =
  ({
    reservation_id,
    cinema_id,
    customer_id,
    email,
    viaStripe,
    callback,
    secondCallback,
    ft_id,
    payment_mode_id,
    appliedCashCard = {},
    fnbData,
  }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      let optionalPayload = {};

      if (!loggedInUser && guestUser) {
        optionalPayload.is_guest_user = 'Y';
        optionalPayload.guest_details = {
          guest_email: guestUser.guest_email,
          guest_user_id: guestUser.guest_user_id,
          guest_mobile: guestUser.guest_mobile,
          guest_country_code: guestUser.guest_country_code,
        };
      }

      dispatch({ type: BOOK_SEAT });
      let offerData = getOfferObject(state);

      const { data } = await MovieService.BookSeats({
        // "is_guest_user": "Y",
        // "guest_details": {
        //   "guest_email":"ajitmaurya3216@gmail.com",
        //   "guest_user_id":"12345678",
        //   "guest_mobile":"7977507897",
        //   "guest_country_code":"91"
        // },
        ...optionalPayload,
        isGroupRateCard: true,
        reservation_id,
        isSplitPayment: false,
        unpaid_booking: false,
        cinema_id,
        payment_mode_id: payment_mode_id ?? 14,
        approverId: null,
        order_code: null,
        oldCustomer: true,
        customer_id,
        cust_id: customer_id,
        email,
        payment_mode: {
          bs_id: 1,
          bs_source: `Box Office`,
          bs_is_active: 'Y',
          created_by: null,
          created_at: null,
          updated_by: 4,
          updated_at: '2020-07-16T04:20:00.000Z',
          bspm_id: 20,
          booking_source_id: 1,
          mapping_is_active: 'Y',
          payment_mode_id: payment_mode_id ?? 14,
          pm_payment_mode:
            payment_mode_id && payment_mode_id === 19
              ? 'Free Ticket'
              : payment_mode_id && payment_mode_id === 13
              ? 'Voucher'
              : payment_mode_id && payment_mode_id === 11
              ? 'Cash Card'
              : 'Stripe',
          pm_icon_url: null,
          pm_is_active: 'Y',
          pm_id: payment_mode_id ?? 14,
          amount: 0,
        },
        isLoyaltyApplied: false,
        offer: offerData,
        ft_id,
        ...appliedCashCard,
        fnbData,
      });
      if (data && data.status) {
        dispatch({ type: BOOK_SEAT_SUCCESS });
        dispatch({ type: SET_SHOW_BOOKING_ID, payload: data.bookingCode });
        if (window.innerWidth < 767) {
          secondCallback(3);
        } else {
          secondCallback(2);
        }
      } else {
        toast.dismiss();
        toast.error(data.message);
        dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error?.message });
      }
    } finally {
      callback(false);
    }
  };

export const cinemaBookSeats =
  ({
    reservation_id,
    cinema_id,
    customer_id,
    email,
    viaStripe,
    callback,
    secondCallback,
    ft_id,
    payment_mode_id,
    appliedCashCard = {},
    fnbData,
  }) =>
  async (dispatch, getState) => {
    try {
      let state = getState();
      const {
        movies: {
          currency: { curr_code: currency_code },
        },
      } = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      let email = loggedInUser?.cust_email;
      let guest_email = guestUser?.guest_email;

      let optionalPayload = {};

      if (!loggedInUser && guestUser) {
        optionalPayload.is_guest_user = 'Y';
        optionalPayload.guest_details = {
          guest_email: guestUser.guest_email,
          guest_user_id: guestUser.guest_user_id,
          guest_mobile: guestUser.guest_mobile,
          guest_country_code: guestUser.guest_country_code,
        };
      }

      dispatch({ type: BOOK_SEAT });
      let offerData = getOfferObject(state);
      const { data } = await MovieService.CinemaBookSeats({
        ...optionalPayload,
        isGroupRateCard: true,
        MyCinema: true,
        reservation_id,
        isSplitPayment: false,
        unpaid_booking: false,
        cinema_id,
        payment_mode_id: payment_mode_id ?? 14,
        approverId: null,
        order_code: null,
        oldCustomer: true,
        customer_id,
        cust_id: customer_id,
        email: email ?? guest_email,
        payment_mode: {
          bs_id: 1,
          bs_source: `Box Office`,
          bs_is_active: 'Y',
          created_by: null,
          created_at: null,
          updated_by: 4,
          updated_at: '2020-07-16T04:20:00.000Z',
          bspm_id: 20,
          booking_source_id: 1,
          mapping_is_active: 'Y',
          payment_mode_id: payment_mode_id ?? 14,
          pm_payment_mode:
            payment_mode_id && payment_mode_id === 19
              ? 'Free Ticket'
              : payment_mode_id && payment_mode_id === 13
              ? 'Voucher'
              : payment_mode_id && payment_mode_id === 11
              ? 'Cash Card'
              : 'Stripe',
          pm_icon_url: null,
          pm_is_active: 'Y',
          pm_id: payment_mode_id ?? 14,
          amount: 0,
        },
        isLoyaltyApplied: false,
        offer: offerData,
        currency_code,
        ft_id,
        ...appliedCashCard,
        fnbData,
      });
      if (data && data.status) {
        dispatch({ type: BOOK_SEAT_SUCCESS });
        dispatch({ type: SET_SHOW_BOOKING_ID, payload: data.sb_booking_code });
        if (window.innerWidth < 767) {
          secondCallback(3);
        } else {
          secondCallback(2);
        }
      } else {
        toast.dismiss();
        toast.error(data.message);
        dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error?.message });
      }
    } finally {
      callback(false);
    }
  };

export const fetchBookingDetails =
  ({ sb_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_BOOKING_DETAILS });
      const { data } = await MovieService.GetBookingDetails({
        sb_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_BOOKING_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
      } else {
        dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: error?.message });
    }
  };

export const fetchBookingDetailsBrij =
  ({ sb_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_BOOKING_DETAILS });
      const { data } = await MovieService.GetBookingDetailsBrij({
        sb_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_BOOKING_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
      } else {
        dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_BOOKING_DETAILS_ERROR, payload: error?.message });
    }
  };

export const fetchStripePublisherKey = (payload) => async (dispatch) => {
  try {
    // const { data } = await MovieService.GetStripePublisherKey();
    // if (data.status) {
    //   dispatch({
    //     type: SET_STRIPE_PUBLISHER_KEY,
    //     payload: data.stripePublisherKey,
    //   });
    //   localStorage.setItem(`stripe_publisher_key`, data.stripePublisherKey);
    // }
    dispatch({
      type: SET_STRIPE_PUBLISHER_KEY,
      payload: 'data.stripePublisherKey',
    });
    localStorage.setItem(`stripe_publisher_key`, 'data.stripePublisherKey');
  } catch (error) {
    console.error(error);
  }
};

export function getPriFnbDataForBookSeats(reservationDetails) {
  let selected_fnb_items_priv = [];
  let fnb_price_priv = {};

  // add fnb to store for private booking fnbData
  if (reservationDetails?.private_rate_card_detail?.[0]?.fnb?.[0]?.item_id) {
    selected_fnb_items_priv.push({
      fnb_private_id:
        reservationDetails?.private_rate_card_detail?.[0]?.fnb?.[0]
          ?.fnb_private_id || 0,
      item_id:
        reservationDetails?.private_rate_card_detail?.[0]?.fnb?.[0]?.item_id || 0,
      price:
        reservationDetails?.private_rate_card_detail?.[0]?.fnb?.[0]?.price || 0,
      net_price:
        reservationDetails?.private_rate_card_detail?.[0]?.fnb?.[0]?.net_price || 0,
      Quantity:
        reservationDetails?.private_rate_card_detail?.[0].customSeatCount || 0,
    });
  }
  if (reservationDetails?.private_rate_card_detail?.[0]?.addon?.[0]?.item_id) {
    selected_fnb_items_priv.push({
      fnb_private_id:
        reservationDetails?.private_rate_card_detail?.[0]?.addon?.[0]
          ?.fnb_private_id || 0,
      item_id:
        reservationDetails?.private_rate_card_detail?.[0]?.addon?.[0]?.item_id || 0,
      Quantity: 1,
      price:
        reservationDetails?.private_rate_card_detail?.[0]?.addon?.[0]?.price || 0,
      net_price:
        reservationDetails?.private_rate_card_detail?.[0]?.addon?.[0]?.net_price ||
        0,
    });
  }

  let total = 0;
  let net = 0;

  selected_fnb_items_priv.forEach((s) => {
    total += (s?.Quantity || 0) * (s?.price || 0);
    net += (s?.Quantity || 0) * (s?.net_price || 0);
  });

  fnb_price_priv.total_price = total;
  fnb_price_priv.total_net = net;

  if (selected_fnb_items_priv.length > 0) {
    selected_fnb_items_priv.forEach((x) => {
      x['quantity'] = x['Quantity'];
    });
  }

  return { selected_fnb_items_priv, fnb_price_priv };
}

export const onSuccessStripeToken =
  ({
    reservation_id,
    token,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    appliedVoucher,
    appliedCashCard,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();

      let reservationDetails = state.movies?.reservationDetails;
      let { selected_fnb_items_priv, fnb_price_priv } =
        getPriFnbDataForBookSeats(reservationDetails);

      const { selected_fnb_items, fnb_price } = state.fnb;
      const { curr_code, curr_id } = state.movies.currency;

      const { data } = await MovieService.CreateStripePaymentIntent({
        reservation_id,
        token,
        cust_id,
        isCinema: category_id == 2 ? false : true,
        selected_fnb_items:
          category_id == 2 ? selected_fnb_items : selected_fnb_items_priv,
        appliedVoucher,
        appliedCashCard,
      });

      if (data.status && data.charge) {
        dispatch({
          type: STRIPE_PAYMENT_SUCCESS,
        });

        let ft_id;
        let fnbData = undefined;

        if (selected_fnb_items && selected_fnb_items.length > 0) {
          fnbData = await dispatch(
            bookFnbGetDataObj({
              reservation_id,
              selectedFnbItems: selected_fnb_items,
              cinemaObj: {
                cinema_id: cinema_id,
                cinema_name: cinema_name,
              },
              currency_id: curr_id,
              ft_total_amount: fnb_price.total_price,
              ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
              email,
              mobile,
              countryMobCode,
              ft_currency_name: curr_code,
            }),
          );

          // perform fnb booking first
          // let result = await dispatch(
          //   bookFnb({
          //     reservation_id,
          //     selectedFnbItems: selected_fnb_items,
          //     cinemaObj: {
          //       cinema_id: cinema_id,
          //       cinema_name: cinema_name,
          //     },
          //     currency_id: curr_id,
          //     ft_total_amount: fnb_price.total_price,
          //     ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
          //     email,
          //     mobile,
          //     countryMobCode,
          //     ft_currency_name: curr_code,
          //   })
          // );
          //
          // if (!result.status) {
          //   dispatch({ type: BOOK_SEAT_ERROR, payload: "Fnb booking failed" });
          //   return;
          // }

          // ft_id = result.ft_id;
        }
        if (
          category_id == 1 &&
          selected_fnb_items_priv &&
          selected_fnb_items_priv.length > 0
        ) {
          fnbData = await dispatch(
            bookFnbGetDataObj({
              reservation_id,
              selectedFnbItems: selected_fnb_items_priv,
              cinemaObj: {
                cinema_id: cinema_id,
                cinema_name: cinema_name,
              },
              currency_id: curr_id,
              ft_total_amount: fnb_price_priv?.total_price || 0,
              ft_net_amount: fnb_price_priv?.total_net || 0,
              email,
              mobile,
              countryMobCode,
              ft_currency_name: curr_code,
            }),
          );
        }

        category_id == 1
          ? dispatch(
              cinemaBookSeats({
                reservation_id,
                cinema_id,
                customer_id: cust_id,
                email,
                viaStripe: true,
                callback,
                secondCallback,
                ft_id,
                appliedCashCard,
                fnbData,
              }),
            )
          : dispatch(
              bookSeats({
                reservation_id,
                cinema_id,
                customer_id: cust_id,
                email,
                viaStripe: true,
                callback,
                secondCallback,
                ft_id,
                appliedCashCard,
                fnbData,
              }),
            );
      }
    } catch (error) {
      console.error(error);
    }
    dispatch(resetTempReservationId());
  };

export const onSuccessTopupStripeToken =
  (payload) => async (dispatch, getState) => {
    // dispatch({ type: STOP_TIMER });
    try {
      const state = getState();

      /*const { data } = await CashCardService.CreateTopupStripePaymentIntent({
      ...payload
    });

    if (data.status && data.charge) {
      dispatch({
        type: STRIPE_PAYMENT_SUCCESS
      });

      dispatch(addTopUpToCashCard({
        ...payload
      }))
      .then((addTopUpToCashCardRes) => {
        if (addTopUpToCashCardRes?.status) {
          payload.onClickNext()
        }
        else {
          toast.dismiss();
          toast.error(addTopUpToCashCardRes?.message || 'Something went wrong!');
        }
      })
      .catch(error => {
        toast.dismiss();
        toast.error(error?.message ?? error);
        console.error(error);
      })
    }
    else {
      toast.dismiss();
      toast.error(data.message);
    }*/

      return dispatch(
        addTopUpToCashCard({
          ...payload,
        }),
      )
        .then((addTopUpToCashCardRes) => {
          if (addTopUpToCashCardRes?.status) {
            payload.setLoading && payload.setLoading(false);
            payload.onClickNext();
          } else {
            toast.dismiss();
            toast.error(addTopUpToCashCardRes?.message || 'Something went wrong!');
          }
        })
        .catch((error) => {
          toast.dismiss();
          toast.error(error?.message ?? error);
          console.error(error);
        });
    } catch (error) {
      toast.dismiss();
      toast.error(error?.message ?? error);
      console.error(error);
    }
  };

export const onSuccessTopupSaferPay = (payload) => async (dispatch, getState) => {
  // dispatch({ type: STOP_TIMER });
  try {
    const state = getState();

    const { data } = await CashCardService.CreateTopupSaferPay({
      ...payload,
      auth_token: TOKEN,
    });

    if (data.status && data.response) {
      dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
      let url = data.response.RedirectUrl;
      // console.log("url", url);
      const newWindow = window.open(url, '_self');
      if (newWindow) newWindow.opener = null;
    } else {
      toast.dismiss();
      toast.error(data.message);
    }
  } catch (error) {
    toast.dismiss();
    toast.error(error?.message ?? error);
    console.error(error);
  } finally {
    payload.setLoading && payload.setLoading(false);
  }
};

export const makeFreeTicketBooking =
  ({
    reservation_id,
    cust_id,
    guest_id,

    callback,
    secondCallback,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });

    try {
      let payload = {
        cust_id: cust_id,
        guest_id: guest_id,
        redirect_origin: window.location.origin,
      };

      let { data } = await MovieService.BookFreeTicket(reservation_id, payload);

      if (data && data.status) {
        dispatch({ type: BOOK_SEAT_SUCCESS });
        dispatch({ type: SET_SHOW_BOOKING_ID, payload: data?.bookingCode });
        if (window.innerWidth < 767) {
          secondCallback(3);
        } else {
          secondCallback(2);
        }
      } else {
        toast.dismiss();
        toast.error(data.message);
        dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
      }
    } catch (error) {
      console.log('makeFreeTicketBooking', error);

      if (error.response) {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error?.message });
      }
    } finally {
      callback(false);
      // dispatch(resetTempReservationId());
    }
  };

export const makeOfferPaymentBooking =
  ({
    reservation_id,
    token,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    payment_mode_id,
    booking_type_id,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();
      const { selected_fnb_items, fnb_price } = state.fnb;
      const { curr_code, curr_id } = state.movies.currency;

      let reservationDetails = state.movies?.reservationDetails;
      let { selected_fnb_items_priv, fnb_price_priv } =
        getPriFnbDataForBookSeats(reservationDetails);

      let ft_id;
      let fnbData = undefined;

      if (selected_fnb_items && selected_fnb_items.length > 0) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price.total_price,
            ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
          }),
        );
      }

      if (
        category_id == 1 &&
        selected_fnb_items_priv &&
        selected_fnb_items_priv.length > 0
      ) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items_priv,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price_priv?.total_price || 0,
            ft_net_amount: fnb_price_priv?.total_net || 0,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
            payment_mode: {
              pm_id: 13,
              pm_payment_mode: 'Voucher / Gutscheine',
            },
          }),
        );
      }

      if (booking_type_id == 0)
        dispatch(
          bookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: true,
            callback,
            secondCallback,
            payment_mode_id,
            fnbData,
          }),
        );
      else
        dispatch(
          cinemaBookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: true,
            callback,
            secondCallback,
            payment_mode_id,
            fnbData,
          }),
        );
    } catch (error) {
      console.error(error);
    }
    dispatch(resetTempReservationId());
  };

export const makeFreeRateCardPaymentBooking =
  ({
    reservation_id,
    token,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    payment_mode_id,
    booking_type_id,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();
      const { selected_fnb_items, fnb_price } = state.fnb;
      const { curr_code, curr_id } = state.movies.currency;

      let reservationDetails = state.movies?.reservationDetails;
      let { selected_fnb_items_priv, fnb_price_priv } =
        getPriFnbDataForBookSeats(reservationDetails);

      let ft_id;
      let fnbData = undefined;

      if (selected_fnb_items && selected_fnb_items.length > 0) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price.total_price,
            ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
          }),
        );
      }

      if (
        category_id == 1 &&
        selected_fnb_items_priv &&
        selected_fnb_items_priv.length > 0
      ) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items_priv,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price_priv?.total_price || 0,
            ft_net_amount: fnb_price_priv?.total_net || 0,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
            payment_mode: {
              pm_id: 19,
              pm_payment_mode: 'Free Ticket',
            },
          }),
        );
      }

      if (booking_type_id == 0)
        dispatch(
          bookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: true,
            callback,
            secondCallback,
            payment_mode_id,
            fnbData,
          }),
        );
      else
        dispatch(
          cinemaBookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: true,
            callback,
            secondCallback,
            payment_mode_id,
            fnbData,
          }),
        );
    } catch (error) {
      console.error(error);
    }
    dispatch(resetTempReservationId());
  };

export const makeFullCashCardpayment =
  ({
    reservation_id,
    token,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    payment_mode_id,
    booking_type_id,
    appliedCashCard,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();
      const { selected_fnb_items, fnb_price } = state.fnb;
      const { curr_code, curr_id } = state.movies.currency;

      let reservationDetails = state.movies?.reservationDetails;
      let { selected_fnb_items_priv, fnb_price_priv } =
        getPriFnbDataForBookSeats(reservationDetails);

      let ft_id;
      let fnbData = undefined;

      if (selected_fnb_items && selected_fnb_items.length > 0) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price.total_price,
            ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
          }),
        );
      }
      if (
        category_id == 1 &&
        selected_fnb_items_priv &&
        selected_fnb_items_priv.length > 0
      ) {
        fnbData = await dispatch(
          bookFnbGetDataObj({
            reservation_id,
            selectedFnbItems: selected_fnb_items_priv,
            cinemaObj: {
              cinema_id: cinema_id,
              cinema_name: cinema_name,
            },
            currency_id: curr_id,
            ft_total_amount: fnb_price_priv?.total_price || 0,
            ft_net_amount: fnb_price_priv?.total_net || 0,
            email,
            mobile,
            countryMobCode,
            ft_currency_name: curr_code,
            payment_mode: {
              pm_id: 11,
              pm_payment_mode: 'Cash Card',
            },
          }),
        );
      }

      if (booking_type_id == 0)
        dispatch(
          bookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: false,
            callback,
            secondCallback,
            payment_mode_id,
            appliedCashCard,
            fnbData,
          }),
        );
      else
        dispatch(
          cinemaBookSeats({
            reservation_id,
            cinema_id,
            customer_id: cust_id,
            email,
            viaStripe: false,
            callback,
            secondCallback,
            payment_mode_id,
            appliedCashCard,
            fnbData,
          }),
        );
    } catch (error) {
      console.error(error);
    }
    dispatch(resetTempReservationId());
  };

export const unpaidBooking =
  ({
    reservation_id,
    cust_id,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    booking_type_id,
  }) =>
  async (dispatch, getState) => {
    const state = getState();

    let loggedInUser = state?.auth?.loggedInUser;
    let guestUser = state?.auth?.guestUser;

    if (!loggedInUser && !guestUser) {
      toast.error('Something Went Wrong');
      window.location.reload();
      return;
    }

    let optionalPayload = {};

    if (!loggedInUser && guestUser) {
      optionalPayload.is_guest_user = 'Y';
      optionalPayload.guest_details = {
        guest_email: guestUser.guest_email,
        guest_user_id: guestUser.guest_user_id,
        guest_mobile: guestUser.guest_mobile,
        guest_country_code: guestUser.guest_country_code,
      };
    }

    dispatch({ type: STOP_TIMER });
    try {
      dispatch({ type: BOOK_SEAT });
      // console.log("reservation_id :>> ", reservation_id);
      const { data } = await MovieService.BookUnpaidSeats({
        isGroupRateCard: true,
        reservationId: reservation_id,
        customer_id: cust_id,
        // unpaid_booking: false,
        // cinema_id,
        // booking_type_id,

        // "is_guest_user": "Y",
        // "guest_details": {
        //   "guest_email":"ajitmaurya3216@gmail.com",
        //   "guest_user_id":"12345678",
        //   "guest_mobile":"9594700957",
        //   "guest_country_code":"91"
        // },
        ...optionalPayload,
      });
      if (data && data.status) {
        dispatch({
          type: BOOK_SEAT_SUCCESS,
          payload: { isUnpaidBooking: true },
        });
        dispatch({
          type: SET_SHOW_BOOKING_ID,
          payload: data?.bookingCode || reservation_id,
        });

        if (window.innerWidth < 767) {
          secondCallback(3);
        } else {
          secondCallback(2);
        }
      } else {
        toast.dismiss();
        toast.error(data.message);
        dispatch({ type: BOOK_SEAT_ERROR, payload: data.message });
      }
    } catch (error) {
      if (error.response) {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error.response.data });
      } else {
        dispatch({ type: BOOK_SEAT_ERROR, payload: error?.message });
      }
    } finally {
      callback(false);
      // dispatch(resetTempReservationId());
    }
  };

export const onSuccessStripeTokenBrij =
  ({
    reservation_id,
    token,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    appliedVoucher,
    appliedCashCard,
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);

    const state = getState();
    const { selected_fnb_items, fnb_price } = state.fnb;
    const { curr_code, curr_id } = state.movies.currency;

    try {
      const { data } = await MovieService.CreateStripePaymentIntentBrij({
        reservation_id,
        token,
        cust_id,
        isCinema: category_id == 2 ? false : true,
        selected_fnb_items,
        appliedVoucher,
        appliedCashCard,
      });
      if (data.status && data.charge) {
        dispatch({
          type: STRIPE_PAYMENT_SUCCESS,
        });

        let ft_id;
        let fnbData = undefined;

        if (selected_fnb_items && selected_fnb_items.length > 0) {
          fnbData = await dispatch(
            bookFnbGetDataObj({
              reservation_id,
              selectedFnbItems: selected_fnb_items,
              cinemaObj: {
                cinema_id: cinema_id,
                cinema_name: cinema_name,
              },
              currency_id: curr_id,
              ft_total_amount: fnb_price.total_price,
              ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
              email,
              mobile,
              countryMobCode,
              ft_currency_name: curr_code,
            }),
          );
          // perform fnb booking first
          // let result = await dispatch(
          //   bookFnb({
          //     reservation_id,
          //     selectedFnbItems: selected_fnb_items,
          //     cinemaObj: {
          //       cinema_id: cinema_id,
          //       cinema_name: cinema_name,
          //     },
          //     currency_id: curr_id,
          //     ft_total_amount: fnb_price.total_price,
          //     ft_net_amount: fnb_price.total_price - fnb_price.total_tax,
          //     email,
          //     mobile,
          //     countryMobCode,
          //     ft_currency_name: curr_code,
          //   })
          // );
          //
          // if (!result.status) {
          //   dispatch({ type: BOOK_SEAT_ERROR, payload: "Fnb booking failed" });
          //   return;
          // }
          //
          // ft_id = result.ft_id;
        }

        category_id == 1
          ? dispatch(
              cinemaBookSeats({
                reservation_id,
                cinema_id,
                customer_id: cust_id,
                email,
                viaStripe: true,
                callback,
                secondCallback,
                ft_id,
                appliedCashCard,
                fnbData,
              }),
            )
          : dispatch(
              bookSeats({
                reservation_id,
                cinema_id,
                customer_id: cust_id,
                email,
                viaStripe: true,
                callback,
                secondCallback,
                ft_id,
                appliedCashCard,
                fnbData,
              }),
            );
      }
    } catch (error) {
      console.error(error);
    }

    dispatch(resetTempReservationId());
  };

export const fetchOtherScreenings =
  ({ movie_id, genre_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_OTHER_SCREENINGS });
      const { data } = await MovieService.GetTodaysScreening({ genre_id });
      if (data.status && data.Records.data.length > 0) {
        data.Records.data = data.Records.data.filter(
          (m) => m.movie_id !== movie_id,
        );
        dispatch({
          type: FETCH_OTHER_SCREENINGS_SUCCESS,
          payload: data.Records.data,
        });
      } else {
        dispatch({ type: FETCH_OTHER_SCREENINGS_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_OTHER_SCREENINGS_ERROR, payload: error });
    }
  };

export const fetchGlobalOptions = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GLOBAL_OPTIONS });
    const { data } = await MovieService.GetGlobalOptions();
    // console.log("data.Records", data.Records);
    dispatch({ type: FETCH_GLOBAL_OPTIONS_SUCCESS, payload: data.Records });
    // const seat_release_time = data.Records.find(
    //   (go) => go.go_key === "RELEASE_SEAT_BLOCKED_TIME"
    // );
    // if (seat_release_time !== undefined) {
    //   dispatch({
    //     type: SET_CHECKOUT_TIMER,
    //     payload: seat_release_time.go_value,
    //   });
    // }
    const currency = data.Records.find((go) => go.go_key === 'CURRENCY');
    if (currency !== undefined) {
      dispatch({ type: FETCH_CURRENCY });
      const { data: currencyData } = await MovieService.GetCurrency({
        curr_id: currency.go_value,
      });

      if (currencyData && currencyData.status && currencyData.Records) {
        dispatch({
          type: FETCH_CURRENCY_SUCCESS,
          payload: currencyData.Records,
        });
      } else {
        dispatch({ type: FETCH_CURRENCY_ERROR });
      }
    }

    const allow_website_booking = data.Records.find(
      (go) => go.go_key === 'ALLOW_WEBSITE_BOOKING',
    );
    if (allow_website_booking) {
      // console.log("allow_website_booking", allow_website_booking.go_value);
      let payload = allow_website_booking.go_value == 'Y' ? true : false;
      dispatch({
        type: ALLOW_WEBSITE_BOOKING,
        payload: payload,
      });
    }
    const MAX_SEAT_SELECTION_COUNT = data.Records.find(
      (go) => go.go_key == 'SELECT_SEAT_COUNT_POPUP',
    );
    if (MAX_SEAT_SELECTION_COUNT) {
      let payload = MAX_SEAT_SELECTION_COUNT.go_value;
      let value = 10;
      if (!isNaN(payload) && payload > 0 && payload <= 10) {
        value = parseInt(payload);
      } else {
        value = 10;
      }
      dispatch({
        type: MAX_SEAT_SELECTION,
        payload: value,
      });
    }

    const lang = data.Records.find(
      (go) => go.go_key === 'APPLICATION_SELECTED_LANGUAGE',
    );
    const kioskLang = data.Records.find((go) => go.go_key === 'KIOSK_LANGUAGE');
    const availLangs = JSON.parse(kioskLang?.go_value || []);
    let [selectedLang] = availLangs.filter((l) => lang?.go_value == l?.lang_id);

    let localStorageLang;
    try {
      localStorageLang = JSON.parse(localStorage.getItem('selected_language'));
    } catch (e) {
      localStorageLang = null;
    } finally {
      // console.log({ localStorageLang })
    }

    if (lang?.go_value && kioskLang?.go_value) {
      if (selectedLang && Object.keys(selectedLang).length > 0) {
        if (!localStorageLang) {
          dispatch(setSelectedLanguage(selectedLang));

          //setting lang for moment
          let langMoment = selectedLang.iso_2
            ? selectedLang.iso_2.toLowerCase()
            : getLangISOCode(selectedLang.lang_name);
          i18n.changeLanguage(langMoment);
          moment.locale(getMomentLocalLang(langMoment.toLowerCase()));
        } else {
          dispatch(setSelectedLanguage(localStorageLang));

          //setting lang for moment
          let langMoment = localStorageLang.iso_2
            ? localStorageLang.iso_2.toLowerCase()
            : getLangISOCode(localStorageLang.lang_name);
          i18n.changeLanguage(langMoment);
          moment.locale(getMomentLocalLang(langMoment.toLowerCase()));
        }
      }
    } else if (!lang?.go_value && availLangs.length > 0) {
      let selectedLang = availLangs[0];
      if (!localStorageLang) {
        dispatch(setSelectedLanguage(selectedLang));

        //setting lang for moment
        let langMoment = selectedLang.iso_2
          ? selectedLang.iso_2.toLowerCase()
          : getLangISOCode(selectedLang.lang_name);
        i18n.changeLanguage(langMoment);
        moment.locale(getMomentLocalLang(langMoment.toLowerCase()));
      } else {
        dispatch(setSelectedLanguage(localStorageLang));

        //setting lang for moment
        let langMoment = localStorageLang.iso_2
          ? localStorageLang.iso_2.toLowerCase()
          : getLangISOCode(localStorageLang.lang_name);
        i18n.changeLanguage(langMoment);
        moment.locale(getMomentLocalLang(langMoment.toLowerCase()));
      }
    }
  } catch (error) {
    dispatch({ type: FETCH_GLOBAL_OPTIONS_ERROR, payload: error?.message });
  }
};

export const fetchScreeningTypes =
  ({ mycinema_ss_d_id, payload }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCREENING_TYPES });
      const { data } = await MovieService.GetScreeningTypes({
        mycinema_ss_d_id,
      });
      if (data.status && data.Records.length > 0) {
        dispatch({
          type: FETCH_SCREENING_TYPES_SUCCESS,
          payload: data.Records,
        });

        dispatch({
          type: SET_STAGE,
          payload,
        });
        const privateType = data.Records.find((type) => type.booking_type_id === 3);
        dispatch({
          type: SET_PRIVATE_SCREEN_AMOUNT,
          payload: {
            amount: privateType.amount,
            net_amount: privateType.net_amount,
          },
        });
      } else {
        dispatch({
          type: FETCH_SCREENING_TYPES_ERROR,
          payload: { message: data.message },
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_SCREENING_TYPES_ERROR, payload: error?.message });
    }
  };

export const startTimer = () => ({
  type: START_TIMER,
  payload: {
    timerInterval,
  },
});

export const resetTimer = () => {
  if (timerInterval) {
    clearInterval(timerInterval);
    timerInterval = null;
  }

  return {
    type: RESET_TIMER,
  };
};

export const startTimerAction = () => async (dispatch) => {
  // dispatch(resetTimer());
  resetTimer();
  timerInterval = setInterval(() => {
    dispatch(startTimer());
  }, 1 * 1000);
};

export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
  dispatch({ type: CLEAR_VOUCHERS_DATA });
};

export const clearMovieSchedules = () => async (dispatch) => {
  dispatch({ type: CLEAR_MOVIE_SCHEDULES });
};

//   {
//     "mrrdr_runtime": 139,
//     "start_time": "10:30",
//     "schedule_date": "2021-03-20",
//     "cinema_ss_id": "1",
//     "guest_user_id": "12345678"
// }

export const createShow =
  ({ mrrdr_runtime, start_time, schedule_date, cinema_ss_id }, history, direct) =>
  async (dispatch) => {
    try {
      // dispatch({ type: CREATE_SHOW });
      const payload = {
        mrrdr_runtime,
        start_time,
        schedule_date,
        cinema_ss_id,
        guest_user_id: localStorage.getItem(`guest_user_id`),
      };
      const { data } = await MovieService.CreateShow(payload);
      // console.log("🚀 ~ file: movieActions.js ~ line 1822 ~ )=> ~ data", data);
      if (data.status) {
        dispatch({
          type: CREATE_SHOW_SUCCESS,
          payload: data,
        });
        // toast.success(data.message);
        dispatch(
          setStage({
            stage: 2,
            show: { mrrdr_runtime, start_time, schedule_date, cinema_ss_id },
            history,
            direct,
          }),
        );
      } else {
        console.log('its coming here because of error in create show');
        toast.error(i18n.t(data.message));
        dispatch({ type: CREATE_SHOW_ERROR, payload: data.message });
        return { status: false };
      }
    } catch (error) {
      dispatch({ type: CREATE_SHOW_ERROR, payload: error?.message });
      return { status: false };
    }
  };

export const createOndemandShow =
  ({ mrrdr_runtime, start_time, schedule_date, cinema_ss_id }, history, direct) =>
  async (dispatch) => {
    try {
      const payload = {
        mrrdr_runtime,
        start_time,
        schedule_date,
        cinema_ss_id,
        guest_user_id: localStorage.getItem(`guest_user_id`),
      };
      const { data } = await MovieService.CreateShow(payload);
      if (data.status) {
        dispatch({
          type: CREATE_SHOW_SUCCESS,
          payload: data,
        });
        return { status: true, mycinema_ss_d_id: data.mycinema_ss_d_id };
      } else {
        toast.error(i18n.t(data.message));
        dispatch({ type: CREATE_SHOW_ERROR, payload: data.message });
        return { status: false };
      }
    } catch (error) {
      dispatch({ type: CREATE_SHOW_ERROR, payload: error?.message });
      return { status: false };
    }
  };

export const setTimeoutError = (payload) => async (dispatch) =>
  dispatch({ type: SET_TIMEOUT_ERROR, payload });

export const setSelectedLanguage = (payload) => async (dispatch) =>
  dispatch({ type: SET_SELECTED_LANGUAGE, payload });

export const fetchReservationDetails =
  ({ reservation_id, category_id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_RESERVATION_DETAILS });
      const { data } = await MovieService.GetReservationDetails({
        reservation_id,
      });
      if (data && data.status) {
        console.log(data.Records[0], 'data.Records');
        dispatch({
          type: FETCH_RESERVATION_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: SET_TIMER,
          payload: {
            minutes: data.Records[0].timer_minutes - 6,
            seconds: data.Records[0].timer_second,
          },
        });
        dispatch(updateFnbDataIfNotPresent(data.Records[0].fnb_data));
        dispatch(startTimerAction());
      } else {
        dispatch({
          type: SET_SHOW,
          payload: {
            movie_id: data.movie_id,
            content_lang_id: data.content_lang_id,
            category_id,
          },
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_RESERVATION_DETAILS_ERROR, error });
    }
  };

export const fetchReservationDetailsBrij =
  ({ reservation_id, category_id, skipResetVoucherAndOfferInReservation }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FETCH_RESERVATION_DETAILS });

      if (!skipResetVoucherAndOfferInReservation) {
        // resetting voucher/offer here
        await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
      }

      const { data } = await MovieService.GetReservationDetailsBrij({
        reservation_id,
      });
      if (data && data.status) {
        let reservationDetails = data?.Records?.[0] ?? {};
        console.log(data.Records[0], 'data.Records123');
        dispatch(getAvailablePromotionsOffers(reservationDetails));
        dispatch({
          type: FETCH_RESERVATION_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: FETCH_SCHEDULE_DETAILS_SUCCESS,
          payload: data.Records[0],
        });
        dispatch({
          type: SET_TIMER,
          payload: {
            minutes: data.Records[0].timer_minutes - 6,
            seconds: data.Records[0].timer_second,
          },
        });
        dispatch({
          type: SET_INTERNET_HANDLING_FEES_APPLIED,
          payload: data?.is_internet_handling_fee_applied === 'Y',
        });
        dispatch(updateFnbDataIfNotPresent(data.Records[0].fnb_data));
        dispatch(startTimerAction());
      } else {
        dispatch({
          type: SET_SHOW,
          payload: {
            movie_id: data.movie_id,
            content_lang_id: data.content_lang_id,
            category_id,
          },
        });
        dispatch({
          type: SET_TIMEOUT_ERROR,
          payload: { message: data.message, show: true },
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_RESERVATION_DETAILS_ERROR, error });
    }
  };

const getAllOfferDetailsBrij = async (offer) => {
  let { os_id = undefined } = offer;

  let fallback = {
    status: true,
    message: 'Records Successfully Fetched',
    error: null,
    Records: [],
    PromotionOfferCinemasRecords: [],
    PromotionOfferMoviesRecords: null,
    PromotionOfferDaysRecords: null,
    PromotionOfferScreensRecords: null,
    PromotionOfferSeatsTypeRecords: null,
    PromotionOfferTypesRecords: [],
    PromotionOfferBuyItems: [],
    PromotionOfferGetItems: [],
    OfferContent: [],
  };

  if (!os_id) return Promise.resolve(fallback);

  return MovieService.GetaActivePromotionAndOffers(os_id)
    .then((res) => {
      return res.data.status ? res.data : fallback;
    })
    .catch((error) => {
      console.log(`error fetching offer details ${os_id}`, error);
      return fallback;
    });
};

export const getAvailablePromotionsOffers =
  (reservationDetails = {}, skipResetVoucherAndOfferInReservation = null) =>
  async (dispatch) => {
    let tt_id = null;
    let seat_type_id = null;

    reservationDetails.selectedSeats?.map((x, index) => {
      if (x.tt_id) {
        tt_id = x.tt_id;
      }
      if (x.screen_seat_type_id) {
        seat_type_id = x.screen_seat_type_id;
      }
    });

    let show_date_time = moment(
      `${moment(reservationDetails?.ss_actual_start_date, 'YYYY-MM-DD').format(
        'YYYY-MM-DD',
      )} ${moment(reservationDetails?.ss_start_show_time, 'HH:mm').format(
        'HH:mm',
      )}`,
    ).valueOf();

    let payload = {
      cinema_id: reservationDetails?.cinema_id ?? null,
      screen_id: reservationDetails?.screen_id ?? null,
      seat_type_id: seat_type_id,
      movie_details_id: reservationDetails?.md_id || null,
      show_date_time: reservationDetails?.ss_start_date_time ?? null,
      show_actual_date_time: show_date_time || null,
      tt_id: tt_id,
    };

    try {
      dispatch({ type: FETCH_PROMOTIONS_OFFERS });

      const { data } = await MovieService.GetAvailablePromotionsOffers(payload);

      if (data.status && Array.isArray(data.Records)) {
        let offers = data.Records;

        let offersWithDetails = await Promise.all(
          offers.map((x) =>
            getAllOfferDetailsBrij(x).then((d) => ({
              ...x,
              // offer: x,
              details: d,
            })),
          ),
        );

        dispatch({
          type: FETCH_PROMOTIONS_OFFERS_SUCCESS,
          payload: offersWithDetails,
        });
      } else {
        dispatch({ type: FETCH_PROMOTIONS_OFFERS_ERROR, payload: data?.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_PROMOTIONS_OFFERS_ERROR, payload: error?.message });
    }
  };

export const sendSms =
  ({ sb_booking_code, reservation_id }) =>
  async (dispatch) => {
    try {
      let payload = {};
      if (sb_booking_code) payload.sb_booking_code = sb_booking_code;
      if (reservation_id) payload.reservation_id = reservation_id;

      dispatch({ type: SEND_SMS });
      const { data } = await MovieService.SendSMS(payload);
      if (data && data.status) {
        dispatch({ type: SEND_SMS_SUCCESS });
        // toast.success(`Sms sent!`);
        return data;
      } else {
        dispatch({ type: SEND_SMS_ERROR, payload: data.error });
        return data;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: SEND_SMS_ERROR, payload: error?.message });
      return error;
    }
  };

export const sendEmail =
  ({ sb_booking_code, reservation_id }) =>
  async (dispatch, getState) => {
    const {
      auth: { loggedInUser, guestUser },
    } = getState();

    let email = loggedInUser?.cust_email;
    let guest_email = guestUser?.guest_email;

    let payload = {};
    if (sb_booking_code) payload.sb_booking_code = sb_booking_code;
    if (reservation_id) payload.reservation_id = reservation_id;

    try {
      const {
        movies: {
          currency: { curr_code: currency_code },
        },
      } = getState();
      dispatch({ type: SEND_EMAIL });
      const { data } = await MovieService.SendEmail({
        ...payload,
        currency_code,
        email: email ?? guest_email,
        guest_id: guestUser?.guest_id,
      });
      if (data && data.status) {
        dispatch({ type: SEND_EMAIL_SUCCESS });
        return data;
        // toast.success(`Email sent!`);
      } else {
        dispatch({ type: SEND_EMAIL_ERROR, payload: data.error });
        return data;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: SEND_EMAIL_ERROR, payload: error?.message });
      return error;
    }
  };

export const sendEmailUnpaid =
  ({ reservation_id }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: { loggedInUser, guestUser },
      } = getState();

      let customer_id = loggedInUser?.cust_id;
      let email = loggedInUser?.cust_email;
      let guest_user_id = guestUser?.guest_user_id;

      dispatch({ type: SEND_EMAIL });
      const { data } = await MovieService.SendEmailUnpaid({
        reservation_id,
        customer_id,
        guest_user_id,
      });
      if (data && data.status) {
        dispatch({ type: SEND_EMAIL_SUCCESS });
        return data;
        // toast.success(`Email sent!`);
      } else {
        dispatch({ type: SEND_EMAIL_ERROR, payload: data.error });
        return data;
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: SEND_EMAIL_ERROR, payload: error?.message });
      return error;
    }
  };

export const getCinemas = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CINEMAS });
    const { data } = await MovieService.GetCinemas();
    if (data && data.status && data.Records.length > 0) {
      dispatch({ type: FETCH_CINEMAS_SUCCESS, payload: data.Records });
      dispatch({
        type: FETCH_CITIES_SUCCESS,
        payload: data?.country_list_array ?? [],
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_CINEMAS_ERROR, payload: error?.message });
  }
};

export const setSelectedCinema = (cinema, history) => async (dispatch) => {
  localStorage.removeItem('selected_city');
  dispatch({ type: SELECT_CINEMA_SUCCESS, payload: cinema });
  // let pathname = window.location.pathname;
  // if (
  //   !pathname ||
  //   (!pathname.startsWith("/final-ticket") &&
  //     !pathname.startsWith("/user-profile"))
  // ) {
  //   history.push(`/`);
  // }
};

export const setSelectedCity = (city, history) => async (dispatch) => {
  dispatch({ type: SELECT_CITY_SUCCESS, payload: city });
};

export const setLanguageSpecificMovieFunction = () => async (dispatch) =>
  dispatch({ type: SET_LANGUAGE_SPECIFIC_MOVIE_DETAILS });

export const checkCreateYourShowEnabled =
  (payload) => async (dispatch, getStore) => {
    try {
      // let res = await MovieService.CheckCreateYourShow(payload);
      // let { data } = res;
      //
      // if (data.status && data?.start_price) {
      //   dispatch({
      //     type: 'SET_IS_CREATE_YOUR_SHOW_PRICE',
      //     payload: data?.start_price,
      //   });
      // } else {
      //   dispatch({ type: 'SET_IS_CREATE_YOUR_SHOW_PRICE', payload: null });
      // }
      //
      // if (data.status && data.createYourShowEnabled) {
      //   dispatch({ type: SET_IS_CREATE_YOUR_SHOW_ENABLED, payload: true });
      // } else {
      //   dispatch({ type: SET_IS_CREATE_YOUR_SHOW_ENABLED, payload: false });
      // }
    } catch (err) {
      console.log('error in checkCreateYourShowEnabled', err);
    }
  };

export const fetchAllMoviesSchedules = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ALL_MOVIES_SCHEDULES });

    const { data } = await MovieService.GetAllMoviesAndSchedules(payload);
    if (data.status) {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_SUCCESS,
        payload: {
          movieSchedules: data.Records,
          datesArray: data.Dates,
          languageArray: data?.languageArray
            ? data?.languageArray?.filter((l) => !!l)
            : // .filter(l => l != 'null').filter(l => l != 'undefined')
              [],
        },
      });
    } else {
      dispatch({
        type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
        payload: data.message,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCH_ALL_MOVIES_SCHEDULES_ERROR,
      payload: err.message,
    });
  }
};

export const getOrganizationDetails = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ORGANIZATION });
    // const { data } = await MovieService.GetOrganization();
    const data = {
      status: true,
      Records: [
        {
          org_id: 1,
          org_name: 'Teamquest Private Limited QFX Cinemas',
          org_logo_url:
            'https://web-api.qfxcinemas.com/uploads/org_logo/1677836546036-logo.png',
          org_logo_url_2: null,
          org_kiosk_screen_image_url: null,
          org_mycinema_logo: null,
          org_font_color: null,
          org_hover_color: null,
          org_color: null,
          org_registration_no: '601117323',
          org_emailid: 'aarju.gaihre@teamquest.com.np',
          org_address: 'Labim Mall, Pulchowk, Lalitpur, Nepal',
          org_pincode: '44700',
          city_id: 4927,
          state_id: 2566,
          country_id: 153,
          org_contact_no_code: '977',
          org_contact_no: '9801200092',
          org_setup_date: '2022-12-22T18:15:00.000Z',
          org_launch_date: '2022-12-22T18:15:00.000Z',
          org_is_trial: 'Y',
          org_no_of_cinemas: 11,
          org_latitude: '',
          org_longitude: '',
          org_is_active: 'Y',
          ticket_image_url: '/uploads/org_logo/1683569614321-ticket_logo.png',
          dummy_poster: null,
          org_website_url: null,
          is_confirmed_show: 'Y',
          is_private_show: 'Y',
          is_crowd_show: 'Y',
          is_movie_club: 'Y',
          is_classic_show: 'Y',
        },
      ],
    };

    window.localStorage.setItem('placeholder_image', data.Records[0].dummy_poster);
    dispatch({ type: FETCH_ORGANIZATION_SUCCESS, payload: data.Records[0] });

    document.title = 'QFX Cinemas';
  } catch (error) {
    dispatch({ type: FETCH_OTHER_SCREENINGS_ERROR, payload: error?.message });
  }
};

export const makeKhaltiPayment =
  ({ reservation_id, cust_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    console.log('reservation_id :>> ', reservation_id);
    try {
      const state = getState();
      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;
      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // call reset timer api
      const { data } = await MovieService.CreateKhaltiRedirect({
        reservation_id,
        cust_id: cust_id ?? 0,
        guest_id: guestUser?.guest_id ?? 0,
        redirect_origin: window.location.origin,
      });

      console.log({
        data,
      });

      if (data.status && data.url) {
        // dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = data.url;
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1600);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback && callback(false);
    }
  };

export const makeHimalayanBankPayment =
  ({ reservation_id, cust_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    console.log('reservation_id :>> ', reservation_id);
    try {
      const state = getState();
      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;
      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // call reset timer api
      const { data } = await MovieService.CreateHimalayanBankRedirect({
        reservation_id,
        cust_id: cust_id ?? 0,
        guest_id: guestUser?.guest_id ?? 0,
        redirect_origin: window.location.origin,
      });

      console.log({
        data,
      });

      if (data.status && data.url) {
        // dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = data.url;
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1600);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback && callback(false);
    }
  };

export const makeEsevaPayment =
  ({ reservation_id, cust_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    console.log('reservation_id :>> ', reservation_id);
    try {
      const state = getState();
      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;
      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: cust_id ?? 0,
        guest_id: guestUser?.guest_id ?? 0,
        redirect_origin: window.location.origin,
        token: TOKEN,
      };
      const { data } = await MovieService.CreateEsevaRedirect(intentPayload);
      const query = createQueryString(intentPayload);
      console.log({
        data,
        query,
      });

      if (data.status && data.url) {
        // dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = `${data.url}${query}`;
        console.log({
          url,
        });

        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1600);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback && callback(false);
    }
  };

export const makeIMEPayPayment =
  ({ reservation_id, cust_id, callback = () => {}, appliedCashCard = {} }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    console.log('reservation_id :>> ', reservation_id);
    try {
      const state = getState();
      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;
      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      // call reset timer api
      let intentPayload = {
        reservation_id,
        cust_id: cust_id ?? 0,
        guest_id: guestUser?.guest_id ?? 0,
        redirect_origin: window.location.origin,
        token: TOKEN,
      };
      const { data } = await MovieService.CreateIMEPayRedirect(intentPayload);
      console.log({
        data,
      });

      if (data.status && data.url) {
        // dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = `${data.url}`;
        console.log({
          url,
        });

        debugger;

        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);

        setTimeout(() => {
          window.location.reload();
        }, 1600);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback && callback(false);
    }
  };

export const makeSaferPayPayment =
  ({
    reservation_id,
    cust_id,
    email,
    cinema_id,
    category_id,
    callback,
    secondCallback,
    cinema_name,
    mobile,
    countryMobCode,
    appliedCashCard = {},
  }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    console.log('reservation_id :>> ', reservation_id);
    try {
      const state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      let optionalPayload = {};

      if (!loggedInUser && guestUser) {
        optionalPayload.is_guest_user = 'Y';
        optionalPayload.guest_details = {
          guest_email: guestUser.guest_email,
          guest_user_id: guestUser.guest_user_id,
          guest_mobile: guestUser.guest_mobile,
          guest_country_code: guestUser.guest_country_code,
        };
      }

      let offerData = getOfferObject(state);
      const { selected_fnb_items, fnb_price } = state.fnb;
      const { curr_code, curr_id } = state.movies.currency;

      let reservationDetails = state.movies?.reservationDetails;
      let { selected_fnb_items_priv, fnb_price_priv } =
        getPriFnbDataForBookSeats(reservationDetails);

      let cinemaObj = {
        cinema_id: cinema_id,
        cinema_name: cinema_name,
      };
      selected_fnb_items.forEach((x) => {
        x['quantity'] = x['Quantity'];
      });
      let fnbData = {
        isGroupRateCard: true,
        reservation_id,
        fnbItemList:
          category_id == 2 ? selected_fnb_items : selected_fnb_items_priv,
        payment_mode_id: '15',
        cinema_id: cinemaObj.cinema_id,
        currency_id: curr_id,
        ft_total_amount:
          category_id == 2
            ? fnb_price.total_price
            : fnb_price_priv?.total_price || 0,
        ft_net_amount:
          category_id == 2
            ? fnb_price.total_price - fnb_price.total_tax
            : fnb_price_priv?.total_net || 0,
        ft_order_status: 3,
        aggregator_cinema: cinemaObj,
        offer: { t_offer_price: 0 },
        // payment_mode: "SaferPay",
        payment_mode: {
          pm_id: 15,
          pm_payment_mode: 'SaferPay',
        },
        booking_source_id: 2,
        fst_booking_source: 'Online',
        customer_details: {
          email: email ?? '',
          country_code: countryMobCode ?? '',
          mobile: mobile ?? '',
        },
        ft_currency_name: curr_code,
        send_email: false,
      };

      let BookSeatsPayload = {
        ...optionalPayload,
        isGroupRateCard: true,
        reservation_id,
        isSplitPayment: false,
        unpaid_booking: false,
        cinema_id,
        redirect_origin: window.location.origin,
        payment_mode_id: 15,
        approverId: null,
        order_code: null,
        oldCustomer: true,
        customer_id: cust_id,
        cust_id: cust_id,
        email,
        payment_mode: {
          bs_id: 1,
          bs_source: `Box Office`,
          bs_is_active: 'Y',
          created_by: null,
          created_at: null,
          updated_by: 4,
          updated_at: '2020-07-16T04:20:00.000Z',
          bspm_id: 29,
          booking_source_id: 1,
          mapping_is_active: 'Y',
          payment_mode_id: 15,
          pm_payment_mode: 'Safer Pay',
          pm_icon_url: null,
          pm_is_active: 'Y',
          pm_id: 15,
          amount: 0,
        },
        isLoyaltyApplied: false,
        offer: offerData,
        ft_id: null,
        currency_code: curr_code,
        auth_token: TOKEN,
        ...appliedCashCard,
      };

      if (category_id == 1) {
        BookSeatsPayload['MyCinema'] = true;
      }

      // console.log("offerData", offerData);
      if (selected_fnb_items && selected_fnb_items.length > 0) {
        BookSeatsPayload['fnbData'] = fnbData;
      }
      if (
        category_id == 1 &&
        selected_fnb_items_priv &&
        selected_fnb_items_priv.length > 0
      ) {
        BookSeatsPayload['fnbData'] = fnbData;
      }

      const { data } = await MovieService.CreateSaferPayRedirect({
        reservation_id,
        cust_id: cust_id ?? 0,
        guest_user_id: guestUser?.guest_user_id ?? 0,
        is_guest_user: !loggedInUser && guestUser ? 'Y' : 'N',
        isCinema: category_id == 2 ? false : true,
        selected_fnb_items:
          category_id == 2 ? selected_fnb_items : selected_fnb_items_priv,
        BookSeatsPayload,
        category_id,
      });

      if (data.status && data.response) {
        dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = data.response.RedirectUrl;
        // console.log("url", url);
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback(false);
    }
  };

export const makeStripePaymentForCashCard =
  ({ reservation_id, callback, secondCallback, email, stripeToken }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      const { data } = await CashCardService.CreateStripeCashCardPay({
        payment_mode_id: 14,
        is_guest_user: !loggedInUser && guestUser ? 'Y' : 'N',
        guest_details: !loggedInUser && guestUser ? guestUser : '',
        customer_email: email,
        reservation_id,
        stripeToken,
      });

      if (data.status) {
        dispatch({
          type: STRIPE_PAYMENT_SUCCESS,
        });

        if (secondCallback && window.innerWidth < 767) {
          secondCallback(3);
        } else {
          secondCallback(2);
        }
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message ?? 'Something Went Wrong');
    } finally {
      callback(false);
    }
  };

export const makeSaferPayPaymentForCashCard =
  ({ reservation_id, callback, secondCallback, email }) =>
  async (dispatch, getState) => {
    dispatch({ type: STOP_TIMER });
    // console.log("reservation_id :>> ", reservation_id);
    try {
      const state = getState();

      let loggedInUser = state?.auth?.loggedInUser;
      let guestUser = state?.auth?.guestUser;

      if (!loggedInUser && !guestUser) {
        toast.error('Something Went Wrong');
        window.location.reload();
        return;
      }

      const { data } = await CashCardService.CreateSaferBuyCashCardPayRedirect({
        payment_mode_id: 15,
        is_guest_user: !loggedInUser && guestUser ? 'Y' : 'N',
        guest_details: !loggedInUser && guestUser ? guestUser : '',
        customer_email: email,
        reservation_id,
        // redirect_origin: window.location.origin,
        auth_token: TOKEN,
      });
      // console.log({data})
      if (data.status && data.response) {
        dispatch({ type: SET_PAY_DISABLE_SAFER_PAY, payload: true });
        let url = data.response.RedirectUrl;
        // console.log("url", url);
        const newWindow = window.open(url, '_self');
        if (newWindow) newWindow.opener = null;
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      callback(false);
    }
  };

export const fetchActivePaymentModes = (payload) => async (dispatch) => {
  try {
    const { data } = await MovieService.GetActivePaymentModes(payload);
    if (data.status && data.Records.length > 0) {
      // console.log("DATA fetchActivePaymentModes", data);
      let SaferPay = data.Records.filter((x) => x.pm_id == 15);
      if (SaferPay && SaferPay.length > 0) {
        dispatch({
          type: SET_SAFER_PAY_ACTIVE,
          payload: true,
        });
      }
      dispatch({
        type: SET_ACTIVE_PAYMENT_MODES,
        payload: data.Records,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
