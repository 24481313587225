import React, { useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import AboutUsHeroText from './AboutUs/helper/AboutUsHeroText';
import AboutUsHeroSlider from './AboutUs/helper/AboutUsHeroSlider';
import AboutUsHowItWorks from './AboutUs/helper/AboutUsHowItWorks';
import AboutUsBookingType from './AboutUs/helper/AboutUsBookingType';

const HowItWorks = ({ t }) => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="about_us_new">
        {/*<AboutUsHeroText />

        <AboutUsHeroSlider />*/}

        <AboutUsHowItWorks />

        <AboutUsBookingType
          type="private"
          titleFirstHalf={t('Private Cinema')}
          titleSecondHalf=""
          description={t('PrivateCinemaMessage')}
          onGetStarted={() => history.push('/new-on-demand')}
          videoId={`705787107`}
          url={'https://vimeo.com/705787107/8ae0b714e8'}
        />

        <AboutUsBookingType
          type="watch_party"
          titleFirstHalf={t('Watch party')}
          titleSecondHalf=""
          description={t('WatchPartyMessage')}
          onGetStarted={() => history.push('/new-on-demand')}
          reverse
          videoId={`705787150`}
          url={'https://vimeo.com/705787150/8708126fa7'}
        />

        {false && (
          <AboutUsBookingType
            type="crowdsourced"
            titleFirstHalf={t('Crowdsourced Cinema')}
            titleSecondHalf=""
            description={t('CrowdsourcedCinemaMessage')}
            onGetStarted={() => history.push('/new-on-demand')}
            videoId={`705787188`}
            url={'https://vimeo.com/705787107/8ae0b714e8'}
          />
        )}

        {/* <BarOffers />

        <AuditoriumPlan />

        <EventsAndAppetisers />

        <TariffsAndVouchers />

        <AgeRating /> */}
      </div>
    </>
  );
};

export default withNamespaces()(HowItWorks);
