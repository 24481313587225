import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CountryCodeDropdown from '../CountryCodeDropdown';
import close from '@assets/pictures/svgs/close.svg';
import mail from '@assets/pictures/svgs/mail.svg';
import ukraineFlag from '@assets/pictures/svgs/flag-ukraine.svg';

import LoginPopup from './LoginPopup.jsx';

export default function RegisterPopup(props) {
  const [one, setOne] = useState(true);
  return (
    <>
      <LoginPopup
        // show={one}
        {...props}
        closeFunc={props.closeFunc}
        register={props.closeFunc}
      />
      <Modal
        // {...props}
        show={false}
        aria-labelledby="contained-modal-title-vcenter"
        className="register-popup"
        centered
        onHide={() => props.closeFunc(false)}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => props.closeFunc(false)}
          />

          <p className="mx-auto title">Get Started</p>

          {/* <SocialMediaButton imgSrc={facebook} name="Facebook" />
          <SocialMediaButton imgSrc={google} name="Google" /> */}
          <SocialMediaButton
            imgSrc={mail}
            name="Email"
            onClick={() => {
              setOne(true);
              props.closeFunc(false);
            }}
          />

          <p className="mx-auto or">OR</p>

          <div className="mobile-num">
            <img src={ukraineFlag} />
            {/* <div className="code">
              <Dropdown className="">
                <Dropdown.Toggle
                  className="dropdownClassic p-0"
                  id="dropdown-basic"
                >
                  <span className="code">+380</span>
                </Dropdown.Toggle>

                <Dropdown.Menu align="right" className="dropdownItems">
                  <Dropdown.Item href="#/action-1" className="selected">
                    ENGLISH
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2" className="">
                    SANSKRIT
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
            <CountryCodeDropdown />
            <input type="text" placeholder="Continue with Mobile Number" />
          </div>
        </section>
      </Modal>
    </>
  );
}

const SocialMediaButton = (props) => {
  return (
    <div onClick={props.onClick} className="social-media-btn-main">
      <img src={props.imgSrc} />
      <p>Continue with {props.name}</p>
    </div>
  );
};
