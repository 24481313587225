import { reactLocalStorage } from 'reactjs-localstorage';

const TOKEN_KEY = 'access_token';
const COUNTRY = 'country';
const LANGUAGE = 'language';
const CITY = 'city';
const PWA = 'IS_PWA';
const USER = 'user';
const GUEST_USER = 'guest_user_id';
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return reactLocalStorage.get(TOKEN_KEY);
  },

  saveToken(accessToken) {
    reactLocalStorage.set(TOKEN_KEY, accessToken);
  },

  removeToken() {
    reactLocalStorage.remove(TOKEN_KEY);
  },

  removeUser() {
    reactLocalStorage.remove(USER);
    reactLocalStorage.remove(GUEST_USER);
  },
};

const LanguageService = {
  getLanguage() {
    return reactLocalStorage.get(LANGUAGE);
  },

  saveLanguage(language) {
    reactLocalStorage.set(LANGUAGE, language);
  },

  removeLanguage() {
    reactLocalStorage.remove(LANGUAGE);
  },
};

const CountryService = {
  getCountry() {
    return reactLocalStorage.get(COUNTRY)
      ? JSON.parse(reactLocalStorage.get(COUNTRY))
      : null;
  },

  saveCountry(country) {
    reactLocalStorage.set(COUNTRY, JSON.stringify(country));
  },

  removeCountry() {
    reactLocalStorage.remove(COUNTRY);
  },
};

const CityService = {
  getCity() {
    return JSON.parse(reactLocalStorage.get(CITY) || null);
  },

  saveCity(city) {
    reactLocalStorage.set(CITY, JSON.stringify(city));
  },

  removeCity() {
    reactLocalStorage.remove(CITY);
  },
};
const PwaService = {
  getIsPwa() {
    return reactLocalStorage.get(PWA);
  },
  setIsPwa(val) {
    reactLocalStorage.set(PWA, val);
  },
  removeIsPwa() {
    reactLocalStorage.remove(PWA);
  },
};

export { TokenService, LanguageService, CountryService, CityService, PwaService };
