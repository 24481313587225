import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Spinner from '@components/partials/Spinner';

import { useDispatch, useSelector } from 'react-redux';
import { fetchGuestHistory, fetchUserHistory } from '@store/auth/authActions';
import { useHistory } from 'react-router-dom';

const OpeningHours = ({ t }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const history = useHistory();

  const loginDetails = useSelector((state) => state.auth);
  const userHistoryLoading = useSelector((state) => state.auth.userHistoryLoading);
  const userLoyaltyHistory = useSelector((state) => state.auth.userLoyaltyHistory);
  const { loggedInUser, userHistory, guestUser } = loginDetails;

  useEffect(() => {
    if (loggedInUser?.cust_id) {
      dispatch(fetchUserHistory({ customer_id: loggedInUser?.cust_id }));
    }

    // if (!loggedInUser) {
    //   history.push(`/`);
    // }
  }, [loggedInUser]);

  let headers = [
    { title: 'Movie' },
    { title: 'Show Datetime' },
    { title: 'Transaction Datetime' },
    { title: 'No of Tickets' },
    { title: 'Ticket Amount' },
    { title: 'FnB Amount' },
    { title: 'Earned Points' },
    { title: 'Spent Points' },
    // { title: 'Payment Method' },
  ];

  let data = [
    ...(userLoyaltyHistory?.loyalty || []),
    ...(userLoyaltyHistory?.fnbLoyalty || []),
  ]
    .sort((a, b) => {
      let dateA = new Date(a?.t_booking_date_time ?? a?.ft_date_time);
      let dateB = new Date(b?.t_booking_date_time ?? b?.ft_date_time);

      let comp = dateB - dateA;
      if (comp === 0) return b.hasOwnProperty('t_booking_date_time') ? 1 : -1;

      return comp;
    })
    ?.map((l) => ({
      ...l,
      transaction_date: `${l?.t_booking_date_time || l?.ft_date_time}`,
      order_detail: `${
        l?.movie_name || l?.sb_booking_code || `FnB ${l?.order_code}`
      }`,
      show_date: `${l?.t_show_date || ''} ${l?.t_show_time || ''}` || '-',
      points_earn: `${l?.earnings_points || 0}pts`,
      points_spend: `${l?.burnings_points || 0}pts`,
      payment_mode: `${l?.payment_mode || '-'}`,
      tickets: `${l?.tickets || l?.sb_booking_code || '-'}`,
    }));

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('Club Qfx')} >`}
          secondHalf={t('Club Loyalty')}
        />
        <>
          <CenteredTitle firstHalf={t('Club QFX Loyalty')} secondHalf={''} />

          {userHistoryLoading ? (
            <div className="row mt-5">
              <div className="col-12 text-center">
                <Spinner dark />
              </div>
            </div>
          ) : (
            <div className="d-block user_details_loyalt">
              <p>
                Name: {loggedInUser?.first_name} {loggedInUser?.mid_name}{' '}
                {loggedInUser?.last_name}
              </p>
              <p>Email: {loggedInUser?.cust_email}</p>
              <p>Phone No: {loggedInUser?.cust_mobile}</p>
              <p>Current Loyalty: {data?.[0]?.ll_name ?? '-'}</p>

              <p>
                Please Note: Conversation rate for {data?.[0]?.ll_name ?? '-'} is{' '}
                {(data?.[0]?.multiple_points || 0) * 100}% in tickets &{' '}
                {(data?.[0]?.multiple_points || 0) * 100}% in F&B
              </p>

              <h3>AVAILABLE POINTS: {data?.[0]?.points ?? 0}</h3>
            </div>
          )}
        </>
      </div>

      {!userHistoryLoading && data?.length > 0 && (
        <div
          className={`container-fluid opening_hours ${
            isMobile ? 'overflow-scroll-mob' : ''
          }`}
        >
          <table className="loyalty_table">
            <thead>
              {headers.map((x) => {
                return <th>{x.title}</th>;
              })}
            </thead>
            <tbody>
              {data.map((y) => {
                console.log({
                  y,
                });

                return (
                  <tr>
                    <td>{y.order_detail}</td>
                    <td>{y.show_date}</td>
                    <td>{y.transaction_date}</td>
                    <td>{y.tickets}</td>
                    <td>{y.sb_total_amount || ''}</td>
                    <td>{y.ft_gross_amount_before_offer || ''}</td>
                    <td>{y.points_earn}</td>
                    <td>{y.points_spend}</td>
                    {/*<td>{y.payment_mode}</td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(OpeningHours);
