import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';

// Images
// import {ReactComponent as StandardSeat} from "@assets/pictures/svgs/seat-standard.svg";
// import {ReactComponent as SelectedSeat} from "@assets/pictures/svgs/seat-standard-selected.svg";
// import { ReactComponent as UnavailableSeat} from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import { ReactComponent as Seat } from '@assets/pictures/svgs/seat_new.svg';
import { useSelector } from 'react-redux';
import { sortSeatsString } from '../../../store/auth/authActions';
import { getSeatIconUrl } from '@src/helper';
import { currencyFormatter } from '@helper/currencyFormatter';
import useIsMobile from '@src/customHooks/useIsMobile';
import ReservedIcon from '@assets/pictures/seats_icon/Reserved.png';
import UnAvailableIcon from '@assets/pictures/seats_icon/unavailable.png';
const SeatPlanIcons = (props) => {
  const { t, classes } = props;

  const isMobile = useIsMobile();
  let [uniqSeatsIcons, setUniqSeatsIcons] = useState([]);
  let userSelection = useSelector((state) => state.movies.userSelection);
  let seats = useSelector((state) => state.seatLayout.seats_selected);
  const seatString = seats.map((seat) => seat.sl_seat_name);
  const seatLayout = useSelector((state) => state.seatLayout.seatLayout);

  useEffect(() => {
    if (seatLayout) {
      let filtered = seatLayout
        ?.flatMap((s) => s?.seats?.flatMap((x) => x))
        ?.filter((x) => !!x);

      setUniqSeatsIcons([
        ...new Map(filtered.map((item) => [item['srt_id'], item])).values(),
      ]);
    }
  }, [seatLayout]);

  let normalIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]?.srt_online_image_url ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]?.srt_online_image_url ||
      uniqSeatsIcons?.[0]?.srt_online_image_url,
  );
  let selectedIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]
      ?.srt_online_image_url_selected ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]
        ?.srt_online_image_url_selected ||
      uniqSeatsIcons?.[0]?.srt_online_image_url_selected,
  );
  let disabledIcon = getSeatIconUrl(
    uniqSeatsIcons?.filter((x) => x?.srt_id == 1)?.[0]
      ?.srt_online_image_url_disabled ||
      uniqSeatsIcons?.filter((x) => x?.srt_id == 10)?.[0]
        ?.srt_online_image_url_disabled ||
      uniqSeatsIcons?.[0]?.srt_online_image_url_disabled,
  );

  return (
    <>
      <div
        className="seat_plan_wrapper seat_plan_type"
        style={{ margin: 'auto', width: isMobile ? '100%' : '70%' }}
      >
        <div className="seat_plan_type_info_mobile">
          <p></p>
        </div>
      </div>

      {false && (
        <div className={`seat_plan_icons ${classes}`}>
          <article>
            {uniqSeatsIcons &&
              uniqSeatsIcons.length > 1 &&
              uniqSeatsIcons
                .filter((s) => s?.srt_id != 2)
                .filter((s) => s?.srt_id != 12)
                .filter((s) => s?.srt_id != 13)
                .map((icon) => (
                  <p className="">
                    <figure className="">
                      {/*<Seat/>*/}
                      <img
                        src={getSeatIconUrl(icon.srt_online_image_url)}
                        width="24px"
                        height="24px"
                      />
                    </figure>
                    <p>{t(icon.srt_name) || icon.srt_name}</p>
                  </p>
                ))}
          </article>
        </div>
      )}

      <div className="seat_plan_type_info_mobile"></div>

      <div className={`seat_plan_icons ${classes}`}>
        <article>
          <p className="">
            <figure className="">
              {/*<Seat/>*/}
              <img src={normalIcon} width="24px" height="24px" />
            </figure>
            <p>{t('Available')}</p>
          </p>

          <p className="">
            <figure className="">
              {/*<Seat/>*/}
              <img src={ReservedIcon} width="24px" height="24px" />
            </figure>
            <p>{t('Reserved')}</p>
          </p>

          <p className="seat_plan_icons_unavailable">
            <figure>
              {/*<Seat/>*/}
              <img src={disabledIcon} width="24px" height="24px" />
            </figure>
            <p>{t('Sold Out')}</p>
          </p>
          <p className="seat_plan_icons_selected">
            <figure>
              {/*<Seat/>*/}
              <img src={selectedIcon} width="24px" height="24px" />
            </figure>
            <p>
              {seats.length > 0 ? t('Selected') : t('Pick Seats')}{' '}
              {seats.length > 0 && `(${seats?.length})`}
            </p>
          </p>
          <p className="seat_plan_icons_unavailable">
            <figure>
              {/*<Seat/>*/}
              <img src={UnAvailableIcon} width="24px" height="24px" />
            </figure>
            <p>{t('Unavailable')}</p>
          </p>
        </article>
        {userSelection?.selectedScreeningType?.booking_type_id != 3 &&
          seats &&
          seats.length > 0 &&
          false && (
            <p>
              (
              {seatString.sort(sortSeatsString).map((seat, index) => (
                <span key={index}>{seat}</span>
              ))}
              )
            </p>
          )}
        {userSelection?.selectedScreeningType?.booking_type_id == 3 && (
          <p>
            (<span>{t('All')}</span>)
          </p>
        )}
      </div>
    </>
  );
};

// Default Props
SeatPlanIcons.defaultProps = {
  classes: '',
};

export default withNamespaces()(SeatPlanIcons);
