import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import Theater_Innovation from '@assets/pictures/Theater_Innovation.png';
import IMMERSIVE_THEATER from '@assets/pictures/IMMERSIVE THEATER.png';
import PICK_OF_THE_PLATTER from '@assets/pictures/PICK OF THE PLATTER.png';
import REACHING_OUT_TO_REGIONS from '@assets/pictures/REACHING OUT TO REGIONS.png';
import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} />
          <CenteredTitle firstHalf={t('About Us')} />
        </div>

        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
              {t(
                'Enriching experiences to amaze you! With technology, comfort and convenience.',
              )}
            </h4>

            {/* First Row */}
            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={` ${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={Theater_Innovation}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`color-black ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'} `}
              >
                <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  THEATER INNOVATIONS
                </h3>
                <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/TheaterInnovation')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span>
              </div>
            </div>

            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`color-black ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  IMMERSIVE THEATER
                </h3>
                <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Immersive')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span>
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={IMMERSIVE_THEATER}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>

            {/* Third Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={PICK_OF_THE_PLATTER}
                  className="img-fluid custom_img_about"
                />
              </div>

              <div
                className={`color-black ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  PICK OF THE PLATTER
                </h3>
                <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Platter')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span>
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`color-black ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  REACHING OUT TO REGIONS
                </h3>
                <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Regions')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span>
              </div>

              <div
                className={` ${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img
                  src={REACHING_OUT_TO_REGIONS}
                  className="img-fluid custom_img_about"
                />
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
