import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { ReactComponent as MobileIcon } from '@assets/pictures/svgs/mobile.svg';
import { ReactComponent as Mail } from '@assets/pictures/svgs/mail.svg';

const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  //Using Inline Function and the The Logical Not (!) to toggle state
  const [toggle, setToggle] = useState(true);
  const [toggleTwo, setToggleTwo] = useState(false);
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Contact Us')} />
          <CenteredTitle firstHalf={t('Contact')} secondHalf={t('Us')} />
        </div>

        <section className="row">
          <article
            className="col-xl-12 mx-auto event_back contact_us_qfx"
            style={{ padding: isTablet ? '50px' : '' }}
          >
            <section>
              <article className="col-xl-12">
                <p
                  className="static_page_highlight mb-4 mb-xl-5"
                  style={{ color: '#000' }}
                >
                  {t('For Lost and Found Queries')}
                  &nbsp;
                  <a
                    // className="color-primary"
                    // style={{ color: 'var(--primary) !important' }}
                    href="https://forms.office.com/pages/responsepage.aspx?id=rdxMqM40B0ubYBPb8fB4KZxYomBVzv5EnEkZBC_nGKxUN0hTR0k3TlpFVU9EUFhXRVZKTzdXMFdTOC4u"
                    target="_blank"
                  >
                    Click Here !
                  </a>
                </p>
              </article>
            </section>
            <Tab.Container id="left-tabs-example" defaultActiveKey="zero">
              <Row>
                <Col sm={5} lg={7} xl={3} className="custom_overflow_contactus">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="zero">
                        <div className="cinema_heading ">
                          <h4>Customer Service</h4>
                          <p className="mb-2">
                            LGF, Labim Mall, Pulchowk, Lalitpur
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-1-5522566">+977-1-5522566</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className="cinema_heading">
                          <h4>Durbar Cinemax</h4>
                          <p className="mb-2">
                            6th floor, Durbar Mall, Durbar Marg
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-4250242">+977-4250242</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <div className="cinema_heading">
                          <h4>QFX Bageshwori</h4>
                          <p className="mb-2">
                            Setu BK Chowk , Newroad, Nepalgunj, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-81-531330">+977-81-531330</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <div className="cinema_heading">
                          <h4>QFX Chhaya Center</h4>
                          <p className="mb-2">
                            4th Floor Chhaya Center, Thamel, Kathmandu
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-1-5252013">+977-1-5252013</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">
                        <div className="cinema_heading">
                          <h4>QFX Civil Mall</h4>
                          <p className="mb-2">
                            7th Floor Civil Mall, Sundhara, Kathmandu, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-1-5316611">+977-1-5316611</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        <div className="cinema_heading">
                          <h4>QFX Jalma</h4>
                          <p className="mb-2">
                            Bharatpur 02, Kshetrapur - Jalma Hall Road, Narayanghat,
                            Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-56-522079">+977-56-522079</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">
                        <div className="cinema_heading">
                          <h4>QFX Labim Mall</h4>
                          <p className="mb-2">
                            3rd & 4th Floor Labim Mall, Pulchowk, Lalitpur
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-1-5529974">+977-1-5529974</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="seventh">
                        <div className="cinema_heading">
                          <h4>QFX One Stop</h4>
                          <p className="mb-2">
                            Krishna Hall Road, Birtamode, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-23-533083">+977-23-533083</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eight">
                        <div className="cinema_heading">
                          <h4>QFX Biratnagar</h4>
                          <p className="mb-2">
                            Bhatbhateni Supermarket Biratnagar, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-21-512644">+977-21-512644</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ninth">
                        <div className="cinema_heading">
                          <h4>QFX Bhaktapur</h4>
                          <p className="mb-2">
                            Bhatbhateni Supermarket Thimi, Bhaktapur, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-1-6636579"> +977-1-6636579/6639297</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="tenth">
                        <div className="cinema_heading">
                          <h4>QFX Butwal</h4>
                          <p className="mb-2">
                            Bhatbhateni Supermarket Butwal, Nepal
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-71-541506"> +977-71-541506</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eleventh">
                        <div className="cinema_heading">
                          <h4>QFX Damauli</h4>
                          <p className="mb-2">
                            Apex Mall, Kalika Marga, Damauli Vyas 2
                          </p>
                          {/* <p className="mb-2">
                            <span className="font-weight-bold">
                              Enquiries :<MobileIcon className="mx-2" />
                            </span>
                            <a href="tel:+977-65-590788"> +977-65-590788</a>
                          </p>
                          <p className="">
                            <span className="font-weight-bold contact_svg_container">
                              <Mail />
                            </span>
                            <a
                              href="mailto:support@qfxcinemas.com"
                              className="mx-2"
                            >
                              support@qfxcinemas.com
                            </a>
                          </p> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={7} lg={5} xl={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="zero">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : isTablet ? '450' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=LGF, Labim Mall, Pulchowk, Lalitpur&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>

                    <Tab.Pane eventKey="first">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : isTablet ? '450' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=Durbar Cinemax, Kathmandu, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas1"
                        src="https://maps.google.com/maps?q=QFX Bageshwori Cinema, New Road, Nepalgunj, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas2"
                        src="https://maps.google.com/maps?q=QFX Chhaya Center&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas3"
                        src="https://maps.google.com/maps?q=QFX Cinemas - Civil Mall, Kathmandu, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Jalma Cinema Hall, Jalma Rd, Bharatpur, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Cinemas, Labim Mall, Lalitpur, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Cinemas One Stop, East - West Highway, Birtamode, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Cinemas, Bhatbhateni Biratnagar, H08, Biratnagar, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="ninth">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Cinemas - Thimi, Bhaktapur, Service Road, Araniko Highway, Madhyapur Thimi, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="tenth">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX cinema butwal, Butwal, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eleventh">
                      <iframe
                        className={isMobile ? 'mt-3' : ''}
                        width={isMobile ? '100%' : '1000'}
                        height={isMobile ? '100%' : '500'}
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=QFX Damauli, Byas, Nepal&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
