//imports multiple reducer here
import reservationReducer from './reservation/reservationReducer';
import foodAndBeveragesReducer from './foodAndBeverages/foodAndBeveragesReducer';
import promotionsReducer from './promotions/promotionsReducer';
import cinemasReducer from './cinemas/cinemasReducer';
import configReducer from './config/configReducer';
import movieReducer from './movies/movieReducer';
import authReducer from './auth/authReducer';
import homepageReducer from './homepage/homepageReducer';
import ancilliaryReducer from './ancilliary/ancilliaryReducer';
import seatLayoutReducer from './seatLayout/seatLayoutReducer';
import cashCardReducer from './cashCard/cashCardReducer';
import { bannersApi } from '@store/rtk/rtk.banner';
import { baseApi } from '@store/rtk/base';

const rootReducer = {
  //and use it here
  reservation: reservationReducer,
  fnb: foodAndBeveragesReducer,
  promotions: promotionsReducer,
  cinemas: cinemasReducer,
  config: configReducer,
  movies: movieReducer,
  auth: authReducer,
  homepage: homepageReducer,
  ancilliary: ancilliaryReducer,
  seatLayout: seatLayoutReducer,
  cashCard: cashCardReducer,
  [bannersApi.reducerPath]: bannersApi.reducer,
};

export default rootReducer;
