import React, { useEffect, useState, useRef, useContext } from 'react';

import { withNamespaces } from 'react-i18next';

function ConfirmedShowList({ t }) {
  useEffect(() => {}, []);
  return (
    <div>
      <h4>Confirmed Show List</h4>
    </div>
  );
}

export default withNamespaces()(ConfirmedShowList);
