import React, { useEffect, useState, useRef, useContext } from 'react';

import { withNamespaces } from 'react-i18next';

import poster from '@assets/pictures/poster.png';

function MoviesList({ t }) {
  useEffect(() => {}, []);
  return (
    <div>
      <div className="container">
        <div className="row mt-5">
          <div className="col">
            <span className="h1">Today's Screenings</span>
            <span>75 Movies</span>
          </div>
        </div>
        {/* Movies List 1*/}
        <div className="row mb-5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
            <div className="col-lg-2">
              <div>
                <div>
                  <img src={poster} className="img-fluid" alt="Movie Poster" />
                </div>
                <div>
                  <div className="row">
                    <div className="col-6">
                      <span>Dark Waters</span>
                    </div>
                    <div className="col-6">
                      <span>DE</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <span>Drama, FSK 18</span>
                    </div>
                    <div className="col-6">
                      <span>2D, 3D, IMAX</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(MoviesList);
