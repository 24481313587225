import React, { useState, useEffect, useCallback } from 'react';

const desktopWidth = 1200;

function useIsDesktop() {
  const [desktop, setDesktop] = useState(window.innerWidth > 1200);

  const resizeEventHandler = useCallback(() => {
    if (window.innerWidth > 1200) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= desktopWidth) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }

    window.addEventListener('resize', resizeEventHandler);

    return () => window.removeEventListener('resize', resizeEventHandler);
  }, [setDesktop]);

  return desktop;
}

export default useIsDesktop;
