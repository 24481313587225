import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useIsMobile from '../customHooks/useIsMobile';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import {
  fetchMoviesListByLabel,
  fetchLabelsList,
} from '../store/movies/movieActions';
import LoadMoreButton from './partials/LoadMoreButton.jsx';
import { getLangSpecificAttribute, getFormattedDate } from '@helper/languages';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from 'react-bootstrap';

// Components
import HorizontalCard from './partials/HorizontalCard';
import MovieCard from './partials/MovieCard';
import MovieSection from './partials/movieSection.jsx';
import { motion } from 'framer-motion';

// Images
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';

const LabelledMovies = (props) => {
  const { label_id } = useParams();
  const dispatch = useDispatch();
  const { match, t } = props;
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(fetchLabelsList());
  }, []);

  useEffect(() => {
    dispatch(fetchMoviesListByLabel(label_id));
  }, [label_id]);

  const labelMovies = useSelector((state) => state.movies.label_movies);
  const labelData = useSelector((state) => state.movies.label_data);
  const labelLoading = useSelector((state) => state.movies.labelMoviesLoading);
  const onDemandLabelMovies = useSelector(
    (state) => state.movies.onDemandLabelMovies,
  );
  const nowShowingLabelMovies = useSelector(
    (state) => state.movies.nowShowingLabelMovies,
  );
  const upcomingLabelMovies = useSelector(
    (state) => state.movies.upcomingLabelMovies,
  );
  const allLabels = useSelector((state) => state.movies.allLabels);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_name, iso_2, lang_id } = selected_language;
  const [activeSection, setActiveSection] = useState("Today's Screenings");
  const history = useHistory();

  return (
    <>
      <div className="">
        <section className="container-fluid container-xl pt-2 label-filter-container">
          <div className="movie-container">
            <p className="movie-labels labelRow">
              {allLabels &&
                allLabels.length > 0 &&
                allLabels?.map((item, i) => (
                  <span
                    key={i}
                    className={
                      label_id == item.label_id ? 'activeBtn mb-3' : 'mb-3'
                    }
                    onClick={() => {
                      history.push(`/filtered-movie-list/${item?.label_id}`);
                    }}
                  >
                    {getLangSpecificAttribute(
                      item?.translations_array,
                      lang_id,
                      'label_name',
                      'lang_id',
                    ) || item?.label_name}
                  </span>
                ))}
            </p>
          </div>
          <div className="row d-flex d-md-none my-3">
            <div className="col-12">
              <motion.div
                className="mobile-movie-sections"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <p
                  className={`${
                    activeSection === "Today's Screenings" ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection("Today's Screenings")}
                >
                  {t('Now Showing')}
                </p>

                {
                  <p
                    className={`mx-4 ${
                      activeSection === 'onDemand' ? 'active' : ''
                    }`}
                    onClick={() => setActiveSection('onDemand')}
                  >
                    {t('On Demand')}
                  </p>
                }
                {
                  <p
                    className={`${activeSection === 'Upcoming' ? 'active' : ''}`}
                    onClick={() => setActiveSection('Upcoming')}
                  >
                    {t('Upcoming')}
                  </p>
                }
              </motion.div>
            </div>
          </div>

          <div className="row d-flex d-md-none mb-4">
            {activeSection === "Today's Screenings" &&
              (nowShowingLabelMovies?.length > 0 ? (
                <>
                  {nowShowingLabelMovies?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={2} />
                  ))}
                </>
              ) : (
                <>
                  <div className="w-100 text-center my-5">
                    {labelLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto"
                      />
                    ) : (
                      <h6 className="mx-auto mb-0 color-black">
                        {t('common.No movies found')}
                      </h6>
                    )}
                  </div>
                </>
              ))}

            {activeSection === 'Upcoming' &&
              (upcomingLabelMovies?.length > 0 ? (
                <>
                  {upcomingLabelMovies?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={3} />
                  ))}
                </>
              ) : (
                <>
                  <div className="w-100 text-center my-5">
                    {labelLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto"
                      />
                    ) : (
                      <h6 className="mx-auto mb-0 color-black">
                        {t('common.No movies found')}
                      </h6>
                    )}
                  </div>
                </>
              ))}

            {activeSection === 'onDemand' &&
              (onDemandLabelMovies?.length > 0 ? (
                <>
                  {onDemandLabelMovies?.map((movie, index) => (
                    <MovieCard {...movie} key={index} index={index} type={1} />
                  ))}
                </>
              ) : (
                <>
                  <div className="w-100 text-center my-5">
                    {labelLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto"
                      />
                    ) : (
                      <h6 className="mx-auto mb-0 color-black">
                        {t('common.No movies found')}
                      </h6>
                    )}
                  </div>
                </>
              ))}
          </div>

          {nowShowingLabelMovies?.length > 0 && (
            <MovieSection
              heading={t('Now Showing')}
              totalMovies={nowShowingLabelMovies?.length}
              moviesArray={nowShowingLabelMovies}
              movieCardArray={nowShowingLabelMovies}
              moviesObject={nowShowingLabelMovies}
              loadMore={false}
              currentPage={1}
              lastPage={1}
              type={2}
              notOnMobile
            />
          )}

          {onDemandLabelMovies?.length > 0 && (
            <MovieSection
              heading={t('On Demand')}
              totalMovies={onDemandLabelMovies?.length}
              moviesArray={onDemandLabelMovies}
              movieCardArray={onDemandLabelMovies}
              moviesObject={onDemandLabelMovies}
              loadMore={false}
              currentPage={1}
              lastPage={1}
              type={1}
              notOnMobile
            />
          )}

          {upcomingLabelMovies?.length > 0 && (
            <MovieSection
              heading={t('Upcoming')}
              totalMovies={4}
              moviesArray={upcomingLabelMovies?.length}
              movieCardArray={upcomingLabelMovies}
              moviesObject={upcomingLabelMovies}
              loadMore={false}
              currentPage={1}
              lastPage={1}
              type={3}
              notOnMobile
              // setShowOffers={setShowOffers}
            />
          )}

          {!isMobile &&
            upcomingLabelMovies?.length == 0 &&
            onDemandLabelMovies?.length == 0 &&
            nowShowingLabelMovies?.length == 0 && (
              <div className="w-100 text-center my-5">
                {labelLoading ? (
                  <Spinner animation="border" role="status" className="mx-auto" />
                ) : (
                  <h6 className="mx-auto mb-0 color-black">
                    {t('common.No movies found')}
                  </h6>
                )}
              </div>
            )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(LabelledMovies);
