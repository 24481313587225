import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CustomInput from '@components/partials/CustomInput';
import Button from '@components/partials/Button';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCashCardDetailsFromCardNumberPin } from '../../../store/cashCard/cashCardActions';
import Spinner from '../../partials/Spinner';
import { currencyFormatter } from '../../../helper/currencyFormatter';
import { CashCardScanner } from '@components/GiftCards/helper/CashCardScanner';

const CheckBalance = (props) => {
  const [showBalance, setShowBalance] = useState(false);
  const { t, classes } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  let [submitted, setSubmitted] = useState(null);

  let [card, setCard] = useState('');
  let [cardError, setCardError] = useState(false);

  let [pin, setPin] = useState('');
  let [pinError, setPinError] = useState(false);
  let [errorMsg, setErrorMsg] = useState('');
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector(
    (state) => state.cashCard.cashCardDetailsLoading,
  );
  const cashCardTopUpSelection = useSelector(
    (state) => state.cashCard.cashCardTopUpSelection,
  );

  let submitCardDetails = () => {
    setErrorMsg('');
    setCardError(false);
    setPinError(false);
    setSubmitted(true);

    let count = 0;
    if (!card) {
      ++count;
      setCardError(true);
    }
    if (!pin) {
      ++count;
      setPinError(true);
    }

    if (count > 0) {
      console.log({ pin, card });
      return;
    }

    dispatch(
      fetchCashCardDetailsFromCardNumberPin({
        cash_card_number: card,
        cash_pin: pin,
      }),
    )
      .then(({ status, message }) => {
        if (!!status) {
          // history.push(`/cash-card-topup/${card}`);
          setTimeout(() => {
            setShowBalance(true);
          }, 100);
        } else {
          if (message != 'Incorrect Password.') {
            setCardError(true);
          } else {
            setPinError(true);
          }
          setErrorMsg(message);
        }
      })
      .catch(console.log)
      .finally(() => {});
  };

  const [showCashCardPinScanner, setshowCashCardPinScanner] = useState(false);

  const [firstCashCard, setFirstCashCard] = useState({});
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length)
      setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);

  return (
    <>
      <div className="check_balance_wrapper">
        <section className="row">
          <article className="col-sm-6 col-lg-4 col-xl-3 mx-auto">
            {showBalance ? (
              <>
                {cashCardDetailsLoading ? (
                  <div className="row">
                    <div className="col-12 text-center">
                      <Spinner />
                    </div>
                  </div>
                ) : (
                  <div className="show_balance">
                    <h5>{t('Your Cash Card Balance is')}</h5>
                    <h4>
                      {curr_code} {currencyFormatter(firstCashCard?.balance_amount)}
                    </h4>
                    <Button
                      text={t('alt.Back')}
                      onClick={() => {
                        setShowBalance(false);
                        setSubmitted(false);
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <CustomInput
                  type="number"
                  label={t('Card Number')}
                  placeholder={t('Enter your Card number')}
                  errorStatus={submitted && cardError}
                  errorMsg={
                    submitted && cardError && !errorMsg
                      ? t('Please enter Card number')
                      : !!errorMsg
                      ? t(errorMsg)
                      : ''
                  }
                  value={card}
                  forCashCardNumber={true}
                  showCashCardPinScanner={showCashCardPinScanner}
                  setshowCashCardPinScanner={setshowCashCardPinScanner}
                  onChange={(e) => {
                    setCard(e?.target?.value);
                  }}
                />

                {!!showCashCardPinScanner && (
                  <CashCardScanner
                    onResult={(result, error) => {
                      if (result && result?.text) {
                        setCard(
                          result?.text?.split(' ').join('').split('-').join('') ||
                            '',
                        );
                        setshowCashCardPinScanner(!showCashCardPinScanner);
                      }
                      if (error) {
                        console.log('cant scan qrcode', error.message);
                      }
                    }}
                  />
                )}

                <CustomInput
                  type={showLoginPassword ? 'number' : 'password'}
                  label={t('PIN number')}
                  placeholder={t('Enter your PIN number')}
                  errorStatus={submitted && pinError}
                  errorMsg={
                    submitted && pinError
                      ? t(!!errorMsg ? errorMsg : 'Please enter PIN number')
                      : ''
                  }
                  value={pin}
                  forPassword={true}
                  showLoginPassword={showLoginPassword}
                  setShowLoginPassword={setShowLoginPassword}
                  onChange={(e) => {
                    setPin(e?.target?.value);
                  }}
                />
                <Button
                  text={t('Proceed')}
                  classes="w-100"
                  onClick={() => submitCardDetails()}
                  loading={submitted && !pinError && !cardError && !errorMsg}
                  disable={submitted && !pinError && !cardError && !errorMsg}
                />
              </>
            )}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(CheckBalance);
