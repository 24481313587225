import React, { useEffect, useState, useRef, useContext } from 'react';
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import code from '@assets/pictures/svgs/code.svg';
import poster2 from '@assets/pictures/poster-2.png';
import filtericon from '@assets/pictures/svgs/filters.svg';
import {
  fetchUserHistory,
  fetchGuestHistory,
  login,
} from '../../store/auth/authActions';
import moment from 'moment';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import { sendEmail, sendSms } from '../../store/movies/movieActions';
import { withNamespaces } from 'react-i18next';
import { currencyFormatter } from '../../helper/currencyFormatter';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { getLangSpecificAttribute } from '@helper/languages';
import Spinner from '.././partials/Spinner';
import useIsMobile from '../../customHooks/useIsMobile';
import { ReactComponent as TicketImg } from '@assets/pictures/svgs/newfinalticket.svg';
import { ReactComponent as TicketImggold } from '@assets/pictures/svgs/ticketimggold.svg';
import { movieLanguagesWithSubs } from '@helper/formatting';
import _ from 'lodash';

const Transactions = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();

  const loginDetails = useSelector((state) => state.auth);
  const userHistoryLoading = useSelector((state) => state.auth.userHistoryLoading);
  const { loggedInUser, userHistory, guestUser } = loginDetails;

  // console.log(loginDetails, 'loginDetails123');
  const resendSMSError = useSelector((state) => state.movies.resendSMSError);
  const resendEmailError = useSelector((state) => state.movies.resendEmailError);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const [ticketIndex, setTicketIndex] = useState(null);
  const { lang_name, iso_2, lang_id } = selected_language;
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log("login :>> ", login);
  //   dispatch(
  //     login({ email: user.email, password: user.password, callback: () => {} })
  //   );
  // }, []);
  useEffect(() => {
    if (loggedInUser?.cust_id) {
      dispatch(
        fetchUserHistory({
          page: 'profile',
          customer_id: loggedInUser?.cust_id,
        }),
      );
    } else if (!guestUser) {
      history.push(`/`);
    } else if (guestUser) {
      dispatch(
        fetchGuestHistory({
          page: 'profile',
          guest_user_id: guestUser.guest_user_id,
          guest_id: guestUser?.guest_id,
        }),
      );
    }
  }, [dispatch, history, loggedInUser, guestUser]);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  function isGoldColor(bookingDetails, category_id) {
    let isPrivate =
      bookingDetails?.selectedSeats?.length == bookingDetails?.booked_seats;

    if (!bookingDetails || !category_id) return false;

    if (bookingDetails?.booking_type_id === 2) return false;

    if (bookingDetails?.booking_type_id === 3) return true;

    if (bookingDetails?.booking_type_id === 1 && isPrivate) return true;

    return false;
  }
  function getInternetHandlingFees(bookingDetails, category_id) {
    if (bookingDetails?.booking_type_id == 3) return 0;

    return bookingDetails?.internet_handling_fee || 0;
  }
  function getFnbTotal(bookingDetails) {
    let total = 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      bookingDetails.fnb_items.forEach((f) => {
        total += f?.fp_total_amount_before_offer || 0;
      });
    }

    // return bookingDetails?.ft_total_amount || 0
    return total;
  }
  function getOfferTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.offer_price || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      bookingDetails.fnb_items.forEach((f) => {
        let t = f?.fp_offer_price * f?.fst_quantity || 0;

        if (
          f.ft_voucher_code == null &&
          f.ft_voucher_name == null &&
          f.ft_offer_name
        ) {
          t = f?.fp_offer_price || 0;
        }

        total += t;
      });
    }

    return total;
  }
  function getEwalletTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.e_wallet_amount || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      total += bookingDetails?.fnb_items?.[0]?.e_wallet_amount || 0;
    }

    return total;
  }
  function getCashCardTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.cash_card_amount || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      total += bookingDetails?.fnb_items?.[0]?.cash_card_amount || 0;
    }

    return total;
  }
  function getTotalWithoutDeductions(bookingDetails) {
    return bookingDetails?.booking_type_id !== 3
      ? parseFloat(
          bookingDetails?.sb_total_amount +
            getInternetHandlingFees(bookingDetails) +
            getFnbTotal(bookingDetails) +
            getRateCardAddonsTotal(bookingDetails),
        )
      : parseFloat(bookingDetails?.private_rate_card_detail?.[0]?.total);
  }
  function getRateCardAddonsTotal(bookingDetails) {
    if (!bookingDetails) return 0;

    return bookingDetails?.add_ons_gross_charge ?? 0;
  }

  function extractSeatTypes(selectedSeats) {
    return _.chain(selectedSeats)
      .groupBy('screen_seat_type_id')
      .toPairs()
      .map((x) => ({
        sst_seat_type: x[1][0].sst_seat_type,
        seats: x[1],
      }))
      .map((x) => {
        x.seats = _.chain(x.seats)
          .groupBy('rate_card_group_child_id')
          .toPairs()
          .map((x) => ({
            tt_name: x[1][0].tt_name,
            ticket_type_translations: x[1][0].ticketTypeTranslations,
            no_of_seats: x[1].length,
            single_seat_price: x[1][0].rc_total_amount,
            single_seat_net_price: x[1][0].rc_net_amount,
            total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
            seats: x[1],
          }))
          .value();
        return x;
      })
      .value();
  }

  function getTicketTypes(selectedSeatsFromTrx = []) {
    let finalResult = [];

    let seatTypes = extractSeatTypes(selectedSeatsFromTrx);

    for (let seatType of seatTypes) {
      let seats = seatType.seats.map(
        (x) =>
          `${x.no_of_seats} ${
            x?.ticket_type_translations?.[iso_2?.toLowerCase()] || x?.tt_name
          }`,
      );
      finalResult.push({
        ticket_type: seatType.sst_seat_type,
        seat_types: seats,
      });
    }

    return finalResult;
  }

  const get_final_addon_gross_charges = (final_addon_gross_charges) => {
    let parsed = [];

    try {
      if (typeof final_addon_gross_charges === 'string') {
        if (['null', 'undefined', '[]', '{}'].includes(final_addon_gross_charges)) {
          parsed = [];
        } else {
          parsed = JSON.parse(final_addon_gross_charges);
        }
      } else if (Array.isArray(final_addon_gross_charges)) {
        parsed = final_addon_gross_charges;
      }
    } catch (e) {
      parsed = [];
      console.log('parsing error', e);
    }
    return parsed;
  };

  return (
    <div className="transactions">
      {/* <div className="header">
        <div className="d-flex align-items-center pb-1">
          <img src={filtericon} className="img-fluid" alt="" />
          <label className="pl-2 pt-2">Filters</label>
        </div>
        <Dropdown className="user-custom-dropdown">
          <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
            Select
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className="dropdownItems">
            <Dropdown.Item className="selected">All</Dropdown.Item>
            <Dropdown.Item>Coming</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      {userHistoryLoading && (
        <div className="row mt-5">
          <div className="col-12 text-center">
            <Spinner />
          </div>
        </div>
      )}
      {userHistory?.length > 0 && (
        <div className="booked-tickets">
          {userHistory?.map((transaction, index) => (
            <>
              {index !== 0 && <hr className="custom-border" />}
              <div className="book-ticket">
                <div className="ticket">
                  <div
                    className={`ticket-header-profile ticket-header-profile-transaction ${
                      isGoldColor(transaction?.Seats[0], 1) ? 'back_gold_final' : ''
                    }`}
                  >
                    <Link to={'/'}>
                      <img
                        src={org_mycinema_logo || org_logo_url}
                        alt={org_name}
                        height={`${isMobile ? '40px' : '80px'}`}
                        width={`${isMobile ? '80px' : '130px'}`}
                        // className="img-fluid"
                      />
                    </Link>

                    <div className="final_svg_top" style={{ alignItems: 'center' }}>
                      {isGoldColor(transaction?.Seats[0], 1) ? (
                        <TicketImggold
                          className="svg_ticket"
                          style={{
                            marginRight: '9px',
                            width: isMobile ? '30px' : '',
                          }}
                        />
                      ) : (
                        <TicketImg
                          style={{
                            marginRight: '9px',
                            marginTop: '2px',
                            width: isMobile ? '30px' : '',
                          }}
                        />
                      )}

                      <h6
                        className="mb-0"
                        style={{
                          color: isGoldColor(transaction?.Seats[0], 1)
                            ? 'black'
                            : 'white',
                          whiteSpace: isMobile ? 'nowrap' : undefined,
                        }}
                      >
                        {isGoldColor(transaction?.Seats[0], 1)
                          ? t('Private Cinema')
                          : t('Your Ticket')}
                      </h6>
                    </div>
                  </div>
                  <div className="header">
                    <div className="left">
                      <div className="movie-img">
                        <img
                          src={
                            getLangSpecificAttribute(
                              transaction?.Seats?.[0]?.languageSpecificImages,
                              lang_id,
                              'artwork',
                            ) || dummyPoster
                          }
                          className="img-fluid"
                          alt="movie-img"
                        />

                        {/* <img src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1609780139976-ramprasad.jpg" className="img-fluid" /> */}
                      </div>
                      {/*<div className="movie-content">
                        <h4 className="heading modelica-bold">
                          {getLangSpecificAttribute(
                            transaction?.Seats?.[0]?.movieTranslations,
                            lang_id,
                            "mc_title"
                          ) || transaction?.Seats?.[0]?.movie_name}
                        </h4>
                        <div className="features">
                          <p>
                            {transaction?.Seats?.[0]?.movie_language}{" "}
                            {transaction?.Seats?.[0]?.movie_format}
                          </p>
                          <p>
                            {transaction?.Seats?.[0]?.genreTranslations?.map(
                              (genre, index) =>
                                genre[iso_2?.toLowerCase()] && (
                                  <span key={index}>
                                    {" "}
                                    {genre[iso_2?.toLowerCase()]} |
                                  </span>
                                )
                            )}{" "}
                            {transaction?.Seats?.[0]?.movie_rating}
                          </p>
                        </div>
                        <div className="booked-details">
                          <div>
                            <p className="fsd-18 text-success modelica-bold">
                              {transaction?.Seats?.[0]?.cine_name}
                            </p>
                            <p className="fsd-14">
                              {transaction?.Seats?.[0]?.cine_address}
                              {/* {transaction?.Seats?.[0]?.cine_location yaha peh tha pehley}
                            </p>
                          </div>
                          <div>
                            <p className="fsd-14">{t(`Seats Selected`)}:</p>
                            <p className="fsd-16 font-weight-bold text-break">
                              {transaction?.Seats[0]
                                ?.mycinema_booking_type_id === 3
                                ? t("entire-hall-booked")
                                : transaction?.seatsString?.join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>*/}
                      <article className="mozilla_final_mob_alignmnet transaction_sec">
                        <p
                          className={`mb-1 final_heading ${
                            isGoldColor(transaction?.Seats[0], 1)
                              ? 'final_heading_gold'
                              : ''
                          }`}
                        >
                          {isGoldColor(transaction?.Seats[0], 1)
                            ? t('Private Cinema')
                            : ''}
                        </p>
                        <h1 className="movie_info_title_trans mb-2">
                          {getLangSpecificAttribute(
                            transaction?.Seats?.[0]?.languageSpecificImages,
                            lang_id,
                            'mc_title',
                          ) || transaction?.Seats?.[0]?.movie_title}
                        </h1>

                        <div className="btn_alignment">
                          {!!transaction?.Seats?.[0]?.movie_lang_name && (
                            <button className="prof_btn mob_prof_btn">
                              {movieLanguagesWithSubs(
                                transaction?.Seats?.[0]?.movie_lang_name,
                                transaction?.Seats?.[0]?.sub_1_iso_2,
                                transaction?.Seats?.[0]?.sub_2_iso_2,
                              )}
                            </button>
                          )}
                          {/*<button className="prof_btn">
                                 136 mins
                                </button>*/}
                          {!!transaction?.Seats?.[0]?.movie_rating && (
                            <button className="prof_btn">
                              {transaction?.Seats?.[0]?.movie_rating}
                            </button>
                          )}
                          {!!transaction?.Seats?.[0]?.movie_format && (
                            <button className="prof_btn">
                              {transaction?.Seats?.[0]?.movie_format}
                            </button>
                          )}
                        </div>
                        {false && (
                          <div
                            className="movie_info_rating_n_format justify-content-start res_final_spacing"
                            style={{
                              marginBottom: !isMobile ? '24px' : undefined,
                            }}
                          >
                            <span className="movie_info_language ">
                              {(!!t(transaction?.Seats?.[0]?.lang_name)
                                ? t(transaction?.Seats?.[0]?.lang_name)
                                : transaction?.Seats?.[0]?.lang_name) ||
                                transaction?.Seats?.[0]?.version_name}
                              {`${
                                transaction?.Seats?.[0]?.sub_1_iso_2
                                  ? `/${transaction?.Seats?.[0]?.sub_1_iso_2}`
                                  : ''
                              }${
                                transaction?.Seats?.[0]?.sub_1_iso_2 &&
                                transaction?.Seats?.[0]?.sub_2_iso_2
                                  ? `-${transaction?.Seats?.[0]?.sub_2_iso_2}`
                                  : ''
                              }`}
                            </span>

                            {
                              <>
                                {transaction?.Seats?.[0]?.duration && (
                                  <button className="newtime_final ">
                                    {transaction?.Seats?.[0]?.duration}{' '}
                                    {t('common.mins')}
                                  </button>
                                )}
                                {/*<span className="movie_info_language">
                                        {(!!t(transaction?.Seats?.[0]?.lang_name) ? t(transaction?.Seats?.[0]?.lang_name) : transaction?.Seats?.[0]?.lang_name)
                                        || transaction?.Seats?.[0]?.version_name}{`${
                                          transaction?.Seats?.[0]?.sub_1_iso_2 ? `/${transaction?.Seats?.[0]?.sub_1_iso_2}` : ''
                                      }${
                                          transaction?.Seats?.[0]?.sub_1_iso_2 && transaction?.Seats?.[0]?.sub_2_iso_2 ? `-${transaction?.Seats?.[0]?.sub_2_iso_2}` : ''
                                      }`}
                                    </span>*/}
                                <span className="movie_info_rating ">
                                  {transaction?.Seats?.[0]?.rating}
                                </span>
                                <span className="movie_info_language ">
                                  {transaction?.Seats?.[0]?.mf_name}
                                </span>
                              </>
                            }
                          </div>
                        )}

                        <div className=" text-left middle-align-prop">
                          <h6
                            className={`final_heading pt-1
                                ${
                                  isGoldColor(transaction?.Seats[0], 1)
                                    ? 'gold_final_text'
                                    : ''
                                }`}
                          >
                            {t('Date & Time')}
                          </h6>
                          <p
                            className="final_para text-uppercase"
                            style={{ color: '#fff' }}
                          >
                            {moment(
                              transaction?.Seats?.[0]?.ss_actual_start_date,
                            ).format('DD MMM YYYY')}{' '}
                            |{' '}
                            {moment(
                              transaction?.Seats?.[0]?.ss_start_show_time,
                              'HH:mm',
                            ).format('HH:mm')}{' '}
                          </p>
                          {/*
                                    transaction?.Seats?.[0]?.booking_type_id === 3 && (<>
                                    <img src={Diamond} alt="diamond" />
                                    </>)*/}

                          {/*<h6 className="">{t("Genre")}</h6>
                                  <p/>
                        {!!(transaction?.Seats?.[0]?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                    &&
                                    <p>
                                    { (<>
                                          {transaction?.Seats?.[0]?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || transaction?.Seats?.[0]?.g_name}
                                        </>)}
                                              </p>}*/}
                        </div>

                        <div className=" text-left middle-align-prop">
                          <h6
                            className={`final_heading pt-1
                                ${
                                  isGoldColor(transaction?.Seats[0], 1)
                                    ? 'gold_final_text'
                                    : ''
                                }`}
                          >
                            {t('Transaction Date & Time')}
                          </h6>
                          <p
                            className="final_para text-uppercase"
                            style={{ color: '#fff' }}
                          >
                            {/* transaction?.transaction_date_time */}
                            {moment(
                              transaction?.Seats?.[0]?.transaction_date_time,
                            ).format('DD MMM YYYY')}{' '}
                            |{' '}
                            {moment(
                              transaction?.Seats?.[0]?.transaction_date_time,
                            ).format('HH:mm')}{' '}
                          </p>
                        </div>

                        <div className="text-left">
                          <h6
                            className={`final_heading 
                                  ${
                                    isGoldColor(transaction?.Seats[0], 1)
                                      ? 'gold_final_text'
                                      : ''
                                  }`}
                          >
                            {t('Cinema')}
                          </h6>
                          <p />
                          <p className="final_para" style={{ color: '#fff' }}>
                            {transaction?.Seats?.[0]?.cine_name},{' '}
                            {transaction?.Seats?.[0]?.cine_address}
                            {/* {transaction?.Seats?.[0]?.cine_location} */}
                          </p>
                        </div>
                        {/* <div className="features">
                                <p>
                                  {
                                    transaction?.Seats?.[0]?.is_version_apply
                                        ? <>{transaction?.Seats[0].version_name}{" "}
                                          {transaction?.Seats?.[0]?.mf_name}</>
                                        : <>{transaction?.Seats?.[0]?.lang_name}{" "}
                                          {transaction?.Seats?.[0]?.mf_name}</>
                                  }
                                </p>
                                <p>
                                  {
                                    !!(transaction?.Seats?.[0]?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                      ? (<>
                                          {transaction?.Seats?.[0]?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || transaction?.Seats?.[0]?.g_name}{" "}
                                          | {transaction?.Seats?.[0]?.rating}
                                        </>)
                                      : (<>
                                          {transaction?.Seats?.[0]?.rating}
                                        </>)
                                  }
                                </p>
                              </div>
                              <div className="cinema-name">
                                <p className="fs-16 d-none d-md-block">
                                  {t("Cinema")}:
                                </p>
                                <p className="font-weight-bold">
                                  {transaction?.Seats?.[0]?.cine_name},{" "}
                                  {transaction?.Seats?.[0]?.cine_address}
                                   {transaction?.Seats?.[0]?.cine_location}
                                </p>
                              </div> */}
                      </article>
                    </div>
                    <div className="right">
                      {/* <div class="py-2 d-flex align-items-center">
                        <div className="date-day d-flex align-items-center pr-3">
                          <h3 className="modelica-bold mb-0">
                            {moment(
                              transaction?.Seats?.[0]?.ss_actual_start_date
                            ).format("DD")}
                          </h3>
                          <p className="fsd-14 pl-2">
                            {moment(
                              transaction?.Seats?.[0]?.ss_actual_start_date
                            ).format("MMMM")}{" "}
                            <br />{" "}
                            {moment(
                              transaction?.Seats?.[0]?.ss_actual_start_date
                            ).format("dddd")}
                          </p>
                        </div>
                        <div className="date-time pl-3">
                          <h3 className="modelica-bold mb-0 pr-4">
                            {moment(
                              transaction?.Seats?.[0]?.ss_start_show_time,
                              "HH:mm"
                            ).format("HH:mm")}{" "}
                          </h3>
                          <div>
                            {/* {moment(
                              transaction?.Seats?.[0]?.ss_actual_start_date_time
                            ).format("A")} //The comment was closed here
                          </div>
                        </div>
                      </div>*/}
                      <div className="ticket_text trans_ticket">
                        <div
                          className={`final_heading final_heading_mob ${
                            isGoldColor(transaction?.Seats[0], 1)
                              ? 'gold_final_text'
                              : ''
                          }`}
                        >
                          {t('Ticket ID')}:
                        </div>

                        <div
                          className="final_para final_para_mob"
                          style={{ color: '#fff' }}
                        >
                          {transaction?.Seats?.[0]?.sb_booking_code}
                        </div>
                      </div>
                      <div className=" pt-1 text-center">
                        {!transaction?.Seats?.[0]?.t_refund_date_time_stamp && (
                          <>
                            {/* <img src={code} className="img-fluid" alt="" /> */}
                            <div className="">
                              {/* trans-barcode */}
                              {/* <Barcode
                              value={transaction?.Seats?.[0]?.sb_booking_code}
                            /> */}
                              {transaction?.Seats?.[0]?.sb_booking_code && (
                                <QRCode
                                  value={transaction?.Seats?.[0]?.sb_booking_code}
                                  className="mx-auto qr-border"
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      {!transaction?.Seats?.[0]?.t_refund_date_time_stamp && (
                        <p
                          className="text-grey fs-12 pb-2"
                          style={{ textAlign: 'center' }}
                        >
                          {t(`QR Code`)}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* {transaction?.Seats?.[0]?.fnb_items?.length > 0 && (*/}

                  {/*auditorium saal section */}
                  <div className="top_prop">
                    <div className="profile_tic_heading">
                      {t('Auditorium')}
                      <p className="para_profile">
                        {transaction?.Seats?.[0]?.screen_name}
                      </p>
                    </div>

                    <div className="profile_tic_heading">
                      {t('Selected Seats')}
                      <p className="para_profile">
                        {transaction?.Seats?.[0]?.mycinema_booking_type_id === 3
                          ? transaction?.Seats?.[0]?.private_rate_card_detail?.[0]
                              ?.customSeatCount
                          : transaction?.seatsString?.join(', ')}
                      </p>
                    </div>

                    {transaction?.Seats?.[0]?.mycinema_booking_type_id !== 3 && (
                      <div className="profile_tic_heading">
                        {t('Ticket Type')}
                        <p className="para_profile">
                          {getTicketTypes(
                            transaction?.Seats?.[0]?.selectedSeats,
                          ).map((seatType) => {
                            // console.log({seatType})
                            return (
                              <div>
                                <span className="">
                                  {t(seatType.ticket_type)}&nbsp;
                                  {!isMobile && '-'}&nbsp;
                                </span>
                                {seatType.seat_types.map((item, index) => (
                                  <span className={`${isMobile ? 'd-block' : ''}`}>
                                    {item}
                                    {seatType.seat_types.length - 1 !== index &&
                                      ', '}
                                  </span>
                                ))}
                              </div>
                            );
                          })}
                        </p>
                      </div>
                    )}
                  </div>

                  {/* My profile ticket bottom fnb section*/}
                  <div className="ticket_bottom_profile">
                    <div className="mob_span_profile">
                      <div>
                        <div className="profile_tic_heading mob_pad">
                          {t('BOOKING / FOOD & BEVERAGE SUMMARY')}
                        </div>

                        {transaction?.Seats?.[0]?.booking_type_id !== 3 &&
                          extractSeatTypes(
                            transaction?.Seats?.[0]?.selectedSeats || [],
                          )?.length > 0 &&
                          extractSeatTypes(
                            transaction?.Seats?.[0]?.selectedSeats || [],
                          )?.map((seatType, seatTypeIndex) => (
                            <React.Fragment key={seatTypeIndex}>
                              {seatType?.seats?.length > 0 &&
                                seatType?.seats?.map((rcg_child, index2) => (
                                  <article
                                    className="main_trans_footer"
                                    key={index2}
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <div className="fnb_profile">
                                      <p className="prop_p mob_prop_p">
                                        <span>
                                          (x{rcg_child.no_of_seats} {t('Seats')})
                                        </span>
                                        <span> {seatType.sst_seat_type}</span>
                                        <span className="">
                                          {' '}
                                          {rcg_child?.ticket_type_translations?.[
                                            iso_2?.toLowerCase()
                                          ] || rcg_child.tt_name}
                                        </span>
                                      </p>
                                      <p className="prop_p">
                                        <span>
                                          {' '}
                                          {curr_code}{' '}
                                          {currencyFormatter(
                                            rcg_child?.total_price,
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                    <div></div>
                                  </article>
                                ))}
                            </React.Fragment>
                          ))}

                        {/* Gross addons on rate card */}
                        {transaction?.Seats?.[0]?.final_addon_gross_charges &&
                          get_final_addon_gross_charges(
                            transaction?.Seats?.[0]?.final_addon_gross_charges,
                          )?.length > 0 && (
                            <>
                              <article className="main_trans_footer my-2 mt-3">
                                <div className="fnb_profile">
                                  <p className="prop_p mob_prop_p">
                                    <span>{t('Addons')}</span>
                                    <span></span>
                                  </p>
                                  <p className="prop_p">
                                    <span></span>
                                  </p>
                                </div>

                                <div></div>
                              </article>
                              {get_final_addon_gross_charges(
                                transaction?.Seats?.[0]?.final_addon_gross_charges,
                              )?.map((item) => (
                                <>
                                  <article className="main_trans_footer my-2">
                                    <div className="fnb_profile">
                                      <p className="prop_p mob_prop_p">
                                        <span>(x{item.quantity}) </span>
                                        <span>
                                          {' '}
                                          {t(item.addons_name) || item.addons_name}
                                        </span>
                                      </p>
                                      <p className="prop_p">
                                        <span>
                                          {' '}
                                          {curr_code}{' '}
                                          {currencyFormatter(item?.final_price)}
                                        </span>
                                      </p>
                                    </div>

                                    <div></div>
                                  </article>
                                </>
                              ))}
                              <article className="main_trans_footer my-2 mb-3">
                                <div className="fnb_profile">
                                  <p className="prop_p mob_prop_p">
                                    <span>
                                      {t('Addons')} {t('Total')}
                                    </span>
                                    <span></span>
                                  </p>
                                  <p className="prop_p">
                                    <span>
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        getRateCardAddonsTotal(
                                          transaction?.Seats[0],
                                        ),
                                      )}
                                    </span>
                                  </p>
                                </div>

                                <div></div>
                              </article>
                            </>
                          )}

                        {/*FnB for non private booking */}
                        {transaction?.Seats?.[0]?.booking_type_id !== 3 &&
                          transaction?.Seats?.[0]?.fnb_items &&
                          transaction?.Seats?.[0]?.fnb_items.length > 0 &&
                          transaction?.Seats?.[0]?.fnb_items.map((item) => (
                            <>
                              {/*<p className="">{t("FnB")}:</p>*/}
                              <article className="main_trans_footer my-2">
                                <div className="fnb_profile">
                                  <p className="prop_p mob_prop_p">
                                    <span>(x{item.fst_quantity})</span>
                                    <span>
                                      {' '}
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        'item_lang_name',
                                      ) || item.item_name}
                                    </span>
                                  </p>
                                  <p className="prop_p">
                                    <span>
                                      {' '}
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        item.fp_total_amount_before_offer,
                                      )}
                                    </span>
                                  </p>
                                </div>
                                {/* <div>
                              <p className="">x{item.fst_quantity}</p>
                           </div>*/}
                                {/*<div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.fp_total_amount)}
                              </p>
                            </div>*/}
                                <div></div>
                              </article>
                            </>
                          ))}

                        {/*private booking fnb*/}
                        {transaction?.Seats?.[0]?.private_rate_card_detail &&
                          transaction?.Seats?.[0]?.private_rate_card_detail
                            ?.length > 0 && (
                            <article className="main_trans_footer my-2">
                              <div className="fnb_profile">
                                <p className="prop_p mob_prop_p">
                                  <span>
                                    {
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail?.[0]
                                        ?.customSeatCount
                                    }{' '}
                                    {t('Seats')}
                                  </span>
                                  <span></span>
                                </p>
                                <p className="prop_p">
                                  <span>
                                    {' '}
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      (transaction?.Seats[0]
                                        ?.private_rate_card_detail?.[0]
                                        ?.customSeatCount || 0) *
                                        (transaction?.Seats[0]
                                          ?.private_rate_card_detail[0]?.slab?.[0]
                                          ?.price || 0),
                                    )}
                                  </span>
                                </p>
                              </div>
                              <div></div>
                            </article>
                          )}
                        {transaction?.Seats?.[0]?.private_rate_card_detail?.[0]
                          ?.fnb &&
                          transaction?.Seats?.[0]?.private_rate_card_detail?.[0]
                            ?.fnb.length > 0 &&
                          transaction?.Seats?.[0]?.private_rate_card_detail?.[0].fnb.map(
                            (item, index) => (
                              <article
                                className="main_trans_footer my-2"
                                key={index}
                              >
                                <div className="fnb_profile">
                                  <p className="prop_p mob_prop_p">
                                    <span>(x{item.quantity})</span>
                                    <span> {item.item_name}</span>
                                  </p>
                                  <p className="prop_p">
                                    <span>
                                      {' '}
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        item.price *
                                          transaction?.Seats[0]
                                            ?.private_rate_card_detail?.[0]
                                            ?.customSeatCount,
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div></div>
                              </article>
                            ),
                          )}
                        {transaction?.Seats?.[0]?.private_rate_card_detail?.[0]
                          ?.addon &&
                          transaction?.Seats?.[0]?.private_rate_card_detail?.[0]
                            ?.addon.length > 0 &&
                          transaction?.Seats?.[0]?.private_rate_card_detail?.[0].addon.map(
                            (item, index) => (
                              <article
                                className="main_trans_footer my-2"
                                key={index}
                              >
                                <div className="fnb_profile">
                                  <p className="prop_p mob_prop_p">
                                    <span>(x{item.quantity})</span>
                                    <span> {item.item_name}</span>
                                  </p>
                                  <p className="prop_p">
                                    <span>
                                      {' '}
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        item.price * item.quantity,
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div></div>
                              </article>
                            ),
                          )}
                        {getInternetHandlingFees(transaction?.Seats[0]) > 0 && (
                          <article className="main_trans_footer my-2">
                            <div className="fnb_profile">
                              <p className="prop_p mob_prop_p">
                                <span>{t('Internet Handling Fee')}</span>
                                <span></span>
                              </p>
                              <p className="prop_p">
                                <span>
                                  {' '}
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    getInternetHandlingFees(transaction?.Seats[0]),
                                  )}
                                </span>
                              </p>
                            </div>
                            <div></div>
                          </article>
                        )}
                      </div>
                    </div>

                    <div
                      className={`${
                        isMobile ? 'mob_span_profile ' : 'mob_span_profile'
                      }`}
                      style={{ marginLeft: isMobile ? undefined : '55px' }}
                    >
                      <div className="profile_tic_heading mob_pad">
                        {t('Payment Method')}:
                      </div>

                      <div className="new_con_tick desktop_grid my-2">
                        {t('Total')}

                        <div
                          className=""
                          style={{ textAlign: isMobile ? 'right' : '' }}
                        >
                          {curr_code}{' '}
                          {currencyFormatter(
                            getTotalWithoutDeductions(transaction?.Seats[0]),
                          )}
                        </div>
                      </div>

                      {/*offer on payment */}
                      {!!getOfferTotal(transaction?.Seats[0]) && (
                        <div className="new_con_tick desktop_grid my-1 text-success">
                          {`${
                            transaction?.Seats?.[0]?.pm_id == 10
                              ? transaction?.Seats?.[0]?.pm_payment_mode
                              : `${transaction?.Seats?.[0]?.voucher_code} ${transaction?.Seats?.[0]?.voucher_code_fallback}`
                                  .toLowerCase()
                                  .includes('loyalty')
                              ? `${
                                  transaction?.Seats?.[0]?.voucher_code ||
                                  transaction?.Seats?.[0]?.voucher_code_fallback
                                }`
                              : !!transaction?.Seats?.[0]?.voucher_code ||
                                !!transaction?.Seats?.[0]?.voucher_code_fallback
                              ? ` ${t('Voucher Code')} ${`(${
                                  transaction?.Seats?.[0]?.voucher_code ||
                                  transaction?.Seats?.[0]?.voucher_code_fallback
                                })`}`
                              : ` ${t('Discount')}`
                          }`}

                          <div
                            className=""
                            style={{ textAlign: isMobile ? 'right' : '' }}
                          >
                            -{curr_code}{' '}
                            {currencyFormatter(
                              // transaction?.Seats?.[0]?.offer_price
                              getOfferTotal(transaction?.Seats[0]),
                            )}
                          </div>
                        </div>
                      )}

                      {/*cash card on payment */}
                      {!!getCashCardTotal(transaction?.Seats[0]) && (
                        <div className="new_con_tick desktop_grid my-1">
                          {`${t(
                            'Cash Card',
                          )} ${`(${transaction?.Seats?.[0]?.cash_card_number})`}`}

                          <div
                            className=""
                            style={{ textAlign: isMobile ? 'right' : '' }}
                          >
                            {curr_code}{' '}
                            {currencyFormatter(
                              getCashCardTotal(transaction?.Seats[0]) +
                                (transaction?.Seats?.[0]?.payment_mode ==
                                'Cash Card'
                                  ? getInternetHandlingFees(transaction?.Seats[0])
                                  : 0),
                            )}
                          </div>
                        </div>
                      )}
                      {/*ewallet on payment */}
                      {!!getEwalletTotal(transaction?.Seats[0]) && (
                        <div className="new_con_tick desktop_grid my-1">
                          {`${t('E-Wallet')}`}
                          <div
                            className=""
                            style={{ textAlign: isMobile ? 'right' : '' }}
                          >
                            {curr_code}{' '}
                            {currencyFormatter(
                              getEwalletTotal(transaction?.Seats[0]) +
                                getInternetHandlingFees(transaction?.Seats[0]),
                            )}
                          </div>
                        </div>
                      )}
                      {![10, 11, 29].includes(
                        Number(transaction?.Seats?.[0]?.payment_mode_id),
                      ) &&
                        transaction?.Seats?.[0]?.payment_mode != 'Cash Card' && (
                          <div className="new_con_tick desktop_grid my-1">
                            {transaction?.Seats?.[0]?.payment_mode ||
                              transaction?.Seats?.[0]?.stripeDetails?.card ||
                              'Debit Card'}

                            <div
                              className=""
                              style={{ textAlign: isMobile ? 'right' : '' }}
                            >
                              {curr_code}{' '}
                              {currencyFormatter(
                                transaction?.Seats?.[0]?.booking_type_id !== 3
                                  ? parseFloat(
                                      transaction?.Seats?.[0]?.sb_total_amount +
                                        getInternetHandlingFees(
                                          transaction?.Seats[0],
                                        ) +
                                        getFnbTotal(transaction?.Seats[0]) -
                                        getOfferTotal(transaction?.Seats[0]) +
                                        getRateCardAddonsTotal(
                                          transaction?.Seats[0],
                                        ),
                                    ) -
                                      getCashCardTotal(transaction?.Seats[0]) -
                                      getEwalletTotal(transaction?.Seats[0])
                                  : parseFloat(
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail?.[0]?.total -
                                        getOfferTotal(transaction?.Seats[0]),
                                    ) -
                                      getCashCardTotal(transaction?.Seats[0]) -
                                      getEwalletTotal(transaction?.Seats[0]),
                              )}
                            </div>
                          </div>
                        )}

                      <div
                        className="new_heading heading_mob_down desktop_grid my-1"
                        style={{
                          color: transaction?.Seats?.[0]?.rr_reason
                            ? 'var(--light-1)'
                            : undefined,
                        }}
                      >
                        {t('Total Amount Paid')}
                        <div
                          className=""
                          style={{ textAlign: isMobile ? 'right' : '' }}
                        >
                          {curr_code}{' '}
                          {currencyFormatter(
                            transaction?.Seats?.[0]?.booking_type_id !== 3
                              ? parseFloat(
                                  transaction?.Seats?.[0]?.sb_total_amount +
                                    getInternetHandlingFees(transaction?.Seats[0]) +
                                    getFnbTotal(transaction?.Seats[0]) -
                                    getOfferTotal(transaction?.Seats[0]) +
                                    getRateCardAddonsTotal(transaction?.Seats[0]),
                                )
                              : parseFloat(
                                  transaction?.Seats[0]
                                    ?.private_rate_card_detail?.[0]?.total -
                                    getOfferTotal(transaction?.Seats[0]),
                                ),
                          )}
                        </div>
                      </div>
                      {transaction?.Seats?.[0]?.rr_reason && (
                        <div className="new_heading heading_mob_down desktop_grid">
                          {t(`Refund Reason`)}
                          <div
                            className=""
                            style={{ textAlign: isMobile ? 'right' : '' }}
                          >
                            {t(transaction?.Seats?.[0]?.rr_reason)}
                          </div>
                        </div>
                      )}
                    </div>
                    {/**/}
                  </div>
                  <div className="transaction_send_btn">
                    {transaction?.Seats[0].ss_actual_start_date_time >
                      moment().valueOf() && (
                      <div className="footer-actions">
                        {(loading.email || loading.sms) && index === ticketIndex ? (
                          <Spinner animation="border" />
                        ) : (
                          <>
                            <p className="text-primary fs-12">
                              <span
                                style={{ cursor: 'pointer' }}
                                className="loader-text"
                                onClick={() => {
                                  !resendEmailError && setTicketIndex(index);
                                  setLoading({ ...loading, email: true });
                                  dispatch(
                                    sendEmail({
                                      sb_booking_code:
                                        transaction?.Seats[0].sb_booking_code,
                                    }),
                                  ).then((response) => {
                                    setLoading({ ...loading, email: false });
                                  });
                                }}
                              >
                                {t(`Resend Email`)}
                              </span>{' '}
                              |{' '}
                              {/* | {loading.email ? <Spinner size="sm" /> : null} */}
                              <span
                                style={{ cursor: 'pointer' }}
                                className={` ${loading.sms && 'loader-text'}`}
                                onClick={() => {
                                  !resendSMSError && setTicketIndex(index);
                                  setLoading({ ...loading, sms: true });
                                  dispatch(
                                    sendSms({
                                      sb_booking_code:
                                        transaction?.Seats[0].sb_booking_code,
                                    }),
                                  ).then((response) => {
                                    setLoading({ ...loading, sms: false });
                                  });
                                }}
                              >
                                {t(`Resend M-ticket`)}
                              </span>{' '}
                              {/* {loading.sms ? <Spinner size="sm" /> : null} */}
                            </p>
                            {resendSMSError && (
                              <p className="error mt-2 mb-0">{resendSMSError}</p>
                            )}
                            {resendEmailError && (
                              <p className="error mt-2 mb-0">{resendEmailError}</p>
                            )}
                            <p></p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {/* <div className="booked-tickets d-flex justify-content-center align-items-center"> */}
      {!userHistoryLoading && userHistory?.length === 0 && (
        <p className="text-center my-5 py-5">{t(`No Transactions Found`)}</p>
      )}
      {/* </div> */}
    </div>
  );
};

export default withNamespaces()(Transactions);
