import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import { setFromMoviePage, createShow } from '../../store/movies/movieActions';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setStage } from '../../store/movies/movieActions';
import cinemaBanner from '@assets/pictures/cinema-banner.png';
import infoCircle from '@assets/pictures/svgs/info-circle.svg';
import { languages, slImageOnhover } from '../../helper/formatting.js';
import { CarouselItem } from 'react-bootstrap';
import { _ } from 'core-js';
import { loadStripe } from '@stripe/stripe-js';
import { truncate } from 'lodash';
import { withNamespaces } from 'react-i18next';

function MovieShowNew({ t, ...props }) {
  // const { movie_id, content_lang_id, category_id } = useParams();
  const {
    movie_id,
    content_lang_id,
    // category_id,
    genre_id,
    cinema,
  } = useParams();
  // console.log(" {category_id} :>> ", { category_id });
  const { cinemaIndex, languages } = props;
  // const { isPrivate } = props.match.params;
  let isPrivate = props.isPrivate;
  // console.log("props.match.params :>> ", props.match.params);
  // const getShowId=(item)=>category_id ?
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => slImageOnhover, []);
  const privateCallback = async (item) => {
    dispatch(createShow(item, history, true)).then(() =>
      props.privateCallback(3, item),
    );
    // dispatch(setStage({ stage: 1, show: item, direct: true }));
    // setTimeout(() => props.privateCallback(3, item), 1000);
  };
  const currency = useSelector((state) => state.movies.currency);
  const no_of_screens = useSelector(
    (state) => state.movies?.selectedCinema?.no_of_screens,
  );
  const allow_website_booking = useSelector(
    (state) => state.movies.allow_website_booking,
  );
  const { curr_code, curr_id } = currency;
  const mycinema_ss_d_id = useSelector(
    (state) => state.movies.myCinemaCreateShow.mycinema_ss_d_id,
  );
  const [loading, setLoading] = React.useState(false);
  // console.log(`mycinema_ss_d_id`, mycinema_ss_d_id);

  const goToScreeningType = (item) => {
    dispatch(createShow(item, history));
    // .then(() => {
    //   setTimeout(
    //     () => history.push(`/screening-type/${mycinema_ss_d_id}`),
    //     2000
    //   );
    // });

    // console.log("item :>> ", item);
  };

  // console.log(props, "logged props");

  return (
    <section className="row movie-show" id="movieShow">
      <div className="col-xl-3">
        <p className="theatre-name">{props.theatre}</p>
        <div className="theatre-address pbm">
          <p className="">
            {props.theatreAddress}
            {cinema?.cinema_image && (
              <span className="hover-image-span">
                <img src={infoCircle} id="hover-image" />
                <div className="seat-type-image">
                  <img src={cinema?.cinema_image} />
                </div>
              </span>
            )}
          </p>
          <div className="seat-type-image">
            <img src={cinemaBanner} alt="" />
          </div>
        </div>
      </div>
      <div className="col-xl-9">
        {props.languages.map((language, index) => (
          <div className="row language-row" key={language + index}>
            <div className="col-md-2">
              {language.hasOwnProperty(`lang_name`) && (
                <p className="theatre-language">
                  {language?.is_version_apply
                    ? language?.version_name ?? language.lang_name
                    : language.lang_name}
                </p>
              )}
            </div>
            <div className="col-md-10 pl-0">
              {language.screens.map((screen, index1) => (
                <section className="show-section" key={index1}>
                  <p className="">
                    {((no_of_screens && no_of_screens > 1) ||
                      language.screens.length > 1) && (
                      <span className="hall-name">{screen.screen_name}</span>
                    )}
                  </p>
                  {screen?.schedule_list
                    ?.sort((a, b) => {
                      if (
                        parseInt(a.showTime.split(':')[0]) -
                          parseInt(b.showTime.split(':')[0]) ===
                        0
                      ) {
                        return (
                          parseInt(a.showTime.split(':')[1]) -
                          parseInt(b.showTime.split(':')[1])
                        );
                      } else {
                        return (
                          parseInt(a.showTime.split(':')[0]) -
                          parseInt(b.showTime.split(':')[0])
                        );
                      }
                    })
                    ?.map((item, index) => (
                      <div
                        disabled={true}
                        className={`show ${
                          item?.showType == 2 ? 'yellow' : 'green'
                        } ${item?.mf_name ? 'lg' : ''} ${
                          item?.showType == 3 ? 'full' : ''
                        } ${!allow_website_booking ? 'btnDisabled' : ''}`}
                        onClick={
                          () => {
                            if (!loading && allow_website_booking) {
                              if (isPrivate == 1) {
                                privateCallback(item);
                              } else if (props.category_id == 2) {
                                dispatch(setFromMoviePage());
                                history.push(
                                  `/seat-layout/${item.ss_id}/${item.screen_id}/${item.md_id}`,
                                );
                              } else {
                                goToScreeningType(item);
                              }
                            }
                            setLoading(true);
                          }

                          // isPrivate == 1
                          //   ? privateCallback(item)
                          //   : props.category_id == 2
                          //   ? (dispatch(setFromMoviePage()),
                          //     history.push(
                          //       `/seat-layout/${item.ss_id}/${item.screen_id}/${item.md_id}`
                          //     ))
                          //   : goToScreeningType(item);
                        }
                        key={index}
                        // ${item.md_id}/${item.ss_id}/${item.screen_id}/${category_id}/${item.gogo_ss_id}/null/${movie_id}/${content_lang_id}/${genre_id}/${isPrivate
                        // style={{
                        //   pointerEvents: item?.showType == 3 ? "none" : "",
                        // }}
                      >
                        <div className="">
                          <p className="show-time">{item?.showTime}</p>

                          {/* Tickets booked status */}
                          {item?.showType == 2 &&
                            props.category_id == 2 &&
                            item?.booked_seats &&
                            (!props.newStatusType ? (
                              <p className="seats-booked">{item.statusType}</p>
                            ) : (
                              <p className="seats-booked">
                                {`${item.statusTypeNumberOfSeats} ${t(
                                  item.statusTypeMessage,
                                )}`.trim()}
                              </p>
                            ))}
                        </div>
                        {item?.mf_name && item?.mf_name.toLowerCase() !== '2d' && (
                          <div className="exp">
                            {item?.mf_name}

                            {/* {props.category_id == 1 && item?.priceArray && (
                          <div className="hover-text">
                            {item?.priceArray?.map((price) => (
                              <p>
                                {price?.screeningType} {curr_code}{" "}
                                {price?.pricing}
                              </p>
                            ))}
                          </div>
                        )} */}
                          </div>
                        )}
                      </div>
                    ))}
                  {/* <p className="view-more">View More</p> */}
                </section>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default withNamespaces()(MovieShowNew);
