import qs from 'qs';

export const getValueFromGlobalOptions = (globalOptions, key) => {
  if (Array.isArray(globalOptions)) {
    let obj = globalOptions.find((x) => x.go_key === key);

    if (obj) {
      return obj['go_value'];
    }
  }

  return null;
};

export const isInStandaloneMode = () => {
  const qparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  let standalone = window.navigator.standalone,
    ua = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(ua),
    ios = /iphone|ipod|ipad/.test(ua);

  let is_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
    navigator.userAgent,
  );
  let is_safari_or_uiwebview = /(iPhone|iPod|iPad).*AppleWebKit/i.test(
    navigator.userAgent,
  );

  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone ||
    document.referrer.includes('android-app://') ||
    (qparams.ispwa && (qparams.ispwa === true || qparams.ispwa === 'true')) ||
    is_uiwebview ||
    is_safari_or_uiwebview ||
    (ios && standalone && !safari) ||
    (ios && !standalone && !safari)
  );
};
