import {
  ADD_USER_TO_SEGMENT_SUCCESS,
  CLEAR_SELECTED_VOUCHER,
  DISABLE_ADD_USER_TO_SEGMENT,
  ENABLE_ADD_USER_TO_SEGMENT,
  SET_APPLIED_VOUCHER,
  SET_VOUCHERS,
} from './promotionsTypes';
import PromotionsService from '@apiService/PromotionsService';
import { toast } from 'react-toastify';
import _ from 'lodash';
import i18n from 'i18next';
import FnbService from '@apiService/FnbService';
import { fetchReservationDetailsBrij } from '@store/movies/movieActions';

import store from '../../store/index';

export const setVouchers = (payload) => ({
  type: SET_VOUCHERS,
  payload,
});

export const setVouchersError = (payload) => ({
  type: SET_VOUCHERS,
  payload,
});

export const setAppliedVoucher = (payload) => ({
  type: SET_APPLIED_VOUCHER,
  payload,
});

export const fetchVouchers = (payload) => async (dispatch, getState) => {
  const state = getState();
  let scheduleDetails = state.movies.scheduleDetails;

  console.log('payload for promotions', payload);
  console.log('scheduleDetails', scheduleDetails);

  const s = {
    cinema_id: scheduleDetails.cine_id,
    movie_details_id: scheduleDetails.movie_details_id,
    screen_id: scheduleDetails.screen_id,
    seat_type_id: payload.seat.sst_id,
    show_date_time: scheduleDetails.ss_start_date_time,
    aggregator_cinema_id: scheduleDetails.cine_id,
  };

  PromotionsService.GetVouchersList(s)
    .then((response) => {
      const { data } = response;
      if (data && data.status) {
        dispatch(setVouchers(data.Records));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setVouchers(err.response ? err.response.data?.message : err.message),
      );
    });
};

export const onUpdateFnbCartItems = async (
  selected_fnb_items,
  reservation_id,
  fnb_price,
  setappliedPromotion,
) => {
  let payload = {
    reservation_id,
    selected_fnb_items,
    fnb_price,
    category_id: 2,
  };

  if (selected_fnb_items.length > 0) {
    selected_fnb_items.forEach((fnb) => {
      if (fnb?.Quantity) {
        fnb.quantity = fnb.Quantity;
      }
    });
  }

  return FnbService.SaveFnbItems(payload)
    .then((result) => {})
    .catch((error) => {
      console.log('Error on SaveFnbItems', error);
    })
    .finally(() => {
      return store
        .dispatch(
          fetchReservationDetailsBrij({
            reservation_id,
            category_id: 2,
            skipResetVoucherAndOfferInReservation: true,
          }),
        )
        .then(() => {
          setappliedPromotion?.();
        });
    });
};

export const resetVoucherAndOfferInReservation =
  (reservation_id) => async (dispatch, getState) => {
    return await PromotionsService.ResetVoucherAndOfferInReservation(
      reservation_id,
    );
  };

export const validateVoucherCode =
  (payload, reservation_id) => async (dispatch, getState) => {
    try {
      await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

      const state = getState();
      const response = await PromotionsService.ValidateVoucherCodeV2(payload);

      const { data } = response;

      if (data && data.status && data.Records.length > 0) {
        let selectedVoucher = data.Records[0];
        // const discount = calculateVoucherDiscount({
        //   selectedVoucher,
        //   totalAmount,
        //   netAmount,
        // });
        const discount = data?.BoxOffice?.TotalOfferPrice ?? 0;
        console.log({ voucher_discount: discount });
        selectedVoucher.voucher_discount = discount;
        dispatch(
          setAppliedVoucher({
            ...selectedVoucher,
            BoxOffice: data.BoxOffice,
          }),
        );

        let { data: saveData } = await PromotionsService.SaveVoucherInReservation({
          reservation_id,
          voucher_code: payload.voucher_code,
        });
      } else {
        // toast.error(`Invalid Promo Code`);
        throw new Error('Invalid Promo Code');
      }

      return {
        is100PercentOff:
          data?.BoxOffice?.actualTotalGross === 0 ||
          data?.BoxOffice?.actualTotalGross < 0,
        status: true,
      };
    } catch (err) {
      console.log({ voucherPayloadErr: err });
      return {
        is100PercentOff: false,
        status: false,
        message: err.message,
      };
    }
  };

export const validateEwallet =
  (appliedEwalletAmt, reservation_id) => async (dispatch, getState) => {
    try {
      await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

      dispatch({ type: 'SET_APPLIED_EWALLET', payload: appliedEwalletAmt });

      let { data } = PromotionsService.SaveEwalletInReservation({
        reservation_id,
        loyalty_details: {
          eWalletAmount: appliedEwalletAmt,
          type: '2',
        },
      });

      return {
        status: true,
      };
    } catch (e) {
      console.log('validateEwallet Error', e);
      return {
        status: false,
      };
    }
  };

export const validateLoyalty =
  (payload, reservation_id) => async (dispatch, getState) => {
    try {
      await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

      const { data } = await PromotionsService.ValidateLoyalty(payload);

      if (data.status) {
        dispatch({ type: 'SET_APPLIED_LOYALTY', payload: data });

        let payload = {
          reservation_id,
          loyalty_details: {
            customerBoBurnPoints: Number((data?.boBurnPoints || 0).toFixed(4)) || 0,
            customerFnbBurnPoints:
              Number((data?.fnbBurnPoints || 0).toFixed(4)) || 0,
            customerPointsToBeBurn: data?.BurnPoints ?? 0,
            loyalty_amount: data?.LoyaltyAmount ?? 0,
            bo_amount: data?.boxOfficeLoyaltyAmount ?? 0,
            fnb_amount: data?.fnbLoyaltyAmount ?? 0,
            type: '3',
          },
        };

        let { data: saveData } = await PromotionsService.SaveLoyaltyInReservation(
          payload,
        );
      } else {
        toast.error(data.message);
      }

      return {
        ...data,
        status: data.status,
      };
    } catch (e) {
      return {
        status: false,
      };
    }
  };

export const calculateOfferDetails = async (
  offer,
  reservationDetails,
  reservation_id,
  all_fnb_items,
  setappliedPromotion,
) => {
  let fnbItems = all_fnb_items ?? [];
  let totalAmount = 0;
  let FNbPrice = {
    total_price: 0,
    total_tax: 0,
  };

  if (offer.offer_type_id == 9) {
    let TotalSeats = reservationDetails?.selectedSeats?.length ?? 0;
    let FinalFNBArray = [];
    let selectedFnbItems = [];
    let totalTicketOffer = 0;

    totalTicketOffer = parseInt(
      Math.floor(TotalSeats / parseInt(offer.oc_buy_no_of_ticket)),
    );

    if (
      offer.oc_max_no_of_ticket_per_transaction &&
      totalTicketOffer > offer.oc_max_no_of_ticket_per_transaction &&
      offer.oc_max_no_of_ticket_per_transaction > 0
    ) {
      totalTicketOffer = offer.oc_max_no_of_ticket_per_transaction;
    }

    if (TotalSeats >= offer.oc_buy_no_of_ticket) {
      if (offer.oc_is_combo_with_fnb == 'Y') {
        let fnbItemsArray = offer.fnb_group_data || [];

        for (let i = 0; i < fnbItemsArray.length; i++) {
          let itemObj = fnbItemsArray[i];

          let offerFnbItem = fnbItems.filter(
            (x) =>
              x.item_code == itemObj.oc_buy_item_code ||
              itemObj.oc_buy_item_id == x.item_id,
          );
          if (offerFnbItem.length > 0) {
            if (
              reservationDetails.fnb_data &&
              reservationDetails.fnb_data.hasOwnProperty('selected_fnb_items') &&
              reservationDetails.fnb_data.selected_fnb_items &&
              reservationDetails.fnb_data.selected_fnb_items.length > 0
            ) {
              selectedFnbItems = reservationDetails.fnb_data.selected_fnb_items;

              reservationDetails.fnb_data.selected_fnb_items.forEach((i) => {
                i.Qty = i.quantity || i.Quantity;
              });
            }

            let itemQty =
              parseInt(itemObj.oc_buy_no_of_item || 1) *
              parseInt(totalTicketOffer || 1);
            let checkAlreadyAddedItem = selectedFnbItems.findIndex(
              (x) => x.item_code == itemObj.oc_buy_item_code,
            );
            if (checkAlreadyAddedItem > -1) {
              if (selectedFnbItems[checkAlreadyAddedItem].Qty == itemQty) {
                // DO NOTHING
              } else {
                selectedFnbItems[checkAlreadyAddedItem].Qty += itemQty;
                selectedFnbItems[checkAlreadyAddedItem].quantity += itemQty;
                selectedFnbItems[checkAlreadyAddedItem].Quantity += itemQty;
              }
            } else {
              let fnbObj = {
                ...offerFnbItem[0],
                Qty: itemQty,
                quantity: itemQty,
                Quantity: itemQty,
              };
              selectedFnbItems.push(fnbObj);
            }
          } else {
            // resetting voucher/offer here
            await PromotionsService.ResetVoucherAndOfferInReservation(
              reservation_id,
            );
          }
        }
        if (selectedFnbItems.length > 0) {
          selectedFnbItems.forEach((x) => {
            let TotalPrice = +parseFloat(x.fp_total_amount).toFixed(4) * x.Qty;
            let TotalTax =
              +parseFloat(x.fp_total_amount - x.fp_amount).toFixed(4) * x.Qty;

            FNbPrice['total_price'] = +parseFloat(
              FNbPrice['total_price'] + TotalPrice,
            ).toFixed(4);
            FNbPrice['total_tax'] = +parseFloat(
              FNbPrice['total_tax'] + TotalTax,
            ).toFixed(4);
            let obj = {
              ...x,
              Quantity: x.Qty,
              quantity: x.Qty,
              total_price: TotalPrice,
              total_tax: TotalTax,
            };
            FinalFNBArray.push(obj);
            totalAmount = +parseFloat(
              parseFloat(totalAmount) + parseFloat(TotalPrice),
            ).toFixed(4);
          });

          await onUpdateFnbCartItems(
            FinalFNBArray,
            reservation_id,
            FNbPrice,
            setappliedPromotion,
          );
        }
      }
    } else {
      // resetting voucher/offer here
      await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
    }
  }

  if (offer.offer_type_id == 3) {
    let selectedFnbItems = [];
    if (
      reservationDetails.fnb_data &&
      reservationDetails.fnb_data.hasOwnProperty('selected_fnb_items') &&
      reservationDetails.fnb_data.selected_fnb_items &&
      reservationDetails.fnb_data.selected_fnb_items.length > 0
    ) {
      selectedFnbItems = reservationDetails.fnb_data.selected_fnb_items;
    }

    if (
      offer.oc_is_combo == 'Y' &&
      offer.oc_is_combo_free == 'Y' &&
      offer.oc_is_combo_with_fnb == 'N'
    ) {
      let TotalSeats = reservationDetails.selectedSeats.length;

      if (TotalSeats >= offer.oc_buy_no_of_ticket) {
        let offerFnbItem = fnbItems.filter(
          (x) => x.item_code == offer.oc_get_item_code,
        );

        let getItemCount = 0;
        selectedFnbItems.forEach((x) => {
          if (
            x.item_id == offer.oc_get_item_id ||
            x.item_code == offer.oc_get_item_code
          ) {
            getItemCount += x.quantity;
          }
        });
        let totalFreeItemQty = 0;
        if (
          offer.oc_buy_no_of_ticket == offer.oc_get_item_count &&
          offer.oc_buy_item_count > 1
        ) {
          totalFreeItemQty = parseInt(
            Math.floor(
              TotalSeats /
                parseInt(offer.oc_buy_no_of_ticket + offer.oc_get_item_count),
            ) * offer.oc_get_item_count,
          );
        } else {
          totalFreeItemQty = parseInt(
            Math.floor(TotalSeats / parseInt(offer.oc_buy_no_of_ticket)) *
              offer.oc_get_item_count,
          );
        }

        if (offerFnbItem.length > 0) {
          let itemQty = totalFreeItemQty || 1;
          let checkAlreadyAddedItem = selectedFnbItems.findIndex(
            (x) => x.item_code == offer.oc_get_item_code,
          );
          if (checkAlreadyAddedItem > -1) {
            if (selectedFnbItems[checkAlreadyAddedItem].Qty == itemQty) {
              // DO NOTHING
            } else {
              selectedFnbItems[checkAlreadyAddedItem].Qty += itemQty;
              selectedFnbItems[checkAlreadyAddedItem].quantity += itemQty;
              selectedFnbItems[checkAlreadyAddedItem].Quantity += itemQty;
            }
          } else {
            let fnbObj = {
              ...offerFnbItem[0],
              Qty: itemQty,
              quantity: itemQty,
              Quantity: itemQty,
            };
            selectedFnbItems.push(fnbObj);
          }

          let FinalFNBArray = [];
          if (selectedFnbItems.length > 0) {
            selectedFnbItems.forEach((x) => {
              let TotalPrice = +parseFloat(x.fp_total_amount).toFixed(4) * x.Qty;
              let TotalTax =
                +parseFloat(x.fp_total_amount - x.fp_amount).toFixed(4) * x.Qty;

              FNbPrice['total_price'] = +parseFloat(
                FNbPrice['total_price'] + TotalPrice,
              ).toFixed(4);
              FNbPrice['total_tax'] = +parseFloat(
                FNbPrice['total_tax'] + TotalTax,
              ).toFixed(4);
              let obj = {
                ...x,
                Quantity: x.Qty,
                quantity: x.Qty,
                total_price: TotalPrice,
                total_tax: TotalTax,
              };
              FinalFNBArray.push(obj);
              totalAmount = +parseFloat(
                parseFloat(totalAmount) + parseFloat(TotalPrice),
              ).toFixed(4);
            });

            await onUpdateFnbCartItems(
              FinalFNBArray,
              reservation_id,
              FNbPrice,
              setappliedPromotion,
            );
          }
        } else {
          // resetting voucher/offer here
          await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
        }
      } else {
        // resetting voucher/offer here
        await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
      }
    } else if (
      offer.oc_is_combo == 'Y' &&
      offer.oc_is_combo_free == 'N' &&
      offer.oc_is_combo_with_fnb == 'N'
    ) {
      let TotalSeats = reservationDetails.selectedSeats.length;

      if (TotalSeats >= offer.oc_buy_no_of_ticket) {
        let offerFnbItem = fnbItems.filter(
          (x) => x.item_code == offer.oc_get_item_code,
        );

        let getItemCount = 0;
        selectedFnbItems.forEach((x) => {
          if (
            x.item_id == offer.oc_get_item_id ||
            x.item_code == offer.oc_get_item_code
          ) {
            getItemCount += x.quantity;
          }
        });
        let totalFreeItemQty = 0;
        if (
          offer.oc_buy_no_of_ticket == offer.oc_get_item_count &&
          offer.oc_buy_item_count > 1
        ) {
          totalFreeItemQty = parseInt(
            Math.floor(
              TotalSeats /
                parseInt(offer.oc_buy_no_of_ticket + offer.oc_get_item_count),
            ) * offer.oc_get_item_count,
          );
        } else {
          totalFreeItemQty = parseInt(
            Math.floor(TotalSeats / parseInt(offer.oc_buy_no_of_ticket)) *
              offer.oc_get_item_count,
          );
        }

        if (offerFnbItem.length > 0) {
          let itemQty = totalFreeItemQty || 1;
          let checkAlreadyAddedItem = selectedFnbItems.findIndex(
            (x) => x.item_code == offer.oc_get_item_code,
          );
          if (checkAlreadyAddedItem > -1) {
            if (selectedFnbItems[checkAlreadyAddedItem].Qty == itemQty) {
              // DO NOTHING
            } else {
              selectedFnbItems[checkAlreadyAddedItem].Qty += itemQty;
            }
          } else {
            let fnbObj = {
              ...offerFnbItem[0],
              Qty: itemQty,
            };
            selectedFnbItems.push(fnbObj);
          }

          let FinalFNBArray = [];
          if (selectedFnbItems.length > 0) {
            selectedFnbItems.forEach((x) => {
              let TotalPrice = +parseFloat(x.fp_total_amount).toFixed(4) * x.Qty;
              let TotalTax =
                +parseFloat(x.fp_total_amount - x.fp_amount).toFixed(4) * x.Qty;

              FNbPrice['total_price'] = +parseFloat(
                FNbPrice['total_price'] + TotalPrice,
              ).toFixed(4);
              FNbPrice['total_tax'] = +parseFloat(
                FNbPrice['total_tax'] + TotalTax,
              ).toFixed(4);
              let obj = {
                ...x,
                Quantity: x.Qty,
                quantity: x.Qty,
                total_price: TotalPrice,
                total_tax: TotalTax,
              };
              FinalFNBArray.push(obj);
              totalAmount = +parseFloat(
                parseFloat(totalAmount) + parseFloat(TotalPrice),
              ).toFixed(4);
            });

            await onUpdateFnbCartItems(
              FinalFNBArray,
              reservation_id,
              FNbPrice,
              setappliedPromotion,
            );
          }
        } else {
          // resetting voucher/offer here
          await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
        }
      } else {
        // resetting voucher/offer here
        await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

        console.log(
          'Please select atleast ' + offer.oc_buy_no_of_ticket + ' ticket(s)',
        );
      }
    } else if (
      offer.oc_is_combo == 'Y' &&
      offer.oc_is_combo_free == 'Y' &&
      offer.oc_is_combo_with_fnb == 'Y'
    ) {
      let array = selectedFnbItems.filter((singleItem) => {
        return (
          (singleItem.item_id == offer.oc_buy_item_id ||
            singleItem.item_code == offer.oc_buy_item_code) &&
          singleItem.Qty >= offer.oc_buy_item_count
        );
      });

      let toBuyItem = fnbItems.filter(
        (x) =>
          x.item_id == offer.oc_buy_item_id ||
          x.item_code == offer.oc_buy_item_code,
      );
      let offerFnbItemIndex = selectedFnbItems.findIndex(
        (fnbItem) =>
          (offer.oc_get_item_code && fnbItem.item_code == offer.oc_get_item_code) ||
          fnbItem.item_id == offer.oc_get_item_id,
      );

      let itemCount = 0;
      selectedFnbItems.forEach((x) => {
        if (
          x.item_id == offer.oc_buy_item_id ||
          x.item_code == offer.oc_buy_item_code
        ) {
          itemCount += x.quantity;
        }
      });

      let totalFreeItemQty = 0;
      if (
        offer.oc_buy_item_count == offer.oc_get_item_count &&
        offer.oc_buy_item_count > 1
      ) {
        totalFreeItemQty = parseInt(
          Math.floor(
            itemCount / parseInt(offer.oc_buy_item_count + offer.oc_get_item_count),
          ) * offer.oc_get_item_count,
        );
      } else {
        totalFreeItemQty = parseInt(
          Math.floor(itemCount / parseInt(offer.oc_buy_item_count)) *
            offer.oc_get_item_count,
        );
      }

      if (array && array.length > 0) {
        let offerFnbItem = this.fnbItems.filter(
          (x) =>
            (offer.oc_get_item_code && x.item_code == offer.oc_get_item_code) ||
            x.item_id == offer.oc_get_item_id,
        );
        let getItemCount = 0;
        selectedFnbItems.forEach((x) => {
          if (
            x.item_id == offer.oc_get_item_id ||
            x.item_code == offer.oc_get_item_code
          ) {
            getItemCount += x.quantity;
          }
        });
        if (offerFnbItem.length > 0) {
          let itemQty = totalFreeItemQty || 1;
          let checkAlreadyAddedItem = selectedFnbItems.findIndex(
            (x) => x.item_code == offer.oc_get_item_code,
          );
          if (checkAlreadyAddedItem > -1) {
            if (selectedFnbItems[checkAlreadyAddedItem].Qty == itemQty) {
              // DO NOTHING
            } else {
              selectedFnbItems[checkAlreadyAddedItem].Qty += itemQty;
            }
          } else {
            let fnbObj = {
              ...offerFnbItem[0],
              Qty: itemQty,
            };
            selectedFnbItems.push(fnbObj);
          }

          let FinalFNBArray = [];
          if (selectedFnbItems.length > 0) {
            selectedFnbItems.forEach((x) => {
              let TotalPrice = +parseFloat(x.fp_total_amount).toFixed(4) * x.Qty;
              let TotalTax =
                +parseFloat(x.fp_total_amount - x.fp_amount).toFixed(4) * x.Qty;

              FNbPrice['total_price'] = +parseFloat(
                FNbPrice['total_price'] + TotalPrice,
              ).toFixed(4);
              FNbPrice['total_tax'] = +parseFloat(
                FNbPrice['total_tax'] + TotalTax,
              ).toFixed(4);
              let obj = {
                ...x,
                Quantity: x.Qty,
                quantity: x.Qty,
                total_price: TotalPrice,
                total_tax: TotalTax,
              };
              FinalFNBArray.push(obj);
              totalAmount = +parseFloat(
                parseFloat(totalAmount) + parseFloat(TotalPrice),
              ).toFixed(4);
            });

            await onUpdateFnbCartItems(
              FinalFNBArray,
              reservation_id,
              FNbPrice,
              setappliedPromotion,
            );
          }
        } else {
          // resetting voucher/offer here
          await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
          console.log('NO FNB ITEM FOUND');
        }
      } else {
        let offerFnbItem = fnbItems.filter(
          (x) =>
            (offer.oc_get_item_code && x.item_code == offer.oc_get_item_code) ||
            x.item_id == offer.oc_get_item_id,
        );
        let msg = `Offer not applicable on selected items`;
        if (toBuyItem && toBuyItem.length > 0) {
          msg =
            'Offer is applicable on puchasing' +
            offer.oc_buy_item_count +
            ' ' +
            toBuyItem[0].item_name;
        }
        // resetting voucher/offer here
        await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
      }
    } else if (
      offer.oc_is_combo == 'Y' &&
      offer.oc_is_combo_free == 'N' &&
      offer.oc_is_combo_with_fnb == 'Y'
    ) {
      let array = selectedFnbItems.filter((singleItem) => {
        return (
          (singleItem.item_id == offer.oc_buy_item_id ||
            singleItem.item_code == offer.oc_buy_item_code) &&
          singleItem.quantity >= offer.oc_buy_item_count
        );
      });
      let toBuyItem = fnbItems.filter(
        (x) =>
          x.item_id == offer.oc_buy_item_id ||
          x.item_code == offer.oc_buy_item_code,
      );
      let offerFnbItemIndex = selectedFnbItems.findIndex(
        (fnbItem) =>
          (offer.oc_get_item_code && fnbItem.item_code == offer.oc_get_item_code) ||
          fnbItem.item_id == offer.oc_get_item_id,
      );

      let itemCount = 0;
      selectedFnbItems.forEach((x) => {
        if (
          x.item_id == offer.oc_buy_item_id ||
          x.item_code == offer.oc_buy_item_code
        ) {
          itemCount += x.quantity;
        }
      });

      let totalFreeItemQty = 0;
      if (
        offer.oc_buy_item_count == offer.oc_get_item_count &&
        offer.oc_buy_item_count > 1
      ) {
        totalFreeItemQty = parseInt(
          Math.floor(
            itemCount / parseInt(offer.oc_buy_item_count + offer.oc_get_item_count),
          ) * offer.oc_get_item_count,
        );
      } else {
        totalFreeItemQty = parseInt(
          Math.floor(itemCount / parseInt(offer.oc_buy_item_count)) *
            offer.oc_get_item_count,
        );
      }

      if (array && array.length > 0) {
        let offerFnbItem = fnbItems.filter(
          (x) =>
            (offer.oc_get_item_code && x.item_code == offer.oc_get_item_code) ||
            x.item_id == offer.oc_get_item_id,
        );
        let getItemCount = 0;
        selectedFnbItems.forEach((x) => {
          if (
            x.item_id == offer.oc_get_item_id ||
            x.item_code == offer.oc_get_item_code
          ) {
            getItemCount += x.quantity;
          }
        });

        if (offerFnbItem.length > 0) {
          let itemQty = totalFreeItemQty || 1;
          let checkAlreadyAddedItem = selectedFnbItems.findIndex(
            (x) => x.item_code == offer.oc_get_item_code,
          );
          if (checkAlreadyAddedItem > -1) {
            if (selectedFnbItems[checkAlreadyAddedItem].Qty == itemQty) {
              // DO NOTHING
            } else {
              selectedFnbItems[checkAlreadyAddedItem].Qty += itemQty;
            }
          } else {
            let fnbObj = {
              ...offerFnbItem[0],
              Qty: itemQty,
            };
            selectedFnbItems.push(fnbObj);
          }

          let FinalFNBArray = [];
          if (selectedFnbItems.length > 0) {
            selectedFnbItems.forEach((x) => {
              let TotalPrice = +parseFloat(x.fp_total_amount).toFixed(4) * x.Qty;
              let TotalTax =
                +parseFloat(x.fp_total_amount - x.fp_amount).toFixed(4) * x.Qty;

              FNbPrice['total_price'] = +parseFloat(
                FNbPrice['total_price'] + TotalPrice,
              ).toFixed(4);
              FNbPrice['total_tax'] = +parseFloat(
                FNbPrice['total_tax'] + TotalTax,
              ).toFixed(4);
              let obj = {
                ...x,
                Quantity: x.Qty,
                quantity: x.Qty,
                total_price: TotalPrice,
                total_tax: TotalTax,
              };
              FinalFNBArray.push(obj);
              totalAmount = +parseFloat(
                parseFloat(totalAmount) + parseFloat(TotalPrice),
              ).toFixed(4);
            });

            await onUpdateFnbCartItems(
              FinalFNBArray,
              reservation_id,
              FNbPrice,
              setappliedPromotion,
            );
          }
        } else {
          // resetting voucher/offer here
          await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);
          console.log('NO FNB ITEM FOUND');
        }
      } else {
        // resetting voucher/offer here
        await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

        let msg = `Offer not applicable on selected items`;
        if (toBuyItem && toBuyItem.length > 0) {
          msg =
            'Offer is applicable on puchasing' +
            offer.oc_buy_item_count +
            ' ' +
            toBuyItem[0].item_name;
        }
      }
    }
  }
};

export const verifyPromotion =
  (selected_promotion, reservation_id, reservationDetails, promotionPayload) =>
  async (dispatch, getState) => {
    try {
      await PromotionsService.ResetVoucherAndOfferInReservation(reservation_id);

      let { data } = await PromotionsService.VerifyPromotion(promotionPayload);

      if (data.status) {
        // dispatch appliedPromotion
        let setappliedPromotion = () =>
          dispatch({ type: 'SET_APPLIED_PROMOTION', payload: data });
        setappliedPromotion();

        let updateObj = {
          reservation_id,
        };

        if (data.Records && data.Records.length > 0) {
          let OfferDetails = data.Records[0];
          if (OfferDetails && [3, 9].includes(OfferDetails.offer_type_id)) {
            try {
              let all_fnb_items = [];
              const { data: all_fnb_data_resp } = await FnbService.GetAllFnbItems({
                category: null,
                aggregator_cinema_id:
                  reservationDetails?.cinema_id ?? reservationDetails?.cine_id,
                cinema_id:
                  reservationDetails?.cinema_id ?? reservationDetails?.cine_id,
                isCombo: false,
                isEgg: '',
                isVeg: '',
                search: '',
                subCategory: null,
                reservation_id,
              }).catch((e) => {
                all_fnb_items = [];
              });

              if (all_fnb_data_resp.status) {
                all_fnb_items = all_fnb_data_resp?.Records?.Records ?? [];
              }

              if (all_fnb_items.length > 0) {
                await calculateOfferDetails(
                  OfferDetails,
                  reservationDetails,
                  reservation_id,
                  all_fnb_items,
                  setappliedPromotion,
                );
              }
            } catch (e) {
              console.log('calculateOfferDetails error:', e);
            }
          }
          updateObj['oc_id'] = OfferDetails.oc_id;
          updateObj['offer_promotion_payload'] = JSON.stringify(promotionPayload);

          let { data: saveData } =
            await PromotionsService.SavePromotionInReservation(updateObj);

          toast.success(data.message);
        }
      } else {
        // reset dispatch appliedPromotion
        dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });

        toast.error(data.message);
      }
      return {
        is100PercentOff:
          data?.BoxOffice?.actualTotalGross === 0 ||
          data?.BoxOffice?.actualTotalGross < 0,
        status: data.status,
      };
    } catch (err) {
      // reset dispatch appliedPromotion
      dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });

      console.log({ voucherPayloadErr: err });
      return {
        is100PercentOff: false,
        status: false,
        message: err.message,
      };
    }
  };

const calculateVoucherDiscount = ({ selectedVoucher, totalAmount, netAmount }) => {
  let voucherDiscount = 0;

  if (selectedVoucher.voucher_is_percentage === 'Y') {
    voucherDiscount =
      selectedVoucher.voucher_redeem_on_gross === 'Y'
        ? (selectedVoucher.voucher_amount / 100) * totalAmount
        : selectedVoucher.voucher_redeem_on_net === 'Y'
        ? (selectedVoucher.voucher_amount / 100) * netAmount
        : 0;
  } else {
    voucherDiscount = selectedVoucher.voucher_amount;
  }

  if (voucherDiscount > selectedVoucher.voucher_max_redemption_amount) {
    voucherDiscount = selectedVoucher.voucher_max_redemption_amount;
  } else if (voucherDiscount < selectedVoucher.voucher_min_redemption_amount) {
    voucherDiscount = selectedVoucher.voucher_min_redemption_amount;
  }

  voucherDiscount = _.round(voucherDiscount, 2);

  return voucherDiscount;
};

export const clearSelectedVoucher = () => async (dispatch) =>
  dispatch({ type: CLEAR_SELECTED_VOUCHER });

export const addUserToSegment = (payload) => async (dispatch) => {
  dispatch({ type: DISABLE_ADD_USER_TO_SEGMENT });
  try {
    let { data } = await PromotionsService.AddCustomerToSegment(payload);

    if (payload.segment_type !== 'VIEW') {
      dispatch({
        type: ADD_USER_TO_SEGMENT_SUCCESS,
        payload: i18n.t('Thank you for showing interest. We will inform you!'),
      });
    }
  } catch (err) {
    if (payload.segment_type !== 'VIEW') {
      if (err?.response?.data?.message === 'Already exist') {
        // toast.success(
        //   i18n.t("Thank you for showing interest. We will inform you!")
        // );
        dispatch({
          type: ADD_USER_TO_SEGMENT_SUCCESS,
          payload: i18n.t('Thank you for showing interest. We will inform you!'),
        });
      }
    }
  }
  dispatch({ type: ENABLE_ADD_USER_TO_SEGMENT });
};
