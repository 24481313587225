import React, { useState, useEffect, useCallback } from 'react';

const mobileWidth = 768;

function useIsMobile() {
  const [mobile, setMobile] = useState(window.innerWidth < 768);

  const resizeEventHandler = useCallback(() => {
    if (window.innerWidth < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= mobileWidth) {
      setMobile(false);
    } else {
      setMobile(true);
    }

    window.addEventListener('resize', resizeEventHandler);

    return () => window.removeEventListener('resize', resizeEventHandler);
  }, [setMobile]);

  return mobile;
}

export default useIsMobile;
