import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation, Link } from 'react-router-dom';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import CivilMall from '@assets/pictures/Civil_mall.png';
import LabimMall from '@assets/pictures/Labim_Mall.png';
import ChhayaCenter from '@assets/pictures/Chhaya_center.png';
import Jalma from '@assets/pictures/Jalma.png';
import Bageshwori from '@assets/pictures/BageshWori.png';
import OneStopMall from '@assets/pictures/One_stop_mall.png';
import Bhaktapur from '@assets/pictures/bhaktapur.png';
import Butwal from '@assets/pictures/butwal.png';
import BiratNagar from '@assets/pictures/Birtnagar.png';
import Damauli from '@assets/pictures/Damauli.jpg';
import Durbar from '@assets/pictures/Durbar.png';

import rightArrow from '@assets/pictures/svgs/right-white-arrow.svg';
import leftArrow from '@assets/pictures/svgs/left-arrow.svg';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
const Advertise = ({ t }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const history = useHistory();
  return (
    <>
      <div className="static_page align_new">
        <div className="left_right_align">
          {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Theater Innovation')} /> */}
          <CenteredTitle firstHalf={t('Reaching Out To Regions')} />
        </div>

        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <h4 className="static_page_highlight_custom mb-4 mb-xl-5 text-primary">
              {t(
                'We serve a diverse community, bringing the best of technology and amenities to different regional demographics and tastes!',
              )}
            </h4>

            {/* First Row */}
            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={CivilMall} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX CIVIL MALL
                </h3>
                <h5 className="font-weight-bold text-primary">
                  Sundhara, Kathmandu
                </h5>
                {/* <p>
                  Complementing the QFX experience is food and drink. We know you
                  like to try new food flavours, served fresh!
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/CivilMall')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Second Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`read_more_section font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX LABIM MALL
                </h3>
                <h5 className="font-weight-bold text-primary">
                  Pulchowk, Lalitpur
                </h5>
                {/* <p>
                  Special new flavours to tickle your taste buds! QFX cinemas offer
                  a variety of freshly made popcorn to enhance your viewing
                  pleasure.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/LabimMall')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={LabimMall} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Third Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={ChhayaCenter} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX CHHAYA CENTER
                </h3>
                <h5 className="font-weight-bold text-primary">Thamel, Kathmandu</h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/ChhayaCenter')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Fourth Row */}

            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX JALMA
                </h3>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                <h5 className="font-weight-bold text-primary">Narayangarh</h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/JalmaMall')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Jalma} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Fifth Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Bageshwori} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX BAGESHWORI
                </h3>
                <h5 className="font-weight-bold text-primary">Nepalgunj</h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Bageshwori')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Sixth Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX ONE STOP MALL
                </h3>
                <h5 className="font-weight-bold text-primary">Birtamode</h5>
                {/* <p>
                  Special new flavours to tickle your taste buds! QFX cinemas offer
                  a variety of freshly made popcorn to enhance your viewing
                  pleasure.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/OneStopMall')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={OneStopMall} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Sevennth Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Bhaktapur} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX Bhaktapur
                </h3>
                <h5 className="font-weight-bold text-primary">Bhaktapur</h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Bhaktapur')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Eight Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX Butwal
                </h3>
                <h5 className="font-weight-bold text-primary">Butwal</h5>
                {/* <p>
                  Special new flavours to tickle your taste buds! QFX cinemas offer
                  a variety of freshly made popcorn to enhance your viewing
                  pleasure.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Butwal')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Butwal} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Ninth Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={BiratNagar} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX Biratnagar
                </h3>
                <h5 className="font-weight-bold text-primary">Biratnagar</h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/BiratNagar')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>

            {/* Tenth Row */}
            <div className={`row ${!isMobile ? 'mb-4' : ''}`}>
              <div
                className={` ${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  QFX Apex Mall
                </h3>
                <h5 className="font-weight-bold text-primary">Damauli</h5>
                {/* <p>
                  Special new flavours to tickle your taste buds! QFX cinemas offer
                  a variety of freshly made popcorn to enhance your viewing
                  pleasure.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Damauli')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>

              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Damauli} className="img-fluid custom_img_about" />
              </div>
            </div>

            {/* Eleventh Row */}

            <div className={`row ${isMobile ? 'flex-column-reverse' : 'mb-4'}`}>
              <div
                className={`${isMobile || isTablet ? 'mt-2' : ''} ${
                  !isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'
                }`}
              >
                <img src={Durbar} className="img-fluid custom_img_about" />
              </div>

              <div
                className={`${
                  !isMobile ? 'd-flex justify-content-center flex-column' : ''
                } ${!isTablet ? 'col-xl-6 col-md-12' : 'col-md-6'}`}
              >
                <h3
                  className={`color-black text-uppercase font-weight-bold ${
                    isMobile || isTablet ? 'mt-4' : ''
                  }`}
                >
                  Durbar Cinemax
                </h3>
                <h5 className="font-weight-bold text-primary">
                  Durbar Marg, Kathmandu
                </h5>
                {/* <p className='font-weight-bold'>Fill your senses with wonder!</p> */}
                {/* <p>
                  How about a soft drink while you watch? So many to choose from -
                  that vary with all seasons and moods.
                </p> */}
                {/* <span
                  className="mt-2 read_more_section"
                  onClick={() => history.push('/Durbar')}
                >
                  READ MORE
                  <img className="ml-2" src={rightArrow} />
                </span> */}
              </div>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
