import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import close from '@assets/pictures/svgs/close.svg';
import { ReactComponent as ScreenSl } from '@assets/pictures/svgs/screen-sl.svg';
import standardSeat from '@assets/pictures/svgs/seat-standard.svg';
import selectedSeat from '@assets/pictures/svgs/seat-standard-selected.svg';
import unavailableSeat from '@assets/pictures/svgs/seat-standard-unavailable.svg';
import slEntrance from '@assets/pictures/svgs/sl-entrance.svg';
import slEntranceDual from '@assets/pictures/svgs/sl-entrance-dual.svg';

import { slImageOnhover } from '../../../helper/formatting.js';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../Spinner';
import { getSeatIconUrl } from '@src/helper';

export default function SeatTypePopup(props) {
  const { loader } = props;
  useEffect(slImageOnhover);
  const seatLayout = useSelector((state) => state.ancilliary.audiSeatsLayout);
  const organization = useSelector((state) => state.movies.organizationDetails);
  const { org_kiosk_screen_image_url } = organization;

  let [indexRow, setIndexRow] = useState([]);

  useEffect(() => {
    let indexRow = [];
    let indexFromName = [];

    let name = seatLayout?.seatName?.[0] || [];
    let fstRow = seatLayout?.seats?.[0] || [];

    fstRow.forEach((s, index) => {
      indexFromName.push(...(s?.sl_seat_name?.split(name)?.[1] || ''));
      indexRow.push(++index);
    });

    setIndexRow(indexFromName?.[0] == '1' ? indexRow : indexRow.reverse());
  }, [seatLayout]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="seat-layout-popup"
      centered
      onHide={() => props.closeFunc(false)}
    >
      <section className="wrapper container-fluid">
        {loader ? (
          <div className="row my-5">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <img
              src={close}
              className="close-btn-main"
              onClick={() => props.closeFunc(false)}
              alt=""
            />

            <section className="row title-row">
              <div className="col-12">
                <p className="title">
                  {seatLayout &&
                    seatLayout.screenDetails &&
                    seatLayout.screenDetails.screen_name}
                </p>
              </div>
            </section>

            <section className="row">
              <div className="px-3 mx-auto">
                <div className="seat-layout-screen">
                  {/* <img
                    src={org_kiosk_screen_image_url || screenSl}
                    className="img-fluid"
                    alt=""
                  /> */}
                  <ScreenSl />
                </div>
              </div>
            </section>

            <section className="row">
              <div className="col">
                <div className="table-responsive">
                  <table className="master-table table table-borderless">
                    {[1].map((index) => (
                      <tr key={index}>
                        <td className="category">
                          <tr>
                            {/* <td className="category-price">
                          <p>standard</p>
                          <p>$40</p>
                        </td>

                        <td className="category-range"></td> */}

                            <table className="actual-table">
                              <tr>
                                <td>
                                  <table>
                                    {seatLayout &&
                                      seatLayout.seatName &&
                                      seatLayout.seatName.map((name, index) => (
                                        <>
                                          <tr key={index}>
                                            <td className="">
                                              <p
                                                className="number_seat"
                                                style={{ color: 'transparent' }}
                                              >
                                                <>&nbsp;</>
                                              </p>
                                              <div className="cell">
                                                {name.includes('XXX-') ? '' : name}
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                  </table>
                                </td>
                                <td>
                                  <table className={'seats_table'}>
                                    {seatLayout &&
                                      seatLayout.seats &&
                                      seatLayout.seats.map((seatrow, index) => (
                                        <tr key={index}>
                                          {seatrow.map((row, index) => (
                                            <td className="" key={index}>
                                              <p className="number_seat">
                                                {row.sl_seat_name}
                                              </p>
                                              {row.sl_seat_name == '' ? (
                                                <div className="cell"></div>
                                              ) : (
                                                <div
                                                  className={`cell ${
                                                    !!row?.srt_online_image_url
                                                      ? ''
                                                      : 'seat'
                                                  }`}
                                                >
                                                  {!!row?.srt_online_image_url && (
                                                    <img
                                                      src={getSeatIconUrl(
                                                        row?.srt_online_image_url,
                                                      )}
                                                      width="24px"
                                                      height="24px"
                                                    />
                                                  )}
                                                </div>
                                              )}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                  </table>
                                </td>
                                {/* <td>
                              <table>
                                {[1, 2, 3, 4, 5].map((index) => (
                                  <tr>
                                    <td className="">
                                      <div className="cell">{index}</div>
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td> */}
                              </tr>
                            </table>
                          </tr>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      {/* <td colSpan="3">
                    <div className="seat-type">
                      <p className="seat-type">
                        standard
                        <span className="hover-image-span">
                          <img src={infoCircle} id="hover-image" />
                          <div className="seat-type-image">
                            <img src={cinemaBanner} />
                          </div>
                        </span>
                      </p>
                    </div>
                  </td> */}
                    </tr>
                  </table>
                </div>
              </div>
            </section>

            {false && (
              <section className="row">
                <div className="px-3 mx-auto entrance">
                  <div className="">
                    <img src={slEntranceDual} alt="" />
                  </div>
                  <div className="">
                    <img src={slEntrance} alt="" />
                  </div>
                  <p>Entrance</p>
                </div>
              </section>
            )}

            <section className="row">
              <div className="px-3 seat-layout-icons">
                {/*<p className="">
                  <img src={standardSeat} className="mr-3" alt="" />
                  Available
                </p>
                <p className="">
                  <img src={unavailableSeat} className="mr-3" alt="" />
                  Unavailable
                </p>
                <p>
                  <img src={selectedSeat} className="mr-3" alt="" />
                  Selected
                </p> */}
              </div>
            </section>
          </>
        )}
      </section>
    </Modal>
  );
}
