import React, { useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router';

// Components
import Button from '@components/partials/Button';
import { currencyFormatter } from '../../../helper/currencyFormatter';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchScheduleDetails,
  setOndemandScreeningType,
} from '../../../store/movies/movieActions';
import {
  fetchSeatLayout,
  privateScreeningReserveSeat,
} from '../../../store/seatLayout/seatLayoutActions';
import Spinner from '../../partials/Spinner';
import SpecialInstructionsPopup from '../../partials/popups/SpecialInstructionsPopup';
import SpecialInstructionsPopupRegular from '../../partials/popups/SpecialInstructionsPopupRegular';
import { useHistory } from 'react-router-dom';

const BookingScreening = (props) => {
  const {
    t,
    classes,
    type,
    onClickNext,
    screeningType,
    setShowPrivateScreenPopup,
  } = props;
  const dispatch = useDispatch();

  const currency = useSelector((state) => state.movies.currency);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const { curr_code, curr_id } = currency;

  const assigner = (scr, type) => {
    if (type === 'name') {
      if (scr.booking_type_id === 2) {
        return t('Crowdsourced Screening');
      } else if (scr.booking_type_id === 1) {
        return t('Confirmed Screening');
      } else if (scr.booking_type_id === 3) {
        return t('Private Screening');
      } else {
        return t('Confirmed Screening');
      }
    } else if (type === 'desc') {
      if (scr.booking_type_id === 2) {
        return `${t('This screening will get confirmed when atleast')} ${
          scr.min_seats
        } ${t('seats have been booked')}`;
      } else if (scr.booking_type_id === 1) {
        return `${t(
          'No need to wait for the minimum no. of seats to sell. Book',
        )} ${scr.min_seats} ${t('seats and confirm immediately')}`;
      } else if (scr.booking_type_id === 3) {
        return `${t(
          'Book an exclusive private screening for your friends and family',
        )}`;
      } else {
        return `${t(
          'No need to wait for the minimum no. of seats to sell. Book',
        )} ${'25'} ${t('seats and confirm immediately')}`;
      }
    }
  };

  const onSelectScreeningType = () => {
    // get seatlayout list
    dispatch(setOndemandScreeningType(screeningType));
    // dispatch(clearSelectedVoucher());

    setTimeout(() => {
      if (scheduleDetails && scheduleDetails.mycinema_ss_d_id)
        dispatch(
          fetchSeatLayout({
            mycinema_ss_d_id: scheduleDetails.mycinema_ss_d_id,
          }),
        )
          .then(() => {
            if (screeningType.booking_type_id === 3) {
              setShowPrivateScreenPopup && setShowPrivateScreenPopup(true);
            } else {
              onClickNext();
            }

            return dispatch(
              fetchScheduleDetails({
                mycinema_ss_d_id: scheduleDetails.mycinema_ss_d_id,
              }),
            );
          })
          .catch(console.log);
    }, 100);
  };

  return (
    <>
      <article className={`booking_screening ${type} ${classes}`}>
        <h2>{assigner(screeningType, 'name')?.toUpperCase()}</h2>
        <h6>{assigner(screeningType, 'desc')}</h6>
        <h4>
          {t('Starting')} {curr_code} {currencyFormatter(screeningType?.amount)}
        </h4>
        <Button
          text={t('common.Select')}
          classes="mx-auto"
          onClick={() => {
            onSelectScreeningType();
          }}
        />
      </article>
    </>
  );
};

// Default Props
BookingScreening.defaultProps = {
  classes: '',
  type: '',
  onClickNext: () => {},
};

export default withNamespaces()(BookingScreening);
