import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import { useParams } from 'react-router';

// Components
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import placeholderBanner from '@assets/pictures/banner-placeholder-new.png';
import reel from '@assets/pictures/svgs/reel.svg';
import { useGetMiniFestivalQuery } from '@store/rtk/rtk.movieLists';
import {
  getHeroBlurBackDimensions,
  getHeroBlurBackStyle,
} from '@components/partials/Banner';

// Images

const NewMiniFestivals = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [currentPage, setCurrentPage] = useState(1);
  const [category_link_name, setCategoryName] = useState(null);
  const [limit, setLimit] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { mini_fest_id } = useParams();
  const [miniFestId, setMiniFestId] = useState(mini_fest_id || null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_id } = selected_language;

  const [miniFestPayload, setMiniFestPayload] = useState({
    currentPage,
    category_link_name: null,
    index: null,
    category_id: 3,
    miniFestId,
  });

  const { data: miniFestivalsData, isLoading: miniFestivalsLoading } =
    useGetMiniFestivalQuery(miniFestPayload);

  useEffect(() => {
    if (miniFestivalsData && miniFestivalsData?.Records?.length > 0 && miniFestId) {
      setTimeout(() => {
        let element = document.querySelector(`#fest-${miniFestId}`);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
          setMiniFestId(null);
        }
      }, 500);
    }
  }, [miniFestivalsData]);

  const selectedFestival = miniFestivalsData?.Records?.filter(
    (x) => x.unique_id == miniFestId,
  );
  const otherFestivals = miniFestivalsData?.Records?.filter(
    (x) => x.unique_id != miniFestId,
  );

  const heroSlider = {
    pauseOnFocus: true,
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setMiniFestPayload({ ...miniFestPayload, miniFestId });
  }, [currentPage, miniFestId]);

  useEffect(() => {
    if (!!mini_fest_id) setMiniFestId(mini_fest_id);
  }, [mini_fest_id]);

  return (
    <>
      {/* {selectedFestival?.length > 0 && (
        <MiniFestivalsBanner festival={selectedFestival} />
      )} */}

      {selectedFestival && selectedFestival?.length > 0 && (
        <Slider {...heroSlider} className="home_banner">
          {selectedFestival?.length > 0 &&
            selectedFestival?.map((festival, index) => (
              <div className="" key={index}>
                <div
                  className="blur"
                  style={getHeroBlurBackStyle(
                    festival,
                    lang_id,
                    isMobile,
                    festival?.category_banner_image ||
                      festival?.category_link_image,
                  )}
                  onClick={() => {}}
                >
                  <motion.section
                    className="hero-section"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    style={getHeroBlurBackDimensions(isMobile, true)}
                  >
                    <article className="hero-img-section">
                      <img
                        src={
                          festival?.category_banner_image ||
                          festival?.category_link_image
                        }
                        alt="Movie Banner"
                        className={`${isMobile ? 'hero-img-1' : 'hero-img-mini'}`}
                      />
                    </article>
                    <article className="hero-text align-items-end">
                      <div className="container-fluid">
                        <h6>{t('Mini Festivals and Events')}</h6>
                        <h1>
                          {getLangSpecificAttribute(
                            festival?.language_content,
                            lang_id,
                            'event_name',
                            'lang_id',
                          ) || festival?.category_link_name}
                        </h1>
                        {!isMobile && (
                          <p className="text-limit-1 mb-md-3">
                            {getLangSpecificAttribute(
                              festival?.language_content,
                              lang_id,
                              'event_description',
                              'lang_id',
                            ) || festival?.event_description}
                          </p>
                        )}
                      </div>
                    </article>
                  </motion.section>
                </div>
              </div>
            ))}
        </Slider>
      )}

      {(!!miniFestivalsLoading ||
        !selectedFestival ||
        selectedFestival?.length == 0) && (
        <Slider {...heroSlider} className="home_banner">
          <div className="">
            <section className="hero-section" onClick={() => {}}>
              <article className="hero-img-section">
                <img
                  src={placeholderBanner}
                  alt="Movie Banner"
                  className={`${isMobile ? 'hero-img-1' : 'hero-img-mini'}`}
                />
              </article>
              <article className="hero-text align-items-end">
                <div className="container-fluid">
                  <div className="container-fluid container-xl text-center">
                    <section className="placeholder-banner">
                      <figure className="">
                        <img src={reel} alt="" />
                      </figure>
                      <p className="">
                        {/*<img src={clockWhite} alt="" className="mr-2" />*/}
                        {/*{t("Loading")} ...*/}
                      </p>
                    </section>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </Slider>
      )}

      <div className="container-fluid">
        {/* Mini Festivals */}
        {selectedFestival?.length > 0 &&
          selectedFestival?.map((festival, index) => (
            <MoviesNFestsNew
              key={index}
              section={5}
              moviesArray={festival?.movie_data?.data}
              subCategory={
                getLangSpecificAttribute(
                  festival?.language_content,
                  lang_id,
                  'event_name',
                  'lang_id',
                ) || festival?.category_link_name
              }
              category={''}
              movieCount={festival?.movie_data?.pagination?.total}
              slider
            />
          ))}
        {otherFestivals?.length > 0 && (
          <MoviesNFestsNew
            section={3}
            moviesArray={otherFestivals}
            subCategory={t('Other Festivals')}
            category={''}
            movieCount={otherFestivals?.length}
            slider
            type="mini_festival"
          />
        )}

        {false && !!miniFestivalsLoading && (
          <section className="row">
            <div className="col-12 text-center">
              <Spinner animation="border" role="status" className="mx-auto" />
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(NewMiniFestivals);
