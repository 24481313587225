import React, { useEffect, useState, useRef, useContext } from 'react';
import poster from '@assets/pictures/poster.png';
import MovieCard from './MovieCard.jsx';
import Spinner from './Spinner';
import { motion } from 'framer-motion';
import { withNamespaces } from 'react-i18next';

const MovieSection = (props) => {
  const { moviesObject } = props;
  const t = props.t;
  const [loader, setLoader] = useState(false);
  return (
    <div
      className={`row movie-section ${props.notOnMobile ? 'd-none d-md-flex' : ''}`}
    >
      <motion.div
        className="col-12 title-row"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        {props.heading && <span className="name">{props?.heading}</span>}
        {props?.totalMovies && (
          <span className="movies-length">
            {props?.totalMovies} {props?.totalMovies > 1 ? t('Movies') : t('Movie')}
          </span>
        )}
      </motion.div>
      {props?.moviesArray?.map((movie, index) => (
        <MovieCard {...movie} key={index} type={props.type} index={index} />

        // <div className="col-6 col-md-4 col-lg-3 col-xl-2 movie-card">
        //   <div>
        //     <div className="img-card">
        //       <img
        //         src={props.movieSrc}
        //         className="img-fluid"
        //         alt="Movie Poster"
        //       />
        //     </div>
        //     <div className="">
        //       <div className="d-flex">
        //         <span className="movie-name">{props.movieName}</span>
        //         <span className="cinema-name">{props.cinemaName}</span>
        //       </div>
        //       <div className="d-flex">
        //         <span className="genre-n-rating">
        //           {props.genre}, {props.rating}
        //         </span>
        //         <span className="experiences">{props.experiences}</span>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      ))}

      {props?.lastPage > props?.currentPage && (
        <div className="col-12 text-center">
          {/* {loader ? ( */}
          {/* // <div className="row justify-content-center w-100">
            // <Spinner />
          // ) : (
            // </div> */}
          <motion.button
            className={`btn-main mx-auto ${loader ? 'loader-text' : ''}`}
            id="ms-btn"
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.25 }}
            onClick={() => {
              setLoader(true);
              props.loadMore({ callback: setLoader });
            }}
          >
            {t('common.Load More')}
          </motion.button>
          {/* )} */}
        </div>
      )}
    </div>
  );
};

export default withNamespaces()(MovieSection);
