import React, { useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Images
// import { ReactComponent as StandardSeat } from "@assets/pictures/svgs/seat-standard.svg";
// import { ReactComponent as SelectedSeat} from "@assets/pictures/svgs/seat-standard-selected.svg";
// import { ReactComponent as UnavailableSeat } from "@assets/pictures/svgs/seat-standard-unavailable.svg";
// import { ReactComponent as Wheelchair } from "@assets/pictures/svgs/seat-wheel-chair.svg";
// import { ReactComponent as SelectedWheelChair} from "@assets/pictures/svgs/seat-wheel-chair-selected.svg";
// import { ReactComponent as UnavailableWheelChair} from "@assets/pictures/svgs/seat-wheel-chair-unavailable.svg";
// import {ReactComponent as LoungerSeat} from "@assets/pictures/svgs/seat-lounger.svg";
// import {ReactComponent as SelectedLoungerSeat} from "@assets/pictures/svgs/seat-lounger-selected.svg";
// import {ReactComponent as UnavailableLoungerSeat} from "@assets/pictures/svgs/seat-lounger-unavailable.svg";
import { ReactComponent as Seat } from '@assets/pictures/svgs/seat_new.svg';
import ReservedIcon from '@assets/pictures/seats_icon/Reserved.png';
import UnAvailableIcon from '@assets/pictures/seats_icon/unavailable.png';

const SeatCell = (props) => {
  const {
    t,
    classes,
    char,
    disabled,
    onClick,
    isPrivate,
    isSeatSelected = false,
    normalIcon,
    selectedIcon,
    disabledIcon,
    isSold,
    isReserved,
  } = props;
  const seatCellRef = useRef();

  React.useEffect(() => {}, [isSeatSelected, disabled]);

  let Iconsrc = normalIcon;
  if (isSold) {
    Iconsrc = disabledIcon;
  }
  if (isReserved) {
    Iconsrc = UnAvailableIcon;
  }
  if (disabled) {
    Iconsrc = disabledIcon;
  }
  if (isSeatSelected) {
    Iconsrc = selectedIcon;
  }

  return (
    <div
      className={`seat_cell ${classes} ${
        disabled ? 'disabled' : isSeatSelected ? 'selected' : ''
      }`}
      onClick={() => {
        if (isPrivate) return;

        onClick(seatCellRef);
      }}
      ref={seatCellRef}
    >
      {char ? (
        <span>{char}</span>
      ) : (
        <img
          style={{
            cursor: disabled ? undefined : 'pointer',
          }}
          src={Iconsrc}
          width="24px"
          height="24px"
        />
      )}
    </div>
  );
};

// Default Props
SeatCell.defaultProps = {
  char: false,
  disabled: false,
  onClick: () => {},
  classes: '',
};

export default withNamespaces()(SeatCell);
