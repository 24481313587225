import React from 'react';

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
  return (
    <>
      <label class="container_checkbox">
        {name}
        <input
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
          className=""
        />
        <span class="checkmark"></span>
      </label>
    </>
  );
};

export default Checkbox;
