import React from 'react';
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
  Button,
} from 'react-bootstrap';

import redCross from '@assets/pictures/svgs/red-cross.svg';
import currencyIcon from '@assets/pictures/svgs/currency-circle-icon.svg';
import blackCircle from '@assets/pictures/svgs/black-circle.svg';

export default function CustomerCard(props) {
  return (
    <div className="customer-card">
      <div className="header">
        <h5 className="font-weight-bold mb-0">Points History</h5>
      </div>
      <div className="middle">
        <div>
          <label>Points type</label>
          <Dropdown className="user-custom-dropdown">
            <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
              Select
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="dropdownItems">
              <Dropdown.Item className="selected">Earned</Dropdown.Item>
              <Dropdown.Item>Spent</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <label>Date</label>
          <Dropdown className="user-custom-dropdown">
            <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
              Select
            </Dropdown.Toggle>
            <Dropdown.Menu align="right" className="dropdownItems">
              <Dropdown.Item className="selected">Earned</Dropdown.Item>
              <Dropdown.Item>Spent</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="content">
        <div className="top">
          <h5 className="font-weight-bold">Available balance</h5>
          <h1>68</h1>
        </div>
        <div className="bottom">
          <p className="text-grey">Thu, January 27th</p>
          <div className="box">
            <div className="desc d-flex align-items-center">
              <div className="icon plus-icon">
                <img src={blackCircle} />
                <h1>+</h1>
              </div>
              <div className="text">
                <p className="pl-4">
                  Bought a ticket: Forgotten Christmas. Ticket ID: 877387988377.
                  Booking Date & Time: September 04, 2021 | 12:30 PM
                </p>
              </div>
            </div>
            <div className="points">
              <h3 className="text-success font-weight-bold">+6</h3>
            </div>
          </div>
          <div className="box">
            <div className="desc d-flex align-items-center">
              <div className="icon minus-icon">
                <img src={blackCircle} />
                <h1>-</h1>
              </div>
              <div className="text">
                <p className="pl-4">
                  Bought a ticket: Forgotten Christmas. Ticket ID: 877387988377.
                  Booking Date & Time: September 04, 2021 | 12:30 PM
                </p>
              </div>
            </div>
            <div className="points">
              <h3 className="text-primary font-weight-bold">-2</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
