import React from 'react';
import { motion } from 'framer-motion';

export default function AuditoriumCard({
  sideIcon,
  feature,
  clickFunc,
  audi: { screen_name, screen_no_of_seats, screen_image_url, cinema_id },
}) {
  return (
    <motion.div
      className="col-6 col-sm-4"
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
    >
      <div className="cinema-card" onClick={() => clickFunc && clickFunc(true)}>
        <div className="img-wrapper">
          <img src={screen_image_url} alt={screen_name} />
        </div>
        <div className="">
          <div className="detail-row">
            <p className="cinema-name">{screen_name}</p>
            <p className="audi-seats">
              {screen_no_of_seats}
              <img src={sideIcon} className="ml-2" alt="" />
            </p>
          </div>
          <div className="detail-row">
            {/* <p className="cinema-address">{screen_no_of_seats}</p> */}
            {feature && <p className="cinema-feature ml-2 ml-sm-3">{feature}</p>}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
