import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const TnCNewContainer = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(2);
      const { data } = response;
      if (data?.status) {
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let tnc_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });
  return (
    <>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Terms')} & ${t('Conditions')}`}
          />
          <CenteredTitle
            firstHalf={`${t('Terms')} & ${t('Conditions')}`}
            secondHalf={''}
          />
        </div>
        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: tnc_data?.toString(),
              }}
            />
          </article>
          {false && (
            <article className="col-lg-10 col-xl-12 tnc_back">
              <div>
                <p>
                  <span className="tnc_new_text_highlighted">
                    {t('Terms_Conditions_QFX_Cinemas')}
                  </span>{' '}
                </p>
              </div>

              <p className="tnc_new_text_highlighted mb-2">{t('Para_1_tnc')}</p>
              <div>
                <ul>
                  <li>{t('tnc_p1')}</li>
                  <li>{t('tnc_p4')}</li>
                  <li>{t('tnc_p5')}</li>
                  <li>{t('tnc_p6')}</li>
                  <li>{t('tnc_p7')}</li>
                  <li>{t('tnc_p8')}</li>
                  <li>{t('tnc_p9')}</li>
                  <li>{t('tnc_p11')}</li>
                  <li>{t('tnc_p12')}</li>
                  <li>{t('tnc_p13')}</li>
                  <li>{t('tnc_p14')}</li>
                  <li>
                    {t(
                      'In case of reservations, ticket get deactivated an hour prior to the show, customers need to collect their tickets an hour prior to the show from the Box Office.',
                    )}
                  </li>
                  <li>{t('tnc_p3')}</li>
                  <li>{t('tnc_p15')}</li>
                  <li>{t('tnc_p16')}</li>

                  <li>{t('tnc_p10')}</li>
                  {/* <li>{t('tnc_p2')}</li> */}

                  <li>{t('tnc_p17')}</li>
                </ul>
              </div>

              {/* <p className="tnc_new_text_highlighted mb-2">
              {t('Terms_and_Conditions_Goji_Loyalty')}
            </p>
            <div>
              <ul>
                <li>{t('goji_p1')}</li>
                <li>{t('goji_p2')}</li>
                <li>{t('goji_p3')}</li>
                <li>{t('goji_p4')}</li>
                <li>{t('goji_p5')}</li>
                <li>{t('goji_p6')}</li>
                <li>{t('goji_p7')}</li>
                <li>{t('goji_p8')}</li>
                <li>{t('goji_p9')}</li>
                <li>{t('goji_p10')}</li>
                <li>{t('goji_p11')}</li>
                <li>{t('goji_p12')}</li>
              </ul>
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('Points_Accumulation')}
            </p>
            <div>
              <ul>
                <li>{t('pa_1')}</li>
                <li>{t('pa_2')}</li>
                <li>{t('pa_3')}</li>
                <li>{t('pa_4')}</li>
              </ul>
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('Goji_Coins_Accumulation')}
            </p>
            <div>
              <ul>
                <li>{t('gca_1')}</li>
                <li>{t('gca_2')}</li>
                <li>{t('gca_3')}</li>
                <li>{t('gca_4')}</li>
                <li>{t('gca_5')}</li>
              </ul>
            </div>

            <p className="tnc_new_text_highlighted mb-2">
              {t('Redemption_of_Goji_CoinsPoints')}
            </p>
            <div>
              <ul>
                <li>{t('rgc_1')}</li>
                <li>{t('rgc_2')}</li>
                <li>{t('rgc_3')}</li>
                <li>{t('rgc_4')}</li>
                <li>{t('rgc_5')}</li>
                <li>{t('rgc_6')}</li>
                <li>{t('rgc_7')}</li>
              </ul>
            </div> */}

              {/* <p className="text-primary">{t('ArtMediaProductionGmbH')}</p> */}
            </article>
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
