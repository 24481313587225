import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const TnCNewContainer = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const MspagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await GenericService.GetMsPage(3);
      const { data } = response;
      if (data?.status) {
        setcms_pages_data(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(MspagesDataFunc());
  }, []);

  let pp_data = cms_pages_data?.map((x) => {
    return x.page_content;
  });
  return (
    <>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Privacy Policy')}`}
          />
          <CenteredTitle firstHalf={`${t('Privacy Policy')}`} secondHalf={''} />
        </div>
        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: pp_data?.toString(),
              }}
            />
          </article>
          {false && (
            <article className="col-lg-10 col-xl-12 tnc_back">
              <p className="tnc_new_text_highlighted mb-2">{t('Privacy_name')}</p>
              <div>{t('Privay_p1')}</div>

              <div>{t('Privay_p2')}</div>

              <div>{t('Privay_p3')}</div>
              <p className="tnc_new_text_highlighted mb-2">{t('Info_heading')}</p>
              <div>{t('Privay_p4')}</div>
              <p className="tnc_new_text_highlighted mb-2">
                {t('how_info_heading')}
              </p>
              <div>{t('Privay_p5')}</div>

              <p className="tnc_new_text_highlighted mb-2">
                {t('How is the Collected Information Shared')}
              </p>
              <p className="tnc_new_text_highlighted mb-2">
                {t('a)')}
                {t(' ')}
                {t('Sharing Information')}
              </p>
              <div>
                We disclose your information for certain purposes and to third
                parties, as described below: QFX Cinema : We share your information
                among the QFX family of companies as needed for: data processing and
                storage; providing you with access to our services; providing
                customer support; making decisions about service improvements,
                content development; and for other purposes described in the Use of
                Information section of this Privacy Policy.
              </div>

              <div>
                In certain circumstances, the Privacy Act, 2018 and other prevailing
                laws of Nepal allows personal data to be disclosed to public
                authorities without the consent of the data subject. Under these
                circumstances, this Company will disclose requested data. However,
                we will ensure the request is legitimate, seeking assistance from
                the Board and from the Company’s legal advisers where necessary.
              </div>

              <p className="tnc_new_text_highlighted mb-2">
                {t('b)')}
                {t(' ')}
                {t('Manner of Sharing Information')}
              </p>
              <div>
                Likewise, we provide collected information to others only after
                obtaining due consent from concerned individual. We obtain
                additional consent in the event we must provide additional
                information, not permitted by previous consent, to other third
                parties.
              </div>

              <div>
                We execute a Non-disclosure Agreement with third parties with whom
                we share information to require them to keep the provided
                information confidential from unauthorized persons. We share
                information to third parties using secured servers.
              </div>

              <div>
                Whenever in the course of sharing information we transfer personal
                information to countries outside of the countries of operations and
                other regions with comprehensive data protection laws, we will
                ensure that the information is transferred in accordance with this
                Privacy Statement and as permitted by the applicable laws on data
                protection
              </div>

              <div>{t('Privay_p6')}</div>
              <p className="tnc_new_text_highlighted mb-2">{t('If_you_connect')}</p>
              <div>{t('Privay_p7')}</div>
              <p className="tnc_new_text_highlighted mb-2">
                {t('Changes_to_Privacy_Policy')}
              </p>
              <div>{t('Privay_p8')}</div>

              <div>{t('Privay_p9')}</div>

              {/* <p className="tnc_new_text_highlighted mb-2">Kino Stüssihof</p>
            <p className="tnc_new_text_highlighted mb-2">Stüssihofstatt 13</p>
            <p className="tnc_new_text_highlighted mb-2">CH-8001 Zürich</p> */}
            </article>
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
