import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../partials/Spinner';
import { addTopUpToCashCard } from '../../../store/cashCard/cashCardActions';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  onSuccessStripeToken,
  onSuccessStripeTokenBrij,
  onSuccessTopupSaferPay,
  onSuccessTopupStripeToken,
} from '../../../store/movies/movieActions';

const TopupStepTwo = (props) => {
  const { t, classes = '', onClickNext = () => {} } = props;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  let isVoucherEnable = false;
  let topupError = '';

  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [stripeError, setStripeError] = useState(null);

  // let [submitted, setSubmitted] = useState(false)
  let [loading, setLoading] = useState(false);
  const [showStripe, setShowStripe] = useState(false);

  const [SaferPayloading, setSaferPayLoading] = useState(false);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, guestUser } = loginDetails;

  const isSaferPayActive = useSelector((state) => state.movies.isSaferPayActive);
  const isSaferPayButtonDisable = useSelector(
    (state) => state.movies.isSaferPayButtonDisable,
  );
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector(
    (state) => state.cashCard.cashCardDetailsLoading,
  );
  const cashCardTopUpSelection = useSelector(
    (state) => state.cashCard.cashCardTopUpSelection,
  );
  const activePaymentModes = useSelector(
    (state) => state.movies.activePaymentModes,
  );

  let isStripePaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 14)?.length > 0 ?? false;

  let isUnpaidBookingEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 17)?.length > 0 ?? false;

  let ActivePaymentModeAvailable =
    activePaymentModes?.filter((p) => p?.pm_id == 15 || p?.pm_id == 14)?.length >
      0 ?? false;

  let isPayBtnClick = useRef(false);
  const handleStripeSubmit = async (event) => {
    event.preventDefault();
    if (isPayBtnClick.current === true) return;

    isPayBtnClick.current = true;
    setLoading(true);
    const response = await stripe.createToken(elements.getElement(CardElement), {
      currency: curr_code,
      account_holder_name: loggedInUser?.first_name,
      account_holder_type: 'individual',
    });
    console.log('response :>> ', { response });

    if (response.token && !response.token.used) {
      setStripeError(null);
      let balance_amount = firstCashCard?.balance_amount || 0;
      let cashCardTopUpSelectionAmount = cashCardTopUpSelection?.amount || 0;
      let payload = {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        cash_card_id: firstCashCard?.cash_card_id,
        type: '1',
        updateFlag: '1',
        isNewCashCard: false,
        isStreetSale: firstCashCard?.is_street_sale,
        amount: cashCardTopUpSelection?.amount || 0,
        payment_mode_id: 14,
        remaining_balance: +balance_amount + +cashCardTopUpSelectionAmount,
        reason: 'Added Cash from Website',
      };
      // call backend api here

      dispatch(
        onSuccessTopupStripeToken({
          ...payload,
          token: response.token,
          cust_id: loggedInUser?.cust_id,
          email: firstCashCard?.cust_email, // email
          setLoading,
          onClickNext,
        }),
      );
      // .finally(() => { setLoading(false) });
    } else {
      isPayBtnClick.current = false;
      setStripeError(response.error.message);
      return;
    }
  };

  const MakeSaferPayPayment = () => {
    let balance_amount = firstCashCard?.balance_amount || 0;
    let cashCardTopUpSelectionAmount = cashCardTopUpSelection?.amount || 0;

    setSaferPayLoading(true);
    let payload = {
      cash_card_number: firstCashCard?.cash_card_number,
      customer_email: firstCashCard?.cust_email,
      cash_card_id: firstCashCard?.cash_card_id,
      type: '1',
      updateFlag: '1',
      isNewCashCard: false,
      isStreetSale: firstCashCard?.is_street_sale,
      amount: cashCardTopUpSelection?.amount || 0,
      payment_mode_id: 15,
      remaining_balance: +balance_amount + +cashCardTopUpSelectionAmount,
      reason: 'Added Cash from Website',
      callback: setSaferPayLoading,
      secondCallback: onClickNext,
    };
    // dispatch safer pay topup
    dispatch(onSuccessTopupSaferPay(payload));
    setShowStripe(false);
  };

  const [firstCashCard, setFirstCashCard] = useState({});
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length)
      setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);

  useEffect(() => {
    if (loggedInUser) {
      setEmail(loggedInUser?.email || loggedInUser?.cust_email);
      setMobile(loggedInUser?.phone_no || loggedInUser?.cust_mobile);
    } else if (guestUser) {
      setEmail(guestUser.guest_email);
      if (!!guestUser.guest_mobile) setMobile(guestUser.guest_mobile);
      // if (!!guestUser.guest_first_name) setGuestFirstName(guestUser.guest_first_name);
      // if (!!guestUser.guest_last_name) setGuestLastName(guestUser.guest_last_name);
    }
  }, [loggedInUser, guestUser]);

  /*const directTopUp = () => {
    setLoading(true);
    let balance_amount = firstCashCard?.balance_amount || 0
    let cashCardTopUpSelectionAmount = cashCardTopUpSelection?.amount || 0

    dispatch(addTopUpToCashCard({
      cash_card_number: firstCashCard?.cash_card_number,
      customer_email: firstCashCard?.cust_email,
      cash_card_id: firstCashCard?.cash_card_id,
      type: "1",
      updateFlag: "1",
      isNewCashCard: false,
      isStreetSale: firstCashCard?.is_street_sale,
      amount: cashCardTopUpSelection?.amount || 0,
      payment_mode_id: 1,
      remaining_balance: (+balance_amount) + (+cashCardTopUpSelectionAmount),
    }))
    .then(() => {
    })
    .finally(() => {
      setLoading(false);
    });
  };*/

  if (cashCardDetailsLoading)
    return (
      <div className="row">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  return (
    <>
      <div
        className={`gift_card_topup_step_two ${classes} 
promo-code-input`}
      >
        <section className="row">
          <article className="col-sm-8 col-lg-6 col-xl-6 mx-auto">
            {isVoucherEnable && (
              <>
                <label>{t('Do you have a voucher or promo code?')}</label>
                <div className="promo_code_wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Apply your promo code here')}
                  />
                  <button className="btn-main guest_btn">{t('Apply')}</button>
                </div>
              </>
            )}

            <article className="payment-methods">
              {!ActivePaymentModeAvailable && (
                <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-2 pb-3 d-flex">
                  <p className="error stripe-error w-100 fs-16">
                    {t('No Active Payment Modes Found')}
                  </p>
                </div>
              )}

              {false && (
                <button
                  className={`btn-main w-100 mt-4 ${
                    loading && 'loader-text inactive'
                  }`}
                  onClick={() => {
                    // if (loading) return;
                    // directTopUp();
                    onClickNext();
                  }}
                >
                  {t('PAY NOW')}
                </button>
              )}
              {isStripePaymentModeEnabled && (
                <button
                  className={`btn-main`}
                  onClick={() => setShowStripe(!showStripe)}
                >
                  {t('Stripe')}
                </button>
              )}
              {isSaferPayActive && (
                <button
                  className={`btn-main ${
                    SaferPayloading && 'loader-text inactive'
                  }`}
                  disabled={isSaferPayButtonDisable}
                  onClick={MakeSaferPayPayment}
                >
                  {t('Safer Pay')}
                </button>
              )}
            </article>

            {showStripe && isStripePaymentModeEnabled && (
              <div className="px-0 mx-auto mt-4 pb-3">
                {topupError && <p className="error">{topupError}</p>}
                <form onSubmit={handleStripeSubmit}>
                  {stripeKey && (
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: 'white',
                            '::placeholder': {
                              color: '#eeeeee',
                            },
                            padding: '10px',
                            border: '1px solid white',
                          },
                          invalid: {
                            color: '#dc3545',
                          },
                        },
                      }}
                    />
                  )}
                  {stripeError && (
                    <p className="error stripe-error">{t(stripeError)}</p>
                  )}
                  <button
                    className={`btn-main mx-auto mt-3 ${
                      loading && 'loader-text inactive'
                    }`}
                    disabled={!stripe}
                  >
                    {t('Pay')}
                  </button>
                </form>
              </div>
            )}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TopupStepTwo);
