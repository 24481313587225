import React, { useState } from 'react';

import Slider from 'react-slick';

import { motion } from 'framer-motion';
export default function AncilliaryHeroSection(props) {
  const heroSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...heroSlider} className="">
      {props?.sectionObj?.arr?.map((banner, index) => (
        <div key={index}>
          <section className="cinema-hero-section">
            <div className="hero-img-section">
              <img
                src={props.sectionObj.backgroundImg}
                alt="Movie Banner"
                className="hero-img-1"
              />
            </div>
            <div className="hero-text justify-content-center">
              <motion.div
                className="container-fluid container-xl"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <div className="row h-100 align-items-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto text-center">
                    <p className="title">{props.sectionObj.title}</p>
                    <p className="msg">{props.sectionObj.msg}</p>
                    {/* <div className="d-flex justify-content-center">
                      <img src={whiteArrowDown} className="down-arrow" alt="" />
                    </div> */}
                  </div>
                </div>
              </motion.div>
            </div>
          </section>
        </div>
      ))}
    </Slider>
  );
}
